import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import PagesHeader from "components/themes/Header/PagesHeader.jsx";
import Footer from "components/themes/Footer/Footer.jsx";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import FirstTimeLogin from "./FirstTimeLogin";

class FirstTimeLoginPage extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "unset";
  }
  render() {
    const { classes, ...rest } = this.props;    
    // Render
    return (
      <React.Fragment>
        <PagesHeader routes={[]} {...rest} />
        <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPage} >          
            <FirstTimeLogin /> 
            <Footer white />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FirstTimeLoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(FirstTimeLoginPage);