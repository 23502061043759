import {put} from 'redux-saga/effects';
import {auth, authAxios} from 'shared/firebase';
import {getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions/index';
import locale from "locale/index";
import randomstring from 'randomstring';
import querystring from 'querystring';

export function* userRetrieve(action) {
  yield put(actions.userRetrieveInit());
  try{
    let user = null;
    const res = yield apis.get(`/users/${action.email}`, yield getAuthHeader());   
    if (res.status === 200) {
      if (res.data.response.length > 0) {
        user = {...res.data.response[0]};
        yield put(actions.userRetrieveSuccess(user));
        yield put(actions.notificationShow(locale.notification_user_welcome, {name: user.firstName + " " + user.lastName}));  
      } else {
        // What to do here?
        yield put(actions.userRetrieveFail({message: "User data not found. Please contact your administrator"}));
      }      
    } else {
      yield put(actions.userRetrieveFail(res.data.error));
    }
    return user;
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.userRetrieveFail(error));
  };
}  

export function* userRetrieveAll(action) {
  yield put(actions.userRetrieveAllInit());
  try{           
    const res = action.role === 'admin' 
      ? yield apis.get(`/admins`, yield getAuthHeader())
      : yield apis.get(`/accounts/${action.accountId}/users?${querystring.stringify({role: action.role})}`, yield getAuthHeader());     
    if (res.status === 200) {
      const users = res.data.response.slice();      
      yield put(actions.userRetrieveAllSuccess(users)); 
    } else {
      yield put(actions.userRetrieveAllFail(res.data.error)); 
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.userRetrieveAllFail(error));
  };
}  

export function* userAdd(action) {  
  yield put(actions.userPostInit());
  try {     
    // Check if user's email exists, it could have been end-dt
    const res = yield apis.get(`/users/${action.user.email}?includeExpiry=true`, yield getAuthHeader());   
    if (res.status === 200) {
      const isReactivate = res.data.response.length !== 0
      if (!isReactivate) {
        yield userCreate(action);
      } else {
        yield userReactivate(action, res.data.response[0]);
      }
    } else {
      yield put(actions.userPostFail(res.data.error));      
    }    
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.userPostFail(error));
  };
}  

export function* userConvertGuest(action) {  
  yield put(actions.userConvertGuestInit());
  try {     
    const {...user} = action.user; 
    // Check if user has been a voter in another account
    let isRegistered = false;    
    for(var key in user.accounts) {
      if (user.accounts[key] !== "guest") {
        isRegistered = true;
        break;
      }
    }
    // Create authentication
    if (!isRegistered) {
      yield userCreateAuth(user); 
    }
    // Update DB
    user.accounts[action.currentAccount.key] = "voter";
    user.currentAccount = action.currentAccount.key;
    user.role = "voter";
    const res = yield apis.put(`/users/${user.key}`, user, yield getAuthHeader());     
    if (res.status === 200) {
      // User Post tasks
      yield userPostTasks(action, user);
      // Post success
      yield put(actions.userConvertGuestSuccess(user));
      // Notification
      yield put(actions.notificationShow(locale.notification_user_updated, {name: user.firstName + " " + user.lastName}));
    } else {
      yield put(actions.userConvertGuestFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.userConvertGuestFail(error));
  };
}  

function* userCreateAuth(user) {
  // Create Auth User, TODO: THIS LOG IN AS THE USER, So use Axios for now, Admin SDK require NodeJs server
  // https://stackoverflow.com/questions/44582508/firebase-admin-importing-it-to-react-application-throws-module-not-found-error
  // yield rsf.auth.createUserWithEmailAndPassword(user.email, randomstring.generate(12));      
  console.log("Create authentication account for: " + user.email);
  try {
    const header = {email: user.email, password: randomstring.generate(12), returnSecureToken: false};
    yield authAxios.post("/signupNewUser", header);  
  } catch (error) {
    // Ignore error if EMAIL_EXISTS (this kinda bad, need revisiting)
    if (error.response.data.error.message === "EMAIL_EXISTS") {
      console.log(`EMAIL_EXISTS: ${user.email}. Ignored.`);
    } else {
      throw error;
    }
  } 
}

function* userCreate(action) {  
  var user = { ...action.user };
  yield userCreateAuth(user);

  // Update DB
  const res = yield apis.post("/users", user, yield getAuthHeader());    
  if (res.status === 200) { 
    // Update key
    user.key = Number(res.data.response.insertId);
    // User Post tasks
    yield userPostTasks(action, user);
    // Post success
    yield put(actions.userPostSuccess(user));
    // Notification
    yield put(actions.notificationShow(locale.notification_user_added, {name: user.firstName + " " + user.lastName}));
  } else {
    yield put(actions.userPostFail(res.data.error));
  } 
} 

function* userReactivate(action, existing) {  
  var user = { ...action.user };
  // Reactivate User - This will not work if existing is Admin and readded at voter/staff, be careful
  const res = user.isAdmin 
    ? yield apis.put(`/users/${existing.key}`, {expiry: 'NULL'}, yield getAuthHeader())
    : yield apis.put(`/users/${existing.key}/accounts/${user.currentAccount}`, {expiry: 'NULL'}, yield getAuthHeader());  
  if (res.status === 200) {    
    // Update key
    user.key = existing.key;
    // User Post tasks
    yield userPostTasks(action, user);
    // Post success
    yield put(actions.userPostSuccess(user));
    // Notification
    yield put(actions.notificationShow(locale.notification_user_reactivated, {name: user.firstName + " " + user.lastName}));
  } else {
    yield put(actions.userPostFail(res.data.error));
  } 
} 

function* userPostTasks(action, user) {
  // Send Email Link
  if (action.sendEmail) {
    yield auth.sendSignInLinkToEmail(user.email, {
      url: `${locale.app_url}/${user.isAdmin ? 'admin' : 'voter'}/changepw`,
      handleCodeInApp: true 
    });  
    user.sendEmail = true
  }
  // If addToGroups provide add them now      
  if (action.addToGroups) {
    for (let i = 0; i < action.addToGroups.length; i++) {
      const group = action.addToGroups[i];
      group.users.push(user.key);
      yield put(actions.userGroupUpdate(group));
    }        
  }
}

export function* userUpdate(action) {
  yield put(actions.userPostInit());
  try{            
    const {...user} = action.user; 
    // Update DB
    const res = yield apis.put(`/users/${user.key}`, user, yield getAuthHeader());     
    if (res.status === 200) {
      // Post success
      yield put(actions.userPostSuccess(user));
      // Notification
      yield put(actions.notificationShow(locale.notification_user_updated, {name: user.firstName + " " + user.lastName}));
    } else {
      yield put(actions.userPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.userPostFail(error));
  };
}  

export function* userDelete(action) {
  yield put(actions.userPostInit());
  try{
    const user = action.user;
    // Update DB - If admin end-dt user, otherwise end-dt user access to the account
    const res = user.isAdmin 
      ? yield apis.delete(`/users/${user.key}`, yield getAuthHeader())
      : yield apis.delete(`/users/${user.key}/accounts/${action.accountId}`, yield getAuthHeader());     
    if (res.status === 200) {
      // Post success
      yield put(actions.userPostSuccess(user, true));
      // Notification
      yield put(actions.notificationShow(locale.notification_user_updated, {name: user.firstName + " " + user.lastName}));
    } else {
      yield put(actions.userPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    console.error("Error delete user: ", error);
    yield put(actions.userPostFail(error));
  };
}  

export function* userSendEmailLink(action) {
  yield put(actions.userSendEmailLinkInit());
  try {      
    const {...user} = action.user; 
    // Create Auth User
    yield auth.sendSignInLinkToEmail(user.email, {
      url: locale.app_url,
      handleCodeInApp: true 
    });  
    // Update DB
    const res = yield apis.put(`/users/${user.key}`, {sendEmail: true}, yield getAuthHeader());    
    if (res.status === 200) {
      // Post Success
      yield put(actions.userSendEmailLinkSuccess({...user, sendEmail: true}));
      // Notification
      yield put(actions.notificationShow(locale.notification_auth_send_email_link, {email: user.email}));
    } else {
      yield put(actions.userSendEmailLinkFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.userSendEmailLinkFail(error));
  };  
}


