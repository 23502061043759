import {put} from 'redux-saga/effects';
import {getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions';
import locale from "locale/index";

export function* reportRetrieve(action) {
  yield put(actions.reportRetrieveInit());
  try{    
    const res = yield apis.get(`reports/${action.reportId}`, yield getAuthHeader());
    if (res.status === 200) {     
      const report = {...res.data.response[0]};
      // Retrieve Field
      yield put(actions.reportRetrieveField(report.key));
      // Post Success
      yield put(actions.reportRetrieveSuccess(report));     
    } else {
      yield put(actions.reportRetrieveFail({message: locale.error_report_cannot_find_report, values: {id: action.reportId}}));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.reportRetrieveFail(error));
  };
}  

export function* reportRetrieveField(action) {
  yield put(actions.reportRetrieveFieldInit());
  try{    
    const res = yield apis.get(`reports/${action.reportId}/reportFields`, yield getAuthHeader());
    if (res.status === 200) {
      const fields = {};
      res.data.response.forEach(entry => {
        fields[entry.field] = entry.value;
      })      
      yield put(actions.reportRetrieveFieldSuccess(fields));
    } else {
      yield put(actions.reportRetrieveFieldFail(res.data.error));
    }     
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.reportRetrieveFieldFail(error));
  };
}  

export function* reportRetrieveAll(action) {
  yield put(actions.reportRetrieveAllInit());
  try{    
    const res = yield apis.get(`portfolios/${action.portfolioId}/reports`, yield getAuthHeader());       
    if (res.status === 200) { 
      const reports = {};
      res.data.response.forEach(entry => {
        reports[entry.key] = {...entry};
      })
      yield put(actions.reportRetrieveAllSuccess(reports)); 
    } else {
      yield put(actions.reportRetrieveAllFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.reportRetrieveAllFail(error));
  };
}  

export function* reportAdd(action) {  
  yield put(actions.reportPostInit());
  try {
    const entry = {...action.entry};    
    // Update DB
    const res = yield apis.post(`/reports`, entry, yield getAuthHeader()); 
    if (res.status === 200) {    
      // Update key
      entry.key = Number(res.data.response.insertId);
      // Post success
      yield put(actions.reportPostSuccess({...entry}));
      // Notification
      yield put(actions.notificationShow(locale.notification_report_added, {name: entry.name}));
    } else {
      yield put(actions.reportPostFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.reportPostFail(error));
  };
}  

export function* reportUpdate(action) {
  yield put(actions.reportPostInit());
  try{    
    const {...entry} = action.entry; 
    // Update DB
    const res = yield apis.put(`/reports/${entry.key}`, entry, yield getAuthHeader());  
    if (res.status === 200) {
      // Post success
      yield put(actions.reportPostSuccess({...entry}));
      // Notification
      yield put(actions.notificationShow(locale.notification_report_updated, {name: entry.name}));
    } else {
      yield put(actions.reportPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.reportPostFail(error));
  };
}  

export function* reportDelete(action) {
  yield put(actions.reportPostInit());
  try{    
    const {...entry} = action.entry;     
    // Update DB
    const res = yield apis.delete(`/reports/${entry.key}`, yield getAuthHeader());   
    if (res.status === 200) { 
      // Post success
      yield put(actions.reportPostSuccess(entry, true));
      // Notification
      yield put(actions.notificationShow(locale.notification_report_deleted, {name: entry.name}));
    } else {
      yield put(actions.reportPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.reportPostFail(error));
  };
}


// Child ////////////////////////////////////////////////////////////////////////////////
export function* reportUpdateField(action) {
  yield put(actions.reportPostInit());
  try{    
    const {...entry} = action.entry; 
    const {...report} = action.report; 
    // Update DB
    const res = yield apis.post(`/reports/${report.key}/reportFields`, entry, yield getAuthHeader()); 
    if (res.status === 200) {
      // Update state
      report[entry.field] = entry.value;
      // Post success
      yield put(actions.reportPostSuccess(report));
      // Notification
      yield put(actions.notificationShow(locale.notification_report_updated, {name: report.name}));
    } else {
      yield put(actions.reportPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.reportPostFail(error));
  };
}  

export function* reportAddScenario(action) {
  yield put(actions.reportPostInit());
  try{    
    const {...report} = action.report; 
    // Update DB
    const res = yield apis.post(`/reports/${report.key}/scenarios`, [action.scenarioId], yield getAuthHeader());
    if (res.status === 200) {
      // Update state
      report.scenarios.push(action.scenarioId);
      report.scenarios.sort();
      // Post success
      yield put(actions.reportPostSuccess(report));
      // Notification
      yield put(actions.notificationShow(locale.notification_report_updated, {name: report.name}));
    } else {
      yield put(actions.reportPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.reportPostFail(error));
  };
}  

export function* reportRemoveScenario(action) {
  yield put(actions.reportPostInit());
  try{    
    const {...report} = action.report; 
    // Update DB
    const res = yield apis.delete(`/reports/${report.key}/scenarios/${action.scenarioId}`, yield getAuthHeader());  
    if (res.status === 200) {
      // Update state
      report.scenarios = report.scenarios.filter(key => key !== action.scenarioId);
      report.scenarios.sort();
      // Post success
      yield put(actions.reportPostSuccess(report));
      // Notification
      yield put(actions.notificationShow(locale.notification_report_updated, {name: report.name}));
    } else {
      yield put(actions.reportPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.reportPostFail(error));
  };
}  