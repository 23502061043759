import React from 'react';
import './Spinner.css';
import './SpinnerNegative.css';

const spinner = (props) => (
    props.isNegative 
    ? <div className="loaderNegative">Loading...</div>
    : <div className="loader">Loading...</div>
)

export default spinner;