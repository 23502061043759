import * as actionTypes from './actionTypes';

//PORTFOLIO_RETRIEVE////////////////////////////////////////
export const portfolioRetrieve = (id) => {  
  return {
    type: actionTypes.PORTFOLIO_RETRIEVE,
    id: id
  }
} // => saga/index.js

export const portfolioRetrieveInit = () => {  
  return {
    type: actionTypes.PORTFOLIO_RETRIEVE_INIT
  }
} 

export const portfolioRetrieveFail = (error) => {  
  return {
    type: actionTypes.PORTFOLIO_RETRIEVE_FAIL,
    error: error
  }
} 

export const portfolioRetrieveSuccess = (portfolio) => {  
  return {
    type: actionTypes.PORTFOLIO_RETRIEVE_SUCCESS,
    portfolio: portfolio ? portfolio : null
  }
} 

//PORTFOLIO_RETRIEVE_ALL////////////////////////////////////////
export const portfolioRetrieveAll = (accountId) => {  
  return {
    type: actionTypes.PORTFOLIO_RETRIEVE_ALL,
    accountId: accountId
  }
} // => saga/index.js

export const portfolioRetrieveAllInit = () => {  
  return {
    type: actionTypes.PORTFOLIO_RETRIEVE_ALL_INIT
  }
} 

export const portfolioRetrieveAllFail = (error) => {  
  return {
    type: actionTypes.PORTFOLIO_RETRIEVE_ALL_FAIL,
    error: error
  }
} 

export const portfolioRetrieveAllSuccess = (portfolios) => {  
  return {
    type: actionTypes.PORTFOLIO_RETRIEVE_ALL_SUCCESS,
    portfolios: portfolios
  }
} 

//PORTFOLIO_POST////////////////////////////////////////
export const portfolioAdd = (portfolio) => {  
  return {
    type: actionTypes.PORTFOLIO_ADD,
    portfolio: portfolio
  }
} //=> saga/index.js

export const portfolioUpdate = (portfolio) => {  
  return {
    type: actionTypes.PORTFOLIO_UPDATE,
    portfolio: portfolio,
  }
} // => saga/index.js

export const portfolioDelete = (portfolio) => {  
  return {
    type: actionTypes.PORTFOLIO_DELETE,
    portfolio: portfolio,
  }
} // => saga/index.js

export const portfolioPostInit = () => {  
  return {
    type: actionTypes.PORTFOLIO_POST_INIT
  }
} // => saga/index.js

export const portfolioPostFail = (error) => {  
  return {
    type: actionTypes.PORTFOLIO_POST_FAIL,
    error: error
  }
} 

export const portfolioPostSuccess = (portfolio, isDelete) => {  
  return {
    type: actionTypes.PORTFOLIO_POST_SUCCESS,
    portfolio: portfolio,
    isDelete: isDelete ? isDelete : false
  }
} 

//PORTFOLIO_CALCULATE////////////////////////////////////////
export const portfolioSetRecalculationRequest = () => {
  return {
    type: actionTypes.PORTFOLIO_SET_RECALCULATION_REQUEST
  }
}

export const portfolioCalculateImportance = (criteria, criteriaAnswers, idToken) => {
  return {
    type: actionTypes.PORTFOLIO_CALCULATE_IMPORTANCE,
    criteria: criteria, 
    answers: criteriaAnswers,
    idToken: idToken
  }
}

export const portfolioCalculateSensitivity = (portfolio, measurements, criteria, priorityVector, alternatives, alternativeAnswers, idToken) => {
  return {
    type: actionTypes.PORTFOLIO_CALCULATE_SENSITIVITY,
    portfolio: portfolio,
    measurements: measurements,
    criteria: criteria, 
    priorityVector: priorityVector, 
    alternatives: alternatives,
    answers: alternativeAnswers,
    idToken: idToken
  }
}

export const portfolioCalculateVROI = (portfolio, alternatives, sensitivityVectors, idToken) => {
  return {
    type: actionTypes.PORTFOLIO_CALCULATE_VROI,
    portfolio: portfolio,
    alternatives: alternatives, 
    sensitivityVectors: sensitivityVectors,
    idToken: idToken
  }
}

export const portfolioCalculateBudgetOptions = (portfolio, alternatives, alternativeRelationships, sensitivityVectors, idToken) => {
  return {
    type: actionTypes.PORTFOLIO_CALCULATE_BUDGET_OPTIONS,
    portfolio: portfolio,
    alternatives: alternatives, 
    alternativeRelationships: alternativeRelationships, 
    sensitivityVectors: sensitivityVectors,
    idToken: idToken
  }
}

export const portfolioRecalculate = (updates, tasks, isTweak) => {
  return {
    type: actionTypes.PORTFOLIO_RECALCULATE,
    updates: updates,
    tasks: tasks,
    isTweak : isTweak ? isTweak : false
  }
}

export const portfolioRecalculateInit = () => {
  return {
    type: actionTypes.PORTFOLIO_RECALCULATE_INIT,
  }
}

export const portfolioRecalculateFail = (error) => {
  return {
    type: actionTypes.PORTFOLIO_RECALCULATE_FAIL,
    error: error
  }
}

export const portfolioRecalculateSuccess = (updates) => {
  return {
    type: actionTypes.PORTFOLIO_RECALCULATE_SUCCESS,
    updates: updates
  }
}

export const portfolioSetSelectedOption = (selectedOption) => {
  return {
    type: actionTypes.PORTFOLIO_SET_SELECTED_OPTION,
    selectedOption: selectedOption,
  }
}

export const portfolioSetVoteCategory = (voteCategory) => {
  return {
    type: actionTypes.PORTFOLIO_SET_VOTE_CATEGORY,
    voteCategory: voteCategory,
  }
}