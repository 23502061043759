import * as actionTypes from './actionTypes';

//BUDGET_RETRIEVE_ALL////////////////////////////////////////
export const budgetRetrieveAll = (portfolio) => {    
    return {
        type: actionTypes.BUDGET_RETRIEVE_ALL,
        portfolioId: portfolio
    }
} // => saga/index.js

export const budgetRetrieveAllInit = () => {    
    return {
        type: actionTypes.BUDGET_RETRIEVE_ALL_INIT
    }
} 

export const budgetRetrieveAllFail = (error) => {    
    return {
        type: actionTypes.BUDGET_RETRIEVE_ALL_FAIL,
        error: error
    }
} 

export const budgetRetrieveAllSuccess = (entries) => {    
    return {
        type: actionTypes.BUDGET_RETRIEVE_ALL_SUCCESS,
        entries: entries
    }
} 

//BUDGET_POST////////////////////////////////////////
export const budgetAdd = (portfolioId, entry) => {    
    return {
        type: actionTypes.BUDGET_ADD,
        portfolioId: portfolioId,
        entry: entry
    }
} //=> saga/index.js

export const budgetUpdate = (portfolioId, entry) => {    
    return {
        type: actionTypes.BUDGET_UPDATE,
        portfolioId: portfolioId,
        entry: entry,
    }
} // => saga/index.js

export const budgetDelete = (portfolioId, entry) => {    
    return {
        type: actionTypes.BUDGET_DELETE,
        portfolioId: portfolioId,
        entry: entry,
    }
} // => saga/index.js

export const budgetPostInit = () => {    
    return {
        type: actionTypes.BUDGET_POST_INIT
    }
}

export const budgetPostFail = (error) => {    
    return {
        type: actionTypes.BUDGET_POST_FAIL,
        error: error
    }
} 

export const budgetPostSuccess = (entry, isDelete) => {    
    return {
        type: actionTypes.BUDGET_POST_SUCCESS,
        entry: entry,
        isDelete: isDelete ? isDelete : false
    }
} 

//BUDGET_PHASING_UPDATE///////////////////////////////
export const budgetSetPhasing = (key, period, value, oldValue) => {  
  return {
    type: actionTypes.BUDGET_UPDATE_PHASING,    
    key: key,
    period: period,
    value: value,
    oldValue: oldValue,
  }
} // => saga/index.js

export const budgetSetPhasingInit = () => {  
  return {
    type: actionTypes.BUDGET_UPDATE_PHASING_INIT
  }
}

export const budgetSetPhasingSuccess = (key, period, value) => {  
  return {
    type: actionTypes.BUDGET_UPDATE_PHASING_SUCCESS,    
    key: key,
    period: period,
    value: value
  }
}  

export const budgetSetPhasingFail = (error, key, period, oldValue) => {  
  return {
    type: actionTypes.BUDGET_UPDATE_PHASING_FAIL,
    error: error,
    key: key,
    period: period,
    oldValue: oldValue
  }
}  