import * as actionTypes from './actionTypes';
//////////////////////ACCESS////////////////////////////////////
export const authGuestAccess = (accessCode, portfolio, email, firstName, lastName) => {  
  return {
    type: actionTypes.AUTH_GUEST_ACCESS,    
    accessCode: accessCode,
    email: email,
    firstName: firstName,
    lastName: lastName,
    portfolio: portfolio
  }
} // => saga/index.js

export const authGuestAccessInit = () => {
  return {
    type: actionTypes.AUTH_GUEST_ACCESS_INIT
  }
}

export const authGuestAccessSuccess = (email) => {
  return {
    type: actionTypes.AUTH_GUEST_ACCESS_SUCCESS,
    email: email
  }
}

export const authGuestAccessFail = (error) => {
  return {
    type: actionTypes.AUTH_GUEST_ACCESS_FAIL,
    error: error
  }
}
//////////////////////LOG IN////////////////////////////////////
export const authLogin = (email, password) => {  
  return {
    type: actionTypes.AUTH_LOGIN,
    email: email,
    password: password
  }
} // => saga/index.js

export const authLoginInit = () => {
  return {
    type: actionTypes.AUTH_LOGIN_INIT
  }
}

export const authLoginSuccess = (email) => {
  return {
    type: actionTypes.AUTH_LOGIN_SUCCESS,
    email: email
  }
}

export const authLoginFail = (error) => {
  return {
    type: actionTypes.AUTH_LOGIN_FAIL,
    error: error
  }
}
//////////////////////LOG OUT////////////////////////////////////
export const authLogout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};  // => saga/index.js

export const authLogoutSuccess = () => {
  return {
    type: actionTypes.AUTH_LOGOUT_SUCCESS
  };
};
//////////////////////RESET PW/////////////////////////////////////
export const authResetPw = (email) => {  
  return {
    type: actionTypes.AUTH_RESETPW,
    email: email
  }
} // => saga/index.js

export const authResetPwInit = () => {
  return {
    type: actionTypes.AUTH_RESETPW_INIT
  }
}

export const authResetPwSuccess = (email) => {
  return {
    type: actionTypes.AUTH_RESETPW_SUCCESS,
    email: email
  }
}

export const authResetPwFail = (error) => {
  return {
    type: actionTypes.AUTH_RESETPW_FAIL,
    error: error
  }
}
//////////////////////CHANGE PW/////////////////////////////////////
export const authChangePw = (user, existingPassword, newPassword) => {  
  return {
    type: actionTypes.AUTH_CHANGEPW,
    user: user,
    existingPassword: existingPassword,
    newPassword: newPassword
  }
} // => saga/index.js

export const authChangePwInit = () => {
  return {
    type: actionTypes.AUTH_CHANGEPW_INIT
  }
}

export const authChangePwSuccess = () => {
  return {
    type: actionTypes.AUTH_CHANGEPW_SUCCESS
  }
}

export const authChangePwFail = (error) => {
  return {
    type: actionTypes.AUTH_CHANGEPW_FAIL,
    error: error
  }
}
//////////////////////FIRST LOGIN/////////////////////////////////////
export const authFirstLogin = (url, email, newPassword) => {  
  return {
    type: actionTypes.AUTH_FIRST_LOGIN,
    url: url,
    email: email,
    newPassword: newPassword
  }
} // => saga/index.js

export const authFirstLoginInit = () => {
  return {
    type: actionTypes.AUTH_FIRST_LOGIN_INIT
  }
}

export const authFirstLoginSuccess = (email, idToken) => {
  return {
    type: actionTypes.AUTH_FIRST_LOGIN_SUCCESS,
    email: email,
    idToken: idToken
  }
}

export const authFirstLoginFail = (error) => {
  return {
    type: actionTypes.AUTH_FIRST_LOGIN_FAIL,
    error: error
  }
}
//////////////////////OTHERS////////////////////////////////////
export const authSetIdToken = (idToken) => {
  return {
    type: actionTypes.AUTH_SET_ID_TOKEN,
    idToken: idToken
  };
}
export const authSetEmailLink = (url) => {
  return {
    type: actionTypes.AUTH_SET_EMAIL_LINK,
    url: url
  };
}
export const authSetRedirectPath = (path) => {
  return {
    type: actionTypes.AUTH_SET_REDIRECT_PATH,
    path: path
  };
}

// Could use Refresh token instead to exchange for an id token, be careful tho, cross site attack
export const authCheckState = () => {  
  return {
    type: actionTypes.AUTH_CHECK_STATE
  };
} // => saga/index.js

export const authCheckTimeout = (expiresIn) => {
  return {
    type: actionTypes.AUTH_CHECK_TIMEOUT,
    expirationTime: expiresIn
  }
} // => saga/index.js

