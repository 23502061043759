import {find} from 'lodash';
import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../shared/utility';
import {sumAlternativeCostPhasings} from '../../shared/calculationUtility';

const initialState = {
  // Retrieve All
  loadingAll: false,
  errorAll: null,
  entries: null,
  // Total Cost
  hasTotalCostMeasurement: false,
  totalCostMeasurementKey: null,
  // Post
  loadingPost: false,
  errorPost: null,
  // Update Measurement
  loadingUpdateMeasurement: false,
  errorUpdateMeasurement: null,
  // Import 
  loadingImport: false,
  errorImport: null
}
export default (state = initialState, action) => {
  switch (action.type) {
    // Retrieve All
    case actionTypes.ALTERNATIVE_RETRIEVE_ALL_INIT:
      return updateObject(state, {entries: null, loadingAll: true, errorAll: null, hasTotalCostMeasurement: false});
    case actionTypes.ALTERNATIVE_RETRIEVE_ALL_SUCCESS:
      return updateObject(state, {loadingAll: false, entries: action.entries});
    case actionTypes.ALTERNATIVE_RETRIEVE_ALL_FAIL:
      return updateObject(state, {loadingAll: false, errorAll: action.error});
    // Post
    case actionTypes.ALTERNATIVE_POST_INIT:
      return updateObject(state, {loadingPost: true, errorPost: null});      
    case actionTypes.ALTERNATIVE_POST_SUCCESS:     
      return updateEntries(state, action.entry, action.isDelete);
    case actionTypes.ALTERNATIVE_POST_FAIL:
      return updateObject(state, {loadingPost: false, errorPost: action.error});
    // Measurement
    case actionTypes.ALTERNATIVE_UPDATE_MEASUREMENT_INIT:
      return updateObject(state, {loadingUpdateMeasurement: true, errorUpdateMeasurement: null});      
    case actionTypes.ALTERNATIVE_UPDATE_MEASUREMENT_SUCCESS:     
      return updateMeasurement(state, action.altKey, action.measurementKey, action.value);
    case actionTypes.ALTERNATIVE_UPDATE_MEASUREMENT_FAIL:    
      return updateMeasurementError(state, action.error, action.altKey, action.measurementKey, action.oldValue);   
    // Sum Cost
    case actionTypes.ALTERNATIVE_SUM_COST: 
      return updateSumCost(state, action.measurements, action.alternativeCosts);   
    case actionTypes.ALTERNATIVE_SUM_COST_UPDATE: 
      return updateSumCostUpdate(state, action.altKey, action.value);   
    // Import
    case actionTypes.ALTERNATIVE_IMPORT_INIT:
      return updateObject(state, {loadingImport: true, errorImport: null});      
    case actionTypes.ALTERNATIVE_IMPORT_SUCCESS:     
      return updateObject(state, {loadingImport: false, entries: action.entries});
    case actionTypes.ALTERNATIVE_IMPORT_FAIL:    
      return updateObject(state, {loadingImport: false, errorImport: action.error});     
    default:
      return state;
  }
}

const updateEntries = (state, updatedEntry, isDelete) => {
  const newState = {loadingPost: false, errorPost: null};  
  // Update state.entries if has  
  if (state.entries) {  
    const editedEntries = {...state.entries};
    if (editedEntries[updatedEntry.key]) {      
      if (isDelete) {
        // Delete
        delete editedEntries[updatedEntry.key];
      } else {
        // Update
        const currentEntry = editedEntries[updatedEntry.key];
        editedEntries[updatedEntry.key] = {...currentEntry, ...updatedEntry};      
      }
    } else {
      // Add
      editedEntries[updatedEntry.key] = {...updatedEntry};
    }
    newState.entries = editedEntries;
  }
  return updateObject(state, newState);
}

const updateMeasurement = (state, altKey, mKey, value) => {
  const newState = {loadingUpdateMeasurement: false, errorUpdateMeasurement: null};
  // Update state.entries if has  
  if (state.entries) {   
    const editedEntries = {...state.entries}; 
    if (editedEntries[altKey]) {
      // Create if not yet have, i.e. on add
      if (!editedEntries[altKey].measurements) {
        editedEntries[altKey].measurements = {};      
      }
      editedEntries[altKey].measurements[mKey] = value;
    }
    newState.entries = editedEntries;
  }
  return updateObject(state, newState);
}

const updateMeasurementError = (state, error, altKey, mKey, oldValue) => {
  const newState = {loadingUpdateMeasurement: false, errorUpdateMeasurement: error};
  // Update state.entries if has  
  if (state.entries) {   
    const editedEntries = {...state.entries}; 
    if (editedEntries[altKey]) {
      // Create if not yet have, i.e. on add
      if (!editedEntries[altKey].measurements) {
        editedEntries[altKey].measurements = {};      
      }
      editedEntries[altKey].measurements[mKey] = oldValue;
      // TODO: Carry the error?
      // if (measurement) {
      //   measurement.error = error;
      // }    
    }
    newState.entries = editedEntries;
  }
  return updateObject(state, newState);
}

const updateSumCost = (state, measurements, alternativeCosts) => { 
  const newState = {hasTotalCostMeasurement: true};
  // IF not complex budget, no total cost measurement will be found and new state will set the hasTotalCost to true anyway
  const totalCostMeasurement = find(measurements, entry => entry.autoTotalCostFlag);
  if (totalCostMeasurement) { 
    const mTotalKey = totalCostMeasurement.key;
    const editedEntries = {...state.entries}; 
    for (var altKey in editedEntries) {
      // Create if not yet have, i.e. on add
      if (!editedEntries[altKey].measurements) editedEntries[altKey].measurements = {};   
      // Set total cost
      editedEntries[altKey].measurements[mTotalKey] = sumAlternativeCostPhasings(alternativeCosts, altKey);      
    }
    newState.entries = editedEntries;
    // Save mkey for later
    newState.totalCostMeasurementKey = mTotalKey;
  }
  return updateObject(state, newState);
}

const updateSumCostUpdate = (state, altKey, value) => {
  const newState = {};
  const editedEntries = {...state.entries}; 
  if (editedEntries[altKey]) { 
    const mTotalKey = state.totalCostMeasurementKey;
    // Create if not yet have, i.e. on add
    if (!editedEntries[altKey].measurements) editedEntries[altKey].measurements = {};  
    if (!editedEntries[altKey].measurements[mTotalKey]) editedEntries[altKey].measurements[mTotalKey] = 0;  
    // Set total cost
    editedEntries[altKey].measurements[mTotalKey] += value;   
    newState.entries = editedEntries;
  }
  return updateObject(state, newState);
}