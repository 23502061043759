import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../shared/utility';

const initialState = {
  // Retrieve All
  loadingAll: false,
  errorAll: null,
  entries: null,
  // Retrieve 
  loading: false,
  error: null,
  currentUser: null,
  // Post
  loadingPost: false,
  errorPost: null,
  // Email
  loadingEmail: false,
  errorEmail: null,
  // Convert Guest
  loadingConvertGuest: false,
  errorConvertGuest: null
}
export default (state = initialState, action) => {
  switch (action.type) {
    // Clear User
    case actionTypes.USER_CLEAR:
      return updateObject(state, {loadingAll: true, errorAll: null, entries: null});
    // Retrieve All
    case actionTypes.USER_RETRIEVE_ALL_INIT:
      return updateObject(state, {loadingAll: true, errorAll: null});
    case actionTypes.USER_RETRIEVE_ALL_SUCCESS:
      // Since we make 3 call to /users with different role, join here instead of overwritten
      const entries = state.entries ? [...state.entries] : [];
      action.users.forEach(user => {
        const matchedIdx = entries.findIndex(entry => entry.key === user.key)
        if (matchedIdx < 0) entries.push(user);
      })
      return updateObject(state, {loadingAll: false,  errorAll: null, entries: entries});
    case actionTypes.USER_RETRIEVE_ALL_FAIL:
      return updateObject(state, {loadingAll: false, errorAll: action.error});
    // Retrieve
    case actionTypes.USER_RETRIEVE_INIT:
      return updateObject(state, {loading: true, error: null});
    case actionTypes.USER_RETRIEVE_SUCCESS:
      return updateObject(state, {loading: false, currentUser: action.user});
    case actionTypes.USER_RETRIEVE_FAIL:
      return updateObject(state, {loading: false, error: action.error});
    // Post
    case actionTypes.USER_POST_INIT:
      return updateObject(state, {loadingPost: true, errorPost: null});      
    case actionTypes.USER_POST_SUCCESS:
      return updateUser(state, action.user, action.isDelete);
    case actionTypes.USER_POST_FAIL:
      return updateObject(state, {loadingPost: false, errorPost: action.error});
    // Email
    case actionTypes.USER_SEND_EMAIL_LINK_INIT:
      return updateObject(state, {loadingEmail: true, errorEmail: null});
    case actionTypes.USER_SEND_EMAIL_LINK_SUCCESS:    
      return updateUser(state, action.user);
    case actionTypes.USER_SEND_EMAIL_LINK_FAIL:
      return updateObject(state, {loadingEmail: false, errorEmail: action.error});
    // Email
    case actionTypes.USER_CONVERT_GUEST_INIT:
      return updateObject(state, {loadingConvertGuest: true, errorConvertGuest: null});
    case actionTypes.USER_CONVERT_GUEST_SUCCESS:    
      return updateUser(state, action.user);
    case actionTypes.USER_CONVERT_GUEST_FAIL:
      return updateObject(state, {loadingConvertGuest: false, errorConvertGuest: action.error});
    default:
      return state;
  }
}

const updateUser = (state, updatedEntry, isDelete) => {
  const newState = {loadingPost: false, loadingEmail: false,  loadingConvertGuest: false,  errorPost: null, errorEmail: null, errorConvertGuest: null};
  // Update state.users if has  
  if (state.entries) {  
    const editedEntries = [...state.entries];
    const userIndex = editedEntries.findIndex((user) => (user.key === updatedEntry.key));       
    if (userIndex > -1) {
      if (isDelete) {
        // Delete
        editedEntries.splice(userIndex, 1);
      } else { 
        // Update
        const currentEntry = editedEntries[userIndex];
        editedEntries[userIndex] = {...currentEntry, ...updatedEntry};
      }
    } else {
      // Add
      editedEntries.push(updatedEntry)
    }
    // Sort and Save
    editedEntries.sort((a, b) => a.email - b.email);
    newState.entries = editedEntries;
  }
  // Update current user as well if match   
  if(state.currentUser && state.currentUser.key === updatedEntry.key) {
    newState.currentUser = {...state.currentUser, ...updatedEntry};
  }    
  return updateObject(state, newState);
}