import * as actionTypes from './actionTypes';

//ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL////////////////////////////////////////
export const alternativeRelationshipRetrieveAll = (portfolio) => {    
    return {
        type: actionTypes.ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL,
        portfolioId: portfolio
    }
} // => saga/index.js

export const alternativeRelationshipRetrieveAllInit = () => {    
    return {
        type: actionTypes.ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL_INIT
    }
} 

export const alternativeRelationshipRetrieveAllFail = (error) => {    
    return {
        type: actionTypes.ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL_FAIL,
        error: error
    }
} 

export const alternativeRelationshipRetrieveAllSuccess = (entries) => {    
    return {
        type: actionTypes.ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL_SUCCESS,
        entries: entries
    }
} 

//ALTERNATIVE_RELATIONSHIP_POST////////////////////////////////////////
export const alternativeRelationshipAdd = (portfolioId, entry) => {    
    return {
        type: actionTypes.ALTERNATIVE_RELATIONSHIP_ADD,
        portfolioId: portfolioId,
        entry: entry
    }
} //=> saga/index.js

export const alternativeRelationshipUpdate = (portfolioId, entry) => {    
    return {
        type: actionTypes.ALTERNATIVE_RELATIONSHIP_UPDATE,
        portfolioId: portfolioId,
        entry: entry,
    }
} // => saga/index.js

export const alternativeRelationshipDelete = (portfolioId, entry) => {    
    return {
        type: actionTypes.ALTERNATIVE_RELATIONSHIP_DELETE,
        portfolioId: portfolioId,
        entry: entry,
    }
} // => saga/index.js

export const alternativeRelationshipPostInit = () => {    
    return {
        type: actionTypes.ALTERNATIVE_RELATIONSHIP_POST_INIT
    }
}

export const alternativeRelationshipPostFail = (error) => {    
    return {
        type: actionTypes.ALTERNATIVE_RELATIONSHIP_POST_FAIL,
        error: error
    }
} 

export const alternativeRelationshipPostSuccess = (entry, isDelete) => {    
    return {
        type: actionTypes.ALTERNATIVE_RELATIONSHIP_POST_SUCCESS,
        entry: entry,
        isDelete: isDelete ? isDelete : false
    }
}  