import {put} from 'redux-saga/effects';
import {getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions/index';
import locale from "locale/index";

export function* voterPortfolioRetrieveAll(action) {
  yield put(actions.voterPortfolioRetrieveAllInit());
  try{        
    const res = yield apis.get(`users/${action.userKey}/portfolios`, yield getAuthHeader());
    if (res.status === 200) {  
      const portfolios = res.data.response.slice();
      yield put(actions.voterPortfolioRetrieveAllSuccess(portfolios)); 
    } else {
      yield put(actions.voterPortfolioRetrieveAllFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.voterPortfolioRetrieveAllFail(error));
  };
} 

export function* voterPortfolioRetrieve(action) {  
  // Init 
  yield put(actions.voterPortfolioRetrieveInit());
  yield put(actions.criteriaRetrieveAllInit());
  if (action.category === 'criteria') {
    yield put(actions.criteriaVoterRetrieveInit());
  } else if (action.category === 'alternative') {
    yield put(actions.alternativeRetrieveAllInit());
    yield put(actions.alternativeVoterRetrieveInit());
  } 
  try {    
    const res = yield apis.get(`${action.isGuest ? "guests" : "users"}/${action.userId}/portfolios/${action.portfolioId}`, yield getAuthHeader());
    if (res.status === 200) {  
      const voterPortfolio = res.data.response.find(entry => entry.category === action.category);
      if (voterPortfolio) {
        const guestId = action.isGuest ? action.userId : null;
        // Retrieve Info - Criteria is always need, if guest, different route will be used
        yield put(actions.criteriaRetrieveAll(voterPortfolio.key, guestId));
        if (action.category === 'criteria') {
          yield put(actions.criteriaVoterRetrieve(voterPortfolio.voter, guestId));
        } else if (action.category === 'alternative') {
          yield put(actions.alternativeRetrieveAll(voterPortfolio.key, guestId));
          yield put(actions.alternativeVoterRetrieve(voterPortfolio.voter, guestId));
        }           
        // Post Success
        yield put(actions.voterPortfolioRetrieveSuccess(voterPortfolio));
      } else {
        // Not found, or user does not have access
        yield put(actions.voterPortfolioRetrieveFail({message: locale.error_vote_cannot_find_portfolio, values: {id: action.portfolioId}}));  
      }
    } else {
      yield put(actions.voterPortfolioRetrieveFail(res.data.error));
    }     
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.voterPortfolioRetrieveFail(error));
  }; 
} 

export function* voterPortfolioRetrieveByCode(action) {
  yield put(actions.voterPortfolioRetrieveByCodeInit());
  try{        
    const res = yield apis.get(`/guests/${action.accessCode}`);
    if (res.status === 200) {
      if (res.data.response.length === 0) {
        yield put(actions.voterPortfolioRetrieveByCodeFail({message: locale.error_guest_unknown_code}));
        return;
      } else {
        const portfolio = res.data.response[0];
        yield put(actions.voterPortfolioRetrieveByCodeSuccess(portfolio));
      }
    } else {
      yield put(actions.voterPortfolioRetrieveByCodeFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.voterPortfolioRetrieveByCodeFail(error));
  };
} 

export function* voterPortfolioAdd(action) {
  // Init 
  yield put(actions.voterPortfolioAddInit());
  try {
    let matchPortfolio = null;
    let matchCategory = null;
    const res = yield apis.get(`users/${action.userId}/portfolios?code=${action.code}`, yield getAuthHeader());
    if (res.status === 200) {
      // Check if exists
      if (res.data.response.length === 1) {
        matchPortfolio = {...res.data.response[0]};
        if (matchPortfolio.criteriaVotingCode === action.code.toString()) {
          matchCategory = 'criteria';
        } else if (matchPortfolio.alternativeVotingCode === action.code.toString()) {
          matchCategory = 'alternative';
        }
        // Check if it has already been add
        const matchExisting = action.existings.find(entry => {
          return entry.key === matchPortfolio.key && entry.category === matchCategory;
        });
        if (matchExisting) {
          // Portfolio is already in voter's list
          yield put(actions.voterPortfolioAddFail({message: locale.error_vote_add_exists}));  
        } else {
          // Mark self-invited here
          const res = yield apis.put(`/voters/${matchPortfolio.voter}`, {access: new Date()}, yield getAuthHeader());  
          if (res.status === 200) {
            // Post Success
            yield put(actions.voterPortfolioAddSuccess(matchPortfolio));
          } else {
            yield put(actions.voterPortfolioAddFail(res.data.error));
          }
        }
      } else {
        yield put(actions.voterPortfolioAddFail(res.data.error));
      }      
    } else {
      yield put(actions.voterPortfolioAddFail({message: locale.error_vote_unknown_code, values: {code: action.code}}));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.voterPortfolioAddFail(error));
  };
}