import * as actionTypes from './actionTypes';

//ALTERNATIVE_START_SAVE_RETRIEVE_ALL////////////////////////////////////////
export const alternativeStartSaveRetrieveAll = (portfolio) => {  
  return {
    type: actionTypes.ALTERNATIVE_START_SAVE_RETRIEVE_ALL,
    portfolioId: portfolio
  }
} // => saga/index.js

export const alternativeStartSaveRetrieveAllInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_START_SAVE_RETRIEVE_ALL_INIT
  }
} 

export const alternativeStartSaveRetrieveAllFail = (error) => {  
  return {
    type: actionTypes.ALTERNATIVE_START_SAVE_RETRIEVE_ALL_FAIL,
    error: error
  }
} 

export const alternativeStartSaveRetrieveAllSuccess = (alternativeStartSaves) => {  
  return {
    type: actionTypes.ALTERNATIVE_START_SAVE_RETRIEVE_ALL_SUCCESS,
    entries: alternativeStartSaves
  }
} 
//ALTERNATIVE_START_SAVE_SELECT////////////////////////////////////////
export const alternativeStartSaveSelect = (entry) => {  
  return {
    type: actionTypes.ALTERNATIVE_START_SAVE_SELECT,
    entry: entry
  }
}

//ALTERNATIVE_START_SAVE_POST////////////////////////////////////////
export const alternativeStartSaveAdd = (portfolioId, entry) => {  
  return {
    type: actionTypes.ALTERNATIVE_START_SAVE_ADD,
    portfolioId: portfolioId,
    entry: entry
  }
} //=> saga/index.js

export const alternativeStartSaveUpdate = (portfolioId, entry) => {  
  return {
    type: actionTypes.ALTERNATIVE_START_SAVE_UPDATE,
    portfolioId: portfolioId,
    entry: entry,
  }
} // => saga/index.js

export const alternativeStartSaveDelete = (portfolioId, entry) => {  
  return {
    type: actionTypes.ALTERNATIVE_START_SAVE_DELETE,
    portfolioId: portfolioId,
    entry: entry,
  }
} // => saga/index.js

export const alternativeStartSavePostInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_START_SAVE_POST_INIT
  }
}

export const alternativeStartSavePostFail = (error) => {  
  return {
    type: actionTypes.ALTERNATIVE_START_SAVE_POST_FAIL,
    error: error
  }
} 

export const alternativeStartSavePostSuccess = (entry, isDelete) => {  
  return {
    type: actionTypes.ALTERNATIVE_START_SAVE_POST_SUCCESS,
    entry: entry,
    isDelete: isDelete ? isDelete : false
  }
}  

//START_PERIOD//////////////////////////////////////////////
export const alternativeStartUpdateStart = (startPeriods, unallocatedAltKeys) => {
  return {
    type: actionTypes.ALTERNATIVE_START_UPDATE_START_PERIOD,
    startPeriods: startPeriods,
    unallocatedAltKeys: unallocatedAltKeys ? unallocatedAltKeys : []
  }
}
export const alternativeStartUpdateLock = (lockPeriods) => {
  return {
    type: actionTypes.ALTERNATIVE_START_UPDATE_LOCK_PERIOD,
    lockPeriods: lockPeriods
  }
}
export const alternativeStartUpdateBudgetCarryOver = (flag) => {
  return {
    type: actionTypes.ALTERNATIVE_START_UPDATE_BUDGET_CARRY_OVER,
    flag: flag
  }
}
export const alternativeStartUpdateBudgetDistribute = (flag) => {
  return {
    type: actionTypes.ALTERNATIVE_START_UPDATE_BUDGET_DISTRIBUTE,
    flag: flag
  }
}

//CALCULATE//////////////////////////////////////////////
export const alternativeStartCalculate = (updates) => {  
  return {
    type: actionTypes.ALTERNATIVE_START_CALCULATE,
    updates: updates
  }
}

export const alternativeStartCalculateFail = (error) => {  
  return {
    type: actionTypes.ALTERNATIVE_START_CALCULATE_FAIL,
    error: error
  }
} 

export const alternativeStartCalculateSuccess = (costByPeriods, budgetByPeriods) => {  
  return {
    type: actionTypes.ALTERNATIVE_START_CALCULATE_SUCCESS,
    costByPeriods: costByPeriods,
    budgetByPeriods: budgetByPeriods
  }
}  