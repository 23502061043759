export const userRoleTypes = Object.freeze({
  admin: {value: "admin", display: "Admin"},
  facilitator: {value: "facilitator", display: "Facilitator"},
  voter: {value: "voter", display: "Voter"},
  guest: {value: "guest", display: "Guest"}
})

// Assessment Type ////////////////////////////////////////////////
export const assessmentTypes = Object.freeze({
  OPT: {value: "OPT", display: "Portfolio Optimisation"},
  OSC: {value: "OSC", display: "Optimisation, Single Choice"},
  TE: {value: "TE", display: "Tender Evaluation"},
  HRE: {value: "HRE", display: "Human Resource Evaluation"}
})
export const assessmentTypesDecode = (lookupValue) => {
  for (var key in assessmentTypes) {
    if (assessmentTypes[key].value  === lookupValue || assessmentTypes[key].display === lookupValue) {
      return assessmentTypes[key];
    }
  }
  return {value: "UNK", display: "Unknown"};
}