// ##############################
// // // LoginPage view styles
// #############################

import {
  dangerColor,
  cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

const loginPageStyle = theme => ({
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "450px",
    position: "relative",    
    paddingTop: "14vh", 
    paddingBottom: "2vh",
    minHeight: "calc(100vh - 171px)",
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 79px)", 
      maxWidth: "calc(100vw)", 
      paddingTop: "30px",
      width: "auto",
    },
  }, 
  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF"
  },
  cardBody: {
    marginTop: "-32px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-16px"
    }
  },
  textCenter: {
    textAlign: "center"
  },
  errorText: {
    color: dangerColor
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: "#FFFFFF"
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    marginBottom: "20px"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  cardFooterButtonClass: {
    color: "#666666",
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& i": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    },
    "&:hover,&:focus": {
      color: "black"
    }
  },
  center:{
    textAlign: "center"
  },
  // For changePW  
  fullPage: {
    padding: "60px 0",
    position: "relative",
    minHeight: "100vh",
    maxHeight: "1000px",
    display: "flex!important",
    margin: "0",
    border: "0",
    color: "#fff",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content!important",
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important"
    },
    "&:before": {
      backgroundColor: "rgba(0, 0, 0, 0.65)"
    },
    "&:before,&:after": {
      display: "block",
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "2"
    }
  },
});

export default loginPageStyle;
