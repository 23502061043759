import {put} from 'redux-saga/effects';
import {delay} from 'redux-saga';
import apis from 'shared/axiosApi';
import {find} from 'lodash'
import * as actions from '../actions/index';
import locale from "locale/index";
import {getState, getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import {calculateImportance, calculateSensitivity, calculateVROI, calculateBudgetOptions, calculateFlatPriorityVector, sumAlternativeCostPhasings} from '../../shared/calculationUtility';

const CALCULATION_WAIT_TIME = 3000;

export function* portfolioRetrieveAll(action) {
  yield put(actions.portfolioRetrieveAllInit());
  try{    
    const res = yield apis.get(`accounts/${action.accountId}/portfolios`, yield getAuthHeader());    
    if (res.status === 200) {  
      const portfolios = res.data.response.slice();
      yield put(actions.portfolioRetrieveAllSuccess(portfolios)); 
    } else {
      yield put(actions.portfolioRetrieveAllFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.portfolioRetrieveAllFail(error));
  };
} 

export function* portfolioRetrieve(action) {
 // Init 
  yield put(actions.measurementRetrieveAllInit());
  yield put(actions.budgetRetrieveAllInit());
  yield put(actions.criteriaRetrieveAllInit());
  yield put(actions.criteriaVoterRetrieveAllInit());
  yield put(actions.alternativeRetrieveAllInit());
  yield put(actions.alternativeCostRetrieveAllInit());
  yield put(actions.alternativeVoterRetrieveAllInit());
  yield put(actions.alternativeRelationshipRetrieveAllInit());
  yield put(actions.reportRetrieveAllInit());
  yield put(actions.portfolioRetrieveInit());
  yield put(actions.scenarioSelect(null));
  try{  
    const res = yield apis.get(`/portfolios/${action.id}`, yield getAuthHeader());
    if (res.status === 200) {  
      const portfolio = {...res.data.response[0]};
      // Retrieve Portfolio
      yield put(actions.portfolioRetrieveSuccess(portfolio));
      // Retrieve Info
      yield put(actions.measurementRetrieveAll(portfolio.key));
      yield put(actions.budgetRetrieveAll(portfolio.key));
      yield put(actions.criteriaRetrieveAll(portfolio.key));
      yield put(actions.criteriaVoterRetrieveAll(portfolio.key));
      yield put(actions.alternativeRetrieveAll(portfolio.key));
      yield put(actions.alternativeCostRetrieveAll(portfolio.key));
      yield put(actions.alternativeVoterRetrieveAll(portfolio.key));
      yield put(actions.alternativeRelationshipRetrieveAll(portfolio.key));
      yield put(actions.reportRetrieveAll(portfolio.key));
    } else {
      yield put(actions.portfolioRetrieveFail({message: locale.error_vote_cannot_find_portfolio, values: {id: action.id}}));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.portfolioRetrieveFail(error));
  };
} 

export function* portfolioAdd(action) {  
  yield put(actions.portfolioPostInit());
  try {
    const portfolio = {...action.portfolio};
    // Generate new code
    portfolio.criteriaVotingCode = String(Math.floor(Math.random() * 1000000)).padEnd(6, '0');
    portfolio.alternativeVotingCode = String(Math.floor(Math.random() * 1000000)).padEnd(6, '0');
    // Update DB
    const res = yield apis.post("/portfolios", portfolio, yield getAuthHeader());    
    if (res.status === 200) {
      // Update key
      portfolio.key = Number(res.data.response.insertId);

      // Add Auto measurement for Total Cost if this is a complex budget Type
      // Ignore if it's a copy of another portfolio, with measurement included
      const isCopyWithMeasurement = portfolio.copy && portfolio.sections && portfolio.sections.includes('measurement');
      if (portfolio.budgetType === 'complex' && !isCopyWithMeasurement) {
        const autoCostMeasurement = {
          name: "Total Cost (Auto-generated)", //TODO: Use value from localeCommon
          prefixUnit: "$",
          unit: "",
          autoTotalCostFlag: true,
          description: "Total of Alternative Costs",
          type: "continuous"
        }
        yield put(actions.measurementAdd(portfolio.key, autoCostMeasurement, true));
      }      
      // Post success
      yield put(actions.portfolioPostSuccess(portfolio));    
      // Notification
      yield put(actions.notificationShow(locale.notification_portfolio_added, {name: portfolio.name}));
    } else {
      yield put(actions.portfolioPostFail(res.data.error));
    }     
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.portfolioPostFail(error));
  };
} 

export function* portfolioUpdate(action) {
  yield put(actions.portfolioPostInit());
  try{    
    const {...portfolio} = action.portfolio; 
    const prevPortfolio = yield getState('portfolio', 'currentPortfolio');    
    // Update DB
    let res = yield apis.put(`/portfolios/${portfolio.key}`, portfolio, yield getAuthHeader());       
    if (prevPortfolio && portfolio.budgetMeasurement 
      && prevPortfolio.budgetMeasurement !== portfolio.budgetMeasurement) {
      // Update just BudgetMeasurement
      res = yield apis.post(`/portfolios/${portfolio.key}/portfolioBudgetMeasurement`, {measurement: portfolio.budgetMeasurement}, yield getAuthHeader());
    }

    if (res.status === 200) {
      // Post success
      yield put(actions.portfolioPostSuccess(portfolio));     
      // Check if Recalculate is needed
      let recalculateTasks = [];    
      if (prevPortfolio) {      
        if (prevPortfolio.budgetMeasurement !== portfolio.budgetMeasurement) {
          recalculateTasks = ['vroi', 'budget'];
        }
        if (prevPortfolio.assessmentType !== portfolio.assessmentType) {
          recalculateTasks = ['importance', 'sensitivity', 'vroi', 'budget'];
        }
      }    
      if (recalculateTasks.length > 0) yield put(actions.portfolioRecalculate({}, recalculateTasks));
      // Notification
      yield put(actions.notificationShow(locale.notification_portfolio_updated, {name: portfolio.name}));
    } else {
      yield put(actions.portfolioPostFail(res.data.error));
    }    
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.portfolioPostFail(error));
  };
} 

export function* portfolioDelete(action) {
  yield put(actions.portfolioPostInit());
  try{    
    const {...portfolio} = action.portfolio; 
    // Update DB
    const res = yield apis.delete(`/portfolios/${portfolio.key}`, yield getAuthHeader());   
    if (res.status === 200) {
      // Post success
      yield put(actions.portfolioPostSuccess(portfolio, true));
      // Recalculate
      yield put(actions.portfolioRecalculate({}, ['importance', 'sensitivity', 'vroi', 'budget']));
      // Notification
      yield put(actions.notificationShow(locale.notification_portfolio_deleted, {name: portfolio.name}));
    } else {
      yield put(actions.portfolioPostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.portfolioPostFail(error));
  };
}

export function* portfolioRecalculate(action) {
  // Mark the request and delay for 5s
  yield put(actions.portfolioSetRecalculationRequest());
  yield delay(CALCULATION_WAIT_TIME);  
  // Check if another request has overriden this request, if so do nothing
  const recalculationRequestTime = yield getState('portfolio', 'recalculationRequestTime'); 
  if (recalculationRequestTime < (new Date() - CALCULATION_WAIT_TIME)) {
    yield recalculate(action);
  }
}

export function* recalculate(action) {
  try {    
    let updates = {...action.updates};
    let calculationUpdates = {};  
    const portfolio = updates.portfolio ? updates.portfolio : yield getState('portfolio', 'currentPortfolio');  
    const measurements = updates.measurements ? updates.measurements : yield getState('measurement', 'entries');  

    if (portfolio.budgetType === 'complex' && action.tasks.includes('sumAltCost')) {   
      const alternatives = yield getState('alternative', 'entries');
      const alternativeCosts = yield getState('alternativeCost', 'entries');  
      if (measurements && alternatives && alternativeCosts) {
        const totalCostMeasurement = find(measurements, entry => entry.autoTotalCostFlag);
        if (totalCostMeasurement) {
          for (var altKey in alternatives) {
            const totalCost = sumAlternativeCostPhasings(alternativeCosts, altKey);    
            yield put(actions.alternativeSetMeasurementSuccess(altKey, totalCostMeasurement.key, totalCost));
          }
        }      
      }
    }

    // Re-calculate Importance
    const criteria = updates.criteria ? updates.criteria : yield getState('criteria', 'entries');
    const criteriaAnswers = updates.criteriaAnswers ? updates.criteriaAnswers : yield getState('criteriaVoter', 'submittedAnswers');      
    if (action.tasks.includes('importance')) {
      const result = calculateImportance(criteria, criteriaAnswers, false);
      // Apply tweakVetor if has      
      const tweakVectors = updates.tweakVectors ? updates.tweakVectors : yield getState('portfolio', 'tweakVectors');      
      if (tweakVectors) {
        result.priorityVector = {...tweakVectors};
        result.flatPriorityVector = calculateFlatPriorityVector(criteria, tweakVectors);
      }
      // Consolidate Updates
      calculationUpdates = {...calculationUpdates, ...result}
      updates = {...updates, ...result}
    }
    
    // Re-calculate Sensitivity
    const flatPriorityVector = updates.flatPriorityVector ? updates.flatPriorityVector : yield getState('portfolio', 'flatPriorityVector');
    const alternatives = updates.alternatives ? updates.alternatives : yield getState('alternative', 'entries');
    const alternativeAnswers = updates.alternativeAnswers ? updates.alternativeAnswers : yield getState('alternativeVoter', 'submittedAnswers');
    if (flatPriorityVector && action.tasks.includes('sensitivity')) {
      const result = calculateSensitivity(measurements, criteria, flatPriorityVector, alternatives, alternativeAnswers, portfolio.evaluationMethod, false);
      // Dont update sortedKeys if isTweak
      if (action.isTweak) delete result.altSortedKeys;
      calculationUpdates = {...calculationUpdates, ...result, flatPriorityVector: flatPriorityVector}
      updates = {...updates, ...result}
    }

    // Re-calculate VROI
    const sensitivityVectors = updates.sensitivityVectors ? updates.sensitivityVectors : yield getState('portfolio', 'sensitivityVectors');
    if (action.tasks.includes('vroi')) {
      const result = calculateVROI(portfolio, alternatives, sensitivityVectors, false);
      calculationUpdates = {...calculationUpdates, ...result}
      updates = {...updates, ...result} 
    }

    // Re-calculate Options
    const alternativeRelationships = updates.alternativeRelationships ? updates.alternativeRelationships : yield getState('alternativeRelationship', 'entries');
    const alternativeForceFunds = updates.alternativeForceFunds ? updates.alternativeForceFunds : yield getState('portfolio', 'alternativeForceFunds');  
    if (action.tasks.includes('budget')) {
      const result = calculateBudgetOptions(portfolio, alternatives, alternativeRelationships, alternativeForceFunds, sensitivityVectors, false);
      calculationUpdates = {...calculationUpdates, ...result, alternativeForceFunds: alternativeForceFunds}
      updates = {...updates, ...result} 
    }

    // Post Success
    yield put(actions.portfolioRecalculateSuccess(calculationUpdates));
  } catch (error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.portfolioRecalculateFail(error));
  }
}