import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../shared/utility';

const initialState = {
  // Retrieve All
  loadingAll: false,
  errorAll: null,
  entries: null,
  // Post
  loadingPost: false,
  errorPost: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    // Retrieve All
    case actionTypes.CRITERIA_RETRIEVE_ALL_INIT:
      return updateObject(state, {entries: null, loadingAll: true, errorAll: null});
    case actionTypes.CRITERIA_RETRIEVE_ALL_SUCCESS:
      return updateObject(state, {loadingAll: false, entries: action.entries});
    case actionTypes.CRITERIA_RETRIEVE_ALL_FAIL:
      return updateObject(state, {loadingAll: false, errorAll: action.error});
    // Post
    case actionTypes.CRITERIA_POST_INIT:
      return updateObject(state, {loadingPost: true, errorPost: null});      
    case actionTypes.CRITERIA_POST_SUCCESS:     
      return updateEntries(state, action.entry, action.isDelete);
    case actionTypes.CRITERIA_POST_FAIL:
      return updateObject(state, {loadingPost: false, errorPost: action.error});
    default:
      return state;
  }
}

const updateEntries = (state, updatedEntry, isDelete) => {
  const newState = {loadingPost: false, errorPost: null};
  // Update state.entries if has  
  if (state.entries) {  
    const editedEntries = {...state.entries};
    if (editedEntries[updatedEntry.key]) {      
      if (isDelete) {
        // Delete
        delete editedEntries[updatedEntry.key];
      } else {
        // Update
        const currentEntry = editedEntries[updatedEntry.key];
        editedEntries[updatedEntry.key] = {...currentEntry, ...updatedEntry};
      }
    } else {
      // Add
      editedEntries[updatedEntry.key] = {...updatedEntry};
    }
    newState.entries = editedEntries;
  }
  return updateObject(state, newState);
}