import * as actionTypes from './actionTypes';
//USER_CLEAR///////////////////////////////////////////
export const userClear = () => {  
  return {
    type: actionTypes.USER_CLEAR,
  }
}

//USER_RETRIEVE////////////////////////////////////////
export const userRetrieve = (email) => {  
  return {
    type: actionTypes.USER_RETRIEVE,
    email: email
  }
} // => saga/index.js

export const userRetrieveInit = () => {  
  return {
    type: actionTypes.USER_RETRIEVE_INIT
  }
} 

export const userRetrieveFail = (error) => {  
  return {
    type: actionTypes.USER_RETRIEVE_FAIL,
    error: error
  }
} 

export const userRetrieveSuccess = (user) => {  
  return {
    type: actionTypes.USER_RETRIEVE_SUCCESS,
    user: user ? user : null
  }
} 

//USER_RETRIEVE_ALL////////////////////////////////////////
export const userRetrieveAll = (accountId, role) => {
  return {
    type: actionTypes.USER_RETRIEVE_ALL,
    accountId: accountId,
    role: role
  }
} // => saga/index.js

export const userRetrieveAllInit = () => {  
  return {
    type: actionTypes.USER_RETRIEVE_ALL_INIT
  }
} 

export const userRetrieveAllFail = (error) => {  
  return {
    type: actionTypes.USER_RETRIEVE_ALL_FAIL,
    error: error
  }
} 

export const userRetrieveAllSuccess = (users) => {  
  return {
    type: actionTypes.USER_RETRIEVE_ALL_SUCCESS,
    users: users
  }
} 

//USER_POST////////////////////////////////////////
export const userAdd = (user, sendEmail, addToGroups) => {  
  return {
    type: actionTypes.USER_ADD,
    user: user,
    sendEmail: sendEmail,
    addToGroups: addToGroups
  }
} //=> saga/index.js

export const userUpdateLastLoggedIn = (user) => {
  // Set Lastest Date time
  const editedUser = {...user};
  editedUser.last_logged_in = new Date();
  return {
    type: actionTypes.USER_UPDATE,
    user: editedUser,
    isUpdateLastLoggedIn: true
  }
} // => saga/index.js

export const userUpdate = (user) => {  
  return {
    type: actionTypes.USER_UPDATE,
    user: user,
    isUpdateLastLoggedIn: false
  }
} // => saga/index.js

export const userDelete = (user, accountId) => {  
  return {
    type: actionTypes.USER_DELETE,
    user: user,
    accountId: accountId
  }
} // => saga/index.js

export const userPostInit = () => {  
  return {
    type: actionTypes.USER_POST_INIT
  }
} // => saga/index.js

export const userPostFail = (error) => {  
  return {
    type: actionTypes.USER_POST_FAIL,
    error: error
  }
} 

export const userPostSuccess = (user, isDelete) => {  
  return {
    type: actionTypes.USER_POST_SUCCESS,
    user: user,
    isDelete: isDelete ? isDelete : false
  }
} 


//USER_UPDATE_ROLE////////////////////////////////////////
export const userUpdateRole = (userId, newRole) => {  
  return {
    type: actionTypes.USER_UPDATE_ROLE,
    userId: userId,
    newRole: newRole
  }
} // => saga/index.js


//USER_SEND_EMAIL_LINK////////////////////////////////////////
export const userSendEmailLink = (user) => {
  return {
    type: actionTypes.USER_SEND_EMAIL_LINK,
    user: user,
  }
} // => saga/index.js

export const userSendEmailLinkInit = () => {  
  return {
    type: actionTypes.USER_SEND_EMAIL_LINK_INIT
  }
} 

export const userSendEmailLinkFail = (error) => {  
  return {
    type: actionTypes.USER_SEND_EMAIL_LINK_FAIL,
    error: error
  }
} 

export const userSendEmailLinkSuccess = (user) => {  
  return {
    type: actionTypes.USER_SEND_EMAIL_LINK_SUCCESS,
    user: user
  }
} 

//USER_CONVERT_GUEST////////////////////////////////////////
export const userConvertGuest = (user, currentAccount) => {
  return {
    type: actionTypes.USER_CONVERT_GUEST,
    user: user,
    currentAccount: currentAccount
  }
} // => saga/index.js

export const userConvertGuestInit = () => {  
  return {
    type: actionTypes.USER_CONVERT_GUEST_INIT
  }
} 

export const userConvertGuestFail = (error) => {  
  return {
    type: actionTypes.USER_CONVERT_GUEST_FAIL,
    error: error
  }
} 

export const userConvertGuestSuccess = (user) => {  
  return {
    type: actionTypes.USER_CONVERT_GUEST_SUCCESS,
    user: user
  }
} 