import * as actionTypes from './actionTypes';
//ACCOUNT_SELECT////////////////////////////////////////
export const accountSelect = (account) => {  
  return {
    type: actionTypes.ACCOUNT_SELECT,
    account: account
  }
}
//ACCOUNT_RETRIEVE////////////////////////////////////////
export const accountRetrieve = (accountId) => {  
  return {
    type: actionTypes.ACCOUNT_RETRIEVE,
    accountId: accountId
  }
} // => saga/index.js

export const accountRetrieveInit = () => {  
  return {
    type: actionTypes.ACCOUNT_RETRIEVE_INIT
  }
} 

export const accountRetrieveFail = (error) => {  
  return {
    type: actionTypes.ACCOUNT_RETRIEVE_FAIL,
    error: error
  }
} 

export const accountRetrieveSuccess = (account) => {  
  return {
    type: actionTypes.ACCOUNT_RETRIEVE_SUCCESS,
    entry: account
  }
} 

//ACCOUNT_RETRIEVE_ALL////////////////////////////////////////
export const accountRetrieveAll = (selectId) => {  
  return {
    type: actionTypes.ACCOUNT_RETRIEVE_ALL,
    selectId: selectId
  }
} // => saga/index.js

export const accountRetrieveAllInit = () => {  
  return {
    type: actionTypes.ACCOUNT_RETRIEVE_ALL_INIT
  }
} 

export const accountRetrieveAllFail = (error) => {  
  return {
    type: actionTypes.ACCOUNT_RETRIEVE_ALL_FAIL,
    error: error
  }
} 

export const accountRetrieveAllSuccess = (accounts) => {  
  return {
    type: actionTypes.ACCOUNT_RETRIEVE_ALL_SUCCESS,
    entries: accounts
  }
} 

//ACCOUNT_POST////////////////////////////////////////
export const accountAdd = (entry) => {  
  return {
    type: actionTypes.ACCOUNT_ADD,
    entry: entry
  }
} //=> saga/index.js

export const accountUpdate = (entry) => {  
  return {
    type: actionTypes.ACCOUNT_UPDATE,
    entry: entry,
  }
} // => saga/index.js

export const accountDelete = (entry) => {  
  return {
    type: actionTypes.ACCOUNT_DELETE,
    entry: entry,
  }
} // => saga/index.js

export const accountPostInit = () => {  
  return {
    type: actionTypes.ACCOUNT_POST_INIT
  }
}

export const accountPostFail = (error) => {  
  return {
    type: actionTypes.ACCOUNT_POST_FAIL,
    error: error
  }
} 

export const accountPostSuccess = (entry, isDelete) => {  
  return {
    type: actionTypes.ACCOUNT_POST_SUCCESS,
    entry: entry,
    isDelete: isDelete ? isDelete : false
  }
}  