import {put} from 'redux-saga/effects';
import {getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions';
import locale from "locale/index";

export function* alternativeRelationshipRetrieveAll(action) {
  yield put(actions.alternativeRelationshipRetrieveAllInit());
  try{    
    const res = yield apis.get(`portfolios/${action.portfolioId}/alternativeRelationships`, yield getAuthHeader());   
    if (res.status === 200) {  
      const alternativeRelationships = {} 
      res.data.response.forEach(entry => {    
        entry.key = entry.fromAlt + "<->" + entry.toAlt;    
        alternativeRelationships[entry.key] = {...entry};
      });      
      yield put(actions.alternativeRelationshipRetrieveAllSuccess(alternativeRelationships)); 
    } else {
      yield put(actions.alternativeRelationshipRetrieveAllFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeRelationshipRetrieveAllFail(error));
  };
}  

export function* alternativeRelationshipAdd(action) {  
  yield put(actions.alternativeRelationshipPostInit());
  try {
    const entry = {...action.entry};    
    // Update DB
    const res = yield apis.post(`alternativeRelationships`, entry, yield getAuthHeader());
    if (res.status === 200) {  
      // Update key
      entry.key = entry.fromAlt + "<->" + entry.toAlt;
      // Post success
      yield put(actions.alternativeRelationshipPostSuccess({...entry}));
      // Recalculate
      yield put(actions.portfolioRecalculate({}, ['budget']));
      // Notification
      yield put(actions.notificationShow(locale.notification_alternative_rel_added));
    } else {
      yield put(actions.alternativeRelationshipPostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeRelationshipPostFail(error));
  };
}  

export function* alternativeRelationshipUpdate(action) {
  yield put(actions.alternativeRelationshipPostInit());
  try{    
    const {...entry} = action.entry; 
    // Update DB
    const res = yield apis.put(`alternativeRelationships`, entry, yield getAuthHeader());
    if (res.status === 200) {  
      // Post success
      yield put(actions.alternativeRelationshipPostSuccess({...entry}));
      // Recalculate
      yield put(actions.portfolioRecalculate({}, ['budget']));
      // Notification
      yield put(actions.notificationShow(locale.notification_alternative_rel_updated));
    } else {
      yield put(actions.alternativeRelationshipPostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeRelationshipPostFail(error));
  };
}  

export function* alternativeRelationshipDelete(action) {
  yield put(actions.alternativeRelationshipPostInit());
  try{    
    const {...entry} = action.entry; 
    // Update DB
    const res = yield apis.delete(`alternativeRelationships`, {data: entry, ...yield getAuthHeader()});  
    if (res.status === 200) {  
      // Post success
      yield put(actions.alternativeRelationshipPostSuccess(entry, true));
      // Recalculate
      yield put(actions.portfolioRecalculate({}, ['budget']));
      // Notification
      yield put(actions.notificationShow(locale.notification_alternative_rel_deleted));
    } else {
      yield put(actions.alternativeRelationshipPostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeRelationshipPostFail(error));
  };
}
