import {takeEvery} from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { notificationShow } from './notification';
import { logoutSaga, checkAuthTimeoutSaga, authCheckStateSaga, loginSaga, authResetPwSaga, authChangePw, authFirstLogin, authGuestAccess} from './auth';
import { userGroupRetrieveAll, userGroupAdd, userGroupUpdate, userGroupDelete } from './userGroup';
import { userRetrieve, userRetrieveAll, userAdd, userUpdate, userDelete, userSendEmailLink, userConvertGuest } from './user';
import { portfolioRetrieve, portfolioRetrieveAll, portfolioUpdate, portfolioAdd, portfolioDelete, portfolioRecalculate } from './portfolio';
import { measurementRetrieveAll, measurementAdd, measurementUpdate, measurementDelete } from './measurement';
import { budgetRetrieveAll, budgetAdd, budgetUpdate, budgetSetPhasing, budgetDelete } from './budget';
import { criteriaRetrieveAll, criteriaUpdate, criteriaAdd, criteriaDelete } from './criteria';
import { criteriaVoterRetrieveAll, criteriaVoterRetrieve, criteriaVotersUpdate, criteriaVoterSendInvite, criteriaVoterSubmit, criteriaVoterAllowRemote } from './criteriaVoter';
import { alternativeRetrieveAll, alternativeAdd, alternativeUpdate, alternativeDelete, alternativeSetMeasurement, alternativeImport} from './alternative';
import { alternativeVoterRetrieveAll, alternativeVoterRetrieve, alternativeVotersUpdate, alternativeVoterSendInvite, alternativeVoterSubmit, alternativeVoterAllowRemote} from './alternativeVoter';
import { alternativeRelationshipRetrieveAll, alternativeRelationshipUpdate, alternativeRelationshipAdd, alternativeRelationshipDelete } from './alternativeRelationship';
import { alternativeCostRetrieveAll, alternativeCostUpdate, alternativeCostSetPhasing, alternativeCostAdd, alternativeCostDelete } from './alternativeCost';
import { alternativeStartSaveRetrieveAll, alternativeStartSaveUpdate, alternativeStartSaveAdd, alternativeStartSaveDelete, alternativeStartCalculate } from './alternativeStart';
import { scenarioRetrieveAll, scenarioAdd, scenarioUpdate, scenarioDelete } from './scenario';
import { reportRetrieve, reportRetrieveField, reportRetrieveAll, reportAdd, reportUpdate, reportDelete, reportUpdateField, reportAddScenario, reportRemoveScenario } from './report';
import { voterPortfolioRetrieve, voterPortfolioRetrieveAll, voterPortfolioRetrieveByCode, voterPortfolioAdd} from './voterPortfolio';
import { accountRetrieve, accountRetrieveAll, accountAdd, accountUpdate, accountDelete } from './account';

//Listen and wait for AUTH_INIT_LOGOUT and action logoutSaga
export function* watchAuth() {
  yield takeEvery(actionTypes.AUTH_LOGIN, loginSaga);
  yield takeEvery(actionTypes.AUTH_LOGOUT, logoutSaga);
  yield takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga);
  yield takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga);
  yield takeEvery(actionTypes.AUTH_RESETPW, authResetPwSaga);
  yield takeEvery(actionTypes.AUTH_CHANGEPW, authChangePw);
  yield takeEvery(actionTypes.AUTH_FIRST_LOGIN, authFirstLogin);
  yield takeEvery(actionTypes.AUTH_GUEST_ACCESS, authGuestAccess);
}

export function* watchUserGroup() {
  yield takeEvery(actionTypes.USER_GROUP_RETRIEVE_ALL, userGroupRetrieveAll);
  yield takeEvery(actionTypes.USER_GROUP_ADD, userGroupAdd);
  yield takeEvery(actionTypes.USER_GROUP_UPDATE, userGroupUpdate);
  yield takeEvery(actionTypes.USER_GROUP_DELETE, userGroupDelete);
}

export function* watchUser() {
  yield takeEvery(actionTypes.USER_RETRIEVE, userRetrieve);
  yield takeEvery(actionTypes.USER_RETRIEVE_ALL, userRetrieveAll);
  yield takeEvery(actionTypes.USER_ADD, userAdd);
  yield takeEvery(actionTypes.USER_UPDATE, userUpdate);
  yield takeEvery(actionTypes.USER_DELETE, userDelete);
  yield takeEvery(actionTypes.USER_SEND_EMAIL_LINK, userSendEmailLink);
  yield takeEvery(actionTypes.USER_CONVERT_GUEST, userConvertGuest);
}

export function* watchPortfolio() {
  yield takeEvery(actionTypes.PORTFOLIO_RETRIEVE, portfolioRetrieve);
  yield takeEvery(actionTypes.PORTFOLIO_RETRIEVE_ALL, portfolioRetrieveAll);
  yield takeEvery(actionTypes.PORTFOLIO_ADD, portfolioAdd);
  yield takeEvery(actionTypes.PORTFOLIO_UPDATE, portfolioUpdate);
  yield takeEvery(actionTypes.PORTFOLIO_DELETE, portfolioDelete);
  yield takeEvery(actionTypes.PORTFOLIO_RECALCULATE, portfolioRecalculate);
}

export function* watchMeasurement() {
  yield takeEvery(actionTypes.MEASUREMENT_RETRIEVE_ALL, measurementRetrieveAll);
  yield takeEvery(actionTypes.MEASUREMENT_ADD, measurementAdd);
  yield takeEvery(actionTypes.MEASUREMENT_UPDATE, measurementUpdate);
  yield takeEvery(actionTypes.MEASUREMENT_DELETE, measurementDelete);
}

export function* watchBudget() {
  yield takeEvery(actionTypes.BUDGET_RETRIEVE_ALL, budgetRetrieveAll);
  yield takeEvery(actionTypes.BUDGET_ADD, budgetAdd);
  yield takeEvery(actionTypes.BUDGET_UPDATE, budgetUpdate);
  yield takeEvery(actionTypes.BUDGET_UPDATE_PHASING, budgetSetPhasing);
  yield takeEvery(actionTypes.BUDGET_DELETE, budgetDelete);
}

export function* watchCriteria() {
  yield takeEvery(actionTypes.CRITERIA_RETRIEVE_ALL, criteriaRetrieveAll);
  yield takeEvery(actionTypes.CRITERIA_ADD, criteriaAdd);
  yield takeEvery(actionTypes.CRITERIA_UPDATE, criteriaUpdate);
  yield takeEvery(actionTypes.CRITERIA_DELETE, criteriaDelete);
}

export function* watchCriteriaVoter() {
  yield takeEvery(actionTypes.CRITERIA_VOTER_RETRIEVE_ALL, criteriaVoterRetrieveAll);
  yield takeEvery(actionTypes.CRITERIA_VOTER_RETRIEVE, criteriaVoterRetrieve);
  yield takeEvery(actionTypes.CRITERIA_VOTERS_UPDATE, criteriaVotersUpdate);
  yield takeEvery(actionTypes.CRITERIA_VOTER_SEND_INVITE, criteriaVoterSendInvite);
  yield takeEvery(actionTypes.CRITERIA_VOTER_SUBMIT, criteriaVoterSubmit);
  yield takeEvery(actionTypes.CRITERIA_VOTER_ALLOW_REMOTE, criteriaVoterAllowRemote);
}

export function* watchAlternative() {
  yield takeEvery(actionTypes.ALTERNATIVE_RETRIEVE_ALL, alternativeRetrieveAll);
  yield takeEvery(actionTypes.ALTERNATIVE_ADD, alternativeAdd);
  yield takeEvery(actionTypes.ALTERNATIVE_UPDATE, alternativeUpdate);
  yield takeEvery(actionTypes.ALTERNATIVE_UPDATE_MEASUREMENT, alternativeSetMeasurement);
  yield takeEvery(actionTypes.ALTERNATIVE_DELETE, alternativeDelete);  
  yield takeEvery(actionTypes.ALTERNATIVE_IMPORT, alternativeImport);  
}

export function* watchAlternativeVoter() {
  yield takeEvery(actionTypes.ALTERNATIVE_VOTER_RETRIEVE_ALL, alternativeVoterRetrieveAll);
  yield takeEvery(actionTypes.ALTERNATIVE_VOTER_RETRIEVE, alternativeVoterRetrieve);
  yield takeEvery(actionTypes.ALTERNATIVE_VOTERS_UPDATE, alternativeVotersUpdate);
  yield takeEvery(actionTypes.ALTERNATIVE_VOTER_SEND_INVITE, alternativeVoterSendInvite);
  yield takeEvery(actionTypes.ALTERNATIVE_VOTER_SUBMIT, alternativeVoterSubmit);
  yield takeEvery(actionTypes.ALTERNATIVE_VOTER_ALLOW_REMOTE, alternativeVoterAllowRemote);  
}

export function* watchAlternativeRelationship() {
  yield takeEvery(actionTypes.ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL, alternativeRelationshipRetrieveAll);
  yield takeEvery(actionTypes.ALTERNATIVE_RELATIONSHIP_ADD, alternativeRelationshipAdd);
  yield takeEvery(actionTypes.ALTERNATIVE_RELATIONSHIP_UPDATE, alternativeRelationshipUpdate);
  yield takeEvery(actionTypes.ALTERNATIVE_RELATIONSHIP_DELETE, alternativeRelationshipDelete);
}

export function* watchAlternativeCost() {
  yield takeEvery(actionTypes.ALTERNATIVE_COST_RETRIEVE_ALL, alternativeCostRetrieveAll);
  yield takeEvery(actionTypes.ALTERNATIVE_COST_ADD, alternativeCostAdd);
  yield takeEvery(actionTypes.ALTERNATIVE_COST_UPDATE, alternativeCostUpdate);
  yield takeEvery(actionTypes.ALTERNATIVE_COST_UPDATE_PHASING, alternativeCostSetPhasing);
  yield takeEvery(actionTypes.ALTERNATIVE_COST_DELETE, alternativeCostDelete);
}

export function* watchAlternativeStart() {
  yield takeEvery(actionTypes.ALTERNATIVE_START_SAVE_RETRIEVE_ALL, alternativeStartSaveRetrieveAll);
  yield takeEvery(actionTypes.ALTERNATIVE_START_SAVE_ADD, alternativeStartSaveAdd);
  yield takeEvery(actionTypes.ALTERNATIVE_START_SAVE_UPDATE, alternativeStartSaveUpdate);  
  yield takeEvery(actionTypes.ALTERNATIVE_START_SAVE_DELETE, alternativeStartSaveDelete);
  yield takeEvery(actionTypes.ALTERNATIVE_START_CALCULATE, alternativeStartCalculate);
}

export function* watchScenario() {
  yield takeEvery(actionTypes.SCENARIO_RETRIEVE_ALL, scenarioRetrieveAll);
  yield takeEvery(actionTypes.SCENARIO_ADD, scenarioAdd);
  yield takeEvery(actionTypes.SCENARIO_UPDATE, scenarioUpdate);
  yield takeEvery(actionTypes.SCENARIO_DELETE, scenarioDelete);
}

export function* watchReport() {
  yield takeEvery(actionTypes.REPORT_RETRIEVE, reportRetrieve);
  yield takeEvery(actionTypes.REPORT_RETRIEVE_FIELD, reportRetrieveField);
  yield takeEvery(actionTypes.REPORT_RETRIEVE_ALL, reportRetrieveAll);
  yield takeEvery(actionTypes.REPORT_ADD, reportAdd);
  yield takeEvery(actionTypes.REPORT_UPDATE, reportUpdate);
  yield takeEvery(actionTypes.REPORT_DELETE, reportDelete);
  yield takeEvery(actionTypes.REPORT_UPDATE_FIELD, reportUpdateField);
  yield takeEvery(actionTypes.REPORT_ADD_SCENARIO, reportAddScenario);
  yield takeEvery(actionTypes.REPORT_DELETE_SCENARIO, reportRemoveScenario);
}

export function* watchAccount() {
  yield takeEvery(actionTypes.ACCOUNT_RETRIEVE, accountRetrieve);
  yield takeEvery(actionTypes.ACCOUNT_RETRIEVE_ALL, accountRetrieveAll);
  yield takeEvery(actionTypes.ACCOUNT_ADD, accountAdd);
  yield takeEvery(actionTypes.ACCOUNT_UPDATE, accountUpdate);
  yield takeEvery(actionTypes.ACCOUNT_DELETE, accountDelete);
}

export function* watchVoterPortfolio() {
  yield takeEvery(actionTypes.VOTER_PORTFOLIO_RETRIEVE, voterPortfolioRetrieve);
  yield takeEvery(actionTypes.VOTER_PORTFOLIO_RETRIEVE_ALL, voterPortfolioRetrieveAll);
  yield takeEvery(actionTypes.VOTER_PORTFOLIO_RETRIEVE_BY_CODE, voterPortfolioRetrieveByCode);
  yield takeEvery(actionTypes.VOTER_PORTFOLIO_ADD, voterPortfolioAdd);
}

export function* watchNotification() {
  yield takeEvery(actionTypes.NOTIFICATION_SHOW, notificationShow);
}

