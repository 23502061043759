import dateformat from 'dateformat';
import {isDate} from 'lodash'

export const capitalFormatter = (params) =>{
  if (params === null || params === undefined) {
    return null;
  } else if (typeof params === 'string') {
    return params.charAt(0).toUpperCase() + params.slice(1);
  } else if (params.value) {
    return params.value.charAt(0).toUpperCase() + params.value.slice(1);
  } else {
    return null;
  }
}

export const percentageFormatter = (params) =>{
  if (!params) return 0  + "%";
  
  let value = params;
  if (params.value) {
    value = params.value;
  }
  return Math.floor(value * 10000) / 100 + "%";
}

export const dollarFormatter = (params) => {
  if (params.value) {
    return "$ " + numberFormatter(params);
  } else {
    return '';
  }
}

export const numberFormatter = (params) =>{
  if (!isNaN(params.value)) {
    if (params.value === '0') return '-';
    return formatNumber(params.value);
  } else {
    return '';
  }
}

export const numberParser = (params) =>{
  return Number(params.newValue);
}

export const formatNumber = (number) =>{
  return (Math.floor(number * 100) / 100)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const dateFormatter = (params) =>{  
  if (isDate(params)) {
    return dateformat(params, "dS mmm yyyy");
  } else if (params.value) {
    if (params.value.seconds) return dateformat(params.value.toDate(), "dS mmm yyyy");
    else return dateformat(params.value, "dS mmm yyyy");  
  } 
  return '';
}

export const dateParser = (params) =>{
  return Date(params.newValue);
}

export const timeFormatter = (params) =>{
  if (params.value) {
    if (params.value.seconds) return dateformat(params.value.toDate(), "h:MM:ss TT");
    return dateformat(params.value,'h:MM:ss TT');
  } 
  return '';
}