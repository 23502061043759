import * as actionTypes from './actionTypes';

//CRITERIA_RETRIEVE_ALL////////////////////////////////////////
export const criteriaRetrieveAll = (portfolioId, guestId) => {  
  return {
    type: actionTypes.CRITERIA_RETRIEVE_ALL,
    portfolioId: portfolioId,
    guestId: guestId
  }
} // => saga/index.js

export const criteriaRetrieveAllInit = () => {  
  return {
    type: actionTypes.CRITERIA_RETRIEVE_ALL_INIT
  }
} 

export const criteriaRetrieveAllFail = (error) => {  
  return {
    type: actionTypes.CRITERIA_RETRIEVE_ALL_FAIL,
    error: error
  }
} 

export const criteriaRetrieveAllSuccess = (criterias) => {  
  return {
    type: actionTypes.CRITERIA_RETRIEVE_ALL_SUCCESS,
    entries: criterias
  }
} 

//CRITERIA_POST////////////////////////////////////////
export const criteriaAdd = (portfolioId, entry) => {  
  return {
    type: actionTypes.CRITERIA_ADD,
    portfolioId: portfolioId,
    entry: entry
  }
} //=> saga/index.js

export const criteriaUpdate = (portfolioId, entry) => {  
  return {
    type: actionTypes.CRITERIA_UPDATE,
    portfolioId: portfolioId,
    entry: entry,
  }
} // => saga/index.js

export const criteriaDelete = (portfolioId, entry) => {  
  return {
    type: actionTypes.CRITERIA_DELETE,
    portfolioId: portfolioId,
    entry: entry,
  }
} // => saga/index.js

export const criteriaPostInit = () => {  
  return {
    type: actionTypes.CRITERIA_POST_INIT
  }
}

export const criteriaPostFail = (error) => {  
  return {
    type: actionTypes.CRITERIA_POST_FAIL,
    error: error
  }
} 

export const criteriaPostSuccess = (entry, isDelete) => {  
  return {
    type: actionTypes.CRITERIA_POST_SUCCESS,
    entry: entry,
    isDelete: isDelete ? isDelete : false
  }
}  