import * as actionTypes from './actionTypes';

//USER_GROUP_RETRIEVE_ALL////////////////////////////////////////
export const userGroupRetrieveAll = (accountId) => {    
    return {
        type: actionTypes.USER_GROUP_RETRIEVE_ALL,
        accountId: accountId
    }
} // => saga/index.js

export const userGroupRetrieveAllInit = () => {    
    return {
        type: actionTypes.USER_GROUP_RETRIEVE_ALL_INIT
    }
} 

export const userGroupRetrieveAllFail = (error) => {    
    return {
        type: actionTypes.USER_GROUP_RETRIEVE_ALL_FAIL,
        error: error
    }
} 

export const userGroupRetrieveAllSuccess = (userGroups) => {    
    return {
        type: actionTypes.USER_GROUP_RETRIEVE_ALL_SUCCESS,
        entries: userGroups
    }
} 

//USER_GROUP_POST////////////////////////////////////////
export const userGroupAdd = (accountId, entry) => {    
    return {
        type: actionTypes.USER_GROUP_ADD,
        accountId: accountId,
        entry: entry
    }
} //=> saga/index.js

export const userGroupUpdate = (entry) => {    
    return {
        type: actionTypes.USER_GROUP_UPDATE,
        entry: entry,
    }
} // => saga/index.js

export const userGroupDelete = (entry) => {    
    return {
        type: actionTypes.USER_GROUP_DELETE,
        entry: entry,
    }
} // => saga/index.js

export const userGroupPostInit = () => {    
    return {
        type: actionTypes.USER_GROUP_POST_INIT
    }
}

export const userGroupPostFail = (error) => {    
    return {
        type: actionTypes.USER_GROUP_POST_FAIL,
        error: error
    }
} 

export const userGroupPostSuccess = (entry, isDelete) => {    
    return {
        type: actionTypes.USER_GROUP_POST_SUCCESS,
        entry: entry,
        isDelete: isDelete ? isDelete : false
    }
}  