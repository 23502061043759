import {put} from 'redux-saga/effects';
import {getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions';
import locale from "locale/index";

export function* userGroupRetrieveAll(action) {
  yield put(actions.userGroupRetrieveAllInit());
  try{
    const res = yield apis.get(`/accounts/${action.accountId}/accountUserGroups`, yield getAuthHeader());     
    if (res.status === 200) {
      const userGroups = res.data.response.slice();
      yield put(actions.userGroupRetrieveAllSuccess(userGroups)); 
    } else {
      yield put(actions.userGroupRetrieveAllFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.userGroupRetrieveAllFail(error));
  };
}  

export function* userGroupAdd(action) {  
  yield put(actions.userGroupPostInit());
  try {
    const userGroup = {...action.entry, account: action.accountId};
    // Update DB
    const res = yield apis.post("/accountUserGroups", userGroup, yield getAuthHeader());
    if (res.status === 200) {
      // Update key
      userGroup.key = Number(res.data.response.insertId);
      // Post success
      yield put(actions.userGroupPostSuccess({...userGroup}));
      // Notification
      yield put(actions.notificationShow(locale.notification_user_group_added, {name: userGroup.name}));
    } else {
      yield put(actions.userGroupPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.userGroupPostFail(error));
  };
}  

export function* userGroupUpdate(action) {
  yield put(actions.userGroupPostInit());
  try{    
    const {...userGroup} = action.entry; 
    // Update DB
    const res = yield apis.put(`/accountUserGroups/${userGroup.key}`, userGroup, yield getAuthHeader());  
    if (res.status === 200) {
      // Post success
      yield put(actions.userGroupPostSuccess({...userGroup}));
      // Notification
      yield put(actions.notificationShow(locale.notification_user_group_updated, {name: userGroup.name}));
    } else {
      yield put(actions.userGroupPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.userGroupPostFail(error));
  };
}  

export function* userGroupDelete(action) {
  yield put(actions.userGroupPostInit());
  try{    
    const {...userGroup} = action.entry; 
    // Update DB
    const res = yield apis.delete(`/accountUserGroups/${userGroup.key}`, yield getAuthHeader());  
    if (res.status === 200) {
      // Post success
      yield put(actions.userGroupPostSuccess(userGroup, true));
      // Notification
      yield put(actions.notificationShow(locale.notification_user_group_deleted, {name: userGroup.name}));
    } else {
      yield put(actions.userGroupPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.userGroupPostFail(error));
  };
}
