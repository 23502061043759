import React from 'react'
import {FormattedMessage, defineMessages} from 'react-intl';
  //Page Names
const auth = {
  page_name_log_in: <FormattedMessage id="page_name_log_in" defaultMessage="Log In" />,
  page_name_log_out: <FormattedMessage id="page_name_log_out" defaultMessage="Log Out" />,
  page_name_change_pw: <FormattedMessage id="page_name_change_pw" defaultMessage="Change Password" />,
  page_name_change_pw_short: <FormattedMessage id="page_name_change_pw_short" defaultMessage="Change PW" />,
  page_name_forgot_pw: <FormattedMessage id="page_name_forgot_pw" defaultMessage="Forgot Password" />,
  page_name_forgot_pw_short: <FormattedMessage id="page_name_forgot_pw_short" defaultMessage="Forgot Pw" />,
  //Pages - Authentication
  auth_first_time_helper: defineMessages({props: {id: "auth_first_time_helper", defaultMessage: "Since this is your first time logging in, please confirm your email address and set a new password. If you have created a password prior please click {hereLink} to login with your email and password: "}}),  
  auth_first_time_email: <FormattedMessage id="auth_first_time_email" defaultMessage="Confirm Your Email *" />,
  auth_first_time_new_pw: <FormattedMessage id="auth_first_time_new_pw" defaultMessage="New Password *" />,
  auth_first_time_confirm_pw: <FormattedMessage id="auth_first_time_confirm_pw" defaultMessage="Confirm New Password *" />,
  auth_tab_sign_in: <FormattedMessage id="auth_tab_sign_in" defaultMessage="Sign In" />,
  auth_tab_guest: <FormattedMessage id="auth_tab_guest" defaultMessage="Guest" />,
  auth_log_in: <FormattedMessage id="auth_log_in" defaultMessage="Log In" />,
  auth_log_in_email: <FormattedMessage id="auth_log_in_email" defaultMessage="Email" />,
  auth_log_in_password: <FormattedMessage id="auth_log_in_password" defaultMessage="Password" />,
  auth_guest_access_code: <FormattedMessage id="auth_guest_access_code" defaultMessage="Access Code" />,
  auth_guest_submit: <FormattedMessage id="auth_guest_submit" defaultMessage="Submit" />,
  auth_guest_helper: <FormattedMessage id="auth_guest_helper" defaultMessage="Please provide your name and email address, along with the access code provided by the facilitator. If you've already had an account, please sign in instead." />,
  auth_sign_out: <FormattedMessage id="auth_sign_out" defaultMessage="Log Out" />,
  auth_forgot_pw: <FormattedMessage id="auth_forgot_pw" defaultMessage="Forgot password" />,
  auth_reset_pw: <FormattedMessage id="auth_reset_pw" defaultMessage="Send Link" />,
  auth_reset_pw_helper: <FormattedMessage id="auth_reset_pw_helper" defaultMessage="To reset your password enter the email address you use to login. A link will be emailed to this address which will let you reset your password." />,
  auth_reset_email: <FormattedMessage id="auth_reset_email" defaultMessage="Email *" />,
  auth_reset_pw_complete: defineMessages({props: {id: "auth_reset_pw_complete", defaultMessage: "A link to reset your password has been sent to: {email}. If you don't receive an email shortly check your 'bulk email' or 'junk email' folders."}}),    
  auth_change_pw: <FormattedMessage id="auth_change_pw" defaultMessage="Change Password" />,
  auth_change_pw_helper: <FormattedMessage id="auth_change_pw_helper" defaultMessage="Change password for account " />,
  auth_change_pw_existing_pw: <FormattedMessage id="auth_change_pw_existing_pw" defaultMessage="Current Password *" />,
  auth_change_pw_new_pw: <FormattedMessage id="auth_change_pw_new_pw" defaultMessage="New Password *" />,
  auth_change_pw_confirm_pw: <FormattedMessage id="auth_change_pw_confirm_pw" defaultMessage="Confirm New Password *" />,
  auth_change_pw_confirm_help: <FormattedMessage id="auth_change_pw_confirm_help" defaultMessage="Please retype your new password" />
}
export default auth;