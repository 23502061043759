import React from 'react'
import {FormattedMessage, defineMessages} from 'react-intl';
const pagePortfolio = {
  //HeaderLink
  header_pdf_new: <FormattedMessage id="header_pdf_new" defaultMessage="Create New" />,
  header_pdf_new_with_scenario: <FormattedMessage id="header_pdf_new_with_scenario" defaultMessage="Create New With Current Scenario" />,
  header_pdf_new_message: <FormattedMessage id="header_pdf_new_message" defaultMessage="To create, please enter a unique name for the report" />,
  //Heading
  portfolio_baseline_scenario: <FormattedMessage id="portfolio_baseline_scenario" defaultMessage="Baseline Scenario" />,
  //Tab Header
  tab_portfolio_properties: <FormattedMessage id="tab_portfolio_properties" defaultMessage="Properties" />,
  tab_portfolio_budget: <FormattedMessage id="tab_portfolio_budget" defaultMessage="Budgets" />,
  tab_portfolio_criteria: <FormattedMessage id="tab_portfolio_criteria" defaultMessage="Criteria" />,
  tab_portfolio_importance: <FormattedMessage id="tab_portfolio_importance" defaultMessage="Importance" />,
  tab_portfolio_alternative: <FormattedMessage id="tab_portfolio_alternative" defaultMessage="Alternatives" />,
  tab_portfolio_sensitivity: <FormattedMessage id="tab_portfolio_sensitivity" defaultMessage="Sensitivity" />,
  tab_portfolio_optimise: <FormattedMessage id="tab_portfolio_optimise" defaultMessage="Optimise" />,
  tab_portfolio_value: <FormattedMessage id="tab_portfolio_value" defaultMessage="Value" />,
  //Section - Budget
  portfolio_budget_heading: <FormattedMessage id="portfolio_budget_heading" defaultMessage="Budgets" />,
  portfolio_budget_subheading: <FormattedMessage id="portfolio_budget_subheading" defaultMessage="Alternative Budgets" />,
  portfolio_budget_further_info: <FormattedMessage id="portfolio_budget_further_info" defaultMessage="Budgets are the known, objective and quantifiable data for each of the Alternatives in the portfolio. A budget can also be a constrained resource that we can optimise the portfolio against." />,
  portfolio_budget_add: <FormattedMessage id="portfolio_budget_add" defaultMessage="Add New Budget" />,
  portfolio_budget_add_title: <FormattedMessage id="portfolio_budget_add_title" defaultMessage="Add New Budget" />,
  portfolio_budget_add_name: <FormattedMessage id="portfolio_budget_add_name" defaultMessage="Source" />,
  portfolio_budget_add_name_helptext: <FormattedMessage id="portfolio_budget_add_name_helptext" defaultMessage="Source of the budget" />,
  portfolio_budget_add_description: <FormattedMessage id="portfolio_budget_add_description" defaultMessage="Budget Description" />,
  portfolio_budget_add_description_helptext: <FormattedMessage id="portfolio_budget_add_description_helptext" defaultMessage="Description of the budget" />,
  portfolio_budget_add_phasing: <FormattedMessage id="portfolio_budget_add_phasing" defaultMessage="Phasing" />,
  portfolio_budget_add_phasing_helptext: <FormattedMessage id="portfolio_budget_add_phasing_helptext" defaultMessage="Phasing of Budget" />,
  portfolio_budget_add_btn: <FormattedMessage id="portfolio_budget_add_btn" defaultMessage="Create Budget" />,
  portfolio_budget_add_btn_next: <FormattedMessage id="portfolio_budget_add_btn_next" defaultMessage="Create & Next" />,
  portfolio_budget_edit_title: <FormattedMessage id="portfolio_budget_edit_title" defaultMessage="Edit Budget" />,
  portfolio_budget_delete_error: defineMessages({props: {id: "portfolio_budget_delete_error", defaultMessage: "Cannot delete Budget {name} since it's link to existing Cost(s), please remove it first and try again."}}),  
  portfolio_budget_col_name: defineMessages({props: {id: "portfolio_budget_col_name", defaultMessage: "Source"}}),
  portfolio_budget_col_description: defineMessages({props: {id: "portfolio_budget_col_description", defaultMessage: "Description"}}),
  portfolio_budget_col_frequency: defineMessages({props: {id: "portfolio_budget_col_frequency", defaultMessage: "Frequency"}}),
  portfolio_budget_col_has_alternative: defineMessages({props: {id: "portfolio_budget_col_has_alternative", defaultMessage: "Alternative"}}),
    //Section - Budget Phasing  
  portfolio_budget_phasing_subheading: <FormattedMessage id="portfolio_budget_phasing_subheading" defaultMessage="Budget's Phasing" />,
  portfolio_budget_phasing_further_info: <FormattedMessage id="portfolio_budget_phasing_further_info" defaultMessage="How the budget has been split across the life of the project. Select a cell and start typing to edit the selected measurement, press Enter to save." />,
  portfolio_budget_phasing_col_name: defineMessages({props: {id: "portfolio_budget_phasing_col_name", defaultMessage: "Budget"}}),
  //Section - Measurement
  portfolio_measurement: <FormattedMessage id="measurement" defaultMessage="Measurement" />,
  portfolio_measurement_type_discrete: <FormattedMessage id="portfolio_measurement_type_discrete" defaultMessage="Discrete" />,
  portfolio_measurement_type_continuous: <FormattedMessage id="portfolio_measurement_type_continuous" defaultMessage="Continuous" />,
  portfolio_measurement_type_discrete_array: <FormattedMessage id="portfolio_measurement_type_discrete_array" defaultMessage="Discrete Array" />,
  portfolio_measurement_type_discrete_array_helptext: <FormattedMessage id="portfolio_measurement_type_discrete_array_helptext" defaultMessage="Subjective Choices e.g. Very Cheap, Cheap, Normal, Expensive, Very Expensive." />,
  portfolio_measurement_heading: <FormattedMessage id="portfolio_measurement_heading" defaultMessage="Measurements" />,
  portfolio_measurement_subheading: <FormattedMessage id="portfolio_measurement_subheading" defaultMessage="Alternative Measurements" />,
  portfolio_measurement_further_info: <FormattedMessage id="portfolio_measurement_further_info" defaultMessage="Measurements are the known, objective and quantifiable data for each of the Alternatives in the portfolio. A measurement can also be a constrained resource that we can optimise the portfolio against." />,
  portfolio_measurement_add: <FormattedMessage id="portfolio_measurement_add" defaultMessage="Add New Measurement" />,
  portfolio_measurement_add_title: <FormattedMessage id="portfolio_measurement_add_title" defaultMessage="Add New Measurement" />,
  portfolio_measurement_add_name: <FormattedMessage id="portfolio_measurement_add_name" defaultMessage="Name" />,
  portfolio_measurement_add_name_helptext: <FormattedMessage id="portfolio_measurement_add_name_helptext" defaultMessage="Name of the measurement" />,
  portfolio_measurement_add_description: <FormattedMessage id="portfolio_measurement_add_description" defaultMessage="Description" />,
  portfolio_measurement_add_description_helptext: <FormattedMessage id="portfolio_measurement_add_description_helptext" defaultMessage="Description of the measurement" />,
  portfolio_measurement_add_unit: <FormattedMessage id="portfolio_measurement_add_unit" defaultMessage="Unit" />,
  portfolio_measurement_add_unit_helptext: <FormattedMessage id="portfolio_measurement_add_unit_helptext" defaultMessage="Unit of Measurement e.g. % kg" />,
  portfolio_measurement_add_unit_prefix: <FormattedMessage id="portfolio_measurement_add_unit_prefix" defaultMessage="Prefix Unit" />,
  portfolio_measurement_add_unit_prefix_helptext: <FormattedMessage id="portfolio_measurement_add_unit_prefix_helptext" defaultMessage="Prefix Unit of Measurement e.g. $ " />,
  portfolio_measurement_add_unit_error: <FormattedMessage id="portfolio_measurement_add_unit_error" defaultMessage="No Unit has been provided for this measurement" />,
  portfolio_measurement_add_budget: <FormattedMessage id="portfolio_measurement_add_budget" defaultMessage="Budget" />,
  portfolio_measurement_add_budget_helptext: <FormattedMessage id="portfolio_measurement_add_budget_helptext" defaultMessage="Budget in term of this measurement (Optional)" />,
  portfolio_measurement_add_btn: <FormattedMessage id="portfolio_measurement_add_btn" defaultMessage="Create Measurement" />,
  portfolio_measurement_add_btn_next: <FormattedMessage id="portfolio_measurement_add_btn_next" defaultMessage="Create & Next" />,
  portfolio_measurement_edit_title: <FormattedMessage id="portfolio_measurement_edit_title" defaultMessage="Edit Measurement" />,
  portfolio_measurement_delete_error: defineMessages({props: {id: "portfolio_measurement_delete_error", defaultMessage: "Cannot delete Measurement {name} since it's link to an existing Criteria, please remove it first and try again."}}),  
  portfolio_measurement_col_name: defineMessages({props: {id: "portfolio_measurement_col_name", defaultMessage: "Measurement"}}),
  portfolio_measurement_col_description: defineMessages({props: {id: "portfolio_measurement_col_description", defaultMessage: "Description"}}),
  portfolio_measurement_col_type: defineMessages({props: {id: "portfolio_measurement_col_type", defaultMessage: "Type"}}),
  portfolio_measurement_col_unit: defineMessages({props: {id: "portfolio_measurement_col_unit", defaultMessage: "Unit of Measure"}}),
  portfolio_measurement_col_has_criteria: defineMessages({props: {id: "portfolio_measurement_col_has_criteria", defaultMessage: "Criteria"}}),
  portfolio_measurement_col_has_optimise: defineMessages({props: {id: "portfolio_measurement_col_has_optimise", defaultMessage: "Constrained Resource"}}),
  //Section - Criteria
  criteria: <FormattedMessage id="criteria" defaultMessage="Criteria" />,
  criteria_assessment_heading: <FormattedMessage id="criteria_assessment_heading" defaultMessage="Criteria" />,
  criteria_assessment_subheading: <FormattedMessage id="criteria_assessment_subheading" defaultMessage="Assessment Criteria for the portfolio" />,
  criteria_assessment_further_info: <FormattedMessage id="criteria_assessment_further_info" defaultMessage="Assessment Criteria define how you want to assess the portfolio, and how well an alternative acheives the desired goals." />,
  criteria_assessment_add: <FormattedMessage id="criteria_assessment_add" defaultMessage="Add New Criteria" />,
  criteria_add_title: <FormattedMessage id="criteria_add_title" defaultMessage="Add a new criteria to the Portfolio" />,
  criteria_edit_title: <FormattedMessage id="criteria_edit_title" defaultMessage="Edit Criteria" />,
  criteria_add_name_helptext: <FormattedMessage id="criteria_add_name_helptext" defaultMessage="eg. Alignment to goverment policy" />,
  criteria_add_description_helptext: <FormattedMessage id="criteria_add_description_helptext" defaultMessage="How does this criteria help you assess the alternatives" />,
  criteria_add_btn_submit: <FormattedMessage id="criteria_add_btn_submit" defaultMessage="Create Criteria" />,
  criteria_add_btn_submit_next: <FormattedMessage id="criteria_add_btn_submit_next" defaultMessage="Create & Next" />,
  criteria_select_parent: <FormattedMessage id="parent_criteria" defaultMessage="Parent Criteria" />,
  criteria_select_parent_choose: <FormattedMessage id="parent_criteria_choose" defaultMessage="Choose Parent Criteria" />,
  criteria_select_parent_desc: <FormattedMessage id="parent_criteria_desc" defaultMessage="Select the Parent Criteria (Optional)" />,
  criteria_add_cannot_with_vote_submitted: <FormattedMessage id="criteria_add_cannot_with_vote_submitted" defaultMessage="Cannot add new Criteria while there is existing submitted votes please remove them and try again." />,
  criteria_delete_cannot_with_vote_submitted: <FormattedMessage id="criteria_delete_cannot_with_vote_submitted" defaultMessage="Cannot delete Criteria while there is existing submitted votes please remove them and try again." />,
  criteria_assessment_col_name: defineMessages({props: {id: "criteria_assessment_col_name", defaultMessage: "Criteria"}}),
  criteria_assessment_col_description: defineMessages({props: {id: "criteria_assessment_col_description", defaultMessage: "Description"}}),
  criteria_assessment_col_type: defineMessages({props: {id: "criteria_assessment_col_type", defaultMessage: "Criteria Type"}}),
  criteria_assessment_col_comment: defineMessages({props: {id: "criteria_assessment_col_comment", defaultMessage: "Comment"}}),
  //Section - Criteria - Type
  criteria_type: <FormattedMessage id="criteria_type" defaultMessage="Criteria Type" />,
  criteria_type_select_helptext: <FormattedMessage id="criteria_type_select_helptext" defaultMessage="Select the Criteria Type" />,
  criteria_type_discrete: <FormattedMessage id="criteria_type_discrete" defaultMessage="Discrete" />,
  criteria_type_continuous: <FormattedMessage id="criteria_type_continuous" defaultMessage="Continuous" />,
  criteria_type_continuous_upper: <FormattedMessage id="criteria_type_continuous_upper" defaultMessage="Continuous Upper" />,
  criteria_type_continuous_lower: <FormattedMessage id="criteria_type_continuous_lower" defaultMessage="Continuous Lower" />,
  criteria_type_continuous_lower_is_better: <FormattedMessage id="criteria_type_continuous_lower_is_better" defaultMessage="Lower is Better" />,
  criteria_type_continuous_tooltip: defineMessages({props: {id: "criteria_type_continuous_tooltip", defaultMessage: "Continuous: {lower} - {upper}"}}),
  criteria_type_discrete_array: <FormattedMessage id="criteria_type_discrete_array" defaultMessage="Discrete Array" />,
  criteria_type_discrete_array_helptext: <FormattedMessage id="criteria_type_discrete_array_helptext" defaultMessage="Subjective Choices e.g. Very Cheap, Cheap, Normal, Expensive, Very Expensive." />,
  criteria_type_discrete_array_example: <FormattedMessage id="criteria_type_discrete_array_example" defaultMessage="Very Cheap Cheap Normal Expensive Very Expensive" />,
  criteria_type_discrete_array_tooltip: defineMessages({props: {id: "criteria_type_discrete_array_tooltip", defaultMessage: "Discrete: {array}"}}),
  criteria_type_discrete_none_linear: <FormattedMessage id="criteria_type_discrete_none_linear" defaultMessage="Nonlinear Ratings" />,
  criteria_type_measurement: <FormattedMessage id="criteria_type_measurement" defaultMessage="Measurement" />,
  criteria_type_measurement_select: <FormattedMessage id="criteria_type_measurement_select" defaultMessage="Measurement" />,
  criteria_type_measurement_select_choose: <FormattedMessage id="criteria_type_measurement_select_choose" defaultMessage="Choose a Measurement" />,
  criteria_type_measurement_select_helptext: <FormattedMessage id="criteria_type_measurement_select_helptext" defaultMessage="Select the measurement you wish to include in the portoflio criteria" />,
  criteria_type_measurement_desc_pre: <FormattedMessage id="criteria_type_measurement_desc_pre" defaultMessage="See Measurement" />,
  criteria_type_measurement_tooltip: defineMessages({props: {id: "criteria_type_measurement_tooltip", defaultMessage: "Measurement"}}),
  criteria_type_parent: <FormattedMessage id="criteria_type_parent" defaultMessage="Parent Criteria" />,
  criteria_type_parent_select: <FormattedMessage id="criteria_type_parent_select" defaultMessage="Parent Criteria" />,
  criteria_type_parent_select_helptext: <FormattedMessage id="criteria_type_parent_select_helptext" defaultMessage="Select a parent Criteria (Optional)" />,
  //Section - Criteria Voter
  criteria_voter_heading: <FormattedMessage id="criteria_voter_heading" defaultMessage="Criteria Voting" />,
  criteria_voter_subheading: <FormattedMessage id="criteria_voter_subheading" defaultMessage="Current Voting Users for this portfolio's Criteria" />,
  criteria_voter_further_info: <FormattedMessage id="criteria_voter_further_info" defaultMessage="These are the users that will cast votes on the relative merits of each assessment Criteria.  This should generally be a select group of executives or senior stakeholders." />,
  //Section - Importance Pairwise
  importance_pairwise_analysis_heading: <FormattedMessage id="importance_pairwise_analysis_heading" defaultMessage="Criteria Importance" />,
  importance_pairwise_analysis_subheading: <FormattedMessage id="importance_pairwise_analysis_subheading" defaultMessage="The Relative Importance of the Criteria in the Portfolio" />,
  importance_pairwise_analysis_further_info: <FormattedMessage id="importance_pairwise_analysis_further_info" defaultMessage="Based on the pairwise analysis and voting by the Criteria voting member group below, is the relative importance of each of the assessment criteria." />,
  importance_pairwise_analysis_consistency: <FormattedMessage id="importance_pairwise_analysis_consistency" defaultMessage="Consistency Ratio" />,
  importance_pairwise_analysis_consistency_target: <FormattedMessage id="importance_pairwise_analysis_consistency_target" defaultMessage="Target Ratio: < 0.1" />,
  importance_pairwise_analysis_sort_by_alphabet: <FormattedMessage id="importance_pairwise_analysis_sort_by_alphabet" defaultMessage="Sort by Alphabet" />,
  importance_pairwise_analysis_sort_by_priority: <FormattedMessage id="importance_pairwise_analysis_sort_by_priority" defaultMessage="Sort by Priority" />,
  importance_pairwise_graph_file_name: defineMessages({props: {id: "importance_pairwise_graph_file_name", defaultMessage: "pairwise-analysis"}}),
  importance_prioritisation_heading: <FormattedMessage id="importance_prioritisation_heading" defaultMessage="Criteria Prioritisation Matrix" />,
  importance_prioritisation_subheading: <FormattedMessage id="importance_prioritisation_subheading" defaultMessage="Criteria Pairwise Alignment Matrix" />,
  importance_prioritisation_further_info: <FormattedMessage id="importance_prioritisation_further_info" defaultMessage="Shows the paired comparison matrix and its priority vector obtained from normalized Eigen vector of the matrix." />,
  importance_agreement_heading: <FormattedMessage id="importance_agreement_heading" defaultMessage="Criteria Voting Alignment" />,
  importance_agreement_subheading: <FormattedMessage id="importance_agreement_subheading" defaultMessage="The Relative Alignment of Voting Users Responses" />,
  importance_agreement_further_info: <FormattedMessage id="importance_agreement_further_info" defaultMessage="Having all of the Users aligned and voting with the same sentiment is a great result, but can be difficult. Understanding where the misalignment can help facilitate a constructive discussion." />,
  importance_sub_criteria: <FormattedMessage id="importance_sub_criteria" defaultMessage="Sub-Criteria" />,
  importance_col_criteria: defineMessages({props: {id: "importance_col_criteria", defaultMessage: "Criteria"}}),
  importance_col_priority: defineMessages({props: {id: "importance_col_priority", defaultMessage: "Priority"}}),
  importance_col_variation: defineMessages({props: {id: "importance_col_variation", defaultMessage: "Variation"}}),
  //Section - Alternatives
  alternative: <FormattedMessage id="alternative" defaultMessage="Alternative" />,
  alternative_item_heading: <FormattedMessage id="alternative_item_heading" defaultMessage="Alternatives" />,
  alternative_item_subheading: <FormattedMessage id="alternative_item_subheading" defaultMessage="Current Alternatives in this Portfolio" />,
  alternative_item_further_info: <FormattedMessage id="alternative_item_further_info" defaultMessage="Alternatives are the projects, activities or items you are trying to prioritise." />,
  alternative_item_measurement: <FormattedMessage id="alternative_item_measurement" defaultMessage="Measurements" />,
  alternative_item_add: <FormattedMessage id="alternative_item_add" defaultMessage="Add New Alternative" />,
  alternative_item_add_title: <FormattedMessage id="alternative_item_add_title" defaultMessage="Add New Alternative" />,
  alternative_name: <FormattedMessage id="alternative_name" defaultMessage="Name" />,
  alternative_name_helptext: <FormattedMessage id="alternative_name_helptext" defaultMessage="Name of the alternative" />,
  alternative_description: <FormattedMessage id="alternative_description" defaultMessage="Description" />,
  alternative_description_helptext: <FormattedMessage id="alternative_description_helptext" defaultMessage="Description of the alternative" />,
  alternative_abn: <FormattedMessage id="alternative_abn" defaultMessage="ABN" />,
  alternative_abn_helptext: <FormattedMessage id="alternative_abn_helptext" defaultMessage="Australian Business Number" />,
  alternative_item_add_btn: <FormattedMessage id="alternative_item_add_btn" defaultMessage="Create Alternative" />,
  alternative_item_add_btn_next: <FormattedMessage id="alternative_item_add_btn_next" defaultMessage="Create & Next" />,
  alternative_item_edit_title: <FormattedMessage id="alternative_item_edit_title" defaultMessage="Edit Alternative" />,
  alternative_item_add_cannot_with_vote_submitted: <FormattedMessage id="alternative_item_add_cannot_with_vote_submitted" defaultMessage="Cannot add new Alternative while there is existing submitted votes, please remove hem and try again." />,
  alternative_item_delete_cannot_with_vote_submitted: <FormattedMessage id="alternative_item_delete_cannot_with_vote_submitted" defaultMessage="Cannot delete Alternative while there is existing submitted votes, please remove them and try again." />,
  alternative_item_delete_cannot_with_child: <FormattedMessage id="alternative_item_delete_cannot_with_child" defaultMessage="Cannot delete Alternative while there is existing cost(s) or relationship(s), please remove them and try again." />,
  alternative_item_delete_cannot_with_selection: <FormattedMessage id="alternative_item_delete_cannot_with_selection" defaultMessage="Cannot delete Alternative while it is selected in Optimise tab, please remove it and try again." />,
  alternative_item_col_name: defineMessages({props: {id: "alternative_item_col_name", defaultMessage: "Alternative"}}),
  alternative_item_col_description: defineMessages({props: {id: "alternative_item_col_description", defaultMessage: "Description"}}),
  alternative_item_col_abn: defineMessages({props: {id: "alternative_item_col_abn", defaultMessage: "ABN"}}),
  alternative_item_col_comment: defineMessages({props: {id: "alternative_item_col_comment", defaultMessage: "Comment"}}),
  //Section - Alternative Measurement
  alternative_measurement_heading: <FormattedMessage id="alternative_measurement_heading" defaultMessage="Alternative Measurements" />,
  alternative_measurement_subheading: <FormattedMessage id="alternative_measurement_subheading" defaultMessage="Measurements of each Alternative" />,
  alternative_measurement_further_info: <FormattedMessage id="alternative_measurement_further_info" defaultMessage="Enter the value of each or the defined measurments. Select a cell and start typing to edit the selected measurement, press Enter to save." />,
  alternative_measurement_col_name: defineMessages({props: {id: "alternative_measurement_col_name", defaultMessage: "Alternative"}}),
  //Section - Alternative Budget
  alternative_cost: <FormattedMessage id="alternative_cost" defaultMessage="Alternative Cost" />,
  alternative_cost_heading: <FormattedMessage id="alternative_cost_heading" defaultMessage="Alternative Costs" />,
  alternative_cost_subheading: <FormattedMessage id="alternative_cost_subheading" defaultMessage="Costs of each Alternative" />,
  alternative_cost_further_info: <FormattedMessage id="alternative_cost_further_info" defaultMessage="Enter the value of each or the defined costs" />,
  alternative_cost_type_opex: <FormattedMessage id="alternative_cost_type_opex" defaultMessage="OPEX" />,
  alternative_cost_type_capex: <FormattedMessage id="alternative_cost_type_capex" defaultMessage="CAPEX" />,
  alternative_cost_type_other: <FormattedMessage id="alternative_cost_type_other" defaultMessage="Custom" />,
  alternative_cost_col_alternative: defineMessages({props: {id: "alternative_cost_col_alternative", defaultMessage: "Alternative"}}),
  alternative_cost_col_name: defineMessages({props: {id: "alternative_cost_col_name", defaultMessage: "Name"}}),
  alternative_cost_col_description: defineMessages({props: {id: "alternative_cost_col_description", defaultMessage: "Description"}}),
  alternative_cost_col_type: defineMessages({props: {id: "alternative_cost_col_type", defaultMessage: "Type"}}),
  alternative_cost_col_budget: defineMessages({props: {id: "alternative_cost_col_budget", defaultMessage: "Budget Source"}}),
  alternative_cost_add: <FormattedMessage id="alternative_cost_add" defaultMessage="Add New Cost" />,
  alternative_cost_add_title: <FormattedMessage id="alternative_cost_add_title" defaultMessage="Add New Alternative Cost" />,
  alternative_cost_edit_title: <FormattedMessage id="alternative_cost_edit_title" defaultMessage="Edit Alternative Cost" />,
  alternative_cost_add_alternative: <FormattedMessage id="alternative_cost_add_alternative" defaultMessage="Alternative" />,
  alternative_cost_add_alternative_helptext: <FormattedMessage id="alternative_cost_add_alternative_helptext" defaultMessage="Choose Alternative" />,
  alternative_cost_add_name: <FormattedMessage id="alternative_cost_add_name" defaultMessage="Name" />,
  alternative_cost_add_name_helptext: <FormattedMessage id="alternative_cost_add_name_helptext" defaultMessage="Name of Cost" />,
  alternative_cost_add_description: <FormattedMessage id="alternative_cost_add_description" defaultMessage="Description" />,
  alternative_cost_add_description_helptext: <FormattedMessage id="alternative_cost_add_description_helptext" defaultMessage="Description of Cost" />,
  alternative_cost_add_budget: <FormattedMessage id="alternative_cost_add_budget" defaultMessage="Budget Source" />,  
  alternative_cost_add_budget_helptext: <FormattedMessage id="alternative_cost_add_budget_helptext" defaultMessage="Choose Budget Source" />,
  alternative_cost_add_cost_type: <FormattedMessage id="alternative_cost_add_cost_type" defaultMessage="Cost Type" />,
  alternative_cost_add_btn: <FormattedMessage id="alternative_cost_add_btn" defaultMessage="Create Cost" />,
  alternative_cost_add_btn_next: <FormattedMessage id="alternative_cost_add_btn_next" defaultMessage="Create & Next" />,
  //Section - Alternative Relationship
  alternative_relationship: <FormattedMessage id="alternative_relationship" defaultMessage="Relationship" />,
  alternative_relationship_heading: <FormattedMessage id="alternative_relationship_heading" defaultMessage="Relationships" />,
  alternative_relationship_subheading: <FormattedMessage id="alternative_relationship_subheading" defaultMessage="Alternative Relationships" />,
  alternative_relationship_further_info: <FormattedMessage id="alternative_relationship_further_info" defaultMessage="Relationships between alternatives allow you to define how the portfolio will be optimised and prioritised. Some Alternatives or activities might require that another be finished first, while others may only need one or the other." />,
  alternative_relationship_add: <FormattedMessage id="alternative_relationship_add" defaultMessage="Add New Alternative Relationship" />,
  alternative_relationship_add_from: <FormattedMessage id="alternative_relationship_add_from" defaultMessage="Alternative From" />,
  alternative_relationship_add_from_helptext: <FormattedMessage id="alternative_relationship_add_from_helptext" defaultMessage="Choose From Alternative" />,
  alternative_relationship_add_to: <FormattedMessage id="alternative_relationship_add_to" defaultMessage="Alternative To" />,
  alternative_relationship_add_to_helptext: <FormattedMessage id="alternative_relationship_add_to_helptext" defaultMessage="Choose To Alternative" />,
  alternative_relationship_add_relationship_type: <FormattedMessage id="alternative_relationship_add_relationship_type" defaultMessage="Relationship Type" />,
  alternative_relationship_add_relationship_type_helptext: <FormattedMessage id="alternative_relationship_add_relationship_type_helptext" defaultMessage="Choose Relationship Type" />,
  alternative_relationship_add_title: <FormattedMessage id="alternative_relationship_add_title" defaultMessage="Add New Alternative Relationship" />,
  alternative_relationship_add_btn: <FormattedMessage id="alternative_relationship_add_btn" defaultMessage="Create New Relationship" />,
  alternative_relationship_or: <FormattedMessage id="alternative_relationship_or" defaultMessage="Or" />,
  alternative_relationship_required: <FormattedMessage id="alternative_relationship_required" defaultMessage="Required" />,
  alternative_relationship_edit_title: <FormattedMessage id="alternative_relationship_edit_title" defaultMessage="Edit Alternative Relationship" />,
  alternative_relationship_col_from_alt: defineMessages({props: {id: "alternative_relationship_col_from_alt", defaultMessage: "Alternative From"}}),
  alternative_relationship_col_relationship: defineMessages({props: {id: "alternative_relationship_col_relationship", defaultMessage: "Relationship"}}),
  alternative_relationship_col_to_alt: defineMessages({props: {id: "alternative_relationship_col_to_alt", defaultMessage: "Alternative To"}}),
  //Section - Alternative Voting
  alternative_voting_heading: <FormattedMessage id="alternative_voting_heading" defaultMessage="Alternative Voting" />,
  alternative_voting_subheading: <FormattedMessage id="alternative_voting_subheading" defaultMessage="Voting members for Alternatives in the portfolio" />,
  alternative_voting_further_info: <FormattedMessage id="alternative_voting_further_info" defaultMessage="These are the members that will cast votes on how important each of the Alternatives are and how well they align to the assessement criteria you set earlier for this portfolio. These members can be internal to the organistion or drawn from a wider, more public audience." />,
  //Section - Sensitivity Analysis
  sensitivity_analysis_heading: <FormattedMessage id="sensitivity_analysis_heading" defaultMessage="Sensitivity Analysis" />,
  sensitivity_analysis_subheading: <FormattedMessage id="sensitivity_analysis_subheading" defaultMessage="Sensitivity Analysis and Ranking of the Alternatives in the Portfolio" />,
  sensitivity_analysis_further_info: <FormattedMessage id="sensitivity_analysis_further_info" defaultMessage="The sensitivity analysis shows the relative performance of the Alternatives and the alignment to each of the assessment criteria." />,
  sensitivity_analysis_sort_by_alphabet: <FormattedMessage id="sensitivity_analysis_sort_by_alphabet" defaultMessage="Sort by Alphabet" />,
  sensitivity_analysis_sort_by_sum: <FormattedMessage id="sensitivity_analysis_sort_by_sum" defaultMessage="Sort by Sum" />,
  sensitivity_analysis_graph_file_name: defineMessages({props: {id: "sensitivity_analysis_graph_file_name", defaultMessage: "sensitivity-analysis"}}),
  //Section - Sensitivity Alignment
  sensitivity_alignment_heading: <FormattedMessage id="sensitivity_alignment_heading" defaultMessage="Sensitivity Radar" />,
  sensitivity_alignment_subheading: <FormattedMessage id="sensitivity_alignment_subheading" defaultMessage="The Relative Performance of Each Alternative against Criteria" />,
  sensitivity_alignment_further_info: <FormattedMessage id="sensitivity_alignment_further_info" defaultMessage="How well does each alternative perform when compared to the others." />,
  sensitivity_alignment_graph_file_name: defineMessages({props: {id: "sensitivity_alignment_graph_file_name", defaultMessage: "sensitivity-alignment"}}),
  //Section - Sensitivity Alignment
  sensitivity_review_heading: <FormattedMessage id="sensitivity_review_heading" defaultMessage="Vote Review" />,
  sensitivity_review_subheading: <FormattedMessage id="sensitivity_review_subheading" defaultMessage="Review of votes in this portfolio" />,
  sensitivity_review_further_info: <FormattedMessage id="sensitivity_review_further_info" defaultMessage="Provides a comparison of votes sensitivity and deviation for each alternative and criteria." />,  
  sensitivity_review_col_sensitivity: defineMessages({props: {id: "sensitivity_review_col_sensitivity", defaultMessage: "Sensitivity"}}),
  sensitivity_review_col_stdDev: defineMessages({props: {id: "sensitivity_review_col_stdDev", defaultMessage: "Std. Deviation"}}),
  sensitivity_review_col_range: defineMessages({props: {id: "sensitivity_review_col_range", defaultMessage: "Range"}}),
  sensitivity_review_col_view: defineMessages({props: {id: "sensitivity_review_col_view", defaultMessage: "View Vote"}}),
  //Section - Optimise Budget
  optimise_budget_option_heading: <FormattedMessage id="optimise_budget_option_heading" defaultMessage="Portfolio Options" />,
  optimise_budget_option_subheading: <FormattedMessage id="optimise_budget_option_subheading" defaultMessage="Optimised Combinations of Alternatives" />,
  optimise_budget_option_further_info: <FormattedMessage id="optimise_budget_option_further_info" defaultMessage="Find the best combination of alternatives, to meet the goals of the portolfio." />,
  optimise_budget_option_budget: <FormattedMessage id="optimise_budget_option_budget" defaultMessage="Budget" />,
  optimise_budget_option_available: <FormattedMessage id="optimise_budget_option_available" defaultMessage="No. of available positions" />,
  optimise_budget_option_spent: <FormattedMessage id="optimise_budget_option_spent" defaultMessage="Option Cost" />,
  optimise_budget_option_budget_remain: <FormattedMessage id="optimise_budget_option_budget_remain" defaultMessage="Remain" />,
  optimise_budget_option_budget_over: <FormattedMessage id="optimise_budget_option_budget_over" defaultMessage="Overspend" />,
  optimise_budget_option_show_pareto_efficient_options: <FormattedMessage id="optimise_budget_option_show_pareto_efficient_options" defaultMessage="Show Only Pareto Efficient Options" />,
  optimise_budget_option_graph_benefit_label: defineMessages({props: {id: "optimise_budget_option_graph_benefit_label", defaultMessage: "Benefit"}}),
  optimise_budget_option_graph_value_label: defineMessages({props: {id: "optimise_budget_option_graph_value_label", defaultMessage: "Value"}}),
  optimise_budget_option_graph_file_name: defineMessages({props: {id: "optimise_budget_option_graph_file_name", defaultMessage: "optimise-budget-option"}}),
  //Section - Optimise VROI
  optimise_vroi_heading: <FormattedMessage id="optimise_vroi_heading" defaultMessage="Alternative Value" />,
  optimise_vroi_subheading: <FormattedMessage id="optimise_vroi_subheading" defaultMessage="The Value of the Alternatives Against the Constrained Resource" />,
  optimise_vroi_further_info: <FormattedMessage id="optimise_vroi_further_info" defaultMessage="The performance of alternatives are compared against the constrained resource, giving an indication of value vs resource.  ie. value-for-money " />,
  optimise_vroi_sort_by_alphabet: <FormattedMessage id="optimise_vroi_sort_by_alphabet" defaultMessage="Sort by Alphabet" />,
  optimise_vroi_sort_by_benefit: <FormattedMessage id="optimise_vroi_sort_by_benefit" defaultMessage="Sort by Benefits" />,
  optimise_vroi_sort_by_cost: <FormattedMessage id="optimise_vroi_sort_by_cost" defaultMessage="Sort by Costs" />,
  optimise_vroi_sort_by_value: <FormattedMessage id="optimise_vroi_sort_by_value" defaultMessage="Sort by Values" />,
  optimise_vroi_graph_file_name: defineMessages({props: {id: "optimise_vroi_graph_file_name", defaultMessage: "optimise-vroi"}}),
  optimise_vroi_graph_label_cost: defineMessages({props: {id: "optimise_vroi_graph_label_cost", defaultMessage: "Cost"}}),
  optimise_vroi_graph_label_value: defineMessages({props: {id: "optimise_vroi_graph_label_value", defaultMessage: "Value"}}),
  optimise_vroi_graph_label_benefit: defineMessages({props: {id: "optimise_vroi_graph_label_benefit", defaultMessage: "Benefit"}}),  
  optimise_scatter_graph_file_name: defineMessages({props: {id: "optimise_scatter_graph_file_name", defaultMessage: "optimise-cost-vs-benefit"}}),
  //Section - Complex Budget
  optimise_complex_budget_heading: <FormattedMessage id="optimise_complex_budget_heading" defaultMessage="Complex Budget" />,
  optimise_complex_budget_properties_subheading: <FormattedMessage id="optimise_complex_budget_properties_subheading" defaultMessage="Budgets Options" />,
  optimise_complex_budget_properties_further_info: <FormattedMessage id="optimise_complex_budget_properties_further_info" defaultMessage="Change Options relate to Cost and Budget here." />,
  optimise_complex_budget_properties_carry_over: <FormattedMessage id="optimise_complex_budget_properties_carry_over" defaultMessage="Carry Over Un-used Budget" />,
  optimise_complex_budget_properties_allow_transfer: <FormattedMessage id="optimise_complex_budget_properties_allow_transfer" defaultMessage="Allow Transfer Between Budgets" />,
  optimise_complex_budget_subheading: <FormattedMessage id="optimise_complex_budget_subheading" defaultMessage="Budgets Vs. Costs" />,
  optimise_complex_budget_further_info: <FormattedMessage id="optimise_complex_budget_further_info" defaultMessage="The cost of alternatives are compared against the provided budgets. Use the dropdown to select the type of cost to display." />,
  optimise_complex_budget_graph_label_budget: defineMessages({props: {id: "optimise_complex_budget_graph_label_budget", defaultMessage: "Budget"}}),
  optimise_complex_budget_graph_label_budget_actual: defineMessages({props: {id: "optimise_complex_budget_graph_label_budget_actual", defaultMessage: "Actual Budget"}}),
  optimise_complex_budget_graph_label_budget_carry_over: defineMessages({props: {id: "optimise_complex_budget_graph_label_budget_carry_over", defaultMessage: "Carry Over Budget"}}),
  optimise_complex_budget_graph_label_cost: defineMessages({props: {id: "optimise_complex_budget_graph_label_cost", defaultMessage: "Total Cost"}}),
  optimise_complex_budget_graph_label_capex: <FormattedMessage id="optimise_complex_budget_graph_label_capex" defaultMessage="CAPEX" />,
  optimise_complex_budget_graph_label_opex: <FormattedMessage id="optimise_complex_budget_graph_label_opex" defaultMessage="OPEX" />,
  optimise_complex_budget_graph_label_other: <FormattedMessage id="optimise_complex_budget_graph_label_other" defaultMessage="Others" />,
  optimise_complex_budget_graph_file_name: defineMessages({props: {id: "optimise_complex_budget_graph_file_name", defaultMessage: "optimise-budget-vs-cost"}}),
  optimise_complex_budget_view_total: <FormattedMessage id="optimise_complex_budget_view_total" defaultMessage="View Total Cost" />,
  optimise_complex_budget_view_by_type: <FormattedMessage id="optimise_complex_budget_view_by_type" defaultMessage="Split Cost By Type" />,
  optimise_complex_budget_view_by_alternative: <FormattedMessage id="optimise_complex_budget_view_by_alternative" defaultMessage="Split Cost By Alternative" />,
    optimise_complex_budget_properties_carry_over_show: <FormattedMessage id="optimise_complex_budget_properties_carry_over_show" defaultMessage="Seperate Carried Over Budget" />,
  optimise_complex_budget_adjust_start_date: <FormattedMessage id="optimise_complex_budget_adjust_start_date" defaultMessage="Adjust Start Date of Alternatives" />,
  optimise_complex_budget_button_suggest_by_budget: <FormattedMessage id="optimise_complex_budget_button_suggest_by_budget" defaultMessage="Suggest By Budget" />,
  optimise_complex_budget_button_suggest_by_value: <FormattedMessage id="optimise_complex_budget_button_suggest_by_value" defaultMessage="Suggest By Value" />,
  optimise_complex_budget_button_reset: <FormattedMessage id="optimise_complex_budget_button_reset" defaultMessage="Reset" />,
  optimise_complex_budget_button_load: <FormattedMessage id="optimise_complex_budget_button_load" defaultMessage="Load" />,
  optimise_complex_budget_button_save: <FormattedMessage id="optimise_complex_budget_button_save" defaultMessage="Save" />,
  //Section - Cost Breakdown
  optimise_cost_breakdown_subheading: <FormattedMessage id="optimise_cost_breakdown_subheading" defaultMessage="Cost Breakdown" />,
  optimise_cost_breakdown_further_info: <FormattedMessage id="optimise_cost_breakdown_further_info" defaultMessage="Breakdown of cost over periods and budgets." />,
  optimise_cost_breakdown_col_alternative: defineMessages({props: {id: "optimise_cost_breakdown_col_alternative", defaultMessage: "Alternative/Period"}}),
  optimise_cost_breakdown_split_by_budget: <FormattedMessage id="optimise_cost_breakdown_split_by_budget" defaultMessage="Split By Budget Source" />,
  //Section - Tweak
  tweak_top_heading: <FormattedMessage id="tweak_top_heading" defaultMessage="Tweak" />,
  tweak_btn: <FormattedMessage id="tweak_btn" defaultMessage="Edit/Create Scenarios" />,
  tweak_priority_heading: <FormattedMessage id="tweak_priority_heading" defaultMessage="Priority" />,
  tweak_priority_subheading: <FormattedMessage id="tweak_priority_subheading" defaultMessage="Adjust the relative importance and priority of the Criteria" />,
  tweak_priority_further_info: <FormattedMessage id="tweak_priority_further_info" defaultMessage="Make adjustments to the Criteria importance within the Portfolio to understand the effect on the results. You can save the scenario for future viewing and to compare against the baseline scenario." />,
  tweak_priority_vector_sum: <FormattedMessage id="tweak_priority_vector_sum" defaultMessage="Importance Sum" />,
  tweak_priority_vector_auto_adjust: <FormattedMessage id="tweak_priority_vector_auto_adjust" defaultMessage="Auto Adjust" />,
  tweak_fund_heading: <FormattedMessage id="tweak_fund_heading" defaultMessage="Fund" />,
  tweak_fund_subheading: <FormattedMessage id="tweak_fund_subheading" defaultMessage="Forced Alternatives" />,
  tweak_fund_further_info: <FormattedMessage id="tweak_fund_further_info" defaultMessage="Force an alternative to be funded, and included in all portfolio options." />,
  tweak_voter_heading: <FormattedMessage id="tweak_voter_heading" defaultMessage="Voter" />,
  tweak_voter_subheading: <FormattedMessage id="tweak_voter_subheading" defaultMessage="Select Voter" />,
  tweak_voter_further_info: <FormattedMessage id="tweak_voter_further_info" defaultMessage="Calculate results using only selected voters. (Changing criteria voters will reset the Priority adjustments)" />,
  tweak_btn_reset: <FormattedMessage id="tweak_btn_reset" defaultMessage="Reset" />,
  tweak_btn_load: <FormattedMessage id="tweak_btn_load" defaultMessage="Load" />,
  tweak_btn_save: <FormattedMessage id="tweak_btn_save" defaultMessage="Save" />,
  tweak_btn_delete: <FormattedMessage id="tweak_btn_delete" defaultMessage="Delete" />,
  tweak_btn_delete_tooltip: <FormattedMessage id="tweak_btn_delete_tooltip" defaultMessage="Delete Scenario {name}" />,
  tweak_btn_save_message: <FormattedMessage id="tweak_btn_save_message:" defaultMessage="To save, please enter a unique name for the scenario" />,
  //Common - Voter Comment Editor
  voter_comment: <FormattedMessage id="voter_comment" defaultMessage="Voter Comment" />,
  voter_comment_no: <FormattedMessage id="voter_comment_no" defaultMessage="Not Required" />,
  voter_comment_optional: <FormattedMessage id="voter_comment_optional" defaultMessage="Optional" />,
  voter_comment_compulsary: <FormattedMessage id="voter_comment_compulsary" defaultMessage="Compulsary" />,
  //Common - Section - Voter Selection
  voter_add_title: <FormattedMessage id="voter_add_title" defaultMessage="Select Voting Members" />,
  voter_complete: <FormattedMessage id="voter_complete" defaultMessage="Completed surveys" />,
  voter_send_invites: <FormattedMessage id="voter_send_invites" defaultMessage="Send Invites" />,
  voter_send_invites_remain: <FormattedMessage id="voter_send_invites_remain" defaultMessage="Send {count} Remaining Invites" />,
  voter_send_reminders: <FormattedMessage id="voter_send_reminders" defaultMessage="Send {count} Reminders To Vote" />,
  voter_send_invites_warn: <FormattedMessage id="voter_send_invites_warn" defaultMessage="Criteria and Alternatives changes will be limited" />,
  voter_revert_submit: <FormattedMessage id="voter_revert_submit" defaultMessage="Revert Submit" />,
  voter_col_name: defineMessages({props: {id: "voter_col_name", defaultMessage: "Name"}}),
  voter_col_email: defineMessages({props: {id: "voter_col_email", defaultMessage: "Email"}}),
  voter_col_invite_or_access: defineMessages({props: {id: "voter_col_invite_or_access", defaultMessage: "Invite Sent/Accessed"}}),
  voter_col_voted: defineMessages({props: {id: "voter_col_voted", defaultMessage: "Voted"}}),
  voter_col_reverted_submit: defineMessages({props: {id: "voter_col_reverted_submit", defaultMessage: "Revert Submit"}}),
  voter_invited: defineMessages({props: {id: "voter_invited", defaultMessage: "Invited {fromNow}"}}),
  voter_accessed: defineMessages({props: {id: "voter_accessed", defaultMessage: "Accessed {fromNow}"}}),
  voter_submitted: defineMessages({props: {id: "voter_submitted", defaultMessage: "Submitted {fromNow}"}}),
  voter_reminded: defineMessages({props: {id: "voter_reminded", defaultMessage: "Reminded {fromNow}"}}),
  //Common - Section - Voting
  voting_view: <FormattedMessage id="voting_view" defaultMessage="View Votes" />,
  voting_heading: <FormattedMessage id="voting_heading" defaultMessage="Voting" />,
  voting_subheading: <FormattedMessage id="voting_subheading" defaultMessage="Live and Remote Voting" />,
  voting_further_info: <FormattedMessage id="voting_further_info" defaultMessage="Users can vote in a live, facilitated session, or if preffered they can vote remotely." />,
  voting_access_code: <FormattedMessage id="voting_access_code" defaultMessage="Access Code: " />,
  voting_guest: <FormattedMessage id="voting_guest" defaultMessage="Guest Mode" />,
  voting_live: <FormattedMessage id="voting_live" defaultMessage="Live Voting" />,
  voting_col_voter: defineMessages({props: {id: "voting_col_voter", defaultMessage: "Voter"}}),
  voting_col_voter_email: defineMessages({props: {id: "voting_col_voter_email", defaultMessage: "Email"}}),
  voting_col_allow_remote: defineMessages({props: {id: "voting_col_allow_remote", defaultMessage: "Allow Remote Vote"}}),
}
export default pagePortfolio;