import * as actionTypes from './actionTypes';

export const calendarModalShow = (newEventDate) => {    
    return {
        type: actionTypes.CALENDAR_MODAL_SHOW,
        newEventDate: newEventDate
    }
}

export const calendarModalClose = () => {
    return {
        type: actionTypes.CALENDAR_MODAL_CLOSE
    }
}

export const calendarModalInit = () => {
    return {
        type: actionTypes.CALENDAR_MODAL_INIT
    }
}

export const notificationShow = (notificationText, values) => {    
    return {
        type: actionTypes.NOTIFICATION_SHOW,
        text: notificationText,
        values: values
    }
} // => saga/index.js

export const notificationStart = (notificationText, values) => {
    return {
        type: actionTypes.NOTIFICATION_START,
        text: notificationText,
        values: values
    } 
}

export const notificationClose = () => {
    return {
        type: actionTypes.NOTIFICATION_CLOSE
    }
}