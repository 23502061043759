import {generateCombinations} from './generateCombinations';
import { assessmentTypes } from 'shared/constants';
import {forEach} from 'lodash';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//IMPORTANCE
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////  
export const calculateImportance = (criteria, criteriaAnswers, showMath) => {
  const criteriaKeys = Object.keys(criteria);
  const answerCount = Object.keys(criteriaAnswers).length;
  const updateState = {};
  if(showMath) console.log("//////////////////////////////////////////////////////////////////");
  if(showMath) console.log("//IMPORTANCE//////////////////////////////////////////////////////");
  if(showMath) console.log("//////////////////////////////////////////////////////////////////");
  if(showMath) console.log("criteriaKeys", criteriaKeys);
  if(showMath) console.log("criteriaAnswers", criteriaAnswers);

  // 1. Generate Matrix for each voter's answers   
  const matrixList = {};      
  // console.log("matrixList", matrixList); 
  for (var voterKey in criteriaAnswers) {
    if (criteriaAnswers[voterKey].length > 0) {
      let reciprocalMatrix = {};  
      // Create matrix with default 1 for each criteria
      criteriaKeys.forEach(key => {
        reciprocalMatrix[key] = {[key]: 1}
      });     
      // Spread criteria answer against another
      criteriaAnswers[voterKey].forEach(answer => {
        // Check if keys are valid, criteria could have been deleted
        if (criteriaKeys.includes(answer.criteria1.toString()) && criteriaKeys.includes(answer.criteria2.toString())) {          
          collectPairwiseMatrixData(reciprocalMatrix, answer.criteria1, answer.criteria2, answer.answer.toString(), answer.intensity);
        }
      })    
      const voterRMatrixColSum = getMatrixColSumByKeys(criteriaKeys, reciprocalMatrix);
      // console.log("matrix", matrix);
      // console.log("voterRMatrixColSum", voterRMatrixColSum);
      // Might not be safe like this, use pipe?
      criteriaAnswers[voterKey].reciprocalMatrix = reciprocalMatrix;
      criteriaAnswers[voterKey].priorityVector = calculatePriorityVector(reciprocalMatrix, voterRMatrixColSum, false);
      criteriaAnswers[voterKey].consistencyRatios = calculateConsistency(criteria, voterRMatrixColSum, criteriaAnswers[voterKey].priorityVector, false);
      matrixList[voterKey] = reciprocalMatrix;    
    }
  } 
  // 1.1 No vote yet, return equals for all
  if (Object.keys(matrixList).length === 0 ) {
    const equalMatrix = {}
    criteriaKeys.forEach(critKeyA => {
      equalMatrix[critKeyA] = {};
      // Find same level crit
      criteriaKeys.forEach(critKeyB => {
        if (criteria[critKeyA].parentCriteria === criteria[critKeyB].parentCriteria) {
          equalMatrix[critKeyA][critKeyB] = 1;
        }
      });
    })
    if(showMath) console.log("equalMatrix", equalMatrix);
    matrixList["equal"] = equalMatrix;
  }
  if(showMath) console.log("matrixList", matrixList); 

  // 2. Consolidate Reciprocal Matrix
  const meanReciprocalMatrix = calculateGeometricMeanMatrix(matrixList, showMath);
  updateState.reciprocalMatrix = meanReciprocalMatrix;

  // 3. Std Deviation
  updateState.stdDevMatrix = calculateStdDeviationMatrix(criteriaKeys, criteriaAnswers, answerCount, showMath);

  // 4. Generate sum of each column of the consolidated Matrix (vertical)
  const rMatrixColSum = getMatrixColSumByKeys(criteriaKeys, meanReciprocalMatrix);  
  if(showMath) console.log("rMatrixColSum", rMatrixColSum);

  // 5. Calculate priority vector (Eigen vector)
  const priorityVector = calculatePriorityVector(updateState.reciprocalMatrix, rMatrixColSum, showMath);
  updateState.priorityVector = priorityVector;
  if(showMath) console.log("priorityVector", priorityVector);

  // 6. Calculate flat priority vector
  const flatPriorityVector = calculateFlatPriorityVector(criteria, priorityVector);
  updateState.flatPriorityVector = flatPriorityVector;
  if(showMath) console.log("flatPriorityVector", flatPriorityVector);

  // 7. Calculate Principal Eigen value, from the summation of
  // products between each element of Eigen vector and the sum of columns of the reciprocal matrix.
  updateState.consistencyRatios = calculateConsistency(criteria, rMatrixColSum, priorityVector, showMath);

  return updateState;
}

const calculateStdDeviationMatrix = (criteriaKeys, criteriaAnswers, answerCount, showMath) => {
  if(showMath) console.log("==================Std Dev=================");
  // 1. Generate Matrix for each voter's answers   
  const matrixList = {};      
  for (var voterKey in criteriaAnswers) {
    if (criteriaAnswers[voterKey].length > 0) {
      let matrix = {};  
      // Create matrix with default 1 for each criteria
      criteriaKeys.forEach(key => {
        matrix[key] = {[key]: 0}
      });     
      // Spread criteria answer against another
      criteriaAnswers[voterKey].forEach(answer => {    
        // Check if keys are valid, criteria could have been deleted
        if (criteriaKeys.includes(answer.criteria1.toString()) && criteriaKeys.includes(answer.criteria2.toString())) {    
          collectMatrixDataEquals(matrix, answer.criteria1, answer.criteria2, answer.answer, answer.intensity);
        }
      })    
      matrixList[voterKey] = matrix;    
    }
  } 
  if(showMath) console.log("matrixList", matrixList);     
  // 1. Mean Matrix 
  let sumMatrix = null;
  Object.keys(matrixList).forEach(key => {
    const matrix = matrixList[key];
    sumMatrix = sumMatrix ? consolidateMatrix(sumMatrix, matrix, (valueA, valueB) => (valueA + valueB)) : matrix;
  })      
  if(showMath) console.log("sumMatrix", sumMatrix);     
  const meanMatrix = actionMatrix(sumMatrix, (value => value / answerCount));
  if(showMath) console.log("meanMatrix", meanMatrix);     
 
   // 2. Sum of (sqrt(value - mean)
  let sumSquareMatrix = null;  
  Object.keys(matrixList).forEach(key => {
    const squareMatrix = consolidateMatrix(matrixList[key], meanMatrix, (value, valueMean) => Math.pow(Math.abs(value - valueMean), 2));     
    sumSquareMatrix = sumSquareMatrix ? consolidateMatrix(sumSquareMatrix, squareMatrix, (valueA, valueB) => (valueA + valueB)) : squareMatrix
  });     
  if(showMath) console.log("sumSquareMatrix", sumSquareMatrix); 

  // 3. Standard Deviation  2. / (count - 1)
  const stdDevMatrix = actionMatrix(sumSquareMatrix, (value => Math.sqrt(value / (answerCount - 1))));
  if(showMath) console.log("stdDevMatrix", stdDevMatrix); 
  return stdDevMatrix;
}

const calculatePriorityVector = (reciprocalMatrix, rMatrixColSum, showMath) => {
  if(showMath) console.log("===========Priority Vector================");

  // 5. Divide each element of the matrix with the sumNormalized relative weight so the sum of each column is 1.
  const normalisedMatrix = actionMatrix(reciprocalMatrix, (value, keyA, keyB) => {
    return value / rMatrixColSum[keyB];
  });
  if(showMath) console.log("normalisedMatrix", normalisedMatrix); 
  
  // 6. Sum horizontal to get y-priority vector
  const priorityVector = {};
  for(var keyA in normalisedMatrix) {                  
    let sum = 0;
    for(var keyB in normalisedMatrix[keyA]) {       
      sum = sum + normalisedMatrix[keyA][keyB];          
    }
    priorityVector[keyA] = sum / Object.keys(normalisedMatrix[keyA]).length;
  }
  if(showMath) console.log("priorityVector", priorityVector);  

  // 6. Check
  let vectorSum = 0;
  for(var vectorKey in priorityVector) {
    vectorSum += priorityVector[vectorKey];
  }
  if(showMath) console.log("vectorSum", vectorSum);

  return priorityVector;
}

const calculateConsistency = (criteria, rMatrixColSum, priorityVector, showMath) => {
  // Saaty
  // const RIs = [0, 0, 0.58, 0.9, 1.12, 1.24, 1.32, 1.41, 1.45, 1.49];
  // Alonso Lamata
  const RIs = [0, 0, 0.5247, 0.8816, 1.1086, 1.2479, 1.3417, 1.4057, 1.4499, 1.4854, 1.514, 1.5365, 1.5551, 1.5713, 1.5838, 1.5978, 1.6086, 1.6181, 1.6265, 1.6341]; 
  if(showMath) console.log("=============Consistency Ratio=============");
  if(showMath) console.log("RIs", RIs);
  const criteriaKeys = Object.keys(criteria);
  
  // 2. Calculate Principal Eigen value for each criteria group, from the summation of
  // products between each element of Eigen vector and the sum of columns of the reciprocal matrix.
  const principalEigenValues = {};
  criteriaKeys.forEach(key => {
    const aCriteria = criteria[key];
    const parentValue = aCriteria.parentCriteria ? aCriteria.parentCriteria : "";
    // Create princialValue if not yet have
    if (!principalEigenValues[parentValue]) principalEigenValues[parentValue] = {sum: 0, count: 0};
    // Plus rMatrixColSum * priorityVector
    principalEigenValues[parentValue].sum += rMatrixColSum[key] * priorityVector[key];
    principalEigenValues[parentValue].count ++;
  })
  if(showMath) console.log("principalEigenValues", principalEigenValues);

  // 3. Consistency Index (PrincipalEigen - n)/(n - 1)
  const consistencyIndexes = {};
  for(var principalKey in principalEigenValues) {
    const pev = principalEigenValues[principalKey];
    consistencyIndexes[principalKey] = (pev.sum - pev.count) / (pev.count - 1);
  }
  if(showMath) console.log("consistencyIndexes", consistencyIndexes);
  
  // 3. Consistency Ratio = (CI / RandomConsistencyIndex)
  const consistencyRatios = {};
  for(var ciKey in consistencyIndexes) {
    const pev = principalEigenValues[ciKey];
    const ci = consistencyIndexes[ciKey];
    consistencyRatios[ciKey] = ci / RIs[pev.count - 1];
  }
  if(showMath) console.log("consistencyRatios", consistencyRatios);
  return consistencyRatios;
}

export const calculateFlatPriorityVector = (criteria, priorityVector) => {
  // Replace criteria with sub-criteria
  const flatPriorityVector = {...priorityVector};
  const parentCriteriaKeys = [];
  if (criteria) {
    Object.keys(criteria).forEach(criteriaKey => {
      const parentCriteria = criteria[criteriaKey].parentCriteria;
      if (parentCriteria) {
        // Calculate its priority base on parent if has
        if (flatPriorityVector[parentCriteria]) {
          flatPriorityVector[criteriaKey] = flatPriorityVector[parentCriteria] * flatPriorityVector[criteriaKey];          
          // Mark parent to be drop from vector
          if (!parentCriteriaKeys.includes(parentCriteria)) parentCriteriaKeys.push(parentCriteria);
        }
      }
    })
  }
  parentCriteriaKeys.forEach(parentCriteriaKey => (delete flatPriorityVector[parentCriteriaKey]));
  return flatPriorityVector;
}
const collectPairwiseMatrixData = (matrix, keyA, keyB, answer, intensity) => {  
  // Create matrix pair if not yet exists
  if (!matrix[keyA]) matrix[keyA] = {};
  if (!matrix[keyB]) matrix[keyB] = {};
  if (answer === keyA.toString()) {
    matrix[keyA][keyB] = intensity;
    matrix[keyB][keyA] = 1 / intensity;
  } else {
    matrix[keyA][keyB] = 1 /intensity;
    matrix[keyB][keyA] = intensity;
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//SENSITIVITY
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
export const calculateSensitivity = (measurements, criteria, priorityVector, alternatives, alternativeAnswers, evalMethod, showMath) => {    
  if(showMath) console.log("//////////////////////////////////////////////////////////////////");
  if(showMath) console.log("//SENSITIVITY/////////////////////////////////////////////////////");
  if(showMath) console.log("//////////////////////////////////////////////////////////////////");
  if(showMath) console.log("Parameters", measurements, criteria, priorityVector, alternatives, alternativeAnswers, evalMethod, showMath);
  const updateState = {};
  const flatPriorityVector = calculateFlatPriorityVector(criteria, priorityVector);
  const criteriaKeys = Object.keys(flatPriorityVector);
  const altKeys = Object.keys(alternatives);
  if(showMath) console.log("flatPriorityVector", flatPriorityVector);

  // 1. Generate Matrix for each voter's answers   
  const matrixList = {};      
  for (var voterKey in alternativeAnswers) {
    if (alternativeAnswers[voterKey].length > 0) {
      let evaluations = {};  
      // Spread alternative answer against another
      alternativeAnswers[voterKey].forEach(answer => {
        const criteriaKey = answer.criteria1;
        const alternativeKey = answer.alternative;     
        // Check if keys are valid, criteria could have been deleted
        if (criteriaKeys.includes(answer.criteria1.toString()) && altKeys.includes(answer.alternative.toString())) {          
          collectSensitivityData(evaluations, alternativeKey, criteria[criteriaKey], answer.answer, evalMethod, showMath);        
        }
      })    
      // Might not be safe like this, use pipe?
      alternativeAnswers[voterKey].vector = evaluations;
      matrixList[voterKey] = evaluations;    
    }
  }
  // 1.1 No vote yet, or no vote are required, return equals for all
  if (Object.keys(matrixList).length === 0 ) {
    const equalMatrix = {}
    altKeys.forEach(altKey => {
      equalMatrix[altKey] = {};
      // Find same level crit, Ignore measurement, we will do it after
      criteriaKeys.forEach(critKey => {    
        const critEntry = criteria[critKey];
        if (critEntry.criteriaType !== 'measurement') {        
          equalMatrix[altKey][critKey] = 1;
        }        
      });
    });    
    matrixList["equal"] = equalMatrix;
  }
  // 1.2 Push Criteria 'measurement' into each of the answers, also save min/max value for later use
  const critMinMax = {};
  criteriaKeys.forEach(critKey => {
    const critEntry = criteria[critKey];
    let min = NaN, max = NaN;
    switch (critEntry.criteriaType) {
      case 'continuous':
        min = critEntry.continuousLower;
        max = critEntry.continuousUpper;
        break;
      case 'discrete':
        min = 1;
        max = critEntry.discreteArray.split(',').length;
        break;
      case 'measurement':
        const mKey = critEntry.measurement;
        const mEntry = measurements[mKey];
        // Get Max Alt Val for Lower is Better use      
        
        if (mEntry.type === 'continuous') {
          min = getMinInObjectArray(Object.values(alternatives), alt => (alt.measurements[mKey] !== undefined ? alt.measurements[mKey] : NaN));
          max = getMaxInObjectArray(Object.values(alternatives), alt => (alt.measurements[mKey] !== undefined ? alt.measurements[mKey] : NaN));        
        } else if (mEntry.type === 'discrete') {        
          min = 1;
          max = mEntry.discreteArray.split(',').length;
        }
        // Set to each alt
        altKeys.forEach(altKey => {
          const alt = alternatives[altKey];   
          if (alt.measurements && alt.measurements[mKey] !== undefined) {
            addMeasurementValue(matrixList, critEntry, alt, mEntry, altKey, evalMethod, min, max);
          } 
        });
        break;
      default:
        break;
    }
    // Save min/max value for later use    
    critMinMax[critKey] = {min: min, max: max}
  })
  if(showMath) console.log("equalMatrix",  matrixList["equal"]);
  // 1.3 Calculate Individual Sensitivity
  if(showMath) console.log("alternativeAnswers", alternativeAnswers)
  Object.keys(alternativeAnswers).forEach(email => {
    if (alternativeAnswers[email].vector) {
      alternativeAnswers[email].sensitivityVectors = calculatesSensitivityVector(criteria, alternativeAnswers[email].vector, flatPriorityVector, evalMethod, showMath);
    }
  })
  if(showMath) console.log("matrixList", matrixList); 

  // 2. Calculate Mean (geomtric vs arithmetic here?)
  // const meanMatrix = calculateGeometricMeanMatrix(matrixList, showMath);
  const meanMatrix = calculateArithmeticMeanMatrix(matrixList, showMath);

  // 3. Calculate Sensitivity over priority
  updateState.alternativeVectors = meanMatrix;
  const sensitivityVectors = calculatesSensitivityVector(criteria, meanMatrix, flatPriorityVector, evalMethod, showMath);
  updateState.sensitivityVectors = sensitivityVectors
  
  // 4. Keep original ranking
  updateState.altSortedKeys = sortAltKeysByPriority(criteria, alternatives, sensitivityVectors, showMath);

  // 5. Standard Deviation Math.pow(sum(value - mean), 2) / n - 1
  // 5a. Sum of (sqrt(value - mean)
  let sumSquareMatrix = null;  
  Object.keys(matrixList).forEach(key => {
    // Ignore value where, matrixList doesnt has
    const squareMatrix = consolidateMatrix(matrixList[key], meanMatrix, (value, valueMean) => Math.pow(value - valueMean, 2), true);
    sumSquareMatrix = sumSquareMatrix ? consolidateMatrix(sumSquareMatrix, squareMatrix, (valueA, valueB, keyA, keyAB) => valueA + valueB) : squareMatrix
  });     
  if(showMath) console.log("sumSquareMatrix", sumSquareMatrix); 

  // 5b. Standard Deviation  2. / (count - 1)  
  const stdDevMatrix = {}
  for (var altKey in sumSquareMatrix) {
    stdDevMatrix[altKey] = {};
    for(var critKey in sumSquareMatrix[altKey]) {
      const voteCount = getVoteCount(matrixList, altKey, critKey, showMath);  
      if(voteCount > 1) {
        stdDevMatrix[altKey][critKey] = Math.sqrt(sumSquareMatrix[altKey][critKey] / (voteCount - 1));
      }
    }
  }
  updateState.stdDeviations = stdDevMatrix;
  if(showMath) console.log("stdDevMatrix", stdDevMatrix); 

  if(showMath) console.log("critMinMax", critMinMax); 
  // 5c. Normalised Standard Deviation    
  const stdDevMatrixNormalised = {};
  forEach(critMinMax, (values, critKey) => {
    // Normalised
    const {min, max} = values; 
    if (!isNaN(min) && !isNaN(max)) {
      forEach(stdDevMatrix, (altStdDev, altKey) => {
        // Create if not yet have
        if (!stdDevMatrixNormalised[altKey]) stdDevMatrixNormalised[altKey] = {};
        // Set normalised value
        const stdDev = altStdDev[critKey];         
        stdDevMatrixNormalised[altKey][critKey] = stdDev ? stdDev / Math.abs(max - min) : 0;
      })      
    }
  })
  updateState.stdDeviationsNormalised = stdDevMatrixNormalised;
  if(showMath) console.log("stdDevMatrixNormalised", stdDevMatrixNormalised); 

  // 6. Range - max - min
  let minMatrix = null; 
  let maxMatrix = null;
  Object.keys(matrixList).forEach(key => {
    const matrix = matrixList[key];    
    minMatrix = minMatrix ? consolidateMatrix(minMatrix, matrix, (valueA, valueB) => (!isNaN(valueB) && valueB < valueA ? valueB : valueA )) : matrix;
    maxMatrix = maxMatrix ? consolidateMatrix(maxMatrix, matrix, (valueA, valueB) => (!isNaN(valueB) && valueB > valueA ? valueB : valueA )) : matrix;
  }) 
  
  if(showMath) console.log("minMatrix", minMatrix); 
  if(showMath) console.log("maxMatrix", maxMatrix); 
  const rangeMatrix = consolidateMatrix(maxMatrix, minMatrix, (valueA, valueB) => valueA - valueB);
  if(showMath) console.log("rangeMatrix", rangeMatrix); 
  updateState.rangeMatrix = rangeMatrix;
  
  if(showMath) console.log("updateState", updateState); 
  return updateState;
}  

const collectSensitivityData = (evaluations, alternativeKey, aCriteria, answer, evalMethod, showMath) => {  
  if (evalMethod === 'saw') {
    collectSensitivityDataSAW (evaluations, alternativeKey, aCriteria, answer);
  } else {
    collectSensitivityDataTOPSIS (evaluations, alternativeKey, aCriteria, answer);
  }
}

const collectSensitivityDataTOPSIS = (evaluations, alternativeKey, aCriteria, answer) => {  
  // Create matrix pair if not yet exists
  if (!evaluations[alternativeKey]) evaluations[alternativeKey] = {};

  //Collect data w/o calc, convert discrete to choiceIdx
  if (aCriteria.criteriaType === 'continuous') {
    if (aCriteria.continuousLowerIsBetter) {
      evaluations[alternativeKey][aCriteria.key] = (aCriteria.continuousUpper - Number(answer));
    } else {
      evaluations[alternativeKey][aCriteria.key] = Number(answer); 
    }  
  } else if (aCriteria.criteriaType === 'discrete') {    
    const choices = aCriteria.discreteArray.split(',');
    const discreteArrayRatings = aCriteria.discreteArrayRatings ? aCriteria.discreteArrayRatings.split(",") : [];
    const choiceIdx = choices.findIndex(part => part.trim() === String(answer).trim());        
    if (aCriteria.discreteNonLineardiscreteArrayRatings) {          
      evaluations[alternativeKey][aCriteria.key] = parseFloat(discreteArrayRatings[choiceIdx].trim());
    } else {
      evaluations[alternativeKey][aCriteria.key] = choiceIdx;
    }
  }
}

const collectSensitivityDataSAW = (evaluations, alternativeKey, aCriteria, answer) => {  
  // Create matrix pair if not yet exists
  if (!evaluations[alternativeKey]) evaluations[alternativeKey] = {};
  if (aCriteria.criteriaType === 'continuous') {
    // answer / (upper - lower)
    let result = 0;
    if (aCriteria.continuousLowerIsBetter) {
      result = (aCriteria.continuousUpper - answer) / (aCriteria.continuousUpper - aCriteria.continuousLower);
    } else {
      result = (answer - aCriteria.continuousLower) / (aCriteria.continuousUpper - aCriteria.continuousLower);    
    }    
    evaluations[alternativeKey][aCriteria.key] = result;
  } else if (aCriteria.criteriaType === 'discrete') {
    const choices = aCriteria.discreteArray.split(',');
    const choiceIdx = choices.findIndex(part => part.trim() === String(answer).trim());
    if (aCriteria.discreteNonLinear && aCriteria.discreteArrayRatings) {          
      evaluations[alternativeKey][aCriteria.key] = aCriteria.discreteArrayRatings[choiceIdx];
    } else {
      const result = (choiceIdx + 1) / choices.length;
      evaluations[alternativeKey][aCriteria.key] = result;
    }
  }
}

const addMeasurementValue = (matrixList, critEntry, altEntry, mEntry, altKey, evalMethod, minMValue, maxMValue) => {  
  const critKey = critEntry.key;
  const mKey = mEntry.key;
  const mVal = altEntry.measurements[mKey];        
  Object.values(matrixList).forEach(matrix => {
    if (!matrix[altKey]) matrix[altKey] = {};
    if (evalMethod === 'saw') {
      // SAM
      if (critEntry.continuousLowerIsBetter) {
        matrix[altKey][critKey] = (maxMValue - mVal) / (maxMValue - minMValue);
      } else {
        matrix[altKey][critKey] = (mVal - minMValue) / (maxMValue - minMValue);    
      }            
    } else {
      // TOPIS
      if (mEntry.type === 'discrete') {
        const choices = mEntry.discreteArray.split(',');
        const choiceIdx = choices.findIndex(part => part.trim() === String(mVal).trim());    
        matrix[altKey][critKey] = choiceIdx;
      } else {
        if (critEntry.continuousLowerIsBetter) {
          matrix[altKey][critKey] = (maxMValue - mVal);
        } else {
          matrix[altKey][critKey] = mVal;
        }              
      }      
    }
  });    
}

export const calculatesSensitivityVector = (criteria, evaluations, priorityVector, evalMethod, showMath) => {  
  // Calculate Sensitivity
  if (evalMethod === 'saw') {
    if(showMath) console.log("======SAW======"); 
    return calculatesSensitivityVectorSAW (evaluations, priorityVector);
  } else {
    if(showMath) console.log("======TOPSIS======"); 
    return calculatesSensitivityVectorTOPSIS (criteria, evaluations, priorityVector, showMath);
  }
}

export const calculatesSensitivityVectorTOPSIS = (criteria, evaluations, priorityVector, showMath) => {
  if(showMath) console.log("evaluations", evaluations);
  const critKeys = Object.keys(priorityVector);

  // Extra: To deal with negative, normalise to lowest value
  const minCrits = {}; 
  Object.keys(evaluations).forEach(altKey => {
    Object.keys(evaluations[altKey]).forEach(critKey => {
      const critEntry = criteria[critKey];
      if (critEntry.criteriaType === 'measurement') {
        if (!minCrits[critKey] || minCrits[critKey] > evaluations[altKey][critKey]) {
          minCrits[critKey] = evaluations[altKey][critKey];
        }
      }
    })
  })
  if (showMath) console.log("minCrits", minCrits);
  // Normalised
  const normalisedEvals = actionMatrix(evaluations, (val, keyA, keyB) => (minCrits[keyB] ? val + Math.abs(minCrits[keyB]) : val));
  if (showMath) console.log("normalisedEvals", normalisedEvals);  

  // 1. Calculate Vector Normalisation 
  // Square all
  const squareMatrix = actionMatrix(normalisedEvals, val => val * val);
  if (showMath) console.log("squareMatrix", squareMatrix);
  // Sum columns
  const normalisedVector = getMatrixColSumByKeys(critKeys, squareMatrix);
  // Square root
  critKeys.forEach(key => {
    normalisedVector[key] = Math.sqrt(normalisedVector[key]);
  })
  if (showMath) console.log("normalisedVector", normalisedVector);
  
  // 2. Divide each value againse Normalise Vector
  const normalisedMatrix = actionMatrix(normalisedEvals, (val, keyA, keyB) => val / normalisedVector[keyB]);
  if (showMath) console.log("normalisedMatrix", normalisedMatrix);

  // Similar to SAW, just time priority Vector
  const sensitivityVectors = calculatesSensitivityVectorSAW(normalisedMatrix, priorityVector);
  return sensitivityVectors;
}

export const calculatesSensitivityVectorSAW = (evaluations, priorityVector) => {
  // Simply time evaluation values and priority vector
  const sensitivityVectors = {};
  for(var alternativeKey in evaluations) {
    sensitivityVectors[alternativeKey] = {};
    for(var criteriaKey in evaluations[alternativeKey]) {
      sensitivityVectors[alternativeKey][criteriaKey] = evaluations[alternativeKey][criteriaKey] * priorityVector[criteriaKey];      
    }
  }
  return sensitivityVectors;
}

export const sortAltKeysByPriority = (criteria, alternatives, sensitivityVectors, showMath) => {
  const sumBenefits = {};
  const altSortedKeys = Object.keys(alternatives);
  altSortedKeys.forEach(key => {
    sumBenefits[key] = sensitivityVectors ? getSumBenefit(criteria, sensitivityVectors[key]) : 0;
  });    
  if(showMath) console.log("sumBenefits", sumBenefits);
  // Sort
  altSortedKeys.sort((a, b) => (sumBenefits[b] - sumBenefits[a]));   
  return altSortedKeys;
}

export const getSumBenefit = (criteria, altVector) => {  
  let sumBenefit = 0;
  for (var criteriaKey in criteria) {      
    if (altVector && altVector[criteriaKey]) {
      sumBenefit += altVector[criteriaKey];
    }
  }
  return sumBenefit;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//VROI
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
export const calculateVROI = (portfolio, alternatives, sensitivityVectors, showMath) => {
  const updateState = {
    alternativeBenefits: {},
    sumAlternativeCosts: {},
    alternativeCostNormalises: {},
    alternativeVROIs: {},
  };
  if(showMath) console.log("//////////////////////////////////////////////////////////////////");
  if(showMath) console.log("//VROI////////////////////////////////////////////////////////////");
  if(showMath) console.log("//////////////////////////////////////////////////////////////////");
  if(showMath) console.log("Parameters", portfolio, alternatives, sensitivityVectors, showMath);

  // Get Measurement field
  const budgetMKey = portfolio.budgetMeasurement;
  if (showMath) console.log("budgetMeasurement", budgetMKey);

  // Max Cost
  const altKeys = Object.keys(alternatives);
  const minCost = getMinInObjectArray(altKeys, altKey => getAltMeasurement(alternatives, altKey, budgetMKey));
  if (showMath) console.log("minCost", minCost);
  const maxCost = getMaxInObjectArray(altKeys, altKey => getAltMeasurement(alternatives, altKey, budgetMKey));  
  if (showMath) console.log("maxCost", maxCost);

  // For each Alternative
  for(var altKey in alternatives) {        
    // Cost
    const cost = getAltMeasurement(alternatives, altKey, budgetMKey);
    updateState.sumAlternativeCosts[altKey] = cost ;   
    updateState.alternativeCostNormalises[altKey] = cost / maxCost;     
    
    // Benefit    
    updateState.alternativeBenefits[altKey] = 0;
    for(var criKey in sensitivityVectors[altKey]) {
      updateState.alternativeBenefits[altKey] += sensitivityVectors[altKey][criKey];
    } 
    // Value
    updateState.alternativeVROIs[altKey] = cost ? updateState.alternativeBenefits[altKey] / cost : 0;
  }

  // Normalise values
  normalisedByRange(updateState.alternativeVROIs);  

  if (showMath) console.log("updateState", updateState);
  return updateState;
}

const getAltMeasurement = (alternatives, altKey, mKey) => {
  if (alternatives[altKey].measurements && alternatives[altKey].measurements[mKey]) {
    return alternatives[altKey].measurements[mKey];    
  }
  return 0;   
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//Budget Options
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
export const calculateBudgetOptions = (portfolio, alternatives, alternativeRelationships, alternativeForceFunds, sensitivityVectors, showMath) => {  
  if(showMath) console.log("//////////////////////////////////////////////////////////////////");
  if(showMath) console.log("//OPTIONS/////////////////////////////////////////////////////////");
  if(showMath) console.log("//////////////////////////////////////////////////////////////////");
  if(showMath) console.log("Parameters", portfolio, alternatives, alternativeRelationships, alternativeForceFunds, sensitivityVectors, showMath);

  let altKeys = Object.keys(alternatives);
  const budgetMKey = portfolio.budgetMeasurement;


  // 1a. Sum Benefit for each Alt
  const altBenefits = {};
  altKeys.forEach(altKey => {
    let sumBenefit = 0;
    const altVector = sensitivityVectors[altKey];
    for(var criteriaKey in altVector) {
      sumBenefit += altVector[criteriaKey];
    }
    altBenefits[altKey] = sumBenefit;     
  });
  if (showMath) console.log("altBenefits", altBenefits);

  // 1b. Drop alt with no benefit  
  altKeys = altKeys.filter(altKey => altBenefits[altKey] !== 0);

  // 2. Generate Valid Combinations
  let validCombinations = [];
  // SingleChoice or when altKeys is exceed max allowance
  const isSingleChoice = portfolio.assessmentType === assessmentTypes.OSC.value || portfolio.assessmentType === assessmentTypes.TE.value || altKeys.length > 32;
  if (isSingleChoice) {
    //Single choice only
    if(showMath) console.log("Single Choice");
    validCombinations = altKeys.map(key => [key]);
  } else {  
    // Multiple Combination
    validCombinations = generateAllCombinations(altKeys, alternativeForceFunds, alternativeRelationships, showMath);
  }
  if (showMath) console.log("validCombinations", validCombinations);
 
  // 3. Get MaxBenefit and Cost
  let maxBenefit = 0; //number of alt, given each alt has a max benefit of 1
  let maxCost = 0;  
  if (isSingleChoice) {
    maxBenefit = 1;
    maxCost = getMaxInObjectArray(altKeys, altKey => getAltMeasurement(alternatives, altKey, budgetMKey));     
  } else {
    altKeys.forEach(altKey => {
      maxCost += Number(getAltMeasurement(alternatives, altKey, budgetMKey));
      maxBenefit += altBenefits[altKey] ? altBenefits[altKey] : 0;
    });    
  }
  if (showMath) console.log("maxBenefit", maxBenefit);  
  if (showMath) console.log("maxCost", maxCost);  
  
  // Normalise Alt Sum Benefit
  altKeys.forEach(altKey => altBenefits[altKey] = altBenefits[altKey] / maxBenefit)
  if (showMath) console.log("Normalised altBenefits", altBenefits);    
  
  // 4c. Calculate cost/benefit options
  const options = validCombinations.map(combination => {
    let totalCost = 0;
    let benefits = {};
    let sumBenefit = 0;
    combination.forEach(altKey => {
      totalCost += Number(getAltMeasurement(alternatives, altKey, budgetMKey));
      benefits[altKey] = altBenefits[altKey];
      sumBenefit += altBenefits[altKey];
    })
    return {
      keys: [...combination],
      cost: totalCost,
      benefit: benefits,
      sumBenefit: sumBenefit,
      value: totalCost ? (sumBenefit / maxBenefit) / (totalCost / maxCost) : 0
    }
  })

  // 4b. Sort it by Cost
  options.sort((a, b) => a.cost - b.cost);
  if (showMath) console.log("options", options);  

  return {
    fundOptions: options
  }
}

const generateAllCombinations = (altKeys, alternativeForceFunds, alternativeRelationships, showMath) => {
  let validCombinations = [];
  const forcedAlts = [];  
  const singleAlts = [];
  // 1. Collect Forced/Required/OR base on relationship
  // Add all the items that are force funded..
  altKeys.forEach(key => {
    if (alternativeForceFunds && alternativeForceFunds.includes(key)) {
      forcedAlts.push(key); 
    } else {
      singleAlts.push(key);
    }
  })  
  if (showMath) console.log("forcedAlts", forcedAlts); 
  if (showMath) console.log("alts", singleAlts);
  
  // 2. Generates combinations of all possible alternatives that could be funded.  
  const combinations = generateCombinations(forcedAlts, singleAlts);  
  if (showMath) console.log("combinations", combinations);

  // 3. Remove Invalid Option
  const invalidIndexes = [];
  // 3a. Combination where there is a pair of "or"
  const orRelKeys = findAlts(alternativeRelationships, "or");
  if (showMath) console.log("orRelKeys", orRelKeys);
  orRelKeys.forEach(relKey => {
    const parts = relKey.split('<->');
    combinations.forEach((comb, idx) => {
      if (comb.includes(parts[0]) && comb.includes(parts[1])) {
        if (!invalidIndexes.includes(idx)) {
          invalidIndexes.push(idx);
        }
      }
    })
  })

  // 3b. Combination where missing a 'required' alt
  const requiredRelKeys = findAlts(alternativeRelationships, "required");
  if (showMath) console.log("requiredRelKeys", requiredRelKeys);  
  requiredRelKeys.forEach(relKey => {
    const parts = relKey.split('<->');
    combinations.forEach((comb, idx) => {
      if (comb.includes(parts[0]) && !comb.includes(parts[1])) {
        if (!invalidIndexes.includes(idx)) {
          invalidIndexes.push(idx);
        }
      }
    })
  })
  if (showMath) console.log("invalidIndex", invalidIndexes);

  // 3c. Drop invalid Combination
  combinations.forEach((comb, idx)  => {
    if (!invalidIndexes.includes(idx)) validCombinations.push(comb);
  })
  return validCombinations;
}

const findAlts = (relationships,relationshipType) => {
  const matchRels = [];
  for(var key in relationships) {
    if (relationships[key].relationshipType === relationshipType) {
      matchRels.push(key);
    }
  }  
  return matchRels;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//OPTIMISE START PERIODS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
export const optimiseStartPeriods = (periods, budgets, alternativeCosts, alternativeValues, selectedAltKeys, budgetCarryOverFlag, budgetAllowTransferFlag, lockAlternatives, byValue, showMath) => {  
  // showMath = true;
  if (showMath) console.log("//////////////////////////////////////////////////////////////////");
  if (showMath) console.log("//OPTIMISE BUDGET/////////////////////////////////////////////////");
  if (showMath) console.log("//////////////////////////////////////////////////////////////////");
  if (showMath) console.log("Parameters", periods, budgets, alternativeCosts, alternativeValues, selectedAltKeys, budgetCarryOverFlag, budgetAllowTransferFlag, lockAlternatives);
  const altStartPeriods = {};
  const periodIdx = periods.map(entry => entry.key);

  // Populate selected Alt Key Sum Budget
  const {periodCosts, periodCostSplits, alternativePeriodCosts, alternativePeriodCostSplits} = splitCostByPeriod(periods, alternativeCosts, selectedAltKeys, {}, []);
  if (showMath) console.log("alternativePeriodCosts", alternativePeriodCosts);
  if (showMath) console.log("alternativePeriodCostSplits", alternativePeriodCostSplits);

  // Sum Budgets By Period
  const {periodBudgets, periodActualBudgetSplits} = splitBudgetByPeriod(periods, budgets, false, false, periodCosts, periodCostSplits); 
  if (showMath) console.log("periodBudgets", periodBudgets);

  // Generates combinations of all possible alternatives that could be funded. 
  const altKeyCombinations = generateCombinations([], selectedAltKeys);  
  if (showMath) console.log("altKeyCombinations", altKeyCombinations);

  let carryOverBudget = 0;
  let carryOverBudgetSplit = {};
  periodIdx.forEach(periodIdx => {    
    // Get Period Budget
    let budget = periodBudgets[periodIdx];
    let budgetSplit = periodActualBudgetSplits[periodIdx];
    // Plus Carry over
    if (budgetCarryOverFlag) {
      budget += carryOverBudget;
      forEach(carryOverBudgetSplit, (value, budgetKey) => {
        if (budgetSplit[budgetKey]) budgetSplit[budgetKey] += value;
        else budgetSplit[budgetKey] = value;
      });      
    }
    // Minus cost of already started project
    forEach(altStartPeriods, (start, altKey) => {
      if (start < periodIdx){
        budget -= alternativePeriodCosts[altKey][periodIdx]; 
        forEach(alternativePeriodCostSplits[altKey][periodIdx], (value, budgetKey) => {    
          budgetSplit[budgetKey] -= value;
        })     
      }
    })
    // Find Best Combination
    let bestCombination = [];
    let maxSpent = null;
    let maxSpentSplit = {};
    let maxValue = null;
    // let leastOverspentCombination = [];
    // let minOverspent = null;
    if (showMath) console.log(">>Period Start", periodIdx, budget, budgetSplit, altStartPeriods, alternativePeriodCosts);
    altKeyCombinations.forEach(comb => {
      // Check if combination has invalid key
      const hasInvalidKey = comb.find(altKey => {
        // Contain keys of started project
        if (altStartPeriods[altKey] !== undefined) return true;
        // Contain keys of locked alternative, in a different period
        if (lockAlternatives[altKey] !== undefined && lockAlternatives[altKey] !== periodIdx) return true;
        // Else, it's valid
        return false 
      });      
      // Check lockAlternative
      let containLockKey = true;
      forEach(lockAlternatives, (lockPeriod, altKey) => {
        if (lockPeriod === periodIdx && !comb.includes(altKey.toString())) {
          containLockKey = false;
        }
      })
      // Check if still valid
      if (!hasInvalidKey && containLockKey) {
        let combSumCost = 0, combSumValue = 0;
        const combSumCostSplit = {};
        // Add cost of each combination'skey
        comb.forEach(altKey => {
          combSumCost += alternativePeriodCosts[altKey][periodIdx];
          combSumValue += alternativeValues[altKey];
          forEach(alternativePeriodCostSplits[altKey][periodIdx], (value, budgetKey) => {            
            if (combSumCostSplit[budgetKey]) combSumCostSplit[budgetKey] += value;
            else combSumCostSplit[budgetKey] = value;
          })          
        });
        const isWithinBudget = budgetAllowTransferFlag ? combSumCost <= budget : isWithinSplitBudget(budgetSplit, combSumCostSplit);        

        // Check if it's best combination
        if (byValue) {
          // By Max Value
          if (isWithinBudget && (maxValue === null || combSumValue > maxValue)) {
            bestCombination = comb;
            maxValue = combSumValue;
            maxSpent = combSumCost; //Need for carry over budget
            maxSpentSplit = combSumCostSplit;
          } 
        } else {
          // By Total Spent
          if (isWithinBudget && (maxSpent === null || combSumCost > maxSpent)) {
            bestCombination = comb;
            maxSpent = combSumCost;
            maxSpentSplit = combSumCostSplit;
          } 
          // // By Least Overspent
          // if (combSumCost > budget && (minOverspent === null || combSumCost < minOverspent)) {
          //   leastOverspentCombination = comb;
          //   minOverspent = combSumCost;
          // } 
        }
      }
    })    
    // If no combination is found which is under budget, include lock projects
    if (bestCombination.length === 0) {
      forEach(lockAlternatives, (lockPeriod, altKey) => {
        if (lockPeriod === periodIdx) {
          bestCombination.push(altKey);
          maxSpent += alternativePeriodCosts[altKey][periodIdx];
        }
      })
    }
    // Set Start
    bestCombination.forEach(altKey => altStartPeriods[altKey] = periodIdx);  
    // Carry Over     
    carryOverBudget = budget - maxSpent;
    forEach(budgetSplit, (value, budgetKey) => {
      const spend = maxSpentSplit[budgetKey] ? maxSpentSplit[budgetKey] : 0;
      carryOverBudgetSplit[budgetKey] = value - spend;
    })
    // Shift cost for on-hold project
    forEach(alternativePeriodCosts, (costs, altKey) => {
      if (altStartPeriods[altKey] === undefined) {
        alternativePeriodCosts[altKey] = [0, ...costs];
        alternativePeriodCostSplits[altKey] = [{}, ...alternativePeriodCostSplits[altKey]];        
      }
    })
    if (showMath) console.log("<<Period Best Comb", periodIdx, maxSpent, carryOverBudget, carryOverBudgetSplit, bestCombination, alternativePeriodCostSplits);
  })

  const phasingLength = Object.keys(periods).length - 1;   
  const altPeriodRanges = extractAlternativePeriods(alternativeCosts, altStartPeriods);
  let lastBudgetPeriodIdx = 0;
  forEach(periodBudgets, (b, idx) => {if (b && idx > lastBudgetPeriodIdx) lastBudgetPeriodIdx = idx;});

  // Check if any alternative without a start, usually mean not enough fund
  const unallocatedAltKeys = [];
  selectedAltKeys.forEach(altKey => {
    if (altStartPeriods[altKey] === undefined) {
      unallocatedAltKeys.push(altKey);
      // if (budgetCarryOverFlag) {
      //   // Push to last possible
      //   const altLength = altPeriodRanges[altKey][1] - altPeriodRanges[altKey][0];
      //   altStartPeriods[altKey] = phasingLength - altLength;
      // } else {
      //   // Push to last availale budget
      //   altStartPeriods[altKey] = lastBudgetPeriodIdx;
      // }
      if (showMath) console.log("Alt not start", altKey, "set", altStartPeriods[altKey]);
    }
  })

  // Make sure no alternative is overun the project length
  forEach(altStartPeriods, (value, altKey) => {
    const altLength = altPeriodRanges[altKey][1] - altPeriodRanges[altKey][0];
    if (value + altLength > phasingLength) {
      if (showMath) console.log("Alt Start Overrun", altKey, "length", phasingLength, "start", value, altLength);
      // Push to last possible
      altStartPeriods[altKey] = phasingLength - altLength;
    }
  })
  if (showMath) console.log("altStartPeriods", altStartPeriods, unallocatedAltKeys);
  return {altStartPeriods, unallocatedAltKeys};
}

const isWithinSplitBudget = (budgetSplit, periodCostSplit) => {
  let isWithin = true;
  forEach(budgetSplit, (value, key) => {
    if (periodCostSplit[key] > value) isWithin = false;
  })
  // if (isWithin) console.log("Within budget", budgetSplit, periodCostSplit);
  return isWithin;
}

export const splitCostByPeriod = (periods, alternativeCosts, selectedAltKeys, altStartPeriods, unallocatedAltKeys) => {
  // Cost
  const periodCosts = [];    
  const periodCostSplits = [];    
  const periodCapexCosts = [];   
  const periodOpexCosts = [];   
  const periodOtherCosts = [];  
  const alternativePeriodCosts = {};
  const alternativePeriodCostSplits = {};
  selectedAltKeys.forEach(key => {
    alternativePeriodCosts[key] = [];
    alternativePeriodCostSplits[key] = [];
  });
  // For each Period, look for cost 
  periods.forEach(period => {
    let sumCapexCost = 0, sumOpexCost = 0, sumOtherCost = 0, sumCost = 0;
    let sumCostSplit = {}, sumAltCost = {}, sumAltCostSplit = {};
    forEach(alternativeCosts, entry => {      
      const altKey = entry.alternative;    
      if (selectedAltKeys.includes(altKey.toString()) && !unallocatedAltKeys.includes(altKey.toString())) {
        const adjust = altStartPeriods && altStartPeriods[altKey] ? altStartPeriods[altKey] : 0;
        const cost = entry.phasings[period.key - adjust] ? entry.phasings[period.key - adjust] : 0;
        // All
        sumCost += cost;
        // All Split by Budget
        if (sumCostSplit[entry.budget]) sumCostSplit[entry.budget] += cost;     
        else sumCostSplit[entry.budget] = cost;
        // By Type
        if (entry.costType === 'capex') sumCapexCost += cost;
        else if (entry.costType === 'opex') sumOpexCost += cost;
        else if (entry.costType === 'other') sumOtherCost += cost;   
        // By Alternative
        if (sumAltCost[altKey]) sumAltCost[altKey] += cost;     
        else sumAltCost[altKey] = cost;
        // By Alternative, Split By Budget
        if (sumAltCostSplit[altKey]) {
          if (sumAltCostSplit[altKey][entry.budget]) sumAltCostSplit[altKey][entry.budget] += cost;
          else sumAltCostSplit[altKey][entry.budget] = cost;
        } else {
          sumAltCostSplit[altKey] = {[entry.budget]: cost}
        }
      }
    })
    periodCapexCosts.push(sumCapexCost);
    periodOpexCosts.push(sumOpexCost);
    periodOtherCosts.push(sumOtherCost);
    periodCosts.push(sumCost);
    periodCostSplits.push(sumCostSplit);
    forEach(sumAltCost, (value, key) => {
      alternativePeriodCosts[key].push(value);
    })      
    forEach(sumAltCostSplit, (value, key) => {
      alternativePeriodCostSplits[key].push(value);
    })     
  })
  return {periodCosts, periodCostSplits, periodCapexCosts, periodOpexCosts, periodOtherCosts, alternativePeriodCosts, alternativePeriodCostSplits};
}

export const splitBudgetByPeriod = (periods, budgets, budgetCarryOverFlag, budgetAllowTransferFlag, periodCosts, periodCostSplits) => {
  // Budgets    
  const periodBudgets = [];    
  const periodBudgetSplits = [];
  const periodActualBudgets = [];
  const periodActualBudgetSplits = [];
  const periodRemainBudgets = [];
  const periodRemainBudgetSplits = [];
  const periodCarryOverBudgets = [];  
  periods.forEach((period, periodIdx) => {
    let actualBudget = 0;
    const actualBudgetSplit = {};
    const periodBudgetSplit = {};
    const remainSplit = {};
    forEach(budgets, (entry, budgetKey) => {     
      const value = entry.phasings ? entry.phasings[period.key] ? entry.phasings[period.key] : 0 : 0;        
      // Actual    
      actualBudget += value;
      actualBudgetSplit[budgetKey] = value;
      // PeriodBudget      
      periodBudgetSplit[budgetKey] = value;
      if (budgetCarryOverFlag && periodIdx > 0) {
        const carryOver = periodRemainBudgetSplits[periodIdx - 1][budgetKey];         
        periodBudgetSplit[budgetKey] += carryOver; 
      }
      // Remain 
      const periodBudgetCost = periodCostSplits[periodIdx][budgetKey];
      remainSplit[budgetKey] = value - periodBudgetCost;      
      if (budgetCarryOverFlag && periodIdx > 0) {
        const carryOver = periodRemainBudgetSplits[periodIdx - 1][budgetKey];         
        remainSplit[budgetKey] += carryOver; 
      }
    })
    periodBudgetSplits.push(periodBudgetSplit);
    periodActualBudgets.push(actualBudget);
    periodActualBudgetSplits.push(actualBudgetSplit);
    periodRemainBudgetSplits.push(remainSplit);
    // Add CarryOver Fund
    let carryOver = 0;
    if (budgetCarryOverFlag && periodIdx > 0) {
      carryOver = periodBudgets[periodIdx - 1] - periodCosts[periodIdx - 1];
      if (carryOver < 0) {
        carryOver = 0;
      }
    }
    periodCarryOverBudgets.push(carryOver);
    periodBudgets.push(actualBudget + carryOver);
    periodRemainBudgets.push(actualBudget + carryOver - periodCosts[periodIdx]);
  })
  // Add CarryOver to Remain Split
  const periodCarryOverBudgetSplits = [{}, ...periodRemainBudgetSplits];
  // console.log(periodBudgets, periodActualBudgets, periodRemainBudgets, periodCarryOverBudgets);
  // console.log(periodBudgetSplits, periodActualBudgetSplits, periodRemainBudgetSplits, periodCarryOverBudgetSplits);
  return {periodBudgets, periodActualBudgets, periodActualBudgetSplits, periodRemainBudgets, periodRemainBudgetSplits, periodCarryOverBudgets, periodCarryOverBudgetSplits};
}

export const extractAlternativePeriods = (alternativeCosts, alternativeStarts) => {    
  const altPeriodRanges = {}
  forEach(alternativeCosts, (cost) => { 
    let altKey = cost.alternative;   
    // Create if not yet have
    if (!altPeriodRanges[altKey]) altPeriodRanges[altKey] = [Object.keys(cost.phasings).length, 0];
    // Check Min and max
    forEach(cost.phasings, (value, periodIdx) => {
      if (value !== undefined && value > 0) {
        if (altPeriodRanges[altKey][0] > periodIdx) altPeriodRanges[altKey][0] = parseInt(periodIdx);
        if (altPeriodRanges[altKey][1] < periodIdx) altPeriodRanges[altKey][1] = parseInt(periodIdx);        
      }
    })
  })
  // Add Start
  forEach(altPeriodRanges, (range, altKey) => {
    if (alternativeStarts && alternativeStarts[altKey]) {
      altPeriodRanges[altKey] = [range[0] + alternativeStarts[altKey], range[1] + alternativeStarts[altKey]];
    }
  })  
  // console.log("altPeriodRanges",  altPeriodRanges);
  return altPeriodRanges;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//PORTFOLIO UTILITIES
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
export const getFlatCriteria = (criteria) => {
  // Replace criteria with sub-criteria  
  const parentCriteriaKeys = [];
  const flatCriteria = {...criteria};
  if (criteria) {
    Object.keys(criteria).forEach(criteriaKey => {
      const parentCriteria = criteria[criteriaKey].parentCriteria;
      if (parentCriteria) {
        // Mark parent to be drop from vector
        if (!parentCriteriaKeys.includes(parentCriteria)) parentCriteriaKeys.push(parentCriteria);
      }
    })
  }
  parentCriteriaKeys.forEach(parentCriteriaKey => (delete flatCriteria[parentCriteriaKey]));
  return flatCriteria;
}
export const populateSelectedOption = (selectedAlternatives, sumAlternativeCosts, alternativeBenefits, alternativeVROIs) => {
  const selectedOption = {        
    benefit: {},
    cost: 0,
    keys: selectedAlternatives,
    sumBenefit: 0,
    value: 0,
  }
  selectedAlternatives.forEach(altKey => {
    selectedOption.benefit[altKey] = alternativeBenefits[altKey];
    selectedOption.cost += parseInt(sumAlternativeCosts[altKey], 10);
    selectedOption.sumBenefit += alternativeBenefits[altKey];
    selectedOption.value += alternativeVROIs[altKey];
  })
  return selectedOption;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//MATH UTILITIES
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
const collectMatrixDataEquals = (matrix, keyA, keyB, answer, intensity) => {  
  // Create matrix pair if not yet exists
  if (!matrix[keyA]) matrix[keyA] = {};
  if (!matrix[keyB]) matrix[keyB] = {};
  if (answer.toString() === keyA.toString()) {
    matrix[keyA][keyB] = intensity;
    matrix[keyB][keyA] = -1 * intensity;
  } else {
    matrix[keyA][keyB] = -1 * intensity;
    matrix[keyB][keyA] = intensity;
  }
}

const actionMatrix = (matrix, action) => {
  const resultMatrix = {}
  for(var keyA in matrix) {
    resultMatrix[keyA] = {}
    for(var keyB in matrix[keyA]) {
      resultMatrix[keyA][keyB] = action(matrix[keyA][keyB], keyA, keyB);
    }
  }
  return resultMatrix;
}

const getMatrixColSumByKeys = (keys, matrix) => {
  const matrixColSum = {};
  keys.forEach(key => {
    let sum = 0;
    for(var matrixKey in matrix) {
      if (matrix[matrixKey][key] !== undefined) {
        sum = sum + matrix[matrixKey][key];
      }
    }
    matrixColSum[key] = sum;
  })
  return matrixColSum;
}

const consolidateMatrix = (matrixA, matrixB, action, ignoreBOnly) => {
  const consolidateMatrix = {}
  for(var keyA in matrixA) {
    for(var keyAB in matrixA[keyA]) {
      const valueA = matrixA[keyA][keyAB];
      if (matrixB[keyA] && matrixB[keyA][keyAB]) {
        const valueB = matrixB[keyA][keyAB];
        const consolidateValue = action(valueA, valueB, keyA, keyAB);    
        consolidateMatrix[keyA] = {...consolidateMatrix[keyA], [keyAB]: consolidateValue}        
      } else {
        // If not exist in B, just use A, this is sort of dangerous, cause we dont know what the action should be
        const consolidateValue = action(valueA, 0);    
        consolidateMatrix[keyA] = {...consolidateMatrix[keyA], [keyAB]: consolidateValue}
      }
    }
  }
  // Look for key in B but not in A
  if (!ignoreBOnly) {
    for(var keyB in matrixB) {
      if (!matrixA[keyB]){
        consolidateMatrix[keyB] = matrixB[keyB];
      }
    }
  }
  return consolidateMatrix; 
}

const calculateGeometricMeanMatrix = (matrixList, showMath) => {
  if(showMath) console.log("===========GeoMean Matrix=============");
  const matrixCount = Object.keys(matrixList).length;
  // 1. Log() each value, base 10
  const logMatrixList = {};
  for (var voterKey in matrixList) {
    const logMatrix = actionMatrix(matrixList[voterKey], (value => Math.log(value)));
    logMatrixList[voterKey] = logMatrix;    
  }
  if(showMath) console.log("logMatrixList", logMatrixList); 

  // 2. Sum all Log Matrix 
  let sumLogMatrix = null;
  for (var key in logMatrixList) {
    const matrix = logMatrixList[key];
    sumLogMatrix = sumLogMatrix ? consolidateMatrix(sumLogMatrix, matrix, (valueA, valueB) => (valueA + valueB)) : matrix;
  }   
  if(showMath) console.log("sumLogMatrix", sumLogMatrix);      

  // 3. Consolidate Matrix, Exponential(Sum / Number of voters)
  const geoMeanMatrix = actionMatrix(sumLogMatrix, (value => Math.exp(value / matrixCount)));
  if(showMath) console.log("geoMeanMatrix", geoMeanMatrix); 
  return geoMeanMatrix;
}

export const calculateArithmeticMeanMatrix = (matrixList, showMath) => {  
  
  // 1. Sum up the matrix
  let sumMatrix = null;
  for (var key in matrixList) {
    const vector = matrixList[key];
    sumMatrix = 
     sumMatrix 
     ? consolidateMatrix(sumMatrix, vector, (valueA, valueB) => (valueA + valueB)) 
     : vector;
  }      
  if(showMath) console.log("sumMatrix", sumMatrix); 
  
  // 3. Average sum/average, in case not all voter has vote in all alternative,
  // count voters for each alternative
  const arithMeanMatrix = {};
  for (var altKey in sumMatrix) {
    arithMeanMatrix[altKey] = {};
    // Average By Count
    for(var critKey in sumMatrix[altKey]) {
      const voteCount = getVoteCount(matrixList, altKey, critKey, showMath);  
      arithMeanMatrix[altKey][critKey] = sumMatrix[altKey][critKey] / voteCount;
    }
  }
  // const arithMeanMatrix = actionMatrix(sumMatrix, (value => value / matrixCount));
  if(showMath) console.log("arithMeanMatrix", arithMeanMatrix); 
  return arithMeanMatrix;
}

export const getVoteCount = (matrixList, altKey, critKey, showMath) => {
  let voteCount = 0;          
  for (var voterKey in matrixList) {
    if (matrixList[voterKey][altKey] && !isNaN(matrixList[voterKey][altKey][critKey])) {
      voteCount++;
    } else if (matrixList[voterKey][altKey]) {
      // if (showMath) console.log("Obmit Vote", voterKey, altKey, critKey)
    }
  }
  return voteCount;
}

// $normalized = ($value - $min) / ($max - $min);  
export const normalisedByRange = (arrayObjs) => {
  // Get min, max    
  const min = Math.min.apply(Math, Object.values(arrayObjs));
  const max = Math.max.apply(Math, Object.values(arrayObjs));
  Object.keys(arrayObjs).forEach(key => arrayObjs[key] = (arrayObjs[key] - min) / (max - min));
}

// $denormalized = ($normalized * ($max - $min) + $min);
export const denormalisedByRange = (arrayObjs, getVal, min, max) => {  
  return arrayObjs.map(o => getVal(o) * (max - min) - min);
}

export const getMaxInObjectArray = (arrayObjs, getVal) => {
  return Math.max.apply(Math, arrayObjs.map(o => getVal(o)));
}
export const getMinInObjectArray = (arrayObjs, getVal) => {
  return Math.min.apply(Math, arrayObjs.map(o => getVal(o)));
}
 
export const getSelectedAnswers = (voters, answers, hideVoters) => {
  const selected = [];
  voters.forEach(voter => {
    if (!hideVoters[voter.key]) selected.push(answers[voter.key]);
  })
  return selected;
}

export const sumAlternativeCostPhasings = (alternativeCosts, altKey) => {
  let totalCost = 0;
  forEach(alternativeCosts, (cost) => {
    if (cost.alternative.toString() === altKey.toString()) {
      forEach(cost.phasings, (phasing) => {
        totalCost += phasing;
      })
    }
  })
  return totalCost;      
}