import React from 'react'
import {FormattedMessage, defineMessages} from 'react-intl';
const pageVote = {
  //Page Names
  page_name_voter_dashboard: <FormattedMessage id="page_name_voter_dashboard" defaultMessage="Dashboard" />,
  page_name_voter_vote: <FormattedMessage id="page_name_voter_vote" defaultMessage="Vote" />,
  //Tab Header
  tab_voter_portfolios: <FormattedMessage id="tab_voter_portfolios" defaultMessage="Portfolios" />,
  tab_voter_active: <FormattedMessage id="tab_voter_active" defaultMessage="Active" />,
  tab_voter_completed: <FormattedMessage id="tab_voter_completed" defaultMessage="Completed" />,
  tab_voter_open: <FormattedMessage id="tab_voter_open" defaultMessage="Open" />,
  //Section - Voter's Portfolios
  voter_portfolio_code_heading: <FormattedMessage id="voter_portfolio_code_heading" defaultMessage="Portfolio Code" />,
  voter_portfolio_code_subheading: <FormattedMessage id="voter_portfolio_code_subheading" defaultMessage="Enter a provided code to start vote/review a Portfolio" />,
  voter_portfolio_code_further_info: <FormattedMessage id="voter_portfolio_code_further_info" defaultMessage="The Portfolio Code will be given to you at the beginning of a live voting session" />,
  voter_portfolio_current_heading: <FormattedMessage id="voter_portfolio_current_heading" defaultMessage="Current" />,
  voter_portfolio_current_subheading: <FormattedMessage id="voter_portfolio_current_subheading" defaultMessage="Portfolio are assigned to you" />,
  voter_portfolio_current_further_info: <FormattedMessage id="voter_portfolio_current_further_info" defaultMessage="List of completed/incompleted vote which have been assigned to you	 click link to open to vote/review" />,
  voter_portfolio_col_name: defineMessages({props: {id: "voter_portfolio_col_name", defaultMessage: "Name"}}),
  voter_portfolio_col_goal: defineMessages({props: {id: "voter_portfolio_col_goal", defaultMessage: "Goal"}}),
  voter_portfolio_col_category: defineMessages({props: {id: "voter_portfolio_col_category", defaultMessage: "Category"}}),  
  voter_portfolio_col_live: defineMessages({props: {id: "voter_portfolio_col_live", defaultMessage: "Live"}}),
  voter_portfolio_col_updated: defineMessages({props: {id: "voter_portfolio_col_updated", defaultMessage: "Updated"}}),
  voter_portfolio_col_completed: defineMessages({props: {id: "voter_portfolio_col_completed", defaultMessage: "Completed"}}),  
}
export default pageVote;
