import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../shared/utility';

const initialState = {  
  email: null,
  idToken: null,
  authRedirectPath: null,
  authEmailLink: null,
  // guest access
  loadingGuestAccess: false,
  errorGuestAccess: null,
  // signin
  loading: false,
  error: null,
  // reset
  loadingReset: false,
  errorReset: null,
  // change
  loadingChange: false,
  errorChange: null,
  // firstime
  loadingFirst: false,
  errorFirst: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    // GUEST ACCESS
    case actionTypes.AUTH_GUEST_ACCESS_INIT:
      return updateObject(state, {loadingGuestAccess: true});
    case actionTypes.AUTH_GUEST_ACCESS_SUCCESS:
      return updateObject(state, {loadingGuestAccess: false, errorGuestAccess: null, email: action.email});
    case actionTypes.AUTH_GUEST_ACCESS_FAIL:
      return updateObject(state, {loadingGuestAccess: false, errorGuestAccess: action.error});
    // LOGIN
    case actionTypes.AUTH_LOGIN_INIT:
      return updateObject(state, {loading: true});
    case actionTypes.AUTH_LOGIN_SUCCESS:
      return updateObject(state, {loading: false, error: null, email: action.email});
    case actionTypes.AUTH_LOGIN_FAIL:
      return updateObject(state, {loading: false, error: action.error});
    case actionTypes.AUTH_LOGOUT_SUCCESS:
      return updateObject(state, {email: null, idToken : null});
    // RESET
    case actionTypes.AUTH_RESETPW_INIT:
      return updateObject(state, {loadingReset: true, errorReset: null});
    case actionTypes.AUTH_RESETPW_SUCCESS:
      return updateObject(state, {loadingReset: false, email: action.email});
    case actionTypes.AUTH_RESETPW_FAIL:
      return updateObject(state, {loadingReset: false, errorReset: action.error});
    // CHANGE
    case actionTypes.AUTH_CHANGEPW_INIT:
      return updateObject(state, {loadingChange: true, errorChange: null});
    case actionTypes.AUTH_CHANGEPW_SUCCESS:
      return updateObject(state, {loadingChange: false, errorChange: null});
    case actionTypes.AUTH_CHANGEPW_FAIL:
      return updateObject(state, {loadingChange: false, errorChange: action.error});
    // FIRST LOGIN
    case actionTypes.AUTH_FIRST_LOGIN_INIT:
      return updateObject(state, {loadingFirst: true, errorFirst: null});
    case actionTypes.AUTH_FIRST_LOGIN_SUCCESS:
      return updateObject(state, {loadingFirst: false, errorFirst: null, authEmailLink: null});
    case actionTypes.AUTH_FIRST_LOGIN_FAIL:
      return updateObject(state, {loadingFirst: false, errorFirst: action.error});
    // OTHER
    case actionTypes.AUTH_SET_ID_TOKEN: 
      return updateObject(state, {idToken: action.idToken});
    case actionTypes.AUTH_SET_EMAIL_LINK: 
      return updateObject(state, {authEmailLink: action.url});
    case actionTypes.AUTH_SET_REDIRECT_PATH:
      return updateObject(state, {authRedirectPath: action.path});
    default:
      return state;
  }
}