import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../shared/utility';

const initialState = {
  // Retrieve All
  loadingAll: false,
  errorAll: null,
  entries: null,
  // Post
  loadingPost: false,
  errorPost: null,
}
export default (state = initialState, action) => {
  switch (action.type) {  
  // Retrieve All
  case actionTypes.USER_GROUP_RETRIEVE_ALL_INIT:
    return updateObject(state, {entries: null, loadingAll: true, errorAll: null});
  case actionTypes.USER_GROUP_RETRIEVE_ALL_SUCCESS:
    return updateObject(state, {loadingAll: false, entries: action.entries});
  case actionTypes.USER_GROUP_RETRIEVE_ALL_FAIL:
    return updateObject(state, {loadingAll: false, errorAll: action.error});
  // Post
  case actionTypes.USER_GROUP_POST_INIT:
    return updateObject(state, {loadingPost: true, errorPost: null});    
  case actionTypes.USER_GROUP_POST_SUCCESS:   
    return updateEntries(state, action.entry, action.isDelete);
  case actionTypes.USER_GROUP_POST_FAIL:
    return updateObject(state, {loadingPost: false, errorPost: action.error});
  default:
    return state;
  }
}

const updateEntries = (state, updatedEntry, isDelete) => {
  const newState = {loadingPost: false, errorPost: null};
  // Update state.entrys if has  
  if (state.entries) {  
    const editedEntries = [...state.entries];
    const entryIndex = editedEntries.findIndex((entry) => (entry.key === updatedEntry.key));       
    if (entryIndex > -1) {
      if (isDelete) {
        // Delete
        editedEntries.splice(entryIndex, 1);
      } else { 
        // Update
        const currentEntry = editedEntries[entryIndex];
        editedEntries[entryIndex] = {...currentEntry, ...updatedEntry};
      }
    } else {
      // Add
      editedEntries.push(updatedEntry)
    }
    // Sort and Save
    editedEntries.sort((a, b) => a.key - b.key);
    newState.entries = editedEntries;
  }   
  return updateObject(state, newState);
}