import {defineMessages} from 'react-intl';
const message = {
  //Notification
  notification_auth_send_email_link: defineMessages({props: {id: "notification_auth_send_email_link", defaultMessage: "Sent Login Email Link to {email}"}}),
  notification_user_welcome: defineMessages({props: {id: "notification_user_welcome", defaultMessage: "Hi {name}, welcome back!"}}),
  notification_user_added: defineMessages({props: {id: "notification_user_added", defaultMessage: "Added User {name}"}}),
  notification_user_access: defineMessages({props: {id: "notification_user_access", defaultMessage: "{name} has entered"}}),
  notification_user_reactivated: defineMessages({props: {id: "notification_user_reactivated", defaultMessage: "Reactivated User {name}"}}),
  notification_user_updated: defineMessages({props: {id: "notification_user_updated", defaultMessage: "Updated User {name}"}}),
  notification_user_deleted: defineMessages({props: {id: "notification_user_deleted", defaultMessage: "Deleted User {name}"}}),
  notification_user_group_added: defineMessages({props: {id: "notification_user_group_added", defaultMessage: "Added User Group {name}"}}),
  notification_user_group_updated: defineMessages({props: {id: "notification_user_group_updated", defaultMessage: "Updated User Group {name}"}}),
  notification_user_group_deleted: defineMessages({props: {id: "notification_user_group_deleted", defaultMessage: "Deleted User Group {name}"}}),
  notification_portfolio_added: defineMessages({props: {id: "notification_portfolio_added", defaultMessage: "Added Portfolio {name}"}}),
  notification_portfolio_updated: defineMessages({props: {id: "notification_portfolio_updated", defaultMessage: "Updated Portfolio {name}"}}),
  notification_portfolio_deleted: defineMessages({props: {id: "notification_portfolio_deleted", defaultMessage: "Deleted Portfolio {name}"}}),
  notification_budget_added: defineMessages({props: {id: "notification_budget_added", defaultMessage: "Added Budget {name}"}}),
  notification_budget_updated: defineMessages({props: {id: "notification_budget_updated", defaultMessage: "Updated Budget {name}"}}),
  notification_budget_deleted: defineMessages({props: {id: "notification_budget_deleted", defaultMessage: "Deleted Budget {name}"}}),
  notification_measurement_added: defineMessages({props: {id: "notification_measurement_added", defaultMessage: "Added Measurement {name}"}}),
  notification_measurement_updated: defineMessages({props: {id: "notification_measurement_updated", defaultMessage: "Updated Measurement {name}"}}),
  notification_measurement_deleted: defineMessages({props: {id: "notification_measurement_deleted", defaultMessage: "Deleted Measurement {name}"}}),
  notification_alternative_voters_updated: defineMessages({props: {id: "notification_alternative_voters_updated", defaultMessage: "Updated Alternative Voters"}}),
  notification_alternative_voters_send_invite: defineMessages({props: {id: "notification_alternative_voters_send_invite", defaultMessage: "Invites were sent to Alternative Voters"}}),
  notification_alternative_voters_send_reminder: defineMessages({props: {id: "notification_alternative_voters_send_reminder", defaultMessage: "Reminders were sent to Alternative Voters"}}),
  notification_alternative_voters_revert_submit: defineMessages({props: {id: "notification_alternative_voters_revert_submit", defaultMessage: "Voter Submit has been Reverted"}}),
  notification_alternative_voters_allow_remote: defineMessages({props: {id: "notification_alternative_voters_allow_remote", defaultMessage: "Allow {email} to remote vote"}}),
  notification_alternative_voters_allow_remote_stop: defineMessages({props: {id: "notification_alternative_voters_allow_remote_stop", defaultMessage: "Stop {email} from remote vote"}}),
  notification_alternative_rel_added: defineMessages({props: {id: "notification_alternative_rel_added", defaultMessage: "Added Alternative Relationship"}}),
  notification_alternative_rel_updated: defineMessages({props: {id: "notification_alternative_rel_updated", defaultMessage: "Updated Alternative Relationship"}}),
  notification_alternative_rel_deleted: defineMessages({props: {id: "notification_alternative_rel_deleted", defaultMessage: "Deleted Alternative Relationship"}}),
  notification_criteria_added: defineMessages({props: {id: "notification_criteria_added", defaultMessage: "Added Criteria {name}"}}),
  notification_criteria_updated: defineMessages({props: {id: "notification_criteria_updated", defaultMessage: "Updated Criteria {name}"}}),
  notification_criteria_deleted: defineMessages({props: {id: "notification_criteria_deleted", defaultMessage: "Deleted Criteria {name}"}}),
  notification_criteria_voters_updated: defineMessages({props: {id: "notification_criteria_voters_updated", defaultMessage: "Updated Criteria Voters"}}),
  notification_criteria_voters_send_invite: defineMessages({props: {id: "notification_criteria_voters_send_invite", defaultMessage: "Invites were sent to Criteria Voters"}}),
  notification_criteria_voters_send_reminder: defineMessages({props: {id: "notification_criteria_voters_send_reminder", defaultMessage: "Reminders were sent to Criteria Voters"}}),
  notification_criteria_voters_revert_submit: defineMessages({props: {id: "notification_criteria_voters_revert_submit", defaultMessage: "Voter Submit has been Reverted"}}),
  notification_criteria_voters_allow_remote: defineMessages({props: {id: "notification_criteria_voters_allow_remote", defaultMessage: "Allow {email} to remote vote"}}),
  notification_criteria_voters_allow_remote_stop: defineMessages({props: {id: "notification_criteria_voters_allow_remote_stop", defaultMessage: "Stop {email} from remote vote"}}),
  notification_alternative_added: defineMessages({props: {id: "notification_alternative_added", defaultMessage: "Added Alternative {name}"}}),
  notification_alternative_updated: defineMessages({props: {id: "notification_alternative_updated", defaultMessage: "Updated Alternative {name}"}}),
  notification_alternative_deleted: defineMessages({props: {id: "notification_alternative_deleted", defaultMessage: "Deleted Alternative {name}"}}),
  notification_alternative_cost_added: defineMessages({props: {id: "notification_alternative_cost_added", defaultMessage: "Added Alternative Cost {name}"}}),
  notification_alternative_cost_updated: defineMessages({props: {id: "notification_alternative_cost_updated", defaultMessage: "Updated Alternative Cost {name}"}}),
  notification_alternative_cost_deleted: defineMessages({props: {id: "notification_alternative_cost_deleted", defaultMessage: "Deleted Alternative Cost {name}"}}),
  notification_alternative_start_save_added: defineMessages({props: {id: "notification_alternative_start_save_added", defaultMessage: "Saved {name}"}}),
  notification_alternative_start_save_updated: defineMessages({props: {id: "notification_alternative_start_save_updated", defaultMessage: "Updated {name}"}}),
  notification_alternative_start_save_delete: defineMessages({props: {id: "notification_alternative_start_save_delete", defaultMessage: "Deleted Save {name}"}}),
  notification_scenario_added: defineMessages({props: {id: "notification_scenario_added", defaultMessage: "Added Scenario {name}"}}),
  notification_scenario_updated: defineMessages({props: {id: "notification_scenario_updated", defaultMessage: "Updated Scenario {name}"}}),
  notification_scenario_delete: defineMessages({props: {id: "notification_scenario_delete", defaultMessage: "Deleted Scenario {name}"}}),
  notification_report_added: defineMessages({props: {id: "notification_report_added", defaultMessage: "Added Report {name}"}}),
  notification_report_updated: defineMessages({props: {id: "notification_report_updated", defaultMessage: "Updated Report {name}"}}),
  notification_report_deleted: defineMessages({props: {id: "notification_report_deleted", defaultMessage: "Deleted Report {name}"}}),
  notification_account_added: defineMessages({props: {id: "notification_account_added", defaultMessage: "Added Account {name}"}}),
  notification_account_updated: defineMessages({props: {id: "notification_account_updated", defaultMessage: "Updated Account {name}"}}),
  notification_account_deleted: defineMessages({props: {id: "notification_account_deleted", defaultMessage: "Deleted Account {name}"}}),
  //Error
  error_portfolio_add_exists: defineMessages({props: {id: "error_portfolio_add_exists", defaultMessage: "The entered portfolio name has been used. Please enter a different one."}}),
  error_portfolio_copy_no_source: defineMessages({props: {id: "error_portfolio_copy_no_source", defaultMessage: "Please Select a Source Portfolio and at least one section to copy."}}),
  error_alternative_relationship_add_same: defineMessages({props: {id: "error_alternative_relationship_add_same", defaultMessage: "Cannot have a relationship to with itself."}}),
  error_alternative_relationship_add_exists: defineMessages({props: {id: "error_alternative_relationship_add_exists", defaultMessage: "This relationship is already exists."}}),
  error_guest_add_exists: defineMessages({props: {id: "error_guest_add_exists", defaultMessage: "The entered Email has been used. Please signin instead."}}),
  error_guest_unknown_code: defineMessages({props: {id: "error_guest_unknown_code", defaultMessage: "The entered Access Code is either incorrect or the portfolio does not allow Guest Vote."}}),
  error_user_add_exists: defineMessages({props: {id: "error_user_add_exists", defaultMessage: "The entered Email has been used. Please enter a different one."}}),
  error_user_no_longer_exists: defineMessages({props: {id: "error_user_no_longer_exists", defaultMessage: "Cannot locate user's data. Please contact your facilitator."}}),
  error_user_change_role: defineMessages({props: {id: "error_user_change_role", defaultMessage: "This entered email has been assign a different role in another account. Setting a different role in this account is not supported."}}),
  error_vote_unknown_category: defineMessages({props: {id: "error_vote_unknown_category", defaultMessage: "Unknown Voting Category: {category}"}}),
  error_vote_cannot_find_portfolio: defineMessages({props: {id: "error_vote_cannot_find_portfolio", defaultMessage: "Cannot find Portfolio: {id}"}}),
  error_vote_no_access: defineMessages({props: {id: "error_vote_no_access", defaultMessage: "You do not have access to this portfolio. Please contact your facilitator."}}),
  error_vote_facilitate_not_live_criteria: defineMessages({props: {id: "error_vote_facilitate_not_live_criteria", defaultMessage: "This Portfolio's Criteria is not set for live voting. Please check its settings."}}),
  error_vote_facilitate_not_live_alternative: defineMessages({props: {id: "error_vote_facilitate_not_live_alternative", defaultMessage: "This Portfolio's Alternative is not set for live voting. Please check its settings."}}),
  error_vote_add_exists: defineMessages({props: {id: "error_vote_add_exists", defaultMessage: "Portfolio is already in your list."}}),
  error_vote_unknown_code: defineMessages({props: {id: "error_vote_unknown_code", defaultMessage: "The entered Access Code {code} is either incorrect or the portfolio does not allow Guest Vote."}}),  
  error_scenario_add_exists: defineMessages({props: {id: "error_scenario_add_exists", defaultMessage: "The entered scenario name has been used. Please enter a different one."}}),
  error_report_add_exists: defineMessages({props: {id: "error_report_add_exists", defaultMessage: "The entered report name has been used. Please enter a different one."}}),
  error_report_cannot_find_report: defineMessages({props: {id: "error_report_cannot_find_report", defaultMessage: "Cannot find Report: {id}"}}),
}
export default message;

