// Authentication
export const AUTH_GUEST_ACCESS = 'AUTH_GUEST_ACCESS';
export const AUTH_GUEST_ACCESS_INIT = 'AUTH_GUEST_ACCESS_INIT';
export const AUTH_GUEST_ACCESS_FAIL = 'AUTH_GUEST_ACCESS_FAIL';
export const AUTH_GUEST_ACCESS_SUCCESS = 'AUTH_GUEST_ACCESS_SUCCESS';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_INIT = 'AUTH_LOGIN_INIT';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_RESETPW = 'AUTH_RESETPW';
export const AUTH_RESETPW_INIT = 'AUTH_RESETPW_INIT';
export const AUTH_RESETPW_FAIL = 'AUTH_RESETPW_FAIL';
export const AUTH_RESETPW_SUCCESS = 'AUTH_RESETPW_SUCCESS';
export const AUTH_CHANGEPW = 'AUTH_CHANGEPW';
export const AUTH_CHANGEPW_INIT = 'AUTH_CHANGEPW_INIT';
export const AUTH_CHANGEPW_FAIL = 'AUTH_CHANGEPW_FAIL';
export const AUTH_CHANGEPW_SUCCESS = 'AUTH_CHANGEPW_SUCCESS';
export const AUTH_FIRST_LOGIN = 'AUTH_FIRST_LOGIN';
export const AUTH_FIRST_LOGIN_INIT = 'AUTH_FIRST_LOGIN_INIT';
export const AUTH_FIRST_LOGIN_FAIL = 'AUTH_FIRST_LOGIN_FAIL';
export const AUTH_FIRST_LOGIN_SUCCESS = 'AUTH_FIRST_LOGIN_SUCCESS';
export const AUTH_SET_REDIRECT_PATH = 'AUTH_SET_REDIRECT_PATH';
export const AUTH_SET_ID_TOKEN = 'AUTH_SET_ID_TOKEN';
export const AUTH_SET_EMAIL_LINK = 'AUTH_SET_EMAIL_LINK';
// USER_GROUP
export const USER_GROUP_RETRIEVE_ALL = 'USER_GROUP_RETRIEVE_ALL';
export const USER_GROUP_RETRIEVE_ALL_INIT = 'USER_GROUP_RETRIEVE_ALL_INIT';
export const USER_GROUP_RETRIEVE_ALL_FAIL = 'USER_GROUP_RETRIEVE_ALL_FAIL';
export const USER_GROUP_RETRIEVE_ALL_SUCCESS = 'USER_GROUP_RETRIEVE_ALL_SUCCESS';
export const USER_GROUP_ADD = 'USER_GROUP_ADD';
export const USER_GROUP_UPDATE = 'USER_GROUP_UPDATE';
export const USER_GROUP_DELETE = 'USER_GROUP_DELETE';
export const USER_GROUP_POST_INIT = 'USER_GROUP_POST_INIT';
export const USER_GROUP_POST_FAIL = 'USER_GROUP_POST_FAIL';
export const USER_GROUP_POST_SUCCESS = 'USER_GROUP_POST_SUCCESS';
// User
export const USER_CLEAR = 'USER_CLEAR';
export const USER_RETRIEVE = 'USER_RETRIEVE';
export const USER_RETRIEVE_INIT = 'USER_RETRIEVE_INIT';
export const USER_RETRIEVE_FAIL = 'USER_RETRIEVE_FAIL';
export const USER_RETRIEVE_SUCCESS = 'USER_RETRIEVE_SUCCESS';
export const USER_RETRIEVE_ALL = 'USER_RETRIEVE_ALL';
export const USER_RETRIEVE_ALL_INIT = 'USER_RETRIEVE_ALL_INIT';
export const USER_RETRIEVE_ALL_FAIL = 'USER_RETRIEVE_ALL_FAIL';
export const USER_RETRIEVE_ALL_SUCCESS = 'USER_RETRIEVE_ALL_SUCCESS';
export const USER_ADD = 'USER_ADD';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_LAST_LOGGED_IN = 'USER_UPDATE_LAST_LOGGED_IN';
export const USER_DELETE = 'USER_DELETE';
export const USER_POST_INIT = 'USER_POST_INIT';
export const USER_POST_FAIL = 'USER_POST_FAIL';
export const USER_POST_SUCCESS = 'USER_POST_SUCCESS';
export const USER_UPDATE_ROLE = 'USER_UPDATE_ROLE';
export const USER_SIGNUP = 'USER_SIGNUP';
export const USER_SIGNUP_INIT = 'USER_SIGNUP_INIT';
export const USER_SIGNUP_PERSIST = 'USER_SIGNUP_PERSIST';
export const USER_SIGNUP_FAIL = 'USER_SIGNUP_FAIL';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SEND_EMAIL_LINK = 'USER_SEND_EMAIL_LINK';
export const USER_SEND_EMAIL_LINK_INIT = 'USER_SEND_EMAIL_LINK_INIT';
export const USER_SEND_EMAIL_LINK_FAIL = 'USER_SEND_EMAIL_LINK_FAIL';
export const USER_SEND_EMAIL_LINK_SUCCESS = 'USER_SEND_EMAIL_LINK_SUCCESS';
export const USER_CONVERT_GUEST = 'USER_CONVERT_GUEST';
export const USER_CONVERT_GUEST_INIT = 'USER_CONVERT_GUEST_INIT';
export const USER_CONVERT_GUEST_FAIL = 'USER_CONVERT_GUEST_FAIL';
export const USER_CONVERT_GUEST_SUCCESS = 'USER_CONVERT_GUEST_SUCCESS';
// Notification
export const CALENDAR_MODAL_INIT = 'CALENDAR_MODAL_INIT';
export const CALENDAR_MODAL_SHOW = 'CALENDAR_MODAL_SHOW';
export const CALENDAR_MODAL_CLOSE = 'CALENDAR_MODAL_CLOSE';
export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_START = 'NOTIFICATION_START';
export const NOTIFICATION_CLOSE = 'NOTIFICATION_CLOSE';
// Portfolio
export const PORTFOLIO_RETRIEVE = 'PORTFOLIO_RETRIEVE';
export const PORTFOLIO_RETRIEVE_INIT = 'PORTFOLIO_RETRIEVE_INIT';
export const PORTFOLIO_RETRIEVE_FAIL = 'PORTFOLIO_RETRIEVE_FAIL';
export const PORTFOLIO_RETRIEVE_SUCCESS = 'PORTFOLIO_RETRIEVE_SUCCESS';
export const PORTFOLIO_RETRIEVE_ALL = 'PORTFOLIO_RETRIEVE_ALL';
export const PORTFOLIO_RETRIEVE_ALL_INIT = 'PORTFOLIO_RETRIEVE_ALL_INIT';
export const PORTFOLIO_RETRIEVE_ALL_FAIL = 'PORTFOLIO_RETRIEVE_ALL_FAIL';
export const PORTFOLIO_RETRIEVE_ALL_SUCCESS = 'PORTFOLIO_RETRIEVE_ALL_SUCCESS';
export const PORTFOLIO_ADD = 'PORTFOLIO_ADD';
export const PORTFOLIO_UPDATE = 'PORTFOLIO_UPDATE';
export const PORTFOLIO_DELETE = 'PORTFOLIO_DELETE';
export const PORTFOLIO_POST_INIT = 'PORTFOLIO_POST_INIT';
export const PORTFOLIO_POST_FAIL = 'PORTFOLIO_POST_FAIL';
export const PORTFOLIO_POST_SUCCESS = 'PORTFOLIO_POST_SUCCESS';
export const PORTFOLIO_CALCULATE_IMPORTANCE = 'PORTFOLIO_CALCULATE_IMPORTANCE';
export const PORTFOLIO_CALCULATE_SENSITIVITY = 'PORTFOLIO_CALCULATE_SENSITIVITY';
export const PORTFOLIO_CALCULATE_BUDGET_OPTIONS = 'PORTFOLIO_CALCULATE_OPTIMISE';
export const PORTFOLIO_CALCULATE_VROI = 'PORTFOLIO_CALCULATE_VROI';
export const PORTFOLIO_RECALCULATE = 'PORTFOLIO_RECALCULATE';
export const PORTFOLIO_RECALCULATE_INIT = 'PORTFOLIO_RECALCULATE_INIT';
export const PORTFOLIO_RECALCULATE_FAIL = 'PORTFOLIO_RECALCULATE_FAIL';
export const PORTFOLIO_RECALCULATE_SUCCESS = 'PORTFOLIO_RECALCULATE_SUCCESS';
export const PORTFOLIO_SET_SELECTED_OPTION = 'PORTFOLIO_SET_SELECTED_OPTION';
export const PORTFOLIO_SET_VOTE_CATEGORY = 'PORTFOLIO_SET_VOTE_CATEGORY';
export const PORTFOLIO_SET_RECALCULATION_REQUEST = 'PORTFOLIO_SET_RECALCULATION_REQUEST';
// MEASUREMENT
export const MEASUREMENT_RETRIEVE_ALL = 'MEASUREMENT_RETRIEVE_ALL';
export const MEASUREMENT_RETRIEVE_ALL_INIT = 'MEASUREMENT_RETRIEVE_ALL_INIT';
export const MEASUREMENT_RETRIEVE_ALL_FAIL = 'MEASUREMENT_RETRIEVE_ALL_FAIL';
export const MEASUREMENT_RETRIEVE_ALL_SUCCESS = 'MEASUREMENT_RETRIEVE_ALL_SUCCESS';
export const MEASUREMENT_ADD = 'MEASUREMENT_ADD';
export const MEASUREMENT_UPDATE = 'MEASUREMENT_UPDATE';
export const MEASUREMENT_DELETE = 'MEASUREMENT_DELETE';
export const MEASUREMENT_POST_INIT = 'MEASUREMENT_POST_INIT';
export const MEASUREMENT_POST_FAIL = 'MEASUREMENT_POST_FAIL';
export const MEASUREMENT_POST_SUCCESS = 'MEASUREMENT_POST_SUCCESS';
export const MEASUREMENT_UPDATE_HAS_CRITERIA = 'MEASUREMENT_UPDATE_HAS_CRITERIA';
// BUDGET
export const BUDGET_RETRIEVE_ALL = 'BUDGET_RETRIEVE_ALL';
export const BUDGET_RETRIEVE_ALL_INIT = 'BUDGET_RETRIEVE_ALL_INIT';
export const BUDGET_RETRIEVE_ALL_FAIL = 'BUDGET_RETRIEVE_ALL_FAIL';
export const BUDGET_RETRIEVE_ALL_SUCCESS = 'BUDGET_RETRIEVE_ALL_SUCCESS';
export const BUDGET_ADD = 'BUDGET_ADD';
export const BUDGET_UPDATE = 'BUDGET_UPDATE';
export const BUDGET_DELETE = 'BUDGET_DELETE';
export const BUDGET_POST_INIT = 'BUDGET_POST_INIT';
export const BUDGET_POST_FAIL = 'BUDGET_POST_FAIL';
export const BUDGET_POST_SUCCESS = 'BUDGET_POST_SUCCESS';
export const BUDGET_UPDATE_PHASING = 'BUDGET_UPDATE_PHASING';
export const BUDGET_UPDATE_PHASING_INIT = 'BUDGET_UPDATE_PHASING_INIT';
export const BUDGET_UPDATE_PHASING_FAIL = 'BUDGET_UPDATE_PHASING_FAIL';
export const BUDGET_UPDATE_PHASING_SUCCESS = 'BUDGET_UPDATE_PHASING_SUCCESS';
// CRITERIA
export const CRITERIA_RETRIEVE_ALL = 'CRITERIA_RETRIEVE_ALL';
export const CRITERIA_RETRIEVE_ALL_INIT = 'CRITERIA_RETRIEVE_ALL_INIT';
export const CRITERIA_RETRIEVE_ALL_FAIL = 'CRITERIA_RETRIEVE_ALL_FAIL';
export const CRITERIA_RETRIEVE_ALL_SUCCESS = 'CRITERIA_RETRIEVE_ALL_SUCCESS';
export const CRITERIA_ADD = 'CRITERIA_ADD';
export const CRITERIA_UPDATE = 'CRITERIA_UPDATE';
export const CRITERIA_DELETE = 'CRITERIA_DELETE';
export const CRITERIA_POST_INIT = 'CRITERIA_POST_INIT';
export const CRITERIA_POST_FAIL = 'CRITERIA_POST_FAIL';
export const CRITERIA_POST_SUCCESS = 'CRITERIA_POST_SUCCESS';
// CRITERIA_VOTER
export const CRITERIA_VOTER_RETRIEVE_ALL = 'CRITERIA_VOTER_RETRIEVE_ALL';
export const CRITERIA_VOTER_RETRIEVE_ALL_INIT = 'CRITERIA_VOTER_RETRIEVE_ALL_INIT';
export const CRITERIA_VOTER_RETRIEVE_ALL_FAIL = 'CRITERIA_VOTER_RETRIEVE_ALL_FAIL';
export const CRITERIA_VOTER_RETRIEVE_ALL_SUCCESS = 'CRITERIA_VOTER_RETRIEVE_ALL_SUCCESS';
export const CRITERIA_VOTER_RETRIEVE = 'CRITERIA_VOTER_RETRIEVE';
export const CRITERIA_VOTER_RETRIEVE_INIT = 'CRITERIA_VOTER_RETRIEVE_INIT';
export const CRITERIA_VOTER_RETRIEVE_FAIL = 'CRITERIA_VOTER_RETRIEVE_FAIL';
export const CRITERIA_VOTER_RETRIEVE_SUCCESS = 'CRITERIA_VOTER_RETRIEVE_SUCCESS';
export const CRITERIA_VOTER_UPDATE = 'CRITERIA_VOTER_UPDATE';
export const CRITERIA_VOTERS_UPDATE = 'CRITERIA_VOTERS_UPDATE';
export const CRITERIA_VOTERS_POST_INIT = 'CRITERIA_VOTERS_POST_INIT';
export const CRITERIA_VOTERS_POST_FAIL = 'CRITERIA_VOTERS_POST_FAIL';
export const CRITERIA_VOTERS_POST_SUCCESS = 'CRITERIA_VOTERS_POST_SUCCESS';
export const CRITERIA_VOTER_SEND_INVITE = 'CRITERIA_VOTER_SEND_INVITE';
export const CRITERIA_VOTER_SEND_INVITE_INIT = 'CRITERIA_VOTER_SEND_INVITE_INIT';
export const CRITERIA_VOTER_SEND_INVITE_FAIL = 'CRITERIA_VOTER_SEND_INVITE_FAIL';
export const CRITERIA_VOTER_SEND_INVITE_SUCCESS = 'CRITERIA_VOTER_SEND_INVITE_SUCCESS';
export const CRITERIA_VOTER_SUBMIT = 'CRITERIA_VOTER_SUBMIT';
export const CRITERIA_VOTER_SUBMIT_INIT = 'CRITERIA_VOTER_SUBMIT_INIT';
export const CRITERIA_VOTER_SUBMIT_FAIL = 'CRITERIA_VOTER_SUBMIT_FAIL';
export const CRITERIA_VOTER_SUBMIT_SUCCESS = 'CRITERIA_VOTER_SUBMIT_SUCCESS';
export const CRITERIA_VOTER_ALLOW_REMOTE = 'CRITERIA_VOTER_ALLOW_REMOTE';
export const CRITERIA_VOTER_ALLOW_REMOTE_INIT = 'CRITERIA_VOTER_ALLOW_REMOTE_INIT';
export const CRITERIA_VOTER_ALLOW_REMOTE_FAIL = 'CRITERIA_VOTER_ALLOW_REMOTE_FAIL';
export const CRITERIA_VOTER_ALLOW_REMOTE_SUCCESS = 'CRITERIA_VOTER_ALLOW_REMOTE_SUCCESS';
export const CRITERIA_VOTER_UPDATE_ANSWER = 'CRITERIA_VOTER_UPDATE_ANSWER';
// ALTERNATIVE
export const ALTERNATIVE_RETRIEVE_ALL = 'ALTERNATIVE_RETRIEVE_ALL';
export const ALTERNATIVE_RETRIEVE_ALL_INIT = 'ALTERNATIVE_RETRIEVE_ALL_INIT';
export const ALTERNATIVE_RETRIEVE_ALL_FAIL = 'ALTERNATIVE_RETRIEVE_ALL_FAIL';
export const ALTERNATIVE_RETRIEVE_ALL_SUCCESS = 'ALTERNATIVE_RETRIEVE_ALL_SUCCESS';
export const ALTERNATIVE_ADD = 'ALTERNATIVE_ADD';
export const ALTERNATIVE_UPDATE = 'ALTERNATIVE_UPDATE';
export const ALTERNATIVE_DELETE = 'ALTERNATIVE_DELETE';
export const ALTERNATIVE_POST_INIT = 'ALTERNATIVE_POST_INIT';
export const ALTERNATIVE_POST_FAIL = 'ALTERNATIVE_POST_FAIL';
export const ALTERNATIVE_POST_SUCCESS = 'ALTERNATIVE_POST_SUCCESS';
export const ALTERNATIVE_UPDATE_MEASUREMENT = 'ALTERNATIVE_UPDATE_MEASUREMENT';
export const ALTERNATIVE_UPDATE_MEASUREMENT_INIT = 'ALTERNATIVE_UPDATE_MEASUREMENT_INIT';
export const ALTERNATIVE_UPDATE_MEASUREMENT_FAIL = 'ALTERNATIVE_UPDATE_MEASUREMENT_FAIL';
export const ALTERNATIVE_UPDATE_MEASUREMENT_SUCCESS = 'ALTERNATIVE_UPDATE_MEASUREMENT_SUCCESS';
export const ALTERNATIVE_SUM_COST = 'ALTERNATIVE_SUM_COST';
export const ALTERNATIVE_SUM_COST_UPDATE = 'ALTERNATIVE_SUM_COST_UPDATE';
export const ALTERNATIVE_IMPORT = 'ALTERNATIVE_IMPORT';
export const ALTERNATIVE_IMPORT_INIT = 'ALTERNATIVE_IMPORT_INIT';
export const ALTERNATIVE_IMPORT_FAIL = 'ALTERNATIVE_IMPORT_FAIL';
export const ALTERNATIVE_IMPORT_SUCCESS = 'ALTERNATIVE_IMPORT_SUCCESS';
// ALTERNATIVE_VOTER
export const ALTERNATIVE_VOTER_RETRIEVE_ALL = 'ALTERNATIVE_VOTER_RETRIEVE_ALL';
export const ALTERNATIVE_VOTER_RETRIEVE_ALL_INIT = 'ALTERNATIVE_VOTER_RETRIEVE_ALL_INIT';
export const ALTERNATIVE_VOTER_RETRIEVE_ALL_FAIL = 'ALTERNATIVE_VOTER_RETRIEVE_ALL_FAIL';
export const ALTERNATIVE_VOTER_RETRIEVE_ALL_SUCCESS = 'ALTERNATIVE_VOTER_RETRIEVE_ALL_SUCCESS';
export const ALTERNATIVE_VOTER_RETRIEVE = 'ALTERNATIVE_VOTER_RETRIEVE';
export const ALTERNATIVE_VOTER_RETRIEVE_INIT = 'ALTERNATIVE_VOTER_RETRIEVE_INIT';
export const ALTERNATIVE_VOTER_RETRIEVE_FAIL = 'ALTERNATIVE_VOTER_RETRIEVE_FAIL';
export const ALTERNATIVE_VOTER_RETRIEVE_SUCCESS = 'ALTERNATIVE_VOTER_RETRIEVE_SUCCESS';
export const ALTERNATIVE_VOTERS_UPDATE = 'ALTERNATIVE_VOTERS_UPDATE';
export const ALTERNATIVE_VOTERS_POST_INIT = 'ALTERNATIVE_VOTERS_POST_INIT';
export const ALTERNATIVE_VOTERS_POST_FAIL = 'ALTERNATIVE_VOTERS_POST_FAIL';
export const ALTERNATIVE_VOTERS_POST_SUCCESS = 'ALTERNATIVE_VOTERS_POST_SUCCESS';
export const ALTERNATIVE_VOTER_SEND_INVITE = 'ALTERNATIVE_VOTER_SEND_INVITE';
export const ALTERNATIVE_VOTER_SEND_INVITE_INIT = 'ALTERNATIVE_VOTER_SEND_INVITE_INIT';
export const ALTERNATIVE_VOTER_SEND_INVITE_FAIL = 'ALTERNATIVE_VOTER_SEND_INVITE_FAIL';
export const ALTERNATIVE_VOTER_SEND_INVITE_SUCCESS = 'ALTERNATIVE_VOTER_SEND_INVITE_SUCCESS';
export const ALTERNATIVE_VOTER_SUBMIT = 'ALTERNATIVE_VOTER_SUBMIT';
export const ALTERNATIVE_VOTER_SUBMIT_INIT = 'ALTERNATIVE_VOTER_SUBMIT_INIT';
export const ALTERNATIVE_VOTER_SUBMIT_FAIL = 'ALTERNATIVE_VOTER_SUBMIT_FAIL';
export const ALTERNATIVE_VOTER_SUBMIT_SUCCESS = 'ALTERNATIVE_VOTER_SUBMIT_SUCCESS';
export const ALTERNATIVE_VOTER_ALLOW_REMOTE = 'ALTERNATIVE_VOTER_ALLOW_REMOTE';
export const ALTERNATIVE_VOTER_ALLOW_REMOTE_INIT = 'ALTERNATIVE_VOTER_ALLOW_REMOTE_INIT';
export const ALTERNATIVE_VOTER_ALLOW_REMOTE_FAIL = 'ALTERNATIVE_VOTER_ALLOW_REMOTE_FAIL';
export const ALTERNATIVE_VOTER_ALLOW_REMOTE_SUCCESS = 'ALTERNATIVE_VOTER_ALLOW_REMOTE_SUCCESS';
export const ALTERNATIVE_VOTER_IMPORT = 'ALTERNATIVE_VOTER_IMPORT';
export const ALTERNATIVE_VOTER_IMPORT_INIT = 'ALTERNATIVE_VOTER_IMPORT_INIT';
export const ALTERNATIVE_VOTER_IMPORT_FAIL = 'ALTERNATIVE_VOTER_IMPORT_FAIL';
export const ALTERNATIVE_VOTER_IMPORT_SUCCESS = 'ALTERNATIVE_VOTER_IMPORT_SUCCESS';
export const ALTERNATIVE_VOTER_UPDATE_ANSWER = 'ALTERNATIVE_VOTER_UPDATE_ANSWER';
// ALTERNATIVE_RELATIONSHIP
export const ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL = 'ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL';
export const ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL_INIT = 'ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL_INIT';
export const ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL_FAIL = 'ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL_FAIL';
export const ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL_SUCCESS = 'ALTERNATIVE_RELATIONSHIP_RETRIEVE_ALL_SUCCESS';
export const ALTERNATIVE_RELATIONSHIP_ADD = 'ALTERNATIVE_RELATIONSHIP_ADD';
export const ALTERNATIVE_RELATIONSHIP_UPDATE = 'ALTERNATIVE_RELATIONSHIP_UPDATE';
export const ALTERNATIVE_RELATIONSHIP_DELETE = 'ALTERNATIVE_RELATIONSHIP_DELETE';
export const ALTERNATIVE_RELATIONSHIP_POST_INIT = 'ALTERNATIVE_RELATIONSHIP_POST_INIT';
export const ALTERNATIVE_RELATIONSHIP_POST_FAIL = 'ALTERNATIVE_RELATIONSHIP_POST_FAIL';
export const ALTERNATIVE_RELATIONSHIP_POST_SUCCESS = 'ALTERNATIVE_RELATIONSHIP_POST_SUCCESS';
// ALTERNATIVE_COST
export const ALTERNATIVE_COST_RETRIEVE_ALL = 'ALTERNATIVE_COST_RETRIEVE_ALL';
export const ALTERNATIVE_COST_RETRIEVE_ALL_INIT = 'ALTERNATIVE_COST_RETRIEVE_ALL_INIT';
export const ALTERNATIVE_COST_RETRIEVE_ALL_FAIL = 'ALTERNATIVE_COST_RETRIEVE_ALL_FAIL';
export const ALTERNATIVE_COST_RETRIEVE_ALL_SUCCESS = 'ALTERNATIVE_COST_RETRIEVE_ALL_SUCCESS';
export const ALTERNATIVE_COST_ADD = 'ALTERNATIVE_COST_ADD';
export const ALTERNATIVE_COST_UPDATE = 'ALTERNATIVE_COST_UPDATE';
export const ALTERNATIVE_COST_DELETE = 'ALTERNATIVE_COST_DELETE';
export const ALTERNATIVE_COST_POST_INIT = 'ALTERNATIVE_COST_POST_INIT';
export const ALTERNATIVE_COST_POST_FAIL = 'ALTERNATIVE_COST_POST_FAIL';
export const ALTERNATIVE_COST_POST_SUCCESS = 'ALTERNATIVE_COST_POST_SUCCESS';
export const ALTERNATIVE_COST_UPDATE_PHASING = 'ALTERNATIVE_COST_UPDATE_PHASING';
export const ALTERNATIVE_COST_UPDATE_PHASING_INIT = 'ALTERNATIVE_COST_UPDATE_PHASING_INIT';
export const ALTERNATIVE_COST_UPDATE_PHASING_FAIL = 'ALTERNATIVE_COST_UPDATE_PHASING_FAIL';
export const ALTERNATIVE_COST_UPDATE_PHASING_SUCCESS = 'ALTERNATIVE_COST_UPDATE_PHASING_SUCCESS';
// ALTERNATIVE_START_SAVE
export const ALTERNATIVE_START_SAVE_SELECT = 'ALTERNATIVE_START_SAVE_SELECT';
export const ALTERNATIVE_START_SAVE_RETRIEVE_ALL = 'ALTERNATIVE_START_SAVE_RETRIEVE_ALL';
export const ALTERNATIVE_START_SAVE_RETRIEVE_ALL_INIT = 'ALTERNATIVE_START_SAVE_RETRIEVE_ALL_INIT';
export const ALTERNATIVE_START_SAVE_RETRIEVE_ALL_FAIL = 'ALTERNATIVE_START_SAVE_RETRIEVE_ALL_FAIL';
export const ALTERNATIVE_START_SAVE_RETRIEVE_ALL_SUCCESS = 'ALTERNATIVE_START_SAVE_RETRIEVE_ALL_SUCCESS';
export const ALTERNATIVE_START_SAVE_ADD = 'ALTERNATIVE_START_SAVE_ADD';
export const ALTERNATIVE_START_SAVE_UPDATE = 'ALTERNATIVE_START_SAVE_UPDATE';
export const ALTERNATIVE_START_SAVE_DELETE = 'ALTERNATIVE_START_SAVE_DELETE';
export const ALTERNATIVE_START_SAVE_POST_INIT = 'ALTERNATIVE_START_SAVE_POST_INIT';
export const ALTERNATIVE_START_SAVE_POST_FAIL = 'ALTERNATIVE_START_SAVE_POST_FAIL';
export const ALTERNATIVE_START_SAVE_POST_SUCCESS = 'ALTERNATIVE_START_SAVE_POST_SUCCESS';
export const ALTERNATIVE_START_UPDATE_START_PERIOD = 'ALTERNATIVE_START_UPDATE_START_PERIOD';
export const ALTERNATIVE_START_UPDATE_LOCK_PERIOD = 'ALTERNATIVE_START_UPDATE_LOCK_PERIOD';
export const ALTERNATIVE_START_UPDATE_BUDGET_CARRY_OVER = "ALTERNATIVE_START_UPDATE_BUDGET_CARRY_OVER";
export const ALTERNATIVE_START_UPDATE_BUDGET_DISTRIBUTE = "ALTERNATIVE_START_UPDATE_BUDGET_DISTRIBUTE";
export const ALTERNATIVE_START_CALCULATE = "ALTERNATIVE_START_CALCULATE";
export const ALTERNATIVE_START_CALCULATE_FAIL = "ALTERNATIVE_START_CALCULATE_FAIL";
export const ALTERNATIVE_START_CALCULATE_SUCCESS = "ALTERNATIVE_START_CALCULATE_SUCCESS";
// SCENARIO
export const SCENARIO_SELECT = 'SCENARIO_SELECT';
export const SCENARIO_RETRIEVE_ALL = 'SCENARIO_RETRIEVE_ALL';
export const SCENARIO_RETRIEVE_ALL_INIT = 'SCENARIO_RETRIEVE_ALL_INIT';
export const SCENARIO_RETRIEVE_ALL_FAIL = 'SCENARIO_RETRIEVE_ALL_FAIL';
export const SCENARIO_RETRIEVE_ALL_SUCCESS = 'SCENARIO_RETRIEVE_ALL_SUCCESS';
export const SCENARIO_ADD = 'SCENARIO_ADD';
export const SCENARIO_UPDATE = 'SCENARIO_UPDATE';
export const SCENARIO_DELETE = 'SCENARIO_DELETE';
export const SCENARIO_POST_INIT = 'SCENARIO_POST_INIT';
export const SCENARIO_POST_FAIL = 'SCENARIO_POST_FAIL';
export const SCENARIO_POST_SUCCESS = 'SCENARIO_POST_SUCCESS';
// VOTER_PORTFOLIO
export const VOTER_PORTFOLIO_RETRIEVE = 'VOTER_PORTFOLIO_RETRIEVE';
export const VOTER_PORTFOLIO_RETRIEVE_INIT = 'VOTER_PORTFOLIO_RETRIEVE_INIT';
export const VOTER_PORTFOLIO_RETRIEVE_FAIL = 'VOTER_PORTFOLIO_RETRIEVE_FAIL';
export const VOTER_PORTFOLIO_RETRIEVE_SUCCESS = 'VOTER_PORTFOLIO_RETRIEVE_SUCCESS';
export const VOTER_PORTFOLIO_RETRIEVE_ALL = 'VOTER_PORTFOLIO_RETRIEVE_ALL';
export const VOTER_PORTFOLIO_RETRIEVE_ALL_INIT = 'VOTER_PORTFOLIO_RETRIEVE_ALL_INIT';
export const VOTER_PORTFOLIO_RETRIEVE_ALL_FAIL = 'VOTER_PORTFOLIO_RETRIEVE_ALL_FAIL';
export const VOTER_PORTFOLIO_RETRIEVE_ALL_SUCCESS = 'VOTER_PORTFOLIO_RETRIEVE_ALL_SUCCESS';
export const VOTER_PORTFOLIO_RETRIEVE_BY_CODE = 'VOTER_PORTFOLIO_RETRIEVE_BY_CODE';
export const VOTER_PORTFOLIO_RETRIEVE_BY_CODE_INIT = 'VOTER_PORTFOLIO_RETRIEVE_INIT_BY_CODE';
export const VOTER_PORTFOLIO_RETRIEVE_BY_CODE_FAIL = 'VOTER_PORTFOLIO_RETRIEVE_FAIL_BY_CODE';
export const VOTER_PORTFOLIO_RETRIEVE_BY_CODE_SUCCESS = 'VOTER_PORTFOLIO_RETRIEVE_SUCCESS_BY_CODE';
export const VOTER_PORTFOLIO_ADD = 'VOTER_PORTFOLIO_ADD';
export const VOTER_PORTFOLIO_ADD_INIT = 'VOTER_PORTFOLIO_ADD_INIT';
export const VOTER_PORTFOLIO_ADD_FAIL = 'VOTER_PORTFOLIO_ADD_FAIL';
export const VOTER_PORTFOLIO_ADD_SUCCESS = 'VOTER_PORTFOLIO_ADD_SUCCESS';
export const VOTER_PORTFOLIO_UPDATE = 'VOTER_PORTFOLIO_UPDATE';
export const VOTER_PORTFOLIO_UPDATE_INIT = 'VOTER_PORTFOLIO_UPDATE_INIT';
export const VOTER_PORTFOLIO_UPDATE_FAIL = 'VOTER_PORTFOLIO_UPDATE_FAIL';
export const VOTER_PORTFOLIO_UPDATE_SUCCESS = 'VOTER_PORTFOLIO_UPDATE_SUCCESS';
// REPORT
export const REPORT_RETRIEVE = 'REPORT_RETRIEVE';
export const REPORT_RETRIEVE_INIT = 'REPORT_RETRIEVE_INIT';
export const REPORT_RETRIEVE_FAIL = 'REPORT_RETRIEVE_FAIL';
export const REPORT_RETRIEVE_SUCCESS = 'REPORT_RETRIEVE_SUCCESS';
export const REPORT_RETRIEVE_FIELD = 'REPORT_RETRIEVE_FIELD';
export const REPORT_RETRIEVE_FIELD_INIT = 'REPORT_RETRIEVE_FIELD_INIT';
export const REPORT_RETRIEVE_FIELD_FAIL = 'REPORT_RETRIEVE_FIELD_FAIL';
export const REPORT_RETRIEVE_FIELD_SUCCESS = 'REPORT_RETRIEVE_FIELD_SUCCESS';
export const REPORT_RETRIEVE_ALL = 'REPORT_RETRIEVE_ALL';
export const REPORT_RETRIEVE_ALL_INIT = 'REPORT_RETRIEVE_ALL_INIT';
export const REPORT_RETRIEVE_ALL_FAIL = 'REPORT_RETRIEVE_ALL_FAIL';
export const REPORT_RETRIEVE_ALL_SUCCESS = 'REPORT_RETRIEVE_ALL_SUCCESS';
export const REPORT_ADD = 'REPORT_ADD';
export const REPORT_UPDATE = 'REPORT_UPDATE';
export const REPORT_UPDATE_FIELD = 'REPORT_UPDATE_FIELD';
export const REPORT_ADD_SCENARIO = 'REPORT_ADD_SCENARIO';
export const REPORT_DELETE_SCENARIO = 'REPORT_DELETE_SCENARIO';
export const REPORT_DELETE = 'REPORT_DELETE';
export const REPORT_POST_INIT = 'REPORT_POST_INIT';
export const REPORT_POST_FAIL = 'REPORT_POST_FAIL';
export const REPORT_POST_SUCCESS = 'REPORT_POST_SUCCESS';
// ACCOUNT
export const ACCOUNT_SELECT = 'ACCOUNT_SELECT';
export const ACCOUNT_RETRIEVE = 'ACCOUNT_RETRIEVE';
export const ACCOUNT_RETRIEVE_INIT = 'ACCOUNT_RETRIEVE_INIT';
export const ACCOUNT_RETRIEVE_FAIL = 'ACCOUNT_RETRIEVE_FAIL';
export const ACCOUNT_RETRIEVE_SUCCESS = 'ACCOUNT_RETRIEVE_SUCCESS';
export const ACCOUNT_RETRIEVE_ALL = 'ACCOUNT_RETRIEVE_ALL';
export const ACCOUNT_RETRIEVE_ALL_INIT = 'ACCOUNT_RETRIEVE_ALL_INIT';
export const ACCOUNT_RETRIEVE_ALL_FAIL = 'ACCOUNT_RETRIEVE_ALL_FAIL';
export const ACCOUNT_RETRIEVE_ALL_SUCCESS = 'ACCOUNT_RETRIEVE_ALL_SUCCESS';
export const ACCOUNT_ADD = 'ACCOUNT_ADD';
export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE';
export const ACCOUNT_DELETE = 'ACCOUNT_DELETE';
export const ACCOUNT_POST_INIT = 'ACCOUNT_POST_INIT';
export const ACCOUNT_POST_FAIL = 'ACCOUNT_POST_FAIL';
export const ACCOUNT_POST_SUCCESS = 'ACCOUNT_POST_SUCCESS';


