import * as actionTypes from './actionTypes';

//ALTERNATIVE_RETRIEVE_ALL////////////////////////////////////////
export const alternativeRetrieveAll = (portfolio, guestId) => {  
  return {
    type: actionTypes.ALTERNATIVE_RETRIEVE_ALL,
    portfolioId: portfolio,
    guestId: guestId
  }
} // => saga/index.js

export const alternativeRetrieveAllInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_RETRIEVE_ALL_INIT
  }
} 

export const alternativeRetrieveAllFail = (error) => {  
  return {
    type: actionTypes.ALTERNATIVE_RETRIEVE_ALL_FAIL,
    error: error
  }
} 

export const alternativeRetrieveAllSuccess = (entries) => {  
  return {
    type: actionTypes.ALTERNATIVE_RETRIEVE_ALL_SUCCESS,
    entries: entries
  }
} 

//ALTERNATIVE_POST////////////////////////////////////////
export const alternativeAdd = (portfolioId, entry) => {  
  return {
    type: actionTypes.ALTERNATIVE_ADD,
    portfolioId: portfolioId,
    entry: entry
  }
} //=> saga/index.js

export const alternativeUpdate = (portfolioId, entry) => {  
  return {
    type: actionTypes.ALTERNATIVE_UPDATE,
    portfolioId: portfolioId,
    entry: entry,
  }
} // => saga/index.js

export const alternativeDelete = (portfolioId, entry) => {  
  return {
    type: actionTypes.ALTERNATIVE_DELETE,
    portfolioId: portfolioId,
    entry: entry,
  }
} // => saga/index.js

export const alternativePostInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_POST_INIT
  }
}

export const alternativePostFail = (error) => {  
  return {
    type: actionTypes.ALTERNATIVE_POST_FAIL,
    error: error
  }
} 

export const alternativePostSuccess = (entry, isDelete) => {  
  return {
    type: actionTypes.ALTERNATIVE_POST_SUCCESS,
    entry: entry,
    isDelete: isDelete ? isDelete : false
  }
}  

//ALTERNATIVE_MEASUREMENT_UPDATE///////////////////////////////
export const alternativeSetMeasurement = (altKey, measurementKey, value, oldValue) => {  
  return {
    type: actionTypes.ALTERNATIVE_UPDATE_MEASUREMENT,    
    altKey: altKey,
    measurementKey: measurementKey,
    value: value,
    oldValue: oldValue,
  }
} // => saga/index.js

export const alternativeSetMeasurementInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_UPDATE_MEASUREMENT_INIT
  }
}

export const alternativeSetMeasurementSuccess = (altKey, measurementKey, value) => {  
  return {
    type: actionTypes.ALTERNATIVE_UPDATE_MEASUREMENT_SUCCESS,    
    altKey: altKey,
    measurementKey: measurementKey,
    value: value
  }
}  

export const alternativeSetMeasurementFail = (error, altKey, measurementKey, oldValue) => {  
  return {
    type: actionTypes.ALTERNATIVE_UPDATE_MEASUREMENT_FAIL,
    error: error,
    altKey: altKey,
    measurementKey: measurementKey,
    oldValue: oldValue
  }
} 

//ALTERNATIVE_SUM_COST////////////////////////////////////////
export const alternativeSumCost = (measurements, alternativeCosts) => {
  return {
    type: actionTypes.ALTERNATIVE_SUM_COST,
    measurements: measurements,
    alternativeCosts: alternativeCosts,
  }
}
export const alternativeSumCostUpdate = (altKey, value) => {
  return {
    type: actionTypes.ALTERNATIVE_SUM_COST_UPDATE,
    altKey: altKey,
    value: value
  }
}
//ALTERNATIVE_IMPORT////////////////////////////////////////
export const alternativeImport = (portfolioId, user, data) => {
  return {
    type: actionTypes.ALTERNATIVE_IMPORT,
    portfolioId: portfolioId,
    user: user,
    data: data,
  }
} //=> saga/index.js

export const alternativeImportInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_IMPORT_INIT
  }
}

export const alternativeImportFail = (error) => {  
  return {
    type: actionTypes.ALTERNATIVE_IMPORT_FAIL,
    error: error
  }
} 

export const alternativeImportSuccess = (entries) => {  
  return {
    type: actionTypes.ALTERNATIVE_IMPORT_SUCCESS,
    entries: entries
  }
}  