import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../shared/utility';

const initialState = {    
    calendarModal: false,
    calendarModalNewEventDate: null,
    show: false,
    text: '',
    values: {}
}
export default (state = initialState, action) => {
    switch (action.type) {
        // NOTIFICATION
        case actionTypes.NOTIFICATION_START:
            return updateObject(state, {show: true, text: action.text, values: action.values});
        case actionTypes.NOTIFICATION_CLOSE:
            return updateObject(state, {show: false, text: '', values: {}});       
        // CALENDAR MODAL
        case actionTypes.CALENDAR_MODAL_INIT:
            return updateObject(state, {calendarModalNewEventDate: null});
        case actionTypes.CALENDAR_MODAL_SHOW:
            return updateObject(state, {calendarModal: true, calendarModalNewEventDate: action.newEventDate});
        case actionTypes.CALENDAR_MODAL_CLOSE:
            return updateObject(state, {calendarModal: false, calendarModalNewEventDate: null});
        default:
            return state;
    }
}