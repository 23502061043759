import {put} from 'redux-saga/effects';
import {getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions';
import locale from "locale/index";

export function* criteriaRetrieveAll(action) {
  yield put(actions.criteriaRetrieveAllInit());
  try{
    const guestUrl = action.guestId ? "/guests/" + action.guestId : "";
    const res = yield apis.get(`${guestUrl}/portfolios/${action.portfolioId}/criteria`, yield getAuthHeader());    
    if (res.status === 200) {  
      const criteria = {} 
      res.data.response.forEach(entry => {        
        criteria[entry.key] = {...entry};
      });      
      yield put(actions.criteriaRetrieveAllSuccess(criteria)); 
    } else {
      yield put(actions.criteriaRetrieveAllFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.criteriaRetrieveAllFail(error));
  };
}  

export function* criteriaAdd(action) {    
  yield put(actions.criteriaPostInit());
  try {
    const entry = {...action.entry, portfolio: action.portfolioId};
    // Update DB 
    const res = yield apis.post(`/criteria`, entry, yield getAuthHeader());
    if (res.status === 200) {
      // Update key
      entry.key = Number(res.data.response.insertId);
      // Post success
      yield put(actions.criteriaPostSuccess({...entry}));
      // Recalculate
      if (entry.criteriaType === 'measurement') {
        yield put(actions.measurementUpdateHasCriteria(entry.measurement, false));
      }
      yield put(actions.portfolioRecalculate({}, ['importance', 'sensitivity', 'vroi', 'budget']));
      // Notification
      yield put(actions.notificationShow(locale.notification_criteria_added, {name: entry.name}));
    } else {
      yield put(actions.criteriaPostFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.criteriaPostFail(error));
  };
}  

export function* criteriaUpdate(action) {  
  yield put(actions.criteriaPostInit());
  try{        
    const {...entry} = action.entry; 
    // Update DB 
    const res = yield apis.put(`/criteria/${entry.key}`, entry, yield getAuthHeader());  
    if (res.status === 200) {
      // Post success
      yield put(actions.criteriaPostSuccess({...entry}));
      // Notification
      yield put(actions.notificationShow(locale.notification_criteria_updated, {name: entry.name}));
    } else {
      yield put(actions.criteriaPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.criteriaPostFail(error));
  };
}  

export function* criteriaDelete(action) {
  yield put(actions.criteriaPostInit());
  try{        
    const {...entry} = action.entry; 
    // Update DB 
    const res = yield apis.delete(`/criteria/${entry.key}`, yield getAuthHeader());   
    if (res.status === 200) {      
      // Post success
      yield put(actions.criteriaPostSuccess(entry, true));
      // Recalculate
      if (entry.criteriaType === 'measurement') {
        yield put(actions.measurementUpdateHasCriteria(entry.measurement, true));
      }
      yield put(actions.portfolioRecalculate({}, ['importance', 'sensitivity', 'vroi', 'budget']));
      // Notification
      yield put(actions.notificationShow(locale.notification_criteria_deleted, {name: entry.name}));
    } else {
      yield put(actions.criteriaPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.criteriaPostFail(error));
  };
}
