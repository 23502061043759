import {put} from 'redux-saga/effects';
import {getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions'
import locale from "locale/index";

export function* measurementRetrieveAll(action) {
  yield put(actions.measurementRetrieveAllInit());
  try{    
    const res = yield apis.get(`portfolios/${action.portfolioId}/measurements`, yield getAuthHeader());     
    if (res.status === 200) {  
      const measurements = {} 
      res.data.response.forEach(entry => {        
        measurements[entry.key] = {...entry};
      });      
      yield put(actions.measurementRetrieveAllSuccess(measurements)); 
    } else {
      yield put(actions.measurementRetrieveAllFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.measurementRetrieveAllFail(error));
  };
}  

export function* measurementAdd(action) {   
  yield put(actions.measurementPostInit());
  try {
    const entry = {...action.entry, portfolio: action.portfolioId}; 
    // Update DB 
    const res = yield apis.post(`/measurements`, entry, yield getAuthHeader());
    if (res.status === 200) {
      // Update key
      entry.key = Number(res.data.response.insertId);
      // Post success
      yield put(actions.measurementPostSuccess({...entry}));
      // Also set budget measurement if need
      if (action.isBudgetMeasurement) {
        yield apis.post(`/portfolios/${action.portfolioId}/portfolioBudgetMeasurement`, {measurement: entry.key}, yield getAuthHeader());
        // No need to Update portfolio state, it will re-retrieve when open
        // yield put(actions.portfolioPostSuccess({key: action.portfolioId, measurement: entry.key}));
      }
      // Notification
      yield put(actions.notificationShow(locale.notification_measurement_added, {name: entry.name}));
    } else {
      yield put(actions.measurementPostFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.measurementPostFail(error));
  };
}  

export function* measurementUpdate(action) {
  yield put(actions.measurementPostInit());
  try{    
    const {...entry} = action.entry;  
    // Update DB 
    const res = yield apis.put(`/measurements/${entry.key}`, entry, yield getAuthHeader());   
    if (res.status === 200) {   
      // Post success
      yield put(actions.measurementPostSuccess({...entry}));
      // Notification
      yield put(actions.notificationShow(locale.notification_measurement_updated, {name: entry.name}));
    } else {
      yield put(actions.measurementPostFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.measurementPostFail(error));
  };
}  

export function* measurementDelete(action) {
  yield put(actions.measurementPostInit());
  try{    
    const {...entry} = action.entry;  
    // Update DB 
    const res = yield apis.delete(`/measurements/${entry.key}`, yield getAuthHeader());  
    if (res.status === 200) {     
      // Post success
      yield put(actions.measurementPostSuccess(entry, true));
      // Notification
      yield put(actions.notificationShow(locale.notification_measurement_deleted, {name: entry.name}));
    } else {
      yield put(actions.measurementPostFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.measurementPostFail(error));
  };
}
