import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
  // Retrieve All
  loadingAll: false,
  errorAll: null,
  entries: null,
  // Retrieve
  loading: false,
  error: null,
  answers: null,
  submittedAnswers: null,
  // Post
  loadingPost: false,
  errorPost: null,
  // Send Invite
  loadingInvite: false,
  errorInvite: null,
  // Submit
  loadingSubmit: false,
  errorSubmit: null,
  // AllowRemote
  loadingAllowRemote: false,
  errorAllowRemote: null
}
export default (state = initialState, action) => {
  switch (action.type) {
    // Retrieve All
    case actionTypes.CRITERIA_VOTER_RETRIEVE_ALL_INIT:
      return updateObject(state, {entries: null, loadingAll: true, answers: null, errorAll: null}); 
    case actionTypes.CRITERIA_VOTER_RETRIEVE_ALL_SUCCESS:      
      // Update wil end with retrieve all so turn off Post loading here
      return updateObject(state, {loadingAll: false, loadingPost: false, entries: action.entries, ...seperateAnswers(action.entries), errorAll: null});
    case actionTypes.CRITERIA_VOTER_RETRIEVE_ALL_FAIL:
      return updateObject(state, {loadingAll: false, errorAll: action.error});      
    // Retrieve
    case actionTypes.CRITERIA_VOTER_RETRIEVE_INIT:
      return updateObject(state, {loading: true, answers: null, error: null});
    case actionTypes.CRITERIA_VOTER_RETRIEVE_SUCCESS:
      return updateObject(state, {loading: false,  entries: action.entries, ...seperateAnswers(action.entries), error: null});
    case actionTypes.CRITERIA_VOTER_RETRIEVE_FAIL:
      return updateObject(state, {loading: false, error: action.error});
    // Post
    case actionTypes.CRITERIA_VOTERS_POST_INIT:
      return updateObject(state, {loadingPost: true, errorPost: null});      
    case actionTypes.CRITERIA_VOTERS_POST_SUCCESS:     
      return updateEntries(state, [action.voter]);
    case actionTypes.CRITERIA_VOTERS_POST_FAIL:
      return updateObject(state, {loadingPost: false, errorPost: action.error});
    // Invite
    case actionTypes.CRITERIA_VOTER_SEND_INVITE_INIT:
      return updateObject(state, {loadingInvite: true, errorInvite: null});
    case actionTypes.CRITERIA_VOTER_SEND_INVITE_SUCCESS:     
      return updateEntries(state, action.entries);
    case actionTypes.CRITERIA_VOTER_SEND_INVITE_FAIL:
      return updateObject(state, {loadingInvite: false, errorInvite: action.error});
    // Revert Submit
    case actionTypes.CRITERIA_VOTER_SUBMIT_INIT:
      return updateObject(state, {loadingSubmit: true, errorSubmit: null});
    case actionTypes.CRITERIA_VOTER_SUBMIT_SUCCESS:     
      return updateEntries(state, [action.voter]);
    case actionTypes.CRITERIA_VOTER_SUBMIT_FAIL:
      return updateObject(state, {loadingSubmit: false, errorSubmit: action.error});
    // Allow Remote
    case actionTypes.CRITERIA_VOTER_ALLOW_REMOTE_INIT:
      return updateObject(state, {loadingAllowRemote: true, errorAllowRemote: null});
    case actionTypes.CRITERIA_VOTER_ALLOW_REMOTE_SUCCESS:     
      return updateEntries(state, [action.voter]);
    case actionTypes.CRITERIA_VOTER_ALLOW_REMOTE_FAIL:
      return updateObject(state, {loadingAllowRemote: false, errorAllowRemote: action.error});
    // Update Answer  
    case actionTypes.CRITERIA_VOTER_UPDATE_ANSWER:
      return updateAnswer(state, action.answer);
    default:
      return state;
  }
}

const updateAnswer = (state, entry) => {
  // Update Voter's Answer
  const voterKey = entry.voter;
  const voterAnswers = state.answers[voterKey] ? state.answers[voterKey].slice() : [];
  const matchIdx = voterAnswers.findIndex(answer => answer.key === entry.key);
  if (matchIdx >= 0) {
    voterAnswers[matchIdx] = {...entry}
  } else {
    voterAnswers.push(entry);
  }

  // Update State
  const newState = {answers : {
    ...state.answers, 
    [voterKey] : voterAnswers
  }};
  return updateObject(state, newState);
}

const seperateAnswers = (voters) => {
  const answers = {};
  const submittedAnswers = {};
  voters.forEach(voter => {
    if (voter.answer && voter.answer.length > 0) {
      answers[voter.key] = voter.answer;
      if (voter.submit) {
        submittedAnswers[voter.key] = voter.answer;
      }
    }
  })
  return {
    answers: answers,    
    submittedAnswers: submittedAnswers
  };
}

const updateEntries = (state, updatedEntries) => {
  const newState = {loading: false, loadingInvite: false, loadingSubmit: false, loadingAllowRemote: false};
  // Update state.entries if has  
  const editedEntries = state.entries ? state.entries.slice() : [];
  const editedSubmittedAnswers= {...state.submittedAnswers};
  updatedEntries.forEach(updateEntry => {
    const matchEntryIdx = editedEntries.findIndex(existing => existing.key === updateEntry.key);
    if (matchEntryIdx > -1) {      
      const currEntry = editedEntries[matchEntryIdx];
      // Check if reverted submit - remove from submittedAnswers
      if (currEntry.submit && updateEntry.submit === null) {
        delete editedSubmittedAnswers[updateEntry.key];
      }
      //Update
      editedEntries[matchEntryIdx] = {...currEntry, ...updateEntry};
    } else {
      // Add
      editedEntries.push(updateEntry);
    }
  })  
  newState.entries = editedEntries;
  newState.submittedAnswers = editedSubmittedAnswers;
  
  return updateObject(state, newState);
}