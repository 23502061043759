import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from 'react-redux';
// Style 
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
// core components
import Button from "components/themes/CustomButtons/Button.jsx";
import Card from "components/themes/Card/Card.jsx";
import CardBody from "components/themes/Card/CardBody.jsx";
//Utilities
import whiteLogoSvg from "assets/img/white-logo.svg";
// Local
import { logToSplunk } from "shared/utility";

class ErrorBoundary extends React.Component {
  ////////////////////////////////////////////////////////////
  //LIFE-CYCLE
  ////////////////////////////////////////////////////////////  
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Log the error to Splunk
    logToSplunk("error", error, this.props.idToken);
  } 
  ////////////////////////////////////////////////////////////
  //EVENT HANDLERS
  //////////////////////////////////////////////////////////// 
  refresh = () => {
    this.setState({ hasError: false });
  }
  ////////////////////////////////////////////////////////////
  //RENDER
  //////////////////////////////////////////////////////////// 
  render() {
    const { classes } = this.props; 
    if (this.state.hasError) {         
      const card = (
        <Card login>
          <CardBody> 
            <div className={classes.center}>      
              <p>
                Oops! An unexpected error has occured. We're sorry for any inconvenient, please try refreshing the page. 
                To assist us on fixing this error, please raise a support ticket and describe your last action by clicking the "?" at the bottom corner of the page. Thanks you.
              </p>
              <Button color="primary" round
                onClick={() => this.refresh()}>
                Refresh
              </Button>
            </div>
          </CardBody>
        </Card>
      )
      return this.props.fullPage
      ? <div className={classes.fullPage}>
          <div className={classes.container}>   
            <img src={whiteLogoSvg} width="100%" height="45" alt="white-logo"/>
            {card}
          </div>
        </div>
      : card;
    }
    return this.props.children;
  }
}
const mapStateToProps = (state) => {
  return {
    idToken: state.auth.idToken
  };
}
export default connect(mapStateToProps, null)(withStyles(loginPageStyle)(ErrorBoundary));