const pagesStyle = theme => ({
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0",
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 59px)",
      maxHeight: "calc(100vh - 59px)",
    },
  },
  wrapperAuth: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0"
  },
  overflowPage:{
    background: "#532576",
    paddingTop: "70px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "59px",
    },
  },
  fullPage: {
    background: "#532576",
    padding: "50px 0",
    position: "relative",
    minHeight: "100vh",
    maxHeight: "1000px",
    display: "flex!important",
    margin: "0",
    border: "0",
    color: "#fff",
    // alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      // minHeight: "fit-content!important",
      padding: "10px 0",
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important"
    },
    "&:before": {
      backgroundColor: "rgba(0, 0, 0, 0.65)"
    },
    "&:before,&:after": {
      display: "block",
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      // zIndex: "3"
      zIndex: "-1"
    }
  },
  containerMdUp: {    
    position: "relative",
    zIndex: "1"
  },
  container: {
    // ...container,
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    minHeight: 'calc(100vh - 141px)',
    // "@media (min-width: 768px)": {
      
    // },
    // "@media (min-width: 960px)": {
    //   paddingRight: "15px",
    //   paddingLeft: "15px",
    //   width: "95%"
    // },
    // "@media (min-width: 1200px)": {
    //   paddingRight: "15px",
    //   paddingLeft: "15px",
    //   width: "95%"
    // },
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    },
    position: "relative",
    zIndex: "1"
  }
});

export default pagesStyle;
