import * as actionTypes from './actionTypes';

//CRITERIA_VOTER_RETRIEVE_ALL////////////////////////////////////////
export const criteriaVoterRetrieveAll = (portfolioId, userId) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_RETRIEVE_ALL,
    portfolioId: portfolioId
  }
} // => saga/index.js

export const criteriaVoterRetrieveAllInit = () => {  
  return {
    type: actionTypes.CRITERIA_VOTER_RETRIEVE_ALL_INIT
  }
} 

export const criteriaVoterRetrieveAllFail = (error) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_RETRIEVE_ALL_FAIL,
    error: error
  }
} 

export const criteriaVoterRetrieveAllSuccess = (entries) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_RETRIEVE_ALL_SUCCESS,
    entries: entries
  }
} 
//CRITERIA_VOTER_RETRIEVE////////////////////////////////////////
export const criteriaVoterRetrieve = (voterId, guestId) => {
  return {
    type: actionTypes.CRITERIA_VOTER_RETRIEVE,
    voterId: voterId,
    guestId: guestId
  }
} // => saga/index.js

export const criteriaVoterRetrieveInit = () => {  
  return {
    type: actionTypes.CRITERIA_VOTER_RETRIEVE_INIT
  }
} 

export const criteriaVoterRetrieveFail = (error) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_RETRIEVE_FAIL,
    error: error
  }
} 

export const criteriaVoterRetrieveSuccess = (entries) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_RETRIEVE_SUCCESS,
    entries: entries
  }
}
//CRITERIA_VOTER_SEND_INVITE////////////////////////////////////////
export const criteriaVoterSendInvite = (portfolioId, currentVoters, isReminder) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_SEND_INVITE,
    portfolioId: portfolioId,
    currentVoters: currentVoters,
    isReminder: isReminder
  }
} // => saga/index.js

export const criteriaVoterSendInviteInit = () => {  
  return {
    type: actionTypes.CRITERIA_VOTER_SEND_INVITE_INIT
  }
} 

export const criteriaVoterSendInviteFail = (error) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_SEND_INVITE_FAIL,
    error: error
  }
} 

export const criteriaVoterSendInviteSuccess = (entries) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_SEND_INVITE_SUCCESS,
    entries: entries
  }
}
//CRITERIA_VOTERS_POST////////////////////////////////////////
export const criteriaVotersUpdate = (portfolioId, currentVoters, entries) => {  
  return {
    type: actionTypes.CRITERIA_VOTERS_UPDATE,
    portfolioId: portfolioId,
    currentVoters: currentVoters,
    entries: entries,
  }
} // => saga/index.js

export const criteriaVotersPostInit = () => {  
  return {
    type: actionTypes.CRITERIA_VOTERS_POST_INIT
  }
}

export const criteriaVotersPostFail = (error) => {  
  return {
    type: actionTypes.CRITERIA_VOTERS_POST_FAIL,
    error: error
  }
} 

export const criteriaVotersPostSuccess = (voter) => {  
  return {
    type: actionTypes.CRITERIA_VOTERS_POST_SUCCESS,
    voter: voter
  }
}  
//CRITERIA_VOTER_SUBMIT////////////////////////////////////////
export const criteriaVoterSubmit = (portfolioId, voterId, isRevert) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_SUBMIT,
    portfolioId: portfolioId,
    voterId: voterId,
    isRevert: isRevert
  }
} // => saga/index.js

export const criteriaVoterSubmitInit = () => {  
  return {
    type: actionTypes.CRITERIA_VOTER_SUBMIT_INIT
  }
} 

export const criteriaVoterSubmitFail = (error) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_SUBMIT_FAIL,
    error: error
  }
} 

export const criteriaVoterSubmitSuccess = (voter) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_SUBMIT_SUCCESS,
    voter: voter
  }
}
//CRITERIA_VOTER_ALLOW_REMOTE////////////////////////////////////////
export const criteriaVoterAllowRemote = (portfolioId, voter, user) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_ALLOW_REMOTE,
    portfolioId: portfolioId,
    voter: voter,
    user: user,
  }
} // => saga/index.js

export const criteriaVoterAllowRemoteInit = () => {  
  return {
    type: actionTypes.CRITERIA_VOTER_ALLOW_REMOTE_INIT
  }
} 

export const criteriaVoterAllowRemoteFail = (error) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_ALLOW_REMOTE_FAIL,
    error: error
  }
} 

export const criteriaVoterAllowRemoteSuccess = (voter) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_ALLOW_REMOTE_SUCCESS,
    voter: voter
  }
}

//CRITERIA_VOTER_UPDATE_ANSWER////////////////////////////////////////
export const criteriaVoterUpdateAnswer = (answer) => {  
  return {
    type: actionTypes.CRITERIA_VOTER_UPDATE_ANSWER,
    answer: answer
  }
}