import * as actionTypes from './actionTypes';

//ALTERNATIVE_VOTER_RETRIEVE_ALL////////////////////////////////////////
export const alternativeVoterRetrieveAll = (portfolioId, userId) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_RETRIEVE_ALL,
    portfolioId: portfolioId
  }
} // => saga/index.js

export const alternativeVoterRetrieveAllInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_RETRIEVE_ALL_INIT
  }
} 

export const alternativeVoterRetrieveAllFail = (error) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_RETRIEVE_ALL_FAIL,
    error: error
  }
} 

export const alternativeVoterRetrieveAllSuccess = (entries) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_RETRIEVE_ALL_SUCCESS,
    entries: entries
  }
} 
//ALTERNATIVE_VOTER_RETRIEVE////////////////////////////////////////
export const alternativeVoterRetrieve = (voterId, guestId) => {
  return {
    type: actionTypes.ALTERNATIVE_VOTER_RETRIEVE,
    voterId: voterId,
    guestId: guestId
  }
} // => saga/index.js

export const alternativeVoterRetrieveInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_RETRIEVE_INIT
  }
} 

export const alternativeVoterRetrieveFail = (error) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_RETRIEVE_FAIL,
    error: error
  }
} 

export const alternativeVoterRetrieveSuccess = (entries) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_RETRIEVE_SUCCESS,
    entries: entries
  }
}
//ALTERNATIVE_VOTER_SEND_INVITE////////////////////////////////////////
export const alternativeVoterSendInvite = (portfolioId, currentVoters, isReminder) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_SEND_INVITE,
    portfolioId: portfolioId,
    currentVoters: currentVoters,
    isReminder: isReminder
  }
} // => saga/index.js

export const alternativeVoterSendInviteInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_SEND_INVITE_INIT
  }
} 

export const alternativeVoterSendInviteFail = (error) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_SEND_INVITE_FAIL,
    error: error
  }
} 

export const alternativeVoterSendInviteSuccess = (entries) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_SEND_INVITE_SUCCESS,
    entries: entries
  }
}
//ALTERNATIVE_VOTERS_POST////////////////////////////////////////
export const alternativeVotersUpdate = (portfolioId, currentVoters, entries) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTERS_UPDATE,
    portfolioId: portfolioId,
    currentVoters: currentVoters,
    entries: entries,
  }
} // => saga/index.js

export const alternativeVotersPostInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTERS_POST_INIT
  }
}

export const alternativeVotersPostFail = (error) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTERS_POST_FAIL,
    error: error
  }
} 

export const alternativeVotersPostSuccess = (voter) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTERS_POST_SUCCESS,
    voter: voter
  }
}  
//ALTERNATIVE_VOTER_SUBMIT////////////////////////////////////////
export const alternativeVoterSubmit = (portfolioId, voterId, isRevert) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_SUBMIT,
    portfolioId: portfolioId,
    voterId: voterId,
    isRevert: isRevert
  }
} // => saga/index.js

export const alternativeVoterSubmitInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_SUBMIT_INIT
  }
} 

export const alternativeVoterSubmitFail = (error) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_SUBMIT_FAIL,
    error: error
  }
} 

export const alternativeVoterSubmitSuccess = (voter) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_SUBMIT_SUCCESS,
    voter: voter
  }
}
//ALTERNATIVE_VOTER_ALLOW_REMOTE////////////////////////////////////////
export const alternativeVoterAllowRemote = (portfolioId, voter, user) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_ALLOW_REMOTE,
    portfolioId: portfolioId,
    voter: voter,
    user: user,
  }
} // => saga/index.js

export const alternativeVoterAllowRemoteInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_ALLOW_REMOTE_INIT
  }
} 

export const alternativeVoterAllowRemoteFail = (error) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_ALLOW_REMOTE_FAIL,
    error: error
  }
} 

export const alternativeVoterAllowRemoteSuccess = (voter) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_ALLOW_REMOTE_SUCCESS,
    voter: voter
  }
}

//ALTERNATIVE_VOTER_UPDATE_ANSWER////////////////////////////////////////
export const alternativeVoterUpdateAnswer = (answer) => {  
  return {
    type: actionTypes.ALTERNATIVE_VOTER_UPDATE_ANSWER,
    answer: answer
  }
}