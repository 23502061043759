/* Program to generate all r-Combinations of a set with distinct element
 * This code is a part of https://phoxis.wordpress.com/2009/10/13/allcombgen/
 */
 
const MAX = 10000;
const FALSE = 0;
const ALPHABET = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

export const generateCombinations = (presetItems, collections) => {
  const combinations = [];
  let uniString = '';
  collections.forEach((entry, idx) => {
    uniString += ALPHABET[idx];
  });
  // Generate Index String
  const combinationIdxs = generateCombinationIndexes(uniString);
  // Convert to collection combination
  combinationIdxs.forEach(comb => {
    const combination = [...presetItems];
    comb.forEach(combChar => {
      const index = ALPHABET.findIndex(char => char === combChar);
      combination.push(collections[index]);
    })
    combinations.push(combination);
  })
  return combinations;
}

 
/* Main Function, driving generate_combination() */
export const generateCombinationIndexes = (source_string) => {
  const combinations = [];
  const len = source_string.length;
  const max_mask_length = ~(0x00000001 << len); 
  let comb_mask = 0x00000001;   
  while (comb_mask < MAX)  {
    const combination = generateCombination (source_string, comb_mask, max_mask_length);    
    if (combination === FALSE)  break;
    combinations.push(combination);
    comb_mask++;
  }
  return combinations;
}
 
/*
 *  File Name     : combination.c
 *  Function Name : generate_combination
 *  Parameters    :
 *                @ (const char *) source_string
 *                @ (char *)       combination_string
 *  Return Type   : (int)
 *                    # return FALSE if no more mermutations, else return TRUE
 *  Globals       :
 *                @ (unsigned int) comb_mask
 *                    # A bitmask. Used to select unique combinations from source
 *                      string. This function increments and updates this mask
 *                      in each iteration. Each call results in a new permutation.
 *                @ (unsigned int) max_mask_length
 *                    # A bitmask. Used to control the length of masking of
 *                      comb_mask variable. This is not modified in this function.
 *                      Initilized in function main()
 *  Description   : Each call to this function generated a new combination from
 *                  the source_string and then places into combination_string
 *                  The combination is done based upon comb_mask variable. The
 *                  positions, in which comb_mask has a '1' , are only selected
 *                  from the source_string to make a combination.
 *  Note          : The combination generation is comb_mask dependent. To generate
 *                  unique combinations in each call comb_mask should not be modified
 *                  any where else, if some explicit customized combinination is to be
 *                  generated. Each bit field has a fixed length (32bit) , the combintaion
 *                  can be generated is limited to this length, in this version of code.
 *                  This function does not return the null set.
 */
 
const generateCombination = (source_string, comb_mask, max_mask_length) => {
  let mask = 0x00000001;
  let s_pos = 0, c_pos = 0;
  let combination = [];
 
  /* Main logic */
  while ((mask & max_mask_length))
    {
      if ((comb_mask & mask))
      {
          combination[c_pos] = source_string[s_pos];
          c_pos++;
      }
      s_pos++;
      mask <<= 1;
    } 
  /* Terminate the combination_string with NULL character */
  // combination_string[c_pos] = 0;
 
  /* If combination_string is empty, ie. c_pos == 0 , return FALSE else return TRUE */
  return (c_pos === 0 ? FALSE : combination);
}