import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import LockOpen from "@material-ui/icons/LockOpen";
import Menu from "@material-ui/icons/Menu";

// core components
import Button from "components/themes/CustomButtons/Button";
// Local
import locale from "locale/index";
import pagesHeaderStyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx";
import * as actions from 'store/actions/index';
import logoSvg from "assets/img/logo-inner.svg";

class PagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    const {pathname} = this.props.location;    
    if (routeName.endsWith("/:id")) {
      return !pathname.replace(routeName.replace("/:id", "") + "/", "").includes("/");
    } else if (routeName.includes("/:id")) {
      const parts = routeName.split("/:id");
      return pathname.startsWith(parts[0]) && pathname.endsWith(parts[1]);
    } else {
      return pathname.indexOf(routeName) > -1 ? true : false;
    }
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false });
    }
  }
  render() {
    const { classes, color } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });
    var routes = this.props.routes;
    var list = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect) {
            return null;
          }
          const navLink = classes.navLink + cx({[" " + classes.navLinkActive]: this.activeRoute(prop.path)});
          return (
            <ListItem key={key} className={classes.listItem}>
              <NavLink to={prop.path} className={navLink}>
                <ListItemIcon className={classes.listItemIcon}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={prop.short}
                  disableTypography={true}
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
          );
        })}     
        {this.props.isAuthenticated    
        ? <React.Fragment>
            <ListItem className={classes.listItem}>
              <NavLink to={"/logout"} className={classes.navLink}>
                <ListItemIcon className={classes.listItemIcon}>
                  <LockOpen />
                </ListItemIcon>
                <span className={classes.listItemText}>{locale.auth_sign_out}</span>
              </NavLink>        
            </ListItem>
          </React.Fragment>
        : null}
      </List>
    );
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          {/* /////////////////////////////////////////// */}
          {/* Full Page */}
          {/* /////////////////////////////////////////// */}
          <Hidden smDown>
            <div className={classes.flex}>
              <Button href="#" className={classes.title} color="transparent" >
                <img src={logoSvg} alt="logo-hr"></img>   
              </Button>
            </div>
          </Hidden>
          <Hidden smDown>{list}</Hidden>
          
          {/* /////////////////////////////////////////// */}
          {/* Mobile Page */}          
          {/* /////////////////////////////////////////// */}
          <Hidden mdUp>
            <div className={classes.flex}>
              <Button href="#" className={classes.titleMdUp} color="transparent" size="sm" style={{paddingLeft: "0"}}>
                <img src={logoSvg} width="166" height="25" alt="logo-hr"></img>                
              </Button>
            </div>
          </Hidden>
          <Hidden mdUp>
            <Button
              className={classes.sidebarButton}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor={"right"}
                open={this.state.open}
                classes={{
                  paper: classes.drawerPaper
                }}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {list}
              </Drawer>
            </Hidden>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};


const mapStateToProps = (state) => {
  return {
      isAuthenticated: state.auth.idToken !== null, 
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogOut: () => dispatch(actions.authLogout())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pagesHeaderStyle)(PagesHeader));