import * as actionTypes from './actionTypes';
//REPORT_RETRIEVE////////////////////////////////////////
export const reportRetrieve = (portfolioId, reportId) => {  
  return {
    type: actionTypes.REPORT_RETRIEVE,
    portfolioId: portfolioId,
    reportId: reportId
  }
} // => saga/index.js

export const reportRetrieveInit = () => {  
  return {
    type: actionTypes.REPORT_RETRIEVE_INIT
  }
} 

export const reportRetrieveFail = (error) => {  
  return {
    type: actionTypes.REPORT_RETRIEVE_FAIL,
    error: error
  }
} 

export const reportRetrieveSuccess = (report) => {  
  return {
    type: actionTypes.REPORT_RETRIEVE_SUCCESS,
    entry: report
  }
} 
//REPORT_RETRIEVE_FIELD////////////////////////////////////////
export const reportRetrieveField = (reportId) => {  
  return {
    type: actionTypes.REPORT_RETRIEVE_FIELD,
    reportId: reportId
  }
} // => saga/index.js

export const reportRetrieveFieldInit = () => {  
  return {
    type: actionTypes.REPORT_RETRIEVE_FIELD_INIT
  }
} 

export const reportRetrieveFieldFail = (error) => {  
  return {
    type: actionTypes.REPORT_RETRIEVE_FIELD_FAIL,
    error: error
  }
} 
export const reportRetrieveFieldSuccess = (fields) => {  
  return {
    type: actionTypes.REPORT_RETRIEVE_FIELD_SUCCESS,
    fields: fields
  }
} 

//REPORT_RETRIEVE_ALL////////////////////////////////////////
export const reportRetrieveAll = (portfolioId) => {  
  return {
    type: actionTypes.REPORT_RETRIEVE_ALL,
    portfolioId: portfolioId
  }
} // => saga/index.js

export const reportRetrieveAllInit = () => {  
  return {
    type: actionTypes.REPORT_RETRIEVE_ALL_INIT
  }
} 

export const reportRetrieveAllFail = (error) => {  
  return {
    type: actionTypes.REPORT_RETRIEVE_ALL_FAIL,
    error: error
  }
} 

export const reportRetrieveAllSuccess = (reports) => {  
  return {
    type: actionTypes.REPORT_RETRIEVE_ALL_SUCCESS,
    entries: reports
  }
} 

//REPORT_POST////////////////////////////////////////
export const reportAdd = (entry) => {  
  return {
    type: actionTypes.REPORT_ADD,
    entry: entry
  }
} //=> saga/index.js

export const reportUpdate = (entry) => {  
  return {
    type: actionTypes.REPORT_UPDATE,
    entry: entry,
  }
} // => saga/index.js

export const reportDelete = (entry) => {  
  return {
    type: actionTypes.REPORT_DELETE,
    entry: entry,
  }
} // => saga/index.js

// Child ////////////////
export const reportUpdateField = (report, entry) => {  
  return {
    type: actionTypes.REPORT_UPDATE_FIELD,
    report: report,
    entry: entry,
  }
} // => saga/index.js

export const reportAddScenario = (report, scenarioId) => {  
  return {
    type: actionTypes.REPORT_ADD_SCENARIO,
    report: report,
    scenarioId: scenarioId,
  }
} // => saga/index.js

export const reportRemoveScenario = (report, scenarioId) => {  
  return {
    type: actionTypes.REPORT_DELETE_SCENARIO,
    report: report,
    scenarioId: scenarioId,
  }
} // => saga/index.js

export const reportPostInit = () => {  
  return {
    type: actionTypes.REPORT_POST_INIT
  }
}

export const reportPostFail = (error) => {  
  return {
    type: actionTypes.REPORT_POST_FAIL,
    error: error
  }
} 

export const reportPostSuccess = (entry, isDelete) => {  
  return {
    type: actionTypes.REPORT_POST_SUCCESS,
    entry: entry,
    isDelete: isDelete ? isDelete : false
  }
}  