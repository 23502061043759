import * as actionTypes from './actionTypes';

//MEASUREMENT_RETRIEVE_ALL////////////////////////////////////////
export const measurementRetrieveAll = (portfolio) => {  
  return {
    type: actionTypes.MEASUREMENT_RETRIEVE_ALL,
    portfolioId: portfolio
  }
} // => saga/index.js

export const measurementRetrieveAllInit = () => {  
  return {
    type: actionTypes.MEASUREMENT_RETRIEVE_ALL_INIT
  }
} 

export const measurementRetrieveAllFail = (error) => {  
  return {
    type: actionTypes.MEASUREMENT_RETRIEVE_ALL_FAIL,
    error: error
  }
} 

export const measurementRetrieveAllSuccess = (entries) => {  
  return {
    type: actionTypes.MEASUREMENT_RETRIEVE_ALL_SUCCESS,
    entries: entries
  }
} 

//MEASUREMENT_POST////////////////////////////////////////
export const measurementAdd = (portfolioId, entry, isBudgetMeasurement) => {  
  return {
    type: actionTypes.MEASUREMENT_ADD,
    portfolioId: portfolioId,
    entry: entry,
    isBudgetMeasurement: isBudgetMeasurement
  }
} //=> saga/index.js

export const measurementUpdate = (portfolioId, entry) => {  
  return {
    type: actionTypes.MEASUREMENT_UPDATE,
    portfolioId: portfolioId,
    entry: entry,
  }
} // => saga/index.js

export const measurementDelete = (portfolioId, entry) => {  
  return {
    type: actionTypes.MEASUREMENT_DELETE,
    portfolioId: portfolioId,
    entry: entry,
  }
} // => saga/index.js

export const measurementPostInit = () => {  
  return {
    type: actionTypes.MEASUREMENT_POST_INIT
  }
}

export const measurementPostFail = (error) => {  
  return {
    type: actionTypes.MEASUREMENT_POST_FAIL,
    error: error
  }
} 

export const measurementPostSuccess = (entry, isDelete) => {  
  return {
    type: actionTypes.MEASUREMENT_POST_SUCCESS,
    entry: entry,
    isDelete: isDelete ? isDelete : false
  }
}  

export const measurementUpdateHasCriteria = (entryKey, isDelete) => {  
  return {
    type: actionTypes.MEASUREMENT_UPDATE_HAS_CRITERIA,
    entryKey: entryKey,
    isDelete: isDelete ? isDelete : false
  }
}  