import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from 'react-intl';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import CustomInput from "components/themes/CustomInput/CustomInput.jsx";
import Button from "components/themes/CustomButtons/Button.jsx";
import Card from "components/themes/Card/Card.jsx";
import CardBody from "components/themes/Card/CardBody.jsx";
import Danger from "components/themes/Typography/Danger.jsx";
//Style 
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
//Utilities
import locale, {fancyFormat} from "locale/index";
import * as actions from 'store/actions/index';
import Spinner from 'UI/Spinner/Spinner';
import whiteLogoSvg from "assets/img/white-logo.svg";
import {changeValidate, getErrorDetails} from 'shared/utility';


class FirstTimeLogin extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      password: '',
      passwordState: '',
      reTypePassword: '',
      reTypePasswordState: ''
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      100
    );    
  } 

  handleChange = (event, stateName, type, compareValue, maxValue) => {    
    this.setState(changeValidate(event, stateName, type, compareValue, maxValue));
  }

  handleSubmit = (event) => {      
    event.preventDefault();   
    this.props.onSetPassword(this.props.signInEmailLink, this.state.email.toLowerCase(), this.state.password);    
  }  

  handleCancel = () => {   
    this.props.onAuthSetEmailLink(null);
  }

  render() {        
    const { classes, intl} = this.props;    
    return (      
      <div className={classes.container}>
        <img src={whiteLogoSvg} width="100%" height="45" alt="white-logo"/>
        <Card login className={classes[this.state.cardAnimaton]}>
          <CardBody>
            {this.props.loading
            ? <Spinner/>
            : <form onSubmit={this.handleSubmit}>            
                <p>
                  {fancyFormat(intl, locale.auth_first_time_helper, {
                    hereLink: <NavLink to="/auth/login" onClick={this.handleCancel}><strong>{locale.here}</strong></NavLink>
                  })}
                </p>                
                <Danger>{getErrorDetails(this.props.intl, this.props.error)}</Danger>
                <CustomInput
                    labelText={locale.auth_first_time_email}
                    id="registeremail"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: event =>
                          this.handleChange(event, "email", "email"),
                        type: "email",
                    }}
                />
                <CustomInput
                    success={this.state.passwordState === "success"}
                    error={this.state.passwordState === "error"}                    
                    labelText={locale.auth_first_time_new_pw}
                    id="password"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: event =>
                          this.handleChange(event, "password", "password"),
                        type: "password"
                    }}
                />
                <CustomInput
                    success={
                      this.state.reTypePasswordState === "success"
                    }
                    error={this.state.reTypePasswordState === "error"}                    
                    labelText={locale.auth_first_time_confirm_pw}
                    id="reTypePassword"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event =>
                          this.handleChange(
                            event,
                            "reTypePassword",
                            "equalTo",
                            this.state.password
                          ),
                      type: "password"
                    }}
                />
                <div className={classes.center}>
                  <Button color="primary" round type="submit" 
                    disabled={!(this.state.passwordState==='success' && this.state.reTypePasswordState==='success')}>
                    {locale.confirm}
                  </Button>
                </div>
              </form>
            }
          </CardBody>
        </Card>
      </div>       
    );
  }
}

FirstTimeLogin.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loadingFirst,
    error: state.auth.errorFirst,
    signInEmailLink: state.auth.authEmailLink,
  };
}

const mapDispatchToProps = (dispatch) => {
    return {
      onAuthSetRedirectPath: (path) => dispatch(actions.authSetRedirectPath(path)),
      onSetPassword: (url, email, password) => dispatch(actions.authFirstLogin(url, email, password))
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(injectIntl(FirstTimeLogin)));
