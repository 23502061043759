import {put} from 'redux-saga/effects';
import {getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions';
import locale from "locale/index";

export function* alternativeCostRetrieveAll(action) {
  yield put(actions.alternativeCostRetrieveAllInit());
  try{    
    const res = yield apis.get(`portfolios/${action.portfolioId}/alternativeCosts`, yield getAuthHeader());   
    if (res.status === 200) {  
      const alternativeCosts = {} 
      res.data.response.forEach(entry => {        
        alternativeCosts[entry.key] = {...entry};
      });      
      yield put(actions.alternativeCostRetrieveAllSuccess(alternativeCosts)); 
    } else {
      yield put(actions.alternativeCostRetrieveAllFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);  
    yield put(actions.alternativeCostRetrieveAllFail(error));
  };
}  

export function* alternativeCostAdd(action) {  
  yield put(actions.alternativeCostPostInit());
  try {
    const entry = {...action.entry};    
    // Update DB
    const res = yield apis.post(`alternativeCosts`, entry, yield getAuthHeader());
    if (res.status === 200) {  
      // Update key
      entry.key = Number(res.data.response.insertId);
      // Post success
      yield put(actions.alternativeCostPostSuccess({...entry}));
      // Recalculate
      yield put(actions.portfolioRecalculate({}, ['alternativeCost']));
      // Notification
      yield put(actions.notificationShow(locale.notification_alternative_cost_added, {name: entry.name}));
    } else {
      yield put(actions.alternativeCostPostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);  
    yield put(actions.alternativeCostPostFail(error));
  };
}  

export function* alternativeCostUpdate(action) {
  yield put(actions.alternativeCostPostInit());
  try{    
    const {...entry} = action.entry; 
    // Update DB
    const res = yield apis.put(`/alternativeCosts/${entry.key}`, entry, yield getAuthHeader());
    if (res.status === 200) {  
      // Post success
      yield put(actions.alternativeCostPostSuccess({...entry}));
      // Recalculate
      yield put(actions.portfolioRecalculate({}, ['alternativeCost']));
      // Notification
      yield put(actions.notificationShow(locale.notification_alternative_cost_updated, {name: entry.name}));
    } else {
      yield put(actions.alternativeCostPostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);  
    yield put(actions.alternativeCostPostFail(error));
  };
}  

export function* alternativeCostDelete(action) {
  yield put(actions.alternativeCostPostInit());
  try{    
    const {...entry} = action.entry; 
    // Update DB
    const res = yield apis.delete(`/alternativeCosts/${entry.key}`, {data: entry, ...yield getAuthHeader()});  
    if (res.status === 200) {  
      // Post success
      yield put(actions.alternativeCostPostSuccess(entry, true));
      // Recalculate
      yield put(actions.portfolioRecalculate({}, ['alternativeCost']));
      // Notification
      yield put(actions.notificationShow(locale.notification_alternative_cost_deleted, {name: entry.name}));
    } else {
      yield put(actions.alternativeCostPostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);  
    yield put(actions.alternativeCostPostFail(error));
  };
}

export function* alternativeCostSetPhasing(action) {
  const {key, period, value, oldValue} = action; 
  yield put(actions.alternativeCostSetPhasingInit());
  try{
    // Post success first, roll back if error
    yield put(actions.alternativeCostSetPhasingSuccess(key, period, value));
    // Update DB
    const res = yield apis.post(`/alternativeCosts/${key}/phasings/${period}`, {value: value}, yield getAuthHeader());
    if (res.status === 200) {
      // Update alternativeSumCost      
      yield put(actions.alternativeSumCostUpdate(action.altKey, value - oldValue));
      // Recalculate - now success is guarantee
      yield put(actions.portfolioRecalculate({}, ['sensitivity', 'vroi', 'alternativeCost']));
    } else {
      yield put(actions.alternativeCostPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);  
    yield put(actions.alternativeCostSetPhasingFail(error, key, period, oldValue));
  };
} 
