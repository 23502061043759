import * as actionTypes from '../actions/actionTypes'
import {updateObject, logToSplunk} from '../../shared/utility';
import {calculateImportance, calculateSensitivity, calculateVROI, calculateBudgetOptions} from '../../shared/calculationUtility';

const initialState = {
  // Retrieve All
  loadingAll: false,
  errorAll: null,
  portfolios: null,
  // Retrieve 
  loading: false,
  error: null,
  currentPortfolio: null,
  // Post
  loadingPost: false,
  errorPost: null,
  // Importance
  loadingImportance: false,
  errorImportance: null,  
  reciprocalMatrix: null, 
  priorityVector: null, 
  flatPriorityVector: null,
  stdDevMatrix: null,
  consistencyRatios: null,
  // Sensitivity
  loadingSensitivity: false,
  errorSensitivity: null,  
  alternativeVectors: null, 
  sensitivityVectors: null,
  sensitivityStdDeviations: null,
  sensitivityRangeMatrix: null,
  altSortedKeys: null,
  // VROI
  loadingVROI: false,
  errorVROI: null,  
  sumAlternativeCosts: null, 
  alternativeCostNormalises: null,
  alternativeBenefits: null,
  alternativeVROIs: null,
  // Fund Options
  loadingFundOptions: false,
  errorFundOptions: null,  
  fundOptions: null, 
  selectedOption: null,
  // Tweak
  alternativeForceFunds: [],
  loadingTweak: false,
  errorTweak: null,  
  // VoteCategory
  voteCategory: null,
  // Recalculation
  recalculationRequestTime: null
}
export default (state = initialState, action) => {
  switch (action.type) {
    // Retrieve All
    case actionTypes.PORTFOLIO_RETRIEVE_ALL_INIT:
      return updateObject(state, {loadingAll: true, errorAll: null});
    case actionTypes.PORTFOLIO_RETRIEVE_ALL_SUCCESS:
      return updateObject(state, {loadingAll: false, portfolios: action.portfolios});
    case actionTypes.PORTFOLIO_RETRIEVE_ALL_FAIL:
      return updateObject(state, {loadingAll: false, errorAll: action.error});
    // Retrieve
    case actionTypes.PORTFOLIO_RETRIEVE_INIT:
      return updateObject(state, {...initialState, portfolios: state.portfolios, loading: true, loadingImportance: true, loadingSensitivity: true, loadingVROI: true, loadingFundOptions: true });
    case actionTypes.PORTFOLIO_RETRIEVE_SUCCESS:
      return updateObject(state, {loading: false, currentPortfolio: action.portfolio});
    case actionTypes.PORTFOLIO_RETRIEVE_FAIL:
      return updateObject(state, {loading: false, error: action.error});
    // Post
    case actionTypes.PORTFOLIO_POST_INIT:
      return updateObject(state, {loadingPost: true, errorPost: null});      
    case actionTypes.PORTFOLIO_POST_SUCCESS:    
      return updatePortfolio(state, action.portfolio, action.isDelete);
    case actionTypes.PORTFOLIO_POST_FAIL:
      return updateObject(state, {loadingPost: false, errorPost: action.error});
    // Calculate
    case actionTypes.PORTFOLIO_CALCULATE_IMPORTANCE:
      return updateImportance(state, action.criteria, action.answers, action.idToken);
    case actionTypes.PORTFOLIO_CALCULATE_SENSITIVITY:
      return updateSensitivity(state, action.portfolio, action.measurements, action.criteria, action.priorityVector, action.alternatives, action.answers, action.idToken);
    case actionTypes.PORTFOLIO_CALCULATE_VROI:
      return updateVROI(state, action.portfolio, action.alternatives, action.sensitivityVectors, action.idToken);
    case actionTypes.PORTFOLIO_CALCULATE_BUDGET_OPTIONS:
      return updateBudgetOptions(state, action.portfolio, action.alternatives, action.alternativeRelationships, action.sensitivityVectors, action.idToken); 
    // Tweak
    case actionTypes.PORTFOLIO_RECALCULATE_INIT:
      return updateObject(state, {loadingTweak: true, errorTweak: null});
    case actionTypes.PORTFOLIO_RECALCULATE_SUCCESS:
      return updateObject(state, {...action.updates, loadingTweak: false, errorTweak: null});
    case actionTypes.PORTFOLIO_RECALCULATE_FAIL:
      return updateObject(state, {loadingTweak: false, errorTweak: action.error});
    // Vote Facilicate     
    case actionTypes.PORTFOLIO_SET_SELECTED_OPTION:
      return updateObject(state, {selectedOption: action.selectedOption});
    case actionTypes.PORTFOLIO_SET_VOTE_CATEGORY:
      return updateObject(state, {voteCategory: action.voteCategory});
    // Mark Recalculation Request
    case actionTypes.PORTFOLIO_SET_RECALCULATION_REQUEST:
      return updateObject(state, {recalculationRequestTime: new Date()});
    default:
      return state;
  }
}

const updatePortfolio = (state, updatedPortfolio, isDelete) => {
  const newState = {loadingPost: false, errorPost: null};
  // Update state.portfolios if has  
  if (state.portfolios) {  
    const editedPortfolios = state.portfolios.slice();
    const portfolioIndex = editedPortfolios.findIndex((portfolio) => (portfolio.key === updatedPortfolio.key));
    if (portfolioIndex > -1) {      
      if (isDelete) {
        // Delete
        editedPortfolios.splice(portfolioIndex, 1);
      } else {
        // Update
        editedPortfolios[portfolioIndex] = {...editedPortfolios[portfolioIndex], ...updatedPortfolio};
      }
    } else {
      // Add
      editedPortfolios.push(updatedPortfolio)
    }
    newState.portfolios = editedPortfolios;
  }
  // Update current portfolio as well if match   
  if(state.currentPortfolio && state.currentPortfolio.key === updatedPortfolio.key) {
    newState.currentPortfolio = {...updatedPortfolio};
  }  
  return updateObject(state, newState);
}

const updateImportance = (state, criteria, answers, idToken) => {
  try {
    const {reciprocalMatrix, stdDevMatrix, priorityVector, flatPriorityVector, consistencyRatios} = calculateImportance(criteria, answers, false);
    return updateObject(state, {
      reciprocalMatrix: reciprocalMatrix, 
      priorityVector: priorityVector, 
      flatPriorityVector: flatPriorityVector, 
      stdDevMatrix: stdDevMatrix,
      consistencyRatios: consistencyRatios,
      loadingImportance: false, errorImportance: null
    });
  } catch (error) {
    logToSplunk("error", error, idToken);
    return updateObject(state, {loadingImportance: false, errorImportance: error});
  }
}

const updateSensitivity = (state, portfolio, measurements, criteria, priorityVector, alternatives, answers, idToken) => {
  try {
    const {alternativeVectors, sensitivityVectors, altSortedKeys, stdDeviations, stdDeviationsNormalised, rangeMatrix} = calculateSensitivity(measurements, criteria, priorityVector, alternatives, answers, portfolio.evaluationMethod, false);
    return updateObject(state, {
      alternativeVectors: alternativeVectors,
      sensitivityVectors: sensitivityVectors,
      altSortedKeys: altSortedKeys,
      sensitivityStdDeviations: stdDeviations,
      sensitivityStdDeviationNormalised: stdDeviationsNormalised,
      sensitivityRangeMatrix: rangeMatrix,
      loadingSensitivity: false, errorSensitivity: null
    });
  } catch (error) {
    logToSplunk("error", error, idToken);
    return updateObject(state, {loadingSensitivity: false, errorSensitivity: error});
  }
}

const updateVROI = (state, portfolio, alternatives, sensitivityVectors, idToken) => {
  try {
    const {alternativeBenefits, sumAlternativeCosts, alternativeCostNormalises, alternativeVROIs} = calculateVROI(portfolio, alternatives, sensitivityVectors, false);
    return updateObject(state, {
      alternativeBenefits: alternativeBenefits,
      sumAlternativeCosts: sumAlternativeCosts,
      alternativeCostNormalises: alternativeCostNormalises,
      alternativeVROIs: alternativeVROIs,
      loadingVROI: false, errorVROI: null
    });
  } catch (error) {
    logToSplunk("error", error, idToken);
    return updateObject(state, {loadingVROI: false, errorVROI: error});
  }
}
 
const updateBudgetOptions = (state, portfolio, alternatives, alternativeRelationships, sensitivityVectors, idToken) => {
  try {
    const {fundOptions} = calculateBudgetOptions(portfolio, alternatives, alternativeRelationships, [], sensitivityVectors, false);
    return updateObject(state, {
      fundOptions: fundOptions,
      loadingFundOptions: false, errorVROI: null
    });
  } catch (error) {
    logToSplunk("error", error, idToken);
    return updateObject(state, {loadingFundOptions: false, errorFundOptions: error});
  }
}