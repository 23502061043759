import * as actionTypes from './actionTypes';

//VOTER_PORTFOLIO_RETRIEVE////////////////////////////////////////
export const voterPortfolioRetrieve = (portfolioId, category, userId, isGuest) => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_RETRIEVE,
    portfolioId: portfolioId,
    category: category ? category : "Unknown",
    userId: userId,
    isGuest: isGuest
  }
} // => saga/index.js

export const voterPortfolioRetrieveInit = () => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_RETRIEVE_INIT
  }
} 

export const voterPortfolioRetrieveFail = (error) => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_RETRIEVE_FAIL,
    error: error
  }
} 

export const voterPortfolioRetrieveSuccess = (portfolio) => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_RETRIEVE_SUCCESS,
    portfolio: portfolio ? portfolio : null
  }
} 

//VOTER_PORTFOLIO_RETRIEVE_ALL////////////////////////////////////////
export const voterPortfolioRetrieveAll = (userKey) => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_RETRIEVE_ALL,
    userKey: userKey
  }
} // => saga/index.js

export const voterPortfolioRetrieveAllInit = () => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_RETRIEVE_ALL_INIT
  }
} 

export const voterPortfolioRetrieveAllFail = (error) => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_RETRIEVE_ALL_FAIL,
    error: error
  }
} 

export const voterPortfolioRetrieveAllSuccess = (entries) => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_RETRIEVE_ALL_SUCCESS,
    entries: entries
  }
} 

//VOTER_PORTFOLIO_RETRIEVE_BY_CODE////////////////////////////////////////
export const voterPortfolioRetrieveByCode = (accessCode) => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_RETRIEVE_BY_CODE,
    accessCode: accessCode
  }
} // => saga/index.js

export const voterPortfolioRetrieveByCodeInit = () => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_RETRIEVE_BY_CODE_INIT
  }
} 

export const voterPortfolioRetrieveByCodeFail = (error) => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_RETRIEVE_BY_CODE_FAIL,
    error: error
  }
} 

export const voterPortfolioRetrieveByCodeSuccess = (portfolio) => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_RETRIEVE_BY_CODE_SUCCESS,
    portfolio: portfolio ? portfolio : null
  }
} 
//VOTER_PORTFOLIO_ADD////////////////////////////////////////
export const voterPortfolioAdd = (code, userId, existings) => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_ADD,
    code: code,
    userId: userId,
    existings: existings
  }
} //=> saga/index.js

export const voterPortfolioAddInit = () => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_ADD_INIT
  }
} // => saga/index.js

export const voterPortfolioAddFail = (error) => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_ADD_FAIL,
    error: error
  }
} 

export const voterPortfolioAddSuccess = (portfolio) => {  
  return {
    type: actionTypes.VOTER_PORTFOLIO_ADD_SUCCESS,
    portfolio: portfolio ? portfolio : null
  }
} 