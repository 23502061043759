import React, { Component } from 'react';
import 'c3/c3.css';
import 'assets/css/customC3.css';
import 'assets/css/aggrid.css';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { createBrowserHistory } from "history";
import { auth } from 'shared/firebase';
//Local
import * as actions from 'store/actions/index';
import {userRoleTypes} from 'shared/constants';
import FirstTimeLoginPage from './Pages/Auth/FirstTimeLoginPage/FirstTimeLoginPage';
import Logout from './Pages/Auth/Logout/Logout';
// Async
import asyncComponent from "hoc/asyncComponent";
const AsyncAuthPages = asyncComponent(() => import("containers/Pages/AuthPages"));
const AsyncVoterPages = asyncComponent(() => import("containers/Pages/VoterPages"));
const AsyncStaffPages = asyncComponent(() => import("containers/Pages/StaffPages"));
const AsyncGuestPages = asyncComponent(() => import("containers/Pages/GuestPages"));
const hist = createBrowserHistory();

class App extends Component {
  
  constructor(props) {
    super(props);
    // Confirm the link is a sign-in with email link.
    let signInEmailLink = null;
    if (auth.isSignInWithEmailLink(window.location.href)) {   
      signInEmailLink =  window.location.href;
    }
    this.state = {
      signInEmailLink: signInEmailLink,
    }
  }
  componentWillMount() {
    // Confirm the link is a sign-in with email link.
    if (this.state.signInEmailLink) {      
      this.props.onAuthSetEmailLink(this.state.signInEmailLink);
    }     
    // Redirect Path
    else if(hist.location.pathname.startsWith("/") && hist.location.pathname !== "/index") {      
      this.props.onAuthSetRedirectPath(hist.location);
    }
  }

  componentDidMount() {
    // Only check state if it's not signInEmailLink
    if (!this.props.isAuthenticated && !this.state.signInEmailLink) {
      this.props.onAuthCheckState();
    }
  }

  componentDidUpdate() {
    if(this.props.isAuthenticated && this.props.currentUser && this.props.authRedirectPath) {      
      const {currentUser} = this.props;
      const role = currentUser.role;
      if ((currentUser.isAdmin || role === userRoleTypes.facilitator.value)  && this.props.authRedirectPath.pathname.startsWith("/admin")) {
        hist.push(this.props.authRedirectPath);
      } else if (role === userRoleTypes.voter.value && this.props.authRedirectPath.pathname.startsWith("/voter")) {
        hist.push(this.props.authRedirectPath);
      }
    }
  }
  
  render() {
    // Decide routes to show
    if (this.props.isAuthenticated && this.props.currentUser) {
      // Already Authenticated
      const {currentUser, guestVoterPortfolio} = this.props;
      const role = currentUser.role;
      if (currentUser.isAdmin || role === userRoleTypes.facilitator.value) {
        // ADMIN && FACILITATOR
        return (
          <Router history={hist}>
            <Switch>
              <Route path="/admin" component={AsyncStaffPages}/>
              <Route path="/logout" component={Logout}/>
              <Redirect path="/" to="/admin/dashboard"/>
            </Switch>
          </Router>
        );
      } else if (role === userRoleTypes.voter.value) {
        // REGISTERED VOTER
        return (
          <Router history={hist}>
            <Switch>
              <Route path="/voter" component={AsyncVoterPages}/>
              <Route path="/logout" component={Logout}/>
              <Redirect path="/" to="/voter/find"/>
            </Switch>
          </Router>
        );
      } else if (role === "guest" && guestVoterPortfolio) {
        // GUEST
        return (
          <Router history={hist}>
            <Switch>
              <Route path="/guest/portfolio/:id" component={AsyncGuestPages}/>
              <Route path="/logout" component={Logout}/>
              <Redirect path="/" to={`/guest/portfolio/${guestVoterPortfolio.key}?category=${guestVoterPortfolio.category}`}/>
            </Switch>
          </Router>
        );
      } else {
        // Should never happen
        return <div>These are not the droids you are looking for</div>
      }
    } else {
      // Required Authenticate
      if (this.props.signInEmailLink) {
        return (
          <Router history={hist}>
            <Switch>
              <Route path="/firstlogin" component={FirstTimeLoginPage}/>
              <Redirect path="/" to="/firstlogin"/>
            </Switch>
          </Router>
        );
      } else { 
        return (
          <Router history={hist}>
            <Switch>
              <Route path="/auth" component={AsyncAuthPages}/>
              <Redirect path="/" to="/auth/login"/>
            </Switch>
          </Router>
        );
      }
    }
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    isAuthenticated: state.auth.idToken !== null, 
    signInEmailLink: state.auth.authEmailLink,
    authRedirectPath: state.auth.authRedirectPath,
    currentUser: state.user.currentUser,
    guestVoterPortfolio: state.voterPortfolio.guestVoterPortfolio
  };
}
  
const mapDispatchToProps = (dispatch) => {
  return {
      onAuthCheckState: () => dispatch(actions.authCheckState()),
      onAuthSetEmailLink: (url) => dispatch(actions.authSetEmailLink(url)),
      onAuthSetRedirectPath: (path) => dispatch(actions.authSetRedirectPath(path)),
      onUserUpdateLastLoggedIn: (user) => dispatch(actions.userUpdateLastLoggedIn(user))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);