import {put} from 'redux-saga/effects';
import {getState, getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions';
import locale from "locale/index";

// VOTERS /////////////////////////////
export function* alternativeVoterRetrieveAll(action) {
  yield put(actions.alternativeVoterRetrieveAllInit());
  try{    
    const res = yield apis.get(`portfolios/${action.portfolioId}/voters?voteType=alternative`, yield getAuthHeader());    
    if (res.status === 200) {  
      const voters = res.data.response.slice();
      yield put(actions.alternativeVoterRetrieveAllSuccess(voters)); 
    } else {
      yield put(actions.alternativeVoterRetrieveAllFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeVoterRetrieveAllFail(error));
  };
}
// ANSWER /////////////////////////////
export function* alternativeVoterRetrieve(action) {  
  yield put(actions.alternativeVoterRetrieveInit());
  try{    
    const guestUrl = action.guestId ? "/guests/" + action.guestId : "";
    const res = yield apis.get(`${guestUrl}/voters/${action.voterId}`, yield getAuthHeader());    
    if (res.status === 200) {  
      const voters = res.data.response.slice();     
      yield put(actions.alternativeVoterRetrieveSuccess(voters)); 
    } else {
      yield put(actions.alternativeVoterRetrieveFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeVoterRetrieveFail(error));
  };
}

// UPDATE /////////////////////////////
export function* alternativeVotersUpdate(action) {
  yield put(actions.alternativeVotersPostInit());
  try{     
    const {currentVoters, entries} = action;        
    const newVoters = [], removeVoters = [];    
    // If not in curr, it's add
    entries.forEach(user => {
      if (!currentVoters.find(currVoter => currVoter.user === user.key)) {
        newVoters.push( {
          portfolio: action.portfolioId,
          user: user.key, voteType: 'alternative', allowRemote: false
        });
      }
    })    
    // if curr doesn't has it, it's delete
    currentVoters.forEach(currVoter => {
      const matchedEditEntry = entries.find(user => user.key === currVoter.user);
      if (!matchedEditEntry) {  
        removeVoters.push(currVoter);
      }
    })      

    // Call Add
    if (newVoters.length > 0) {
      const resAdd = yield apis.post(`/voters`, newVoters, yield getAuthHeader()); 
      if (resAdd.status !== 200) {
        yield put(actions.alternativeVotersPostFail(resAdd.data.error));
      }
    }
    // Call Delete
    if (removeVoters.length > 0) {
      const resRemove = yield apis.delete(`/voters`, {data: removeVoters, ...yield getAuthHeader()});  
      if (resRemove.status !== 200) {
        yield put(actions.alternativeVotersPostFail(resRemove.data.error));
      }
    }
    // Call Retrieve All since batch post does not return all insertIds
    yield put(actions.alternativeVoterRetrieveAll(action.portfolioId));    
    // Notification
    yield put(actions.notificationShow(locale.notification_alternative_voters_updated));
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeVotersPostFail(error));
  };
}  

// INVITE /////////////////////////////
export function* alternativeVoterSendInvite(action) {
  yield put(actions.alternativeVoterSendInviteInit());
  try {    
    const {currentVoters, isReminder} = action;
    const editedVoters = [];
    const body = [];
    const users = yield getState("user", "entries");
    const currentPortfolio = yield getState("portfolio", "currentPortfolio");
    // Get Voter's Submitted Answers
    currentVoters.forEach(voter => {     
      const matchUser = users.find(entry => entry.key === voter.user);
      // Request Body
      const bodyEntry = {
        key: voter.key,
        voteType: 'Alternative',
        voterEmail: matchUser.email,
        portfolioName: currentPortfolio.name,
      };
      body.push(bodyEntry);
      // Redux Stage Entry
      const edittedEntry = {...voter};
      if (isReminder) { 
        edittedEntry.remind = new Date();      
      } else {
        edittedEntry.invite = new Date();        
      }    
      editedVoters.push(edittedEntry);
    });
    // Update DB    
    const res = yield apis.put(`portfolios/${action.portfolioId}/emailInvite${isReminder ? "?isReminder" : ""}`, body, yield getAuthHeader()); 
    if (res.status === 200) {
      // Post success
      yield put(actions.alternativeVoterSendInviteSuccess(editedVoters));
       // Notification
      if (isReminder) {
        yield put(actions.notificationShow(locale.notification_alternative_voters_send_reminder));
      } else {
        yield put(actions.notificationShow(locale.notification_alternative_voters_send_invite));
      }
    } else {
      yield put(actions.alternativeVoterSendInviteFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeVoterSendInviteFail(error));
  };
}

// Revert Submit /////////////////////////////
export function* alternativeVoterSubmit(action) {
  yield put(actions.alternativeVoterSubmitInit());
  try{    
    const editedVoter = {key: action.voterId, submit: null};
    // Update DB    
    const body = {
      key: action.voterId,
      portfolio: action.portfolioId,
      voteType: 'alternative',
      submit: action.isRevert ? null : new Date()
    };
    const currentUser = yield getState("user", "currentUser");
    const guestUrl = currentUser.role === 'guest' ? "/guests/" + currentUser.key : "";
    const res = yield apis.put(`${guestUrl}/voters/${action.voterId}`, body, yield getAuthHeader()); 
    if (res.status === 200) {
      // Post success
      yield put(actions.alternativeVoterSubmitSuccess(editedVoter));
      // Recalculate
      if (action.isRevert) {
        yield put(actions.portfolioRecalculate({}, ['sensitivity', 'vroi', 'budget']));
        // Notification
        yield put(actions.notificationShow(locale.notification_alternative_voters_revert_submit));
      }
    } else {
      yield put(actions.alternativeVoterSubmitFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeVoterSubmitFail(error));
  };
}

// Allow Remote /////////////////////////////
export function* alternativeVoterAllowRemote(action) {
  yield put(actions.alternativeVoterAllowRemoteInit());
  try{    
    const {voter, user} = action; 
    // Update DB    
    const res = yield apis.put(`/voters/${voter.key}`, {allowRemote: voter.allowRemote}, yield getAuthHeader()); 
    if (res.status === 200) {
      // Post success
      yield put(actions.alternativeVoterAllowRemoteSuccess({...voter}));
      // Notification
      if (voter.allowRemote) {
        yield put(actions.notificationShow(locale.notification_alternative_voters_allow_remote, {email: user.email}));
      } else {
        yield put(actions.notificationShow(locale.notification_alternative_voters_allow_remote_stop, {email: user.email}));
      }
    } else {
      yield put(actions.alternativeVoterAllowRemoteFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeVoterAllowRemoteFail(error));
  };
}