// https://www.mycodesmells.com/post/add-ie11-support-to-your-react-apps
import 'babel-polyfill';
// https://www.npmjs.com/package/url-search-params-polyfill
import 'url-search-params-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {createStore, compose, applyMiddleware, combineReducers} from 'redux';
import {IntlProvider} from 'react-intl';
import {Provider, intlReducer} from 'react-intl-redux'
import createSagaMiddleware from 'redux-saga';
// Creative Time scss 
import "assets/scss/material-dashboard-pro-react.css?v=1.2.0";
// Saga
import * as actionTypes from './store/actions/actionTypes';
// import registerServiceWorker from './registerServiceWorker';
import {watchAuth, watchUserGroup, watchUser, watchNotification, watchPortfolio, watchCriteria, watchAlternative, watchAlternativeVoter
      , watchCriteriaVoter, watchAlternativeRelationship, watchAlternativeCost, watchScenario, watchMeasurement, watchBudget
      , watchVoterPortfolio, watchReport, watchAccount, watchAlternativeStart} from './store/sagas/index';
import authReducer from './store/reducers/auth';
import userGroupReducer from './store/reducers/userGroup';
import userReducer from './store/reducers/user';
import notificationReducer from './store/reducers/notification';
import portfolioReducer from './store/reducers/portfolio';
import measurementReducer from './store/reducers/measurement';
import budgetReducer from './store/reducers/budget';
import criteriaReducer from './store/reducers/criteria';
import criteriaVoterReducer from './store/reducers/criteriaVoter';
import alternativeReducer from './store/reducers/alternative';
import alternativeVoterReducer from './store/reducers/alternativeVoter';
import alternativeRelationshipReducer from './store/reducers/alternativeRelationship';
import alternativeCostReducer from './store/reducers/alternativeCost';
import alternativeStartReducer from './store/reducers/alternativeStart';
import scenarioReducer from './store/reducers/scenario';
import reportReducer from './store/reducers/report';
import accountReducer from './store/reducers/account';
import voterPortfolioReducer from './store/reducers/voterPortfolio';
// Local
import App from './containers/App';
import ErrorBoundary from 'hoc/ErrorBoundary';

// const composeEnhancers = process.env === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const appReducer = combineReducers({  
  auth: authReducer,
  userGroup: userGroupReducer,  
  user: userReducer,
  notification: notificationReducer,
  portfolio: portfolioReducer,
  measurement: measurementReducer,
  budget: budgetReducer,
  criteria: criteriaReducer,
  criteriaVoter: criteriaVoterReducer,
  alternative: alternativeReducer,
  alternativeVoter: alternativeVoterReducer,  
  alternativeRelationship: alternativeRelationshipReducer,
  alternativeCost: alternativeCostReducer,
  alternativeStart: alternativeStartReducer,
  scenario: scenarioReducer,
  report: reportReducer,
  account: accountReducer,
  voterPortfolio: voterPortfolioReducer,
  intl: intlReducer,
})
const rootReducer = (state, action) => {  
  //https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  if (action.type === actionTypes.AUTH_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action)
}
const sagaMiddleware = createSagaMiddleware();
export const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(sagaMiddleware)));

// Run/Initiate every time app start
sagaMiddleware.run(watchNotification);
sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchUserGroup);
sagaMiddleware.run(watchUser);
sagaMiddleware.run(watchPortfolio);
sagaMiddleware.run(watchMeasurement);
sagaMiddleware.run(watchBudget);
sagaMiddleware.run(watchCriteria);
sagaMiddleware.run(watchCriteriaVoter);
sagaMiddleware.run(watchAlternative);
sagaMiddleware.run(watchAlternativeVoter);
sagaMiddleware.run(watchAlternativeRelationship);
sagaMiddleware.run(watchAlternativeCost);
sagaMiddleware.run(watchAlternativeStart);
sagaMiddleware.run(watchScenario);
sagaMiddleware.run(watchReport);
sagaMiddleware.run(watchAccount);
sagaMiddleware.run(watchVoterPortfolio);

const app = (
  <Provider store={store} >  
    <ErrorBoundary fullPage>
      <IntlProvider locale="en">
        <BrowserRouter> 
          <App />
        </BrowserRouter>
      </IntlProvider>    
    </ErrorBoundary>
  </Provider>
)


ReactDOM.render(app, document.getElementById('root'));
// registerServiceWorker();
