import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../shared/utility';

const initialState = {  
  guestVoterPortfolio: null,
  currentPortfolio: null,
  entries: null,
  // Retrieve All
  loadingAll: false,
  errorAll: null,
  // Retrieve 
  loading: false,
  error: null,
  // Retrieve By Code
  loadingByCode: false,
  errorByCode: null,
  // Post
  loadingAdd: false,
  errorAdd: null,  
  // Post
  loadingUpdate: false,
  errorUpdate: null
}
export default (state = initialState, action) => {
  switch (action.type) {
    // Retrieve
    case actionTypes.VOTER_PORTFOLIO_RETRIEVE_INIT:
      return updateObject(state, {loading: true, error: null});
    case actionTypes.VOTER_PORTFOLIO_RETRIEVE_SUCCESS:
      return updateObject(state, {loading: false, currentPortfolio: action.portfolio});
    case actionTypes.VOTER_PORTFOLIO_RETRIEVE_FAIL:
      return updateObject(state, {loading: false, error: action.error});
    // Retrieve All
    case actionTypes.VOTER_PORTFOLIO_RETRIEVE_ALL_INIT:
      return updateObject(state, {loadingAll: true, errorAll: null});
    case actionTypes.VOTER_PORTFOLIO_RETRIEVE_ALL_SUCCESS:
      return updateObject(state, {loadingAll: false, entries: action.entries});
    case actionTypes.VOTER_PORTFOLIO_RETRIEVE_ALL_FAIL:
      return updateObject(state, {loadingAll: false, errorAll: action.error});
    // Retrieve By Code
    case actionTypes.VOTER_PORTFOLIO_RETRIEVE_BY_CODE_INIT:
      return updateObject(state, {loadingByCode: true, errorByCode: null, currentPortfolio: null, entries: null});
    case actionTypes.VOTER_PORTFOLIO_RETRIEVE_BY_CODE_SUCCESS:
      return updateObject(state, {loadingByCode: false, guestVoterPortfolio: action.portfolio, entries:[action.portfolio]});
    case actionTypes.VOTER_PORTFOLIO_RETRIEVE_BY_CODE_FAIL:
      return updateObject(state, {loadingByCode: false, errorByCode: action.error});
    // Add (with code)
    case actionTypes.VOTER_PORTFOLIO_ADD_INIT:
      return updateObject(state, {loadingAdd: true, errorAdd: null});      
    case actionTypes.VOTER_PORTFOLIO_ADD_SUCCESS:    
      return updatePortfolio(state, action.portfolio);
    case actionTypes.VOTER_PORTFOLIO_ADD_FAIL:
      return updateObject(state, {loadingAdd: false, errorAdd: action.error});    
      // Add (with code)
    case actionTypes.VOTER_PORTFOLIO_UPDATE_INIT:
      return updateObject(state, {loadingUpdate: true, errorUpdate: null});      
    case actionTypes.VOTER_PORTFOLIO_UPDATE_SUCCESS:    
      return updatePortfolio(state, action.portfolio);
    case actionTypes.VOTER_PORTFOLIO_UPDATE_FAIL:
      return updateObject(state, {loadingUpdate: false, errorUpdate: action.error});    
    default:
      return state;
  }
}

const updatePortfolio = (state, updatedPortfolio, isDelete) => {
  const newState = {loadingAdd: false, loadingUpdate: false};
  // Update state.entries if has  
  if (state.entries) {  
    const editedPortfolios = state.entries.slice();
    const portfolioIndex = editedPortfolios.findIndex((portfolio) => (portfolio.key === updatedPortfolio.key && portfolio.category === updatedPortfolio.category));
    if (portfolioIndex > -1) {      
      if (isDelete) {
        // Delete
        editedPortfolios.splice(portfolioIndex, 1);
      } else {
        // Update
        editedPortfolios[portfolioIndex] = {...updatedPortfolio};
      }
    } else {
      // Add
      editedPortfolios.push(updatedPortfolio)
    }
    newState.entries = editedPortfolios;
  }
  // Update current portfolio as well if match   
  if(state.currentPortfolio && state.currentPortfolio.key === updatedPortfolio.key) {
    newState.currentPortfolio = {...updatedPortfolio};
  }  
  return updateObject(state, newState);
}