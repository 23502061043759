import * as actionTypes from './actionTypes';

//SCENARIO_RETRIEVE_ALL////////////////////////////////////////
export const scenarioRetrieveAll = (portfolio) => {    
    return {
        type: actionTypes.SCENARIO_RETRIEVE_ALL,
        portfolioId: portfolio
    }
} // => saga/index.js

export const scenarioRetrieveAllInit = () => {    
    return {
        type: actionTypes.SCENARIO_RETRIEVE_ALL_INIT
    }
} 

export const scenarioRetrieveAllFail = (error) => {    
    return {
        type: actionTypes.SCENARIO_RETRIEVE_ALL_FAIL,
        error: error
    }
} 

export const scenarioRetrieveAllSuccess = (scenarios) => {    
    return {
        type: actionTypes.SCENARIO_RETRIEVE_ALL_SUCCESS,
        entries: scenarios
    }
} 
//SCENARIO_SELECT////////////////////////////////////////
export const scenarioSelect = (scenario) => {    
    return {
        type: actionTypes.SCENARIO_SELECT,
        selectedScenario: scenario
    }
}

//SCENARIO_POST////////////////////////////////////////
export const scenarioAdd = (portfolioId, entry) => {    
    return {
        type: actionTypes.SCENARIO_ADD,
        portfolioId: portfolioId,
        entry: entry
    }
} //=> saga/index.js

export const scenarioUpdate = (portfolioId, entry) => {    
    return {
        type: actionTypes.SCENARIO_UPDATE,
        portfolioId: portfolioId,
        entry: entry,
    }
} // => saga/index.js

export const scenarioDelete = (portfolioId, entry) => {    
    return {
        type: actionTypes.SCENARIO_DELETE,
        portfolioId: portfolioId,
        entry: entry,
    }
} // => saga/index.js

export const scenarioPostInit = () => {    
    return {
        type: actionTypes.SCENARIO_POST_INIT
    }
}

export const scenarioPostFail = (error) => {    
    return {
        type: actionTypes.SCENARIO_POST_FAIL,
        error: error
    }
} 

export const scenarioPostSuccess = (entry, isDelete) => {    
    return {
        type: actionTypes.SCENARIO_POST_SUCCESS,
        entry: entry,
        isDelete: isDelete ? isDelete : false
    }
}  