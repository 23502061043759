import {put} from 'redux-saga/effects';
import {getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions'
import locale from "locale/index";

export function* budgetRetrieveAll(action) {
  yield put(actions.budgetRetrieveAllInit());
  try{    
    const res = yield apis.get(`portfolios/${action.portfolioId}/budgets`, yield getAuthHeader());     
    if (res.status === 200) {  
      const budgets = {} 
      res.data.response.forEach(entry => {        
        budgets[entry.key] = {...entry};
      });      
      yield put(actions.budgetRetrieveAllSuccess(budgets)); 
    } else {
      yield put(actions.budgetRetrieveAllFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.budgetRetrieveAllFail(error));
  };
}  

export function* budgetAdd(action) {   
  yield put(actions.budgetPostInit());
  try {
    const entry = {...action.entry, portfolio: action.portfolioId}; 
    // Update DB 
    const res = yield apis.post(`/budgets`, entry, yield getAuthHeader());
    if (res.status === 200) {
      // Update key
      entry.key = Number(res.data.response.insertId);
      // Post success
      yield put(actions.budgetPostSuccess({...entry}));
      // Notification
      yield put(actions.notificationShow(locale.notification_budget_added, {name: entry.name}));
    } else {
      yield put(actions.budgetPostFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.budgetPostFail(error));
  };
}  

export function* budgetUpdate(action) {
  yield put(actions.budgetPostInit());
  try{    
    const {...entry} = action.entry;  
    // Update DB 
    const res = yield apis.put(`/budgets/${entry.key}`, entry, yield getAuthHeader());   
    if (res.status === 200) {   
      // Post success
      yield put(actions.budgetPostSuccess({...entry}));
      // Notification
      yield put(actions.notificationShow(locale.notification_budget_updated, {name: entry.name}));
    } else {
      yield put(actions.budgetPostFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.budgetPostFail(error));
  };
}  

export function* budgetDelete(action) {
  yield put(actions.budgetPostInit());
  try{    
    const {...entry} = action.entry;  
    // Update DB 
    const res = yield apis.delete(`/budgets/${entry.key}`, yield getAuthHeader());  
    if (res.status === 200) {     
      // Post success
      yield put(actions.budgetPostSuccess(entry, true));
      // Notification
      yield put(actions.notificationShow(locale.notification_budget_deleted, {name: entry.name}));
    } else {
      yield put(actions.budgetPostFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.budgetPostFail(error));
  };
}

export function* budgetSetPhasing(action) {
  const {key, period, value, oldValue} = action; 
  yield put(actions.budgetSetPhasingInit());
  try{
    // Post success first, roll back if error
    yield put(actions.budgetSetPhasingSuccess(key, period, value));
    // Update DB
    const res = yield apis.post(`/budgets/${key}/phasings/${period}`, {value: value}, yield getAuthHeader());
    if (res.status === 200) {
      // Recalculate - now success is guarantee
      yield put(actions.portfolioRecalculate({}, ['sensitivity', 'vroi', 'budget']));
    } else {
      yield put(actions.budgetPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.budgetSetPhasingFail(error, key, period, oldValue));
  };
} 
