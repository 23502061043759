import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../shared/utility';

const initialState = {
  // Retrieve All
  loadingAll: false,
  errorAll: null,
  entries: null,
  // Post
  loadingPost: false,
  errorPost: null,
  // Update Phasing
  loadingUpdatePhasing: false,
  errorUpdatePhasing: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    // Retrieve All
    case actionTypes.BUDGET_RETRIEVE_ALL_INIT:
      return updateObject(state, {entries: null, loadingAll: true, errorAll: null});
    case actionTypes.BUDGET_RETRIEVE_ALL_SUCCESS:
      return updateObject(state, {loadingAll: false, entries: action.entries});
    case actionTypes.BUDGET_RETRIEVE_ALL_FAIL:
      return updateObject(state, {loadingAll: false, errorAll: action.error});
    // Post
    case actionTypes.BUDGET_POST_INIT:
      return updateObject(state, {loadingPost: true, errorPost: null});      
    case actionTypes.BUDGET_POST_SUCCESS:     
      return updateEntries(state, action.entry, action.isDelete);
    case actionTypes.BUDGET_POST_FAIL:
      return updateObject(state, {loadingPost: false, errorPost: action.error});
    // Phasing
    case actionTypes.BUDGET_UPDATE_PHASING_INIT:
      return updateObject(state, {loadingUpdatePhasing: true, errorUpdatePhasing: null});      
    case actionTypes.BUDGET_UPDATE_PHASING_SUCCESS:     
      return updatePhasing(state, action.key, action.period, action.value);
    case actionTypes.BUDGET_UPDATE_PHASING_FAIL:    
      return updatePhasingError(state, action.error, action.key, action.period, action.oldValue);     
    default:
      return state;
  }
}

const updateEntries = (state, updatedEntry, isDelete) => {
  const newState = {loadingPost: false, errorPost: null};
  // Update state.entries if has  
  if (state.entries) {  
    const editedEntries = {...state.entries};
    if (editedEntries[updatedEntry.key]) {      
      if (isDelete) {
        // Delete
        delete editedEntries[updatedEntry.key];
      } else {
        // Update
        const currentEntry = editedEntries[updatedEntry.key];
        editedEntries[updatedEntry.key] = {...currentEntry, ...updatedEntry};
      }
    } else {
      // Add
      editedEntries[updatedEntry.key] = {...updatedEntry};
    }
    newState.entries = editedEntries;
  }   
  return updateObject(state, newState);
}

const updatePhasing = (state, key, mKey, value) => {
  const newState = {loadingUpdatePhasing: false, errorUpdatePhasing: null};
  // Update state.entries if has  
  if (state.entries) {   
    const editedEntries = {...state.entries}; 
    if (editedEntries[key]) {
      if (!editedEntries[key].phasings) editedEntries[key].phasings = {};
      editedEntries[key].phasings[mKey] = value;
    }
    newState.entries = editedEntries;
  }
  return updateObject(state, newState);
}

const updatePhasingError = (state, error, key, mKey, oldValue) => {
  const newState = {loadingUpdatePhasing: false, errorUpdatePhasing: error};
  // Update state.entries if has  
  if (state.entries) {   
    const editedEntries = {...state.entries}; 
    if (editedEntries[key]) {
      editedEntries[key].phasings[mKey] = oldValue;
      // TODO: Carry the error?
      // if (phasing) {
      //   phasing.error = error;
      // }    
    }
    newState.entries = editedEntries;
  }
  return updateObject(state, newState);
}