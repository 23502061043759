import {put} from 'redux-saga/effects';
// import {select} from 'redux-saga/effects';
import apis from 'shared/axiosApi';
// import {forEach} from 'lodash';
import {getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import * as actions from '../actions'
import locale from "locale/index";

export function* alternativeRetrieveAll(action) {
  yield put(actions.alternativeRetrieveAllInit());
  try{ 
    const guestUrl = action.guestId ? "/guests/" + action.guestId : "";
    const res = yield apis.get(`${guestUrl}/portfolios/${action.portfolioId}/alternatives`, yield getAuthHeader());
    if (res.status === 200) {  
      const alternatives = {} 
      res.data.response.forEach(entry => {        
        alternatives[entry.key] = {...entry};
      });      
      yield put(actions.alternativeRetrieveAllSuccess(alternatives)); 
    } else {
      yield put(actions.alternativeRetrieveAllFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.alternativeRetrieveAllFail(error));
  };
}  

export function* alternativeAdd(action) {  
  yield put(actions.alternativePostInit());
  try {
    const entry = {...action.entry, portfolio: action.portfolioId}; 
    // Update DB 
    const res = yield apis.post(`/alternatives`, entry, yield getAuthHeader());
    if (res.status === 200) {
      // Update key
      entry.key = Number(res.data.response.insertId);
      // Post success
      yield put(actions.alternativePostSuccess({...entry}));
      // Recalculate
      yield put(actions.portfolioRecalculate({}, ['sensitivity', 'vroi', 'budget']));
      // Notification
      yield put(actions.notificationShow(locale.notification_alternative_added, {name: entry.name}));
    } else {
      yield put(actions.alternativePostFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);  
    yield put(actions.alternativePostFail(error));
  };
}  

export function* alternativeUpdate(action) {
  yield put(actions.alternativePostInit());
  try{    
    const entry = {...action.entry}; 
    // Update DB 
    const res = yield apis.put(`/alternatives/${entry.key}`, entry, yield getAuthHeader()); 
    if (res.status === 200) {
      // Post success
      yield put(actions.alternativePostSuccess({...entry}));
      // Notification
      yield put(actions.notificationShow(locale.notification_alternative_updated, {name: entry.name}));
    } else {
      yield put(actions.alternativePostFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativePostFail(error));
  };
}  

export function* alternativeDelete(action) {
  yield put(actions.alternativePostInit());
  try{    
    const {...entry} = action.entry; 
    // Update DB
    const res = yield apis.delete(`/alternatives/${entry.key}`, yield getAuthHeader());  
    if (res.status === 200) {
      // Post success
      yield put(actions.alternativePostSuccess(entry, true));
      // Recalculate
      yield put(actions.portfolioRecalculate({}, ['sensitivity', 'vroi', 'budget']));
      // Notification
      yield put(actions.notificationShow(locale.notification_alternative_deleted, {name: entry.name}));
    } else {
      yield put(actions.alternativePostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);  
    yield put(actions.alternativePostFail(error));
  };
}

export function* alternativeSetMeasurement(action) {
  const {altKey, measurementKey, value, oldValue} = action; 
  yield put(actions.alternativeSetMeasurementInit());
  try{
    // Post success first, roll back if error
    yield put(actions.alternativeSetMeasurementSuccess(altKey, measurementKey, value));
    // Update DB
    const res = yield apis.post(`/alternatives/${altKey}/measurements/${measurementKey}`, {value: value}, yield getAuthHeader());
    if (res.status === 200) {
      // Recalculate - now success is guarantee
      yield put(actions.portfolioRecalculate({}, ['sensitivity', 'vroi', 'budget']));
    } else {
      yield put(actions.alternativePostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeSetMeasurementFail(error, altKey, measurementKey, oldValue));
  };
}  

export function* alternativeImport(action) {

}