import moment from 'moment';
import apis from 'shared/axiosApi';
import { isString } from 'lodash';
import { capitalFormatter } from './formatter';
import { forEach } from 'lodash';
import { formatMessage } from 'locale/index';
import { select } from 'redux-saga/effects';
export const updateObject = (oldObject, updatedValues) => {
  return {
    ...oldObject,
    ...updatedValues
  }
};

export function createAuthHeader(idToken) {
  return {headers: {Authorization: `Bearer ${idToken}`}}
}

export function* getAuthHeader() {
  return createAuthHeader(yield getIdToken());
}

export function* getIdToken() {
  return yield getState("auth", "idToken");
}

export function* sagaLogToSplunk(severity, data) {
  logToSplunk(severity, data, yield getState("auth", "idToken"));
}

export const logToSplunk = (severity, data, idToken) => {  
  //https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
  let body = {};
  if (data instanceof Error) {
    Object.getOwnPropertyNames(data).forEach(key => body[key] = data[key]);
  } else {
    body = data;
  }
  // Send to Api to log
  apis.post(`logs/${severity}`, body, createAuthHeader(idToken))
    .then(res => {
      console.log("Log Successed");
    })
    .catch((err) => {
      console.log("Log Failed", severity, body, err);
    });
}

export function* getState(store, value){
  const results = yield select(state => {
    if (state[store]) return state[store][value];
    else return null;
  }); 
  return results;
}

export const fromNow = (time) => {
  if (time) {
  let momentTime = null;
  // Convert time in case is Firebase time
  if (time.seconds) momentTime = moment.unix(time.seconds);
  else momentTime = moment.utc(time).local();
    // Check if it's more than 7 days
    if (moment().diff(momentTime, 'days') > 7) {
      return momentTime.format('D/M/YY');
    } else {
      return momentTime.fromNow();
    }
  } 
  return null;
}

export const isNewName = (name, existings) => {
  let isNew = true;
  existings.forEach(existing => {
    if (generateId(existing.name) === generateId(name)) {
      isNew = false;
    }
  });
  return isNew;
}

export const generateId = (name) => {
  let id = name.toLowerCase();
  // remove all invalid character
  id = id.replace(/[^\w\s]/gi, '')
  // space to '-'
  id = id.replace(/ /g , "-").toLowerCase()
  return id;
}

export const round = (val, decimalPoint) => {
  val = isNaN(val) ? 0 : val;
  if (decimalPoint) {
    let powerOfTen = Math.pow(10, decimalPoint);
    return Math.round(val * powerOfTen) / powerOfTen
  } else {
    return Math.round(val * 100) / 100
  }
}

export const getFirstDefine = (...array) => {
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (element) return element;  
  }
  return null;
}

export const getValueWithUnit = (value, preU, unit, shorten) => {
  const u = unit && shorten ? unit.charAt(0) : unit;
  let returnStr = ''
  if (value >= 0) {  
    returnStr = preU ? `${preU}${value} ${u}` : `${value} ${u}`;
  } else {
    returnStr = preU ? `-${preU}${Math.abs(value)} ${u}` : `${value} ${u}`;
  }
  return returnStr.trim();
}

export const getElementHeightByRef = (ref, defaultHeight) => {
  if (ref && ref.current) {
    return ref.current.scrollHeight
  }
  return defaultHeight;
}

export const getPortfolioPhasingPeriods = (portfolio) => {
  const {createTime, frequency, phasingOffset, phasingCount} = portfolio;
  return getPhasingPeriods(createTime, frequency, phasingOffset, phasingCount)
}

export const getPhasingPeriods = (createTime, frequency, phasingOffset, phasingCount) => {  
  const periods = [];
  for (let i = 0; i <= phasingCount; i++) {
    periods.push({
      key: i,
      name: getPhasingPeriod(createTime, frequency, i + (phasingOffset ? phasingOffset : 0))
    })      
  }
  return periods;
}

export const getPhasingPeriod = (createTime, frequency, offset) => {       
  let m = moment(createTime);
  switch (frequency) {
    case 'annual':     
      m.add(offset, 'year');
      return m.format('YYYY');
    case 'monthly':  
      m.add(offset, 'month');
      return m.format('MMM YYYY');
    case 'quarter':     
      m.add(offset * 3, 'month');      
      return getQuarter(m.format('M'), m.format('YY'));
    default:
      return offset;
  }
}

const getQuarter = (month, year) => {  
  // For the AU Government fiscal year  
  // Jul-Sep = 1
  // Oct-Dec = 2
  // Jan-Mar = 3
  // Apr-Jun = 4
  var m = Math.floor(month / 3) + 2;
  m = m > 4 ? m - 4 : m
  return m <= 2 ? `Q${m} 20${year}-${parseInt(year)+1}` : `Q${m} 20${parseInt(year)-1}-${year}`;
}

const a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen ']
const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']
const regex = /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/

//https://stackoverflow.com/questions/14766951/convert-digits-into-words-with-javascript
export const getNumberInWords = (num) => {
  if ((num = num.toString()).length > 9) {
    throw new Error('overflow') // Does not support converting more than 9 digits yet
  }

  const n = ('000000000' + num).substr(-9).match(regex)
  if (!n) return

  let str = ''
  str += (Number(n[1]) !== 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : ''
  str += (Number(n[2]) !== 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : ''
  str += (Number(n[3]) !== 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : ''
  str += (Number(n[4]) !== 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : ''
  str += (Number(n[5]) !== 0) ? ((str !== '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : ''

  return capitalFormatter(str.trim());
}

export const sliceObj = (obj, fromIdx, toIdx, getSortValue) => {
  // To array
  const array = [];
  forEach(obj, (value, key) => { 
    array.push({key: key, value: value});
  });
  // Sort, default desc unless  other usecase is need
  if (getSortValue) {
    array.sort((a, b) => getSortValue(b.value) - getSortValue(a.value));
  }
  // Slice
  const sliceArray = array.slice(fromIdx, toIdx);  
  // To Obj
  const returnObj = {};
  sliceArray.forEach(entry => {
    returnObj[entry.key] = entry.value;
  })
  return returnObj;
}
/////////////////////////ERROR HANDLING///////////////////////////////////////////////////////////////////
const UNEXPECT_ERR_MSG = "Unexpected Error. Please contact your web webmaster with the following= ";
export const getErrorDetails = (intl, error) => {
  let errorDetails = "";
  if (error) {
    if (error.response && error.response.data) {      
      if (error.response.data.error) {
        switch (error.response.data.error.message) {
          case "EMAIL_NOT_FOUND":
          case "INVALID_PASSWORD":
            errorDetails = "Your email or password is incorrect ";
            break;
          case "USER_DISABLED":
            errorDetails = "Your account has been disable. Please contact your webmaster ";
            break;
          default:
            errorDetails = UNEXPECT_ERR_MSG +  JSON.stringify(error.response.data.error);
            break;
        }
      } else {
        errorDetails = error.response.data.message;
      }
    } else if(error.message) {
      if (isString(error.message)) {
        errorDetails = error.message;
      } else {
        errorDetails = formatMessage(intl, error.message, error.values);
      }
    } else {
      errorDetails = UNEXPECT_ERR_MSG + JSON.stringify(error);
    }
  }
  return errorDetails;
}

/////////////////////////FORM_VALIDATION///////////////////////////////////////////////////////////////////
// function that returns true if value is email, false otherwise
const verifyEmail = (value) => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}
// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
}
// function that verifies if two strings are equal
const compare = (string1, string2) => {
  if (string1 === string2) {
    return true;
  }
  return false;
}
// function that verifies if value contains only numbers
const verifyNumber = (value) => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
}
// function that verifies if value contains only numbers
const verifyDecimal = (value) => {
  var numberRex = new RegExp("^-?[0-9]+(.[0-9]{1,2})?$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
}
// verifies if value is a valid URL
const verifyUrl = (value) => {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
}

const verifyNameId = (value) => {
  return true; 
}

export const changeValidate = (event, stateName, type, compareValue, maxValue) => {
  const state = {};
  const value = event.target.value ? event.target.value : '';
  //Validate
  switch (type) {
    case "custom":       
      if (compareValue(value, maxValue)) {
        state[stateName + "State"] = "success" ;
      } else {
        state[stateName + "State"] = "error" ;
      }
      break;
    case "nameId":       
      if (verifyNameId(value)) {
        state[stateName + "State"] = "success" ;
      } else {
        state[stateName + "State"] = "error" ;
      }
      break;
    case "date":
      //Validate date?
      break;
    case "email":
      if (verifyEmail(value)) {
        state[stateName + "State"] = "success" ;
      } else {
        state[stateName + "State"] = "error" ;
      }
      break;
    case "password":
      if (verifyLength(value, 8)) {
        state[stateName + "State"] = "success";
      } else {
        state[stateName + "State"] = "error" ;
      }
      break;
    case "equalTo":
      if (compare(value, compareValue)) {
        state[stateName + "State"] = "success";
      } else {
        state[stateName + "State"] = "error" ;
      }
      break;
    case "checkbox":
      if (event.target.checked) {
        state[stateName + "State"] = "" ;
      } else {
        state[stateName + "State"] = "error";
      }
      break;
    case "decimal":
      if (verifyDecimal(value)) {
        state[stateName + "State"] = "success";
      } else {
        state[stateName + "State"] = "error" ;
      }
      break;
    case "number":
      if (verifyNumber(value)) {
        state[stateName + "State"] = "success";
      } else {
        state[stateName + "State"] = "error" ;
      }
      break;
    case "length":
      if (verifyLength(value, compareValue)) {
        state[stateName + "State"] = "success";
      } else {
        state[stateName + "State"] = "error" ;
      }
      break;
    case "max-length":
      if (!verifyLength(value, compareValue + 1)) {
        state[stateName + "State"] = "success";
      } else {
        state[stateName + "State"] = "error" ;
      }
      break;
    case "url":
      if (verifyUrl(value)) {
        state[stateName + "State"] = "success";
      } else {
        state[stateName + "State"] = "error" ;
      }
      break;
    case "min-value":
      if (verifyNumber(value) &&
        value >= compareValue
      ) {
        state[stateName + "State"] = "success" ;
      } else {
        state[stateName + "State"] = "error";
      }
      break;
    case "max-value":
      if (verifyNumber(value) &&
        value <= compareValue
      ) {
        state[stateName + "State"] = "success" ;
      } else {
        state[stateName + "State"] = "error" ;
      }
      break;
    case "range":
      if (verifyDecimal(value) &&
        value >= compareValue &&
        value <= maxValue
      ) {
        state[stateName + "State"] = "success";
      } else {
        state[stateName + "State"] = "error";
      }
      break;
    default:
      state[stateName + "State"] = "success";
      break;
  }
  //Set value
  switch (type) { 
    case "date":
      state[stateName] = event.toDate();
      break;
    case "checkbox":
      state[stateName] = event.target.checked ;
      break;
    case "email":
      state[stateName] = value.toLowerCase();
      break;
    default:
      state[stateName] = value ;
      break;
  }
  return state;
}