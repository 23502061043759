import * as actionTypes from './actionTypes';

//ALTERNATIVE_COST_RETRIEVE_ALL////////////////////////////////////////
export const alternativeCostRetrieveAll = (portfolio) => {    
    return {
        type: actionTypes.ALTERNATIVE_COST_RETRIEVE_ALL,
        portfolioId: portfolio
    }
} // => saga/index.js

export const alternativeCostRetrieveAllInit = () => {    
    return {
        type: actionTypes.ALTERNATIVE_COST_RETRIEVE_ALL_INIT
    }
} 

export const alternativeCostRetrieveAllFail = (error) => {    
    return {
        type: actionTypes.ALTERNATIVE_COST_RETRIEVE_ALL_FAIL,
        error: error
    }
} 

export const alternativeCostRetrieveAllSuccess = (entries) => {    
    return {
        type: actionTypes.ALTERNATIVE_COST_RETRIEVE_ALL_SUCCESS,
        entries: entries
    }
} 

//ALTERNATIVE_COST_POST////////////////////////////////////////
export const alternativeCostAdd = (portfolioId, entry) => {    
    return {
        type: actionTypes.ALTERNATIVE_COST_ADD,
        portfolioId: portfolioId,
        entry: entry
    }
} //=> saga/index.js

export const alternativeCostUpdate = (portfolioId, entry) => {    
    return {
        type: actionTypes.ALTERNATIVE_COST_UPDATE,
        portfolioId: portfolioId,
        entry: entry,
    }
} // => saga/index.js

export const alternativeCostDelete = (portfolioId, entry) => {    
    return {
        type: actionTypes.ALTERNATIVE_COST_DELETE,
        portfolioId: portfolioId,
        entry: entry,
    }
} // => saga/index.js

export const alternativeCostPostInit = () => {    
    return {
        type: actionTypes.ALTERNATIVE_COST_POST_INIT
    }
}

export const alternativeCostPostFail = (error) => {    
    return {
        type: actionTypes.ALTERNATIVE_COST_POST_FAIL,
        error: error
    }
} 

export const alternativeCostPostSuccess = (entry, isDelete) => {    
    return {
        type: actionTypes.ALTERNATIVE_COST_POST_SUCCESS,
        entry: entry,
        isDelete: isDelete ? isDelete : false
    }
}  


//ALTERNATIVE_COST_PHASING_UPDATE///////////////////////////////
export const alternativeCostSetPhasing = (key, altKey, period, value, oldValue) => {  
  return {
    type: actionTypes.ALTERNATIVE_COST_UPDATE_PHASING,    
    key: key,   
    altKey: altKey,
    period: period,
    value: value,
    oldValue: oldValue,
  }
} // => saga/index.js

export const alternativeCostSetPhasingInit = () => {  
  return {
    type: actionTypes.ALTERNATIVE_COST_UPDATE_PHASING_INIT
  }
}

export const alternativeCostSetPhasingSuccess = (key, period, value) => {  
  return {
    type: actionTypes.ALTERNATIVE_COST_UPDATE_PHASING_SUCCESS,    
    key: key,
    period: period,
    value: value
  }
}  

export const alternativeCostSetPhasingFail = (error, key, period, oldValue) => {  
  return {
    type: actionTypes.ALTERNATIVE_COST_UPDATE_PHASING_FAIL,
    error: error,
    key: key,
    period: period,
    oldValue: oldValue
  }
}  