import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../shared/utility';

const initialState = {
  // Retrieve
  loading: false,
  error: null,
  currentReport: null,
  // Retrieve All
  loadingAll: false,
  errorAll: null,
  entries: null,
  // Post
  loadingPost: false,
  errorPost: null,
}
export default (state = initialState, action) => {
  switch (action.type) { 
    // Retrieve
    case actionTypes.REPORT_RETRIEVE_INIT:
      return updateObject(state, {...initialState, loading: true });
    case actionTypes.REPORT_RETRIEVE_SUCCESS:
      return updateObject(state, {loading: false, currentReport: action.entry});
    case actionTypes.REPORT_RETRIEVE_FAIL:
      return updateObject(state, {loading: false, error: action.error});
    // Retrieve Fields
    case actionTypes.REPORT_RETRIEVE_FIELD_INIT:
      return updateObject(state, {...initialState, loading: true });
    case actionTypes.REPORT_RETRIEVE_FIELD_SUCCESS:
      return updateObject(state, {loading: false, currentReport: {...state.currentReport, ...action.fields}});
    case actionTypes.REPORT_RETRIEVE_FIELD_FAIL:
      return updateObject(state, {loading: false, error: action.error});
    // Retrieve All
    case actionTypes.REPORT_RETRIEVE_ALL_INIT:
      return updateObject(state, {entries: null, loadingAll: true, errorAll: null});
    case actionTypes.REPORT_RETRIEVE_ALL_SUCCESS:
      return updateObject(state, {loadingAll: false, entries: action.entries});
    case actionTypes.REPORT_RETRIEVE_ALL_FAIL:
      return updateObject(state, {loadingAll: false, errorAll: action.error});
    // Post
    case actionTypes.REPORT_POST_INIT:
      return updateObject(state, {loadingPost: true, errorPost: null});      
    case actionTypes.REPORT_POST_SUCCESS:     
      return updateEntries(state, action.entry, action.isDelete);
    case actionTypes.REPORT_POST_FAIL:
      return updateObject(state, {loadingPost: false, errorPost: action.error});
    default:
      return state;
  }
}

const updateEntries = (state, updatedEntry, isDelete) => {
  const newState = {loadingPost: false, errorPost: null};
  // Update state.entries if has  
  if (state.entries) {  
    const editedEntries = {...state.entries};
    if (editedEntries[updatedEntry.key]) {      
      if (isDelete) {
        // Delete
        delete editedEntries[updatedEntry.key];
      } else {
        // Update
        const currentEntry = editedEntries[updatedEntry.key];
        editedEntries[updatedEntry.key] = {...currentEntry, ...updatedEntry};
      }
    } else {
      // Add
      editedEntries[updatedEntry.key] = {...updatedEntry};
    }
    newState.entries = editedEntries;
  }
  // Update current entry as well if match   
  if(state.currentReport && state.currentReport.key === updatedEntry.key) {
    newState.currentReport = {...updatedEntry};
  }  
  return updateObject(state, newState);
}