import React from 'react'
import { filter, mapValues } from 'lodash';
import {FormattedMessage} from 'react-intl';
import auth from './localeAuth';
import common from './localeCommon';
import message from './localeMessage';
import pageDashboard from './localePageDashboard';
import pagePortfolio from './localePagePortfolio';
import pageVoter from './localePageVoter';
import vote from './localeVote';
const locale = {
  ...auth,
  ...common,
  ...message,
  ...pageDashboard,
  ...pagePortfolio,
  ...pageVoter,
  ...vote,
	app_url: "https://prioritise-development.firebaseapp.com",
  api_url: "https://api.gearedserver.com",
  app_name: <FormattedMessage id="app_name" defaultMessage="Prioritise - Development" />,
  app_name_short: <FormattedMessage id="app_name_short" defaultMessage="Prioritise" />,
  app_version: <FormattedMessage id="app_version" defaultMessage="Version 0.7.8" />
}

export default locale;

//https://github.com/yahoo/react-intl/issues/1163
const PARAM_REGEX = /{[a-z_-]+}/i;
const PARAM_G_REGEX = /{[a-z_-]+}/gi;
const STRIP_CURLIES_REGEX = /^{|}$/g;

export const formatMessage = (intl, msg, values) => {
  return intl.formatMessage(msg.props, values)
}

export const fancyFormat = (intl, msg, values = {}) => {
	const hasFormattedParams =
		filter(values, (value) => typeof value !== 'string').length > 0;

	if (!hasFormattedParams) {
		return intl.formatMessage(msg.props, values)
	}

	const translatedMsg = intl.formatMessage( 
		msg.props,
		// Passing dummy values as second arg to suppress console error warnings,
		// these are used to be manually replaced with react elements below.
		mapValues(values, (v, k) => `{${k}}`)
	);
	// Converting the above dummy values to `values` content
	const matches = translatedMsg
		.match(PARAM_G_REGEX)
		.map((x) => values[x.replace(STRIP_CURLIES_REGEX, '')]);
	const array = translatedMsg.split(PARAM_REGEX);

	return array.reduce((result, part, index) => {
		if (index === 0 || index === array.length) {
			return [...result, part];
		}

		// Splice in the parameters into the result array as Fragments
		return [
			...result,
			// eslint-disable-next-line react/no-array-index-key
			<React.Fragment key={`frag_${index}`}>{matches[index - 1]}</React.Fragment>,
			part,
		];
	}, []);
};


