import {put} from 'redux-saga/effects';
import {getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions';
import locale from "locale/index";

export function* scenarioRetrieveAll(action) {
  yield put(actions.scenarioRetrieveAllInit());
  try{    
    const res = yield apis.get(`portfolios/${action.portfolioId}/scenarios`, yield getAuthHeader());
    if (res.status === 200) {  
      const scenarios = {};
      res.data.response.forEach(entry => {        
        scenarios[entry.key] = {...entry};
      })
      yield put(actions.scenarioRetrieveAllSuccess(scenarios)); 
    } else {
      yield put(actions.scenarioRetrieveAllFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.scenarioRetrieveAllFail(error));
  };
}  

export function* scenarioAdd(action) {  
  yield put(actions.scenarioPostInit());
  try {
    const entry = {...action.entry, portfolio: action.portfolioId};
    // Update DB 
    const res = yield apis.post(`/scenarios`, entry, yield getAuthHeader()); 
    if (res.status === 200) {
      // Post success
      yield put(actions.scenarioPostSuccess({...entry}));
      // Notification
      yield put(actions.notificationShow(locale.notification_scenario_added, {name: entry.name}));
    } else {
      yield put(actions.scenarioPostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.scenarioPostFail(error));
  };
}  

export function* scenarioUpdate(action) {
  yield put(actions.scenarioPostInit());
  try{    
    const {...entry} = action.entry; 
    // Update DB 
    const res = yield apis.put(`/scenarios/${entry.key}`, entry, yield getAuthHeader());   
    if (res.status === 200) {
      // Post success
      yield put(actions.scenarioPostSuccess({...entry}));
      // Notification
      yield put(actions.notificationShow(locale.notification_scenario_updated, {name: entry.name}));
    } else {
      yield put(actions.scenarioPostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.scenarioPostFail(error));
  };
}  

export function* scenarioDelete(action) {
  yield put(actions.scenarioPostInit());
  try{    
    const {...entry} = action.entry; 
    // Update DB 
    const res = yield apis.delete(`/scenarios/${entry.key}`, yield getAuthHeader());   
    if (res.status === 200) {   
      // Post success
      yield put(actions.scenarioPostSuccess(entry, true));
      // Notification
      yield put(actions.notificationShow(locale.notification_scenario_delete, {name: entry.name}));
    } else {
      yield put(actions.scenarioPostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.scenarioPostFail(error));
  };
}
