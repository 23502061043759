import React from 'react'
import {FormattedMessage, defineMessages} from 'react-intl';
const vote = {
  //Page Vote
  vote_code_enter: <FormattedMessage id="vote_code_enter" defaultMessage="Please enter a Portfolio Voting Code" />,
  vote_add: <FormattedMessage id="vote_add" defaultMessage="Add Portfolio" />,
  vote_btn_next: <FormattedMessage id="vote_btn_next" defaultMessage="Next Question" />,
  vote_btn_submit: <FormattedMessage id="vote_btn_submit" defaultMessage="Submit" />,
  vote_btn_submit_vote: <FormattedMessage id="vote_btn_submit_vote" defaultMessage="Submit Answer" />,
  vote_btn_update: <FormattedMessage id="vote_btn_update" defaultMessage="Submit Updates" />,
  vote_please_wait: <FormattedMessage id="vote_please_wait" defaultMessage="Please wait for a vote facilitator" />,
  vote_live_submit_facilitator: <FormattedMessage id="vote_live_submit_facilitator" defaultMessage="Please check every answers have been completed before allow voters to submit" />,
  vote_live_submit_text: defineMessages({props: {id: "vote_live_submit_text", defaultMessage: "Thank you for complete {name} vote. Please hit Submit button below to confirm your answers."}}),    
  vote_comment: defineMessages({props: {id: "vote_comment", defaultMessage: "Additional Comment"}}),
  vote_question_or: <FormattedMessage id="vote_question_or" defaultMessage="or" />,
  vote_question_for: <FormattedMessage id="vote_question_for" defaultMessage="for" />,
  vote_question_of_the: <FormattedMessage id="vote_question_of_the" defaultMessage="of the" />,
  vote_question_in_relation_to: <FormattedMessage id="vote_question_in_relation_to" defaultMessage="in relation to" />,
  vote_question_criteria_title: <FormattedMessage id="vote_question_criteria_title" defaultMessage="Criteria" />,
  vote_question_alternative_title: <FormattedMessage id="vote_question_alternative_title" defaultMessage="Alternative" />,
  vote_question_criteria_important: <FormattedMessage id="vote_question_criteria_important" defaultMessage="What is more important?" />,
  vote_question_criteria_important_context: <FormattedMessage id="vote_question_criteria_important_context" defaultMessage="What is more important in the context of" />,
  vote_question_alternative_continuous: <FormattedMessage id="vote_question_alternative_continuous" defaultMessage="What is the" />,
  vote_question_alternative_discrete: <FormattedMessage id="vote_question_alternative_discrete" defaultMessage="What do you think about the" />,
  vote_question_unanswer_warning: <FormattedMessage id="vote_question_unanswer_warning" defaultMessage="Please answer this question" />,
  vote_question_unanswered: <FormattedMessage id="vote_question_unanswered" defaultMessage="There are unanswered questions please answer them all before submit" />,  
  vote_question_answered_date: defineMessages({props: {id: "vote_question_answered_date", defaultMessage: "Answered {fromNow}"}}),
  vote_submitted_date: defineMessages({props: {id: "vote_submitted_date", defaultMessage: "Submitted {fromNow}"}}),
  vote_submitted_text: defineMessages({props: {id: "vote_submitted_text", defaultMessage: "You have completed voting on {name}. You can continue to review your answers here. If changes need to be made please contact your facilitator."}}),  
}
export default vote;


