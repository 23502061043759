import {put} from 'redux-saga/effects';
import {getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions';
import locale from "locale/index";

export function* accountRetrieve(action) {
  // Init
  yield put(actions.userClear())
  yield put(actions.accountRetrieveInit());
  try{
    const res = yield apis.get("/accounts/" + action.accountId, yield getAuthHeader());     
    if (res.status === 200) {
      if (res.data.response.length > 0) {
        const account = res.data.response[0];   
        yield put(actions.accountRetrieveSuccess(account));    
      } else {
        // Not found        
        yield put(actions.accountRetrieveFail({message: locale.error_account_cannot_find_account, values: {id: action.accountId}}));
      }
    } else {
      yield put(actions.accountRetrieveFail(res.data.error));
    }  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.accountRetrieveFail(error));
  };
}  

export function* accountRetrieveAll(action) {
  yield put(actions.accountRetrieveAllInit());
  try{        
    const accounts = {};
    const res = yield apis.get("/accounts", yield getAuthHeader());
    if (res.status === 200) {
      res.data.response.forEach(entry => {
        accounts[entry.key] = {...entry};
      })
      yield put(actions.accountRetrieveAllSuccess(accounts)); 
    } else {
      yield put(actions.accountRetrieveAllFail(res.data.error)); 
    }   
    // Set Id
    if (action.selectId && accounts[action.selectId]) {
      yield put(actions.accountSelect(accounts[action.selectId])); 
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.accountRetrieveAllFail(error));
  };
}  

export function* accountAdd(action) {  
  yield put(actions.accountPostInit());
  try {
    const account = {...action.entry};    
    // Update DB
    const res = yield apis.post("/accounts", account, yield getAuthHeader());
    if (res.status === 200) {
      // Update key
      account.key = Number(res.data.response.insertId);
      // Post success
      yield put(actions.accountPostSuccess({...account}));
      // Notification
      yield put(actions.notificationShow(locale.notification_account_added, {name: account.name}));
    } else {
      yield put(actions.accountPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.accountPostFail(error));
  };
}  

export function* accountUpdate(action) {
  yield put(actions.accountPostInit());
  try{    
    const {...account} = action.entry; 
    // Update DB
    const res = yield apis.put(`/accounts/${account.key}`, account, yield getAuthHeader());    
    if (res.status === 200) {
      // Post success
      yield put(actions.accountPostSuccess(account));
      // Notification
      yield put(actions.notificationShow(locale.notification_account_updated, {name: account.name}));
    } else {
      yield put(actions.accountPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.accountPostFail(error));
  };
}  

export function* accountDelete(action) {
  yield put(actions.accountPostInit());
  try{    
    const {...account} = action.entry; 
    // Update DB
    const res = yield apis.delete(`/accounts/${account.key}`, yield getAuthHeader());    
    if (res.status === 200) {
      // Post success
      yield put(actions.accountPostSuccess(account, true));
      // Notification
      yield put(actions.notificationShow(locale.notification_account_account, {name: account.name}));
    } else {
      yield put(actions.accountPostFail(res.data.error));
    } 
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);
    yield put(actions.accountPostFail(error));
  };
}
