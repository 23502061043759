import {put} from 'redux-saga/effects';
import {getState, getAuthHeader, sagaLogToSplunk} from 'shared/utility';
import apis from 'shared/axiosApi';
import * as actions from '../actions';
import locale from "locale/index";
import {splitCostByPeriod, splitBudgetByPeriod} from "shared/calculationUtility";
import {getPortfolioPhasingPeriods} from 'shared/utility';

export function* alternativeStartSaveRetrieveAll(action) {
  yield put(actions.alternativeStartSaveRetrieveAllInit());
  try{    
    const res = yield apis.get(`portfolios/${action.portfolioId}/alternativeStartSaves`, yield getAuthHeader());
    if (res.status === 200) {  
      const alternativeStartSaves = {};
      res.data.response.forEach(entry => {        
        alternativeStartSaves[entry.key] = {...entry};
      })
      yield put(actions.alternativeStartSaveRetrieveAllSuccess(alternativeStartSaves)); 
    } else {
      yield put(actions.alternativeStartSaveRetrieveAllFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeStartSaveRetrieveAllFail(error));
  };
}  

export function* alternativeStartSaveAdd(action) {  
  yield put(actions.alternativeStartSavePostInit());
  try {
    const entry = {...action.entry, portfolio: action.portfolioId};
    // Update DB 
    const res = yield apis.post(`/alternativeStartSaves`, entry, yield getAuthHeader()); 
    if (res.status === 200) {
      // Post success
      yield put(actions.alternativeStartSavePostSuccess({...entry}));
      // Notification
      yield put(actions.notificationShow(locale.notification_alternative_start_save_added, {name: entry.name}));
    } else {
      yield put(actions.alternativeStartSavePostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeStartSavePostFail(error));
  };
}  

export function* alternativeStartSaveUpdate(action) {
  yield put(actions.alternativeStartSavePostInit());
  try{    
    const {...entry} = action.entry; 
    // Update DB 
    const res = yield apis.put(`/alternativeStartSaves/${entry.key}`, entry, yield getAuthHeader());   
    if (res.status === 200) {
      // Post success
      yield put(actions.alternativeStartSavePostSuccess({...entry}));
      // Notification
      yield put(actions.notificationShow(locale.notification_alternative_start_save_updated, {name: entry.name}));
    } else {
      yield put(actions.alternativeStartSavePostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeStartSavePostFail(error));
  };
}  

export function* alternativeStartSaveDelete(action) {
  yield put(actions.alternativeStartSavePostInit());
  try{    
    const {...entry} = action.entry; 
    // Update DB 
    const res = yield apis.delete(`/alternativeStartSaves/${entry.key}`, yield getAuthHeader());   
    if (res.status === 200) {   
      // Post success
      yield put(actions.alternativeStartSavePostSuccess(entry, true));
      // Notification
      yield put(actions.notificationShow(locale.notification_alternative_start_save_delete, {name: entry.name}));
    } else {
      yield put(actions.alternativeStartSavePostFail(res.data.error));
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeStartSavePostFail(error));
  };
}

export function* alternativeStartCalculate(action) {  
  try {
    let updates = {...action.updates};
    let alternativeStarts = updates.alternativeStarts ? updates.alternativeStarts : yield getState("alternativeStart", "startPeriods");
    let unallocatedAltKeys = updates.unallocatedAltKeys ? updates.unallocatedAltKeys : yield getState("alternativeStart", "unallocatedAltKeys");    
    let budgetCarryOverFlag = updates.budgetCarryOverFlag ? updates.budgetCarryOverFlag : yield getState("alternativeStart", "budgetCarryOverFlag");
    let budgetAllowTransferFlag = updates.budgetAllowTransferFlag ? updates.budgetAllowTransferFlag : yield getState("alternativeStart", "budgetAllowTransferFlag");
    // Get State
    const currentPortfolio = yield getState("portfolio", "currentPortfolio");
    const budgets = yield getState("budget", "entries");
    const alternativeCosts = yield getState("alternativeCost", "entries");
    const selectedOption = yield getState("portfolio", "selectedOption");
    const periods = getPortfolioPhasingPeriods(currentPortfolio);
    const selectedAltKeys = selectedOption ? selectedOption.keys : [];
    
    // Sum and Split Costs By Period    
    const costByPeriods = splitCostByPeriod(periods, alternativeCosts, selectedAltKeys, alternativeStarts, unallocatedAltKeys); 
    // Sum and Split Budgets By Period  // Sum Budgets By Period
    const budgetByPeriods = splitBudgetByPeriod(periods, budgets, budgetCarryOverFlag, budgetAllowTransferFlag, costByPeriods.periodCosts, costByPeriods.periodCostSplits); 
    // Post Success
    yield put(actions.alternativeStartCalculateSuccess(costByPeriods, budgetByPeriods));
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.alternativeStartCalculateFail(error));
  };
}