import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../shared/utility';

const initialState = {
  currentSave: null,
  // Settings
  lockPeriods: {},
  startPeriods: {},
  unallocatedAltKeys: [],
  budgetCarryOverFlag: true,
  budgetAllowTransferFlag: true,
  // Retrieve All
  loadingAll: false,
  errorAll: null,
  entries: null,
  // Post
  loadingPost: false,
  errorPost: null,
  // Calculation
  costByPeriods: {
    periodCosts: {}, 
    periodCapexCosts: {}, 
    periodOpexCosts: {}, 
    periodOtherCosts: {}, 
    alternativePeriodCosts: {}
  },
  budgetByPeriods: {
    periodBudgets: {}, 
    periodActualBudgets: {}, 
    periodCarryOverBudgets: {}
  },
  hasCalculated: false
}
export default (state = initialState, action) => {
  switch (action.type) {    
    // Retrieve All
    case actionTypes.ALTERNATIVE_START_SAVE_RETRIEVE_ALL_INIT:
      return updateObject(state, {entries: null, loadingAll: true, errorAll: null});
    case actionTypes.ALTERNATIVE_START_SAVE_RETRIEVE_ALL_SUCCESS:
      return updateObject(state, {loadingAll: false, entries: action.entries});
    case actionTypes.ALTERNATIVE_START_SAVE_RETRIEVE_ALL_FAIL:
      return updateObject(state, {loadingAll: false, errorAll: action.error});
    // Select
    case actionTypes.ALTERNATIVE_START_SAVE_SELECT:
      return updateObject(state, {currentSave: action.entry});
    // Post
    case actionTypes.ALTERNATIVE_START_SAVE_POST_INIT:
      return updateObject(state, {loadingPost: true, errorPost: null});      
    case actionTypes.ALTERNATIVE_START_SAVE_POST_SUCCESS:     
      return updateEntries(state, action.entry, action.isDelete);
    case actionTypes.ALTERNATIVE_START_SAVE_POST_FAIL:
      return updateObject(state, {loadingPost: false, errorPost: action.error});
    // Start Period Indexes
    case actionTypes.ALTERNATIVE_START_UPDATE_LOCK_PERIOD:
      return updateObject(state, {lockPeriods: {...action.lockPeriods}});
    case actionTypes.ALTERNATIVE_START_UPDATE_START_PERIOD:
      return updateObject(state, {startPeriods: {...action.startPeriods}, unallocatedAltKeys: [...action.unallocatedAltKeys]});
    case actionTypes.ALTERNATIVE_START_UPDATE_BUDGET_CARRY_OVER:
      return updateObject(state, {budgetCarryOverFlag: action.flag});
    case actionTypes.ALTERNATIVE_START_UPDATE_BUDGET_DISTRIBUTE:
      return updateObject(state, {budgetAllowTransferFlag: action.flag});
    // Calculate
    case actionTypes.ALTERNATIVE_START_CALCULATE_SUCCESS:
      return updateObject(state, {hasCalculated: true, costByPeriods: action.costByPeriods, budgetByPeriods: action.budgetByPeriods});
    default:
      return state;
  }
}

const updateEntries = (state, updatedEntry, isDelete) => {
  const newState = {loadingPost: false, errorPost: null};
  // Update state.entries if has  
  if (state.entries) {  
    const editedEntries = {...state.entries};
    if (editedEntries[updatedEntry.key]) {      
      if (isDelete) {
        // Delete
        delete editedEntries[updatedEntry.key];
      } else {
        // Update
        const currentEntry = editedEntries[updatedEntry.key];
        editedEntries[updatedEntry.key] = {...currentEntry, ...updatedEntry};
      }
    } else {
      // Add
      editedEntries[updatedEntry.key] = {...updatedEntry};
    }
    newState.entries = editedEntries;
  }
  // Update current entry as well if match   
  if(state.currentSave && state.currentSave.key === updatedEntry.key) {
    newState.currentSave = {...updatedEntry};
  }  
  return updateObject(state, newState);
}