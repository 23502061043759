import {put} from 'redux-saga/effects';
import {delay} from 'redux-saga';
import {rsf, auth} from 'shared/firebase';
import apis from 'shared/axiosApi';
import * as actions from '../actions/index';
import {userRetrieve} from './user';
import locale from "locale/index";
import {createAuthHeader, sagaLogToSplunk} from 'shared/utility';

const DEFAULT_EXPIRY_TIME = 3600;

export function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime * 1000);
  yield put(actions.authLogout());
}

export function* authFirstLogin(action) {
  yield put(actions.authFirstLoginInit());
  try{    
    const result = yield signInWithEmailLink(action.email, action.url);
    const {user} = result;
    // Set IdToken
    const idToken = yield getAuthIdToken(user);
    yield put(actions.authSetIdToken(idToken));

    // Retrieve User first to check if it's actual first login
    const userData = yield userRetrieve({email: action.email});  
    if (!userData) {
      // User could have been deleted, short circuit out
      yield put(actions.authFirstLoginFail({message: locale.error_user_no_longer_exists}));
      return;
    }

    // Change User Pasword
    yield user.updatePassword(action.newPassword);
    // Retrieve User
    yield put(actions.userRetrieve(user.email));  
    // Set Timeout for admin/facilitator account
    if (userData.role !== 'voter') {  
      const expirationDate = new Date(new Date().getTime() + DEFAULT_EXPIRY_TIME * 1000);
      yield localStorage.setItem('expirationDate', expirationDate);  
      yield put(actions.authCheckTimeout(DEFAULT_EXPIRY_TIME)); 
    }
    // Put Success
    yield put(actions.authLoginSuccess(user.email));
    yield put(actions.authFirstLoginSuccess(action.email));
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.authFirstLoginFail(error));
  };
}

export function* authGuestAccess(action) {
  yield put(actions.authGuestAccessInit());  
  try{ 
    const {portfolio, accessCode} = action;
    const guestIdToken = `${action.email}-${accessCode}-${portfolio.key}-${portfolio.category}`;
    const guest = {
      email: action.email,
      firstName: action.firstName,
      lastName: action.lastName,
      role: "guest",
      voteType: portfolio.category
    }
    // Check if code is correct and if user has already have an account
    const res = yield apis.post(`/guests/${guest.email}/portfolios/${portfolio.key}`, guest, createAuthHeader(guestIdToken));
    if (res.status === 200) {
      const {userId, voterId} = res.data.response;
      // Put guess user in
      yield put(actions.userRetrieveSuccess({...guest, key: userId}));
      // Update Access Portfolio with new voterid      
      yield put(actions.voterPortfolioRetrieveByCodeSuccess({...portfolio, voterId: voterId}));
      // Finally, Put Success
      yield put(actions.authGuestAccessSuccess(action.email));
      // Save IdToken
      yield put(actions.authSetIdToken(guestIdToken));
    } else {
      yield put(actions.authGuestAccessFail(res.data.error));
    }    
  }
  catch(error) {
    // yield sagaLogToSplunk("error", error);
    yield put(actions.authGuestAccessFail(error));
  };
}
  
export function* loginSaga(action) {
  yield put(actions.authLoginInit());   
  try{ 
    const result = yield rsf.auth.signInWithEmailAndPassword(action.email, action.password);  
    const {user} = result; 
    // Set IdToken
    const idToken = yield getAuthIdToken(user);
    yield put(actions.authSetIdToken(idToken));
    // Retrieve User now that we has a token
    const userData = yield put(actions.userRetrieve(user.email));    
    if (!userData) {
      // User could have been deleted, short circuit out
      yield put(actions.authLoginFail({message: locale.error_user_no_longer_exists}));
      return;
    } else if (userData.role !== 'voter') {  
      // Set Timeout for admin/facilitator account
      const expirationDate = new Date(new Date().getTime() + DEFAULT_EXPIRY_TIME * 1000);
      yield localStorage.setItem('expirationDate', expirationDate);  
      yield put(actions.authCheckTimeout(DEFAULT_EXPIRY_TIME)); 
    }
    // Finally, Put Success
    yield put(actions.authLoginSuccess(user.email));
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.authLoginFail(error));
  };
}  

// * turn function to a generate, which can be execute excrementary
// each yield will wait for the prev to finish
export function* logoutSaga(action) {
  yield auth.signOut();
  yield put(actions.authLogoutSuccess())
}

export function* authCheckStateSaga(action) {
  try{ 
    const user = yield getAuthCurrentUser();
    if (!user) {
      yield put(actions.authLogout());
    } else {
      let expired = false;       
      // Check if expirationDate exists and timeout - TODO: should let firebase handle this?
      const expirationDate = yield new Date(localStorage.getItem('expirationDate'));
      if (expirationDate && expirationDate < new Date()) {
        yield put(actions.authLogout());
        expired = true;
      } 
      // Login if not expired 
      if (!expired) {
        // Set IdToken
        const idToken = yield getAuthIdToken(user);  
        yield put(actions.authSetIdToken(idToken));
        // Retrieve User now that we has a token
        const userData = yield put(actions.userRetrieve(user.email)); 
        if (!userData) {
          // User could have been deleted, short circuit out
          yield put(actions.authLoginFail({message: locale.error_user_no_longer_exists}));
          return;
        } else if (expirationDate && userData.role !== 'voter') {   
          // Restart timeout
          yield put(actions.authCheckTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));   
        }
        // Finally, Put Success
        yield put(actions.authLoginSuccess(user.email));  
      }    
    }
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.authLoginFail(error));
  };
}

export function* authResetPwSaga(action) {
  yield put(actions.authResetPwInit());
  try{
    yield rsf.auth.sendPasswordResetEmail(action.email);    
    yield put(actions.authResetPwSuccess(action.email));  
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.authResetPwFail(error));
  };
}

export function* authChangePw(action) {
  yield put(actions.authChangePwInit());  
  try{
    // Relogin first otherwise firebase will error 
    const data = yield rsf.auth.signInWithEmailAndPassword(action.user.email, action.existingPassword);
    // Change PW
    yield data.user.updatePassword(action.newPassword);    
    // Put Success
    yield put(actions.authChangePwSuccess());
  }
  catch(error) {
    yield sagaLogToSplunk("error", error);   
    yield put(actions.authChangePwFail(error));
  };
}  

function getAuthCurrentUser() {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged((user) => {
    if (user) resolve(user);
    else resolve(null);
    });
  });
}

function getAuthIdToken(user) {
  return new Promise((resolve, reject) => {    
    user.getIdToken().then((idToken) => {      
      if (idToken) resolve(idToken);
      else resolve(null);
    });    
  });
}

function signInWithEmailLink(email, url) {
  return new Promise((resolve, reject) => {    
    auth.signInWithEmailLink(email, url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error)
    })    
  });
}