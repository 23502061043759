import React from 'react'
import {FormattedMessage, defineMessages} from 'react-intl';
const pageDashboard = {
  //Page Names
  page_name_admin_dashboard: <FormattedMessage id="page_name_admin_dashboard" defaultMessage="Dashboard" />,
  page_name_admin_vote: <FormattedMessage id="page_name_admin_vote" defaultMessage="Voting" />,
  page_name_admin_vote_short: <FormattedMessage id="page_name_admin_vote_short" defaultMessage="Vote" />,
  page_name_admin_pdf: <FormattedMessage id="page_name_admin_pdf" defaultMessage="PDF Generation" />,
  page_name_admin_vote_pdf: <FormattedMessage id="page_name_admin_vote_pdf" defaultMessage="PDF" />,
  page_name_admin_portfolio: <FormattedMessage id="page_name_admin_portfolio" defaultMessage="Portfolio" />,
  //Tab Headers
  tab_admin_portfolios: <FormattedMessage id="tab_admin_portfolios" defaultMessage="Portfolios" />,
  tab_admin_voters: <FormattedMessage id="tab_admin_voters" defaultMessage="Voters" />,
  tab_admin_settings: <FormattedMessage id="tab_admin_settings" defaultMessage="Settings" />,
  tab_admin_account: <FormattedMessage id="tab_admin_account" defaultMessage="Account" />,
  //Section - Portfolio
  portfolios_heading: <FormattedMessage id="portfolios_heading" defaultMessage="Portfolios in this Account" />,
  portfolios_subheading: <FormattedMessage id="portfolios_subheading" defaultMessage="Current Portfolios" />,
  portfolios_further_info: <FormattedMessage id="portfolios_further_info" defaultMessage="Each Portfolio is a self contained collection of everything you need to solve a single prioritisation problem." />,
  portfolios_add: <FormattedMessage id="portfolios_add" defaultMessage="Create New Portfolio" />,
  portfolios_add_title: <FormattedMessage id="portfolios_add_title" defaultMessage="Add a New Portfolio to get started" />,
  portfolios_edit_title: <FormattedMessage id="portfolios_edit_title" defaultMessage="Edit Portfolio" />,
  portfolios_add_btn_submit: <FormattedMessage id="portfolios_add_btn_submit" defaultMessage="Create New Portfolio" />,
  portfolio_name: <FormattedMessage id="portfolio_name" defaultMessage="Portfolio Name" />,
  portfolio_name_helptext: <FormattedMessage id="portfolio_name_helptext" defaultMessage="What is the name of this portfolio?" />,
  portfolio_goal: <FormattedMessage id="portfolio_goal" defaultMessage="Goal" />,
  portfolio_goal_helptext: <FormattedMessage id="portfolio_goal_helptext" defaultMessage="What is the goal you are trying to achieve in this portfolio?" />,
  portfolio_budget_type: <FormattedMessage id="portfolio_budget_type" defaultMessage="Budget Type" />,
  portfolio_budget_type_simple: <FormattedMessage id="portfolio_budget_type_simple" defaultMessage="Simple" />,
  portfolio_budget_type_simple_helptext: <FormattedMessage id="portfolio_budget_type_simple_helptext" defaultMessage="Cost of choices are calculated using a single measurement value." />,
  portfolio_budget_type_complex: <FormattedMessage id="portfolio_budget_type_complex" defaultMessage="Complex" />,  
  portfolio_budget_type_complex_helptext: <FormattedMessage id="portfolio_budget_type_complex_helptext" defaultMessage="Budget and Cost of choices are combined of OPEX and CAPEX or Other" />,
  portfolio_alternative_evaluation_method: <FormattedMessage id="portfolio_alternative_evaluation_method" defaultMessage="Evaluation Method" />,
  portfolio_alternative_evaluation_method_saw: <FormattedMessage id="portfolio_alternative_evaluation_method_saw" defaultMessage="Simple Additive Weighting" />,
  portfolio_alternative_evaluation_method_topsis: <FormattedMessage id="portfolio_alternative_evaluation_method_topsis" defaultMessage="TOPSIS" />,
  portfolio_alternative_evaluation_method_helptext: <FormattedMessage id="portfolio_alternative_evaluation_method_helptext" defaultMessage="Method to Evaluation each Alternative against a criteria" />,
  portfolio_assessment_type: <FormattedMessage id="portfolio_assessment_type" defaultMessage="Assessment Type" />,
  portfolio_assessment_type_helptext: <FormattedMessage id="portfolio_assessment_type_helptext" defaultMessage="What type of assessement are you completing on this portfolio?" />,
  portfolio_budget_frequency: <FormattedMessage id="portfolio_budget_frequency" defaultMessage="Frequency" />,
  portfolio_budget_frequency_monthly: <FormattedMessage id="portfolio_budget_frequency_monthly" defaultMessage="Monthly" />,
  portfolio_budget_frequency_quarter: <FormattedMessage id="portfolio_budget_frequency_quarter" defaultMessage="Quarter" />,
  portfolio_budget_frequency_annual: <FormattedMessage id="portfolio_budget_frequency_annual" defaultMessage="Annual" />,
  portfolio_budget_start_period: <FormattedMessage id="portfolio_budget_start_period" defaultMessage="Start Period" />,
  portfolio_budget_number_of_period: <FormattedMessage id="portfolio_budget_number_of_period" defaultMessage="Number of Period" />,
  portfolio_copy_select: <FormattedMessage id="portfolio_copy_select" defaultMessage="Source" />,
  portfolio_copy_select_helptext: <FormattedMessage id="portfolio_copy_select_helptext" defaultMessage="Select portfolio to copy from" />,
  portfolio_copy_selections: <FormattedMessage id="portfolio_copy_selections" defaultMessage="Copy Sections" />,
  
  portfolio_copy_budget: <FormattedMessage id="portfolio_copy_budget" defaultMessage="Budget Items" />,
  portfolio_copy_measurement: <FormattedMessage id="portfolio_copy_measurement" defaultMessage="Measurements" />,
  portfolio_copy_criteria: <FormattedMessage id="portfolio_copy_criteria" defaultMessage="Criteria" />,
  portfolio_copy_criteria_voter: <FormattedMessage id="portfolio_copy_criteria_voter" defaultMessage="Criteria Voters" />,
  portfolio_copy_criteria_vote: <FormattedMessage id="portfolio_copy_criteria_vote" defaultMessage="Criteria Vote" />,
  portfolio_copy_alternative: <FormattedMessage id="portfolio_copy_alternative" defaultMessage="Alternatives" />,
  portfolio_copy_alternative_cost: <FormattedMessage id="portfolio_copy_alternative_cost" defaultMessage="Alternative Cost" />,
  portfolio_copy_alternative_rel: <FormattedMessage id="portfolio_copy_alternative_rel" defaultMessage="Alt. Relationship" />,
  portfolio_copy_alternative_voter: <FormattedMessage id="portfolio_copy_alternative_voter" defaultMessage="Alt. Voters" />,
  portfolio_copy_alternative_vote: <FormattedMessage id="portfolio_copy_alternative_vote" defaultMessage="Alternative Vote" />,
  portfolio_copy_selected_alternative: <FormattedMessage id="portfolio_copy_selected_alternative" defaultMessage="Selected Option" />,
  portfolio_copy_scenario: <FormattedMessage id="portfolio_copy_scenario" defaultMessage="Tweak Scenarios" />,
  portfolio_copy_budget_solution: <FormattedMessage id="portfolio_copy_budget_solution" defaultMessage="Budget Solutions" />,
  portfolio_copy_report: <FormattedMessage id="portfolio_copy_report" defaultMessage="Reports" />,

  portfolio_col_name: defineMessages({props: {id: "portfolio_col_name", defaultMessage: "Name"}}),
  portfolio_col_goal: defineMessages({props: {id: "portfolio_col_goal", defaultMessage: "Goal"}}),
  portfolio_col_assessment_type: defineMessages({props: {id: "portfolio_col_assessment_type", defaultMessage: "Assessment Type"}}),
  portfolio_col_live_criteria: defineMessages({props: {id: "portfolio_col_live_criteria", defaultMessage: "Criteria"}}),
  portfolio_col_live_alternative: defineMessages({props: {id: "portfolio_col_live_alternative", defaultMessage: "Alternative"}}),
  portfolio_col_created: defineMessages({props: {id: "portfolio_col_created", defaultMessage: "Created"}}),
  //Section - Voter Group
  user_group: <FormattedMessage id="user_group" defaultMessage="Voter Group" />,
  user_groups_heading: <FormattedMessage id="user_groups_heading" defaultMessage="Voter Groups" />,
  user_groups_subheading: <FormattedMessage id="user_groups_subheading" defaultMessage="Current Voter Groups" />,
  user_groups_further_info: <FormattedMessage id="user_groups_further_info" defaultMessage="Voter groups allow an additional level of control when you analyse your results, they also make it easier when selecting voting members for a portfolio." />,
  user_groups_add: <FormattedMessage id="user_groups_add" defaultMessage="Add New Voter Group" />,
  user_groups_add_title: <FormattedMessage id="user_groups_add_title" defaultMessage="Add a New Voter Group" />,
  user_groups_edit_title: <FormattedMessage id="user_groups_edit_title" defaultMessage="Edit Voter Group" />,
  user_group_delete_error: <FormattedMessage id="user_group_delete_error" defaultMessage="There are existing users in this group. Please remove them and try again." />,
  user_group_add_name: <FormattedMessage id="user_group_add_name" defaultMessage="Group Name" />,
  user_group_add_name_helptext: <FormattedMessage id="user_group_add_name_helptext" defaultMessage="Name of the Group. ie. Senior Executives or Partners" />,
  user_group_add_description: <FormattedMessage id="user_group_add_description" defaultMessage="Description" />,
  user_group_add_description_helptext: <FormattedMessage id="user_group_add_description_helptext" defaultMessage="Group Description" />,
  user_group_select_user: <FormattedMessage id="user_group_select_user" defaultMessage="Select Voter" />,
  user_group_add_btn: <FormattedMessage id="user_group_add_btn" defaultMessage="Create Group" />,
  user_group_add_btn_next: <FormattedMessage id="user_group_add_btn_next" defaultMessage="Create & Next" />,
  user_group_delete_warning: <FormattedMessage id="user_group_delete_warning" defaultMessage="Deleting a Voter Group does not affect its users or their voting permission" />,
  user_group_col_name: defineMessages({props: {id: "user_group_col_name", defaultMessage: "Group Name"}}),
  user_group_col_description: defineMessages({props: {id: "user_group_col_description", defaultMessage: "Description"}}),
  user_group_col_member_count: defineMessages({props: {id: "user_group_col_member_count", defaultMessage: "Member Count"}}),
  //Section - User
  voters_heading: <FormattedMessage id="voters_heading" defaultMessage="Voters" />,
  voters_subheading: <FormattedMessage id="voters_subheading" defaultMessage="Current Voters in This Account" />,
  voters_further_info: <FormattedMessage id="voters_further_info" defaultMessage="Voters are unique to an account, so you can assign any of these voter to vote on any of the portfolios." />,
  guests_heading: <FormattedMessage id="guests_heading" defaultMessage="Guests" />,
  guests_subheading: <FormattedMessage id="guests_subheading" defaultMessage="Current Guests Voters in This Account" />,
  guests_further_info: <FormattedMessage id="guests_further_info" defaultMessage="Guests Voters are users who vote by enter Portfolio's Access Code. Unique identify by the email address they provided." />,
  facilitators_heading: <FormattedMessage id="facilitators_heading" defaultMessage="Facilitators" />,
  facilitators_subheading: <FormattedMessage id="facilitators_subheading" defaultMessage="Current Facilitators in This Account" />,
  facilitators_further_info: <FormattedMessage id="facilitators_further_info" defaultMessage="Facilitators are unique to an account, can be assigned by an admin or another facilitator." />,
  admins_heading: <FormattedMessage id="admins_heading" defaultMessage="Admins" />,
  admins_subheading: <FormattedMessage id="admins_subheading" defaultMessage="Current Admins in This Account" />,
  admins_further_info: <FormattedMessage id="admins_further_info" defaultMessage="Admin role can only be assigned by another admin." />,
  users_add:  defineMessages({props: {id: "users_add", defaultMessage: "Add New {role}"}}),
  users_add_btn_submit:  defineMessages({props: {id: "users_add_btn_submit", defaultMessage: "Create {role}"}}),
  users_add_btn_next: <FormattedMessage id="users_add_btn_next" defaultMessage="Create & Next" />,
  users_add_title:  defineMessages({props: {id: "users_add_title", defaultMessage: "Add a New {role}"}}),
  users_edit_title:  defineMessages({props: {id: "users_edit_title", defaultMessage: "Edit {role}"}}),
  user_first_name: <FormattedMessage id="user_first_name" defaultMessage="First Name" />,
  user_last_name: <FormattedMessage id="user_last_name" defaultMessage="Last Name" />,
  user_email: <FormattedMessage id="user_email" defaultMessage="Email" />,
  user_select_role: <FormattedMessage id="user_select_role" defaultMessage="Role" />,
  user_select_role_helper: <FormattedMessage id="user_select_role_helper" defaultMessage="What is the role and access level of this user." />,
  user_select_group: <FormattedMessage id="user_select_group" defaultMessage="Select Group" />,
  user_col_first_name: defineMessages({props: {id: "user_col_first_name", defaultMessage: "First Name"}}),
  user_col_last_name: defineMessages({props: {id: "user_col_last_name", defaultMessage: "Last Name"}}),
  user_col_email: defineMessages({props: {id: "user_col_email", defaultMessage: "Email"}}),
  user_col_role: defineMessages({props: {id: "user_col_role", defaultMessage: "Role"}}),
  user_col_email_link: defineMessages({props: {id: "user_col_email_link", defaultMessage: "Email Auth Link"}}),
  user_col_send_link: defineMessages({props: {id: "user_col_send_link", defaultMessage: "Resend Auth Link"}}),
  user_col_convert_guest: defineMessages({props: {id: "user_col_convert_guest", defaultMessage: "Register Voter"}}),
  user_delete_warning: <FormattedMessage id="user_delete_warning" defaultMessage="Previously submitted votes of this user will still be available." />,
  //Section - User
  settings_heading: <FormattedMessage id="settings_heading" defaultMessage="Account Settings" />,
  settings_subheading: <FormattedMessage id="settings_subheading" defaultMessage="Current Settings of This Account" />,
  settings_further_info: <FormattedMessage id="settings_further_info" defaultMessage="Customise your account settings here, these will affect on all users of this account" />,
  setting_user_add_auto_email: <FormattedMessage id="setting_user_add_auto_email" defaultMessage="Send authorisation email automatically when adding a new user" />,
  //Section - Account
  accounts_heading: <FormattedMessage id="accounts_heading" defaultMessage="Accounts" />,
  accounts_subheading: <FormattedMessage id="accounts_subheading" defaultMessage="Create Account" />,
  accounts_further_info: <FormattedMessage id="accounts_further_info" defaultMessage="Account contains separate Portfolios and Voters." />,
  accounts_add: <FormattedMessage id="accounts_add" defaultMessage="Add New Account" />,
  accounts_add_btn_submit: <FormattedMessage id="accounts_add_btn_submit" defaultMessage="Create Account" />,
  accounts_add_btn_next: <FormattedMessage id="accounts_add_btn_next" defaultMessage="Create & Next" />,
  accounts_add_title: <FormattedMessage id="accounts_add_title" defaultMessage="Add a New Account - Admin/Facilitator/Voter" />,
  accounts_edit_title: <FormattedMessage id="accounts_edit_title" defaultMessage="Edit Account" />,
  account_name: <FormattedMessage id="account_name" defaultMessage="Name" />,
  account_col_name: defineMessages({props: {id: "account_col_name", defaultMessage: "Name"}}),    
  account_create_message: <FormattedMessage id="account_create_message" defaultMessage="To create, please enter a unique name for the account" />,
  account_default: <FormattedMessage id="account_default" defaultMessage="Set as default account" />,
  account_change: <FormattedMessage id="account_change" defaultMessage="Switch Account" />,
  account_delete_warning: <FormattedMessage id="account_delete_warning" defaultMessage="Portfolios in this account will also be removed." />,
  // Section - Guest Access
  guest_access_code_change: <FormattedMessage id="guest_access_code_change" defaultMessage="Enter Access Code" />,
}
export default pageDashboard;